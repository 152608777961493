import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Theme } from "@mui/material/styles";
import withTheme from "@mui/styles/withTheme";
import { Redirect } from "react-router-dom";
import queryString from "query-string";
import { useLocation } from "react-router";

import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";

import {
  Button,
  Card,
  Div,
  Flex,
  FontIcon,
  PageContainer,
  RouteLink,
} from "Common";
import { Text } from "Common/components/Atoms/Text";

import { APIData } from "utils/apiData";
import {
  actions as usersActions,
  selectors as usersSelectors,
} from "reducers/users";
import HeaderBar from "components/lib/HeaderBar";

export type AcceptInviteProps = {
  theme: Theme;
  currentUser: APIData<any>;
  acceptInvite: APIData<any>;
};

export const AcceptInvite: React.FC<AcceptInviteProps> = ({
  theme,
  currentUser,
  acceptInvite,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const search = location?.search;
  const inviteKey = search && queryString.parse(search).inviteKey;

  const isLoggedIn = currentUser.isFilled && currentUser.success === true;
  const isNotAuthed = currentUser.isFilled && currentUser.success === false;

  // If an invite key is given and the user is logged in, request to accept the
  // invite.
  useEffect(() => {
    if (inviteKey && isLoggedIn) {
      // The result here can be a string or an Array. Make sure we handle both
      // cases.
      if (inviteKey instanceof Array) {
        dispatch(usersActions.acceptInvite({ inviteKey: inviteKey[0] }));
        return;
      }

      dispatch(usersActions.acceptInvite({ inviteKey }));
    }
  }, [dispatch, inviteKey, isLoggedIn]);

  // We must have an API key in the "search" field
  if (!inviteKey) {
    return <Redirect to="/" />;
  }

  // If the "currentUser" request hasn't resolved, then don't render anything.
  // We need this information in order to proceed with accepting invite.
  if (currentUser.isLoading) {
    return null;
  }

  // If the user is unauthed, redirect to signup page so that they can create a
  // new account.
  if (isNotAuthed) {
    return <Redirect to={`/signup${search}`} />;
  }

  // Invite succeeded - redirect to the dashboard.
  if (acceptInvite.isFilled && acceptInvite.success === true) {
    return <Redirect to="/" />;
  }

  // The "accept" request is in progress
  if (acceptInvite.isLoading) {
    return null;
  }

  // The invite key is invalid. Go ahead and let the user know and redirect
  // them.
  return (
    <React.Fragment>
      <HeaderBar />
      <PageContainer>
        <Flex direction="column" justifyContent="center" alignItems="center">
          <Text variant="h1">Invalid Invite Key</Text>
          <Card width="400px" mt="20px" p="20px">
            <Div style={{ textAlign: "center" }}>
              <Flex alignItems="center" justifyContent="center">
                <FontIcon
                  icon={faTimesCircle}
                  color={theme.palette.error.main}
                  size="4x"
                />
              </Flex>
              <Text variant="body1" mt={2}>
                The given invitation key is invalid.
              </Text>
            </Div>
            <RouteLink to="/" textDecoration="none">
              <Button
                variant="contained"
                color="primary"
                mt={2}
                fullWidth={true}
              >
                Continue
              </Button>
            </RouteLink>
          </Card>
        </Flex>
      </PageContainer>
    </React.Fragment>
  );
};

function mapStateToProps(state: any, ownProps: {}) {
  const currentUser = usersSelectors.currentUser(state);
  const acceptInvite = usersSelectors.acceptInvite(state);
  return {
    currentUser,
    acceptInvite,
  };
}

export default withTheme<Theme, React.JSXElementConstructor<AcceptInviteProps>>(
  connect(mapStateToProps)(AcceptInvite)
);
