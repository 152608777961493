import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import cpp from 'react-syntax-highlighter/dist/esm/languages/hljs/cpp';
import go from 'react-syntax-highlighter/dist/esm/languages/hljs/go';
import java from 'react-syntax-highlighter/dist/esm/languages/hljs/java';
import js from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import ts from 'react-syntax-highlighter/dist/esm/languages/hljs/typescript';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import markdown from 'react-syntax-highlighter/dist/esm/languages/hljs/markdown';
import objectiveC from 'react-syntax-highlighter/dist/esm/languages/hljs/objectivec';
import php from 'react-syntax-highlighter/dist/esm/languages/hljs/php';
import phpTemplate from 'react-syntax-highlighter/dist/esm/languages/hljs/php-template';
import python from 'react-syntax-highlighter/dist/esm/languages/hljs/python';
import ruby from 'react-syntax-highlighter/dist/esm/languages/hljs/ruby';
import swift from 'react-syntax-highlighter/dist/esm/languages/hljs/swift';
import yaml from 'react-syntax-highlighter/dist/esm/languages/hljs/yaml';
import dart from 'react-syntax-highlighter/dist/esm/languages/hljs/dart';
import rust from 'react-syntax-highlighter/dist/esm/languages/hljs/rust';
import kotlin from 'react-syntax-highlighter/dist/esm/languages/hljs/kotlin';

const registerLanguage = SyntaxHighlighter.registerLanguage;

export function registerSyntaxLanguages() {
  registerLanguage('cpp', cpp);
  registerLanguage('go', go);
  registerLanguage('java', java);
  registerLanguage('javascript', js);
  registerLanguage('typescript', ts);
  registerLanguage('json', json);
  registerLanguage('kotlin', kotlin);
  registerLanguage('markdown', markdown);
  registerLanguage('objective-c', objectiveC);
  registerLanguage('php', php);
  registerLanguage('php-template', phpTemplate);
  registerLanguage('python', python);
  registerLanguage('ruby', ruby);
  registerLanguage('swift', swift);
  registerLanguage('yaml', yaml);
  registerLanguage('dart', dart);
  registerLanguage('rust', rust);
}

const extensionToLanguageMapping = {
  c: 'cpp',
  css: 'css',
  dart: 'dart',
  go: 'go',
  h: 'cpp',
  html: 'xml',
  java: 'java',
  js: 'javascript',
  json: 'json',
  jsx: 'javascript',
  kotlin: 'kotlin',
  m: 'objective-c',
  md: 'markdown',
  php: 'php',
  phtml: 'php-template',
  py: 'python',
  rb: 'ruby',
  rs: 'rust',
  swift: 'swift',
  ts: 'typescript',
  tsx: 'typescript',
  yaml: 'yaml',
};

export function languageNameForFilePath(filePath) {
  const splitExt = filePath.split('.');
  if (splitExt.length <= 1) {
    return '';
  }
  const ext = splitExt.pop().toLowerCase();
  const language = extensionToLanguageMapping[ext];
  return language ? language : '';
}
