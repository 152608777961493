import React from 'react';
import { Flex } from '../../Atoms/Flex';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';
import { SpacingProps } from '../../../types/MaterialUI';

interface StyledButtonProps {
  padding: string;
  background: string;
  color: string;
  fontWeight: React.CSSProperties['fontWeight'];
  fontSize: React.CSSProperties['fontSize'];
}

const StyledButton = styled.button<StyledButtonProps>`
  padding: ${(props) => props.padding};
  cursor: pointer;
  outline: none;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize};
  width: 100%;
  border: none;
  border-radius: 7px;
`;

export interface Props extends SpacingProps {
  onClickLeftButton: () => void;
  leftButtonLabel: string;
  onClickRightButton: () => void;
  rightButtonLabel: string;
  isLeftActive?: boolean;

  // optional middle parameters for a third button in the middle
  onClickMiddleButton?: () => void;
  middleButtonLabel?: string;
  isMiddleActive?: boolean;
}

const PillButtonComponent: React.FC<Props> = ({
  onClickLeftButton,
  leftButtonLabel,
  onClickRightButton,
  rightButtonLabel,
  isLeftActive,
  onClickMiddleButton,
  middleButtonLabel,
  isMiddleActive,
  ...passThruProps
}: Props) => {
  const theme = useTheme();
  const padding = `${theme.spacing(1)} ${theme.spacing(2)}`;
  const border = `1px solid ${theme.palette.divider}`;
  const activeColor = theme.palette.action.selected;

  return (
    <Flex
      {...passThruProps}
      justifyContent="center"
      dataTest="pillbutton-molecule"
      border={border}
      borderRadius="8px"
      background={theme.palette.background.paper}
    >
      <StyledButton
        onClick={onClickLeftButton}
        padding={padding}
        color={theme.palette.text.primary}
        background={isLeftActive ? activeColor : theme.palette.background.paper}
        fontWeight={theme.typography.fontWeightMedium}
        fontSize={theme.typography.body1.fontSize}
      >
        {leftButtonLabel}
      </StyledButton>
      {middleButtonLabel !== undefined && (
        <StyledButton
          onClick={onClickMiddleButton}
          padding={padding}
          color={theme.palette.text.primary}
          background={
            isMiddleActive ? activeColor : theme.palette.background.paper
          }
          fontWeight={theme.typography.fontWeightMedium}
          fontSize={theme.typography.body1.fontSize}
        >
          {middleButtonLabel}
        </StyledButton>
      )}
      <StyledButton
        onClick={onClickRightButton}
        padding={padding}
        color={theme.palette.text.primary}
        background={
          !isLeftActive && !isMiddleActive
            ? activeColor
            : theme.palette.background.paper
        }
        fontWeight={theme.typography.fontWeightMedium}
        fontSize={theme.typography.body1.fontSize}
      >
        {rightButtonLabel}
      </StyledButton>
    </Flex>
  );
};

PillButtonComponent.displayName = 'PillButton';

export const PillButton = PillButtonComponent;
