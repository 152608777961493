import { all, call, put, select } from "redux-saga/effects";

import Api, { APIResult } from "utils/Api";
import {
  APIData,
  takeAPIRequestSaga,
  IndirectIDAPIRequest,
} from "utils/apiData";
import {
  takePaginationSaga,
  IndirectPaginatedIDAPIRequest,
} from "utils/pagination";
import {
  types,
  actions,
  Product,
  selectors,
  ProblemTemplate,
  CreateProductRequest,
  UpdateProductRequest,
  SubmitChallengeRequest,
  CandidateChallengeStatus,
} from "reducers/challengesV2";
import { Screening } from "reducers/organizationsV2";
import { types as templatesTypes, TemplateRequest } from "reducers/templates";

/******************************************************************************
 * Screening
 *****************************************************************************/
export const getScreening = takeAPIRequestSaga<
  IndirectIDAPIRequest<Screening>,
  Screening
>(types.screenings.GET, ({ id }: IndirectIDAPIRequest<Screening>) =>
  call(Api.get, `/techscreenings/${id}/`)
);

/******************************************************************************
 * Cancel Screening
 *****************************************************************************/
export const cancelScreening = takeAPIRequestSaga<
  IndirectIDAPIRequest<Screening>,
  Screening
>(
  types.screenings.CANCEL,
  ({ id }: IndirectIDAPIRequest<Screening>) =>
    call(Api.post, `/techscreenings/${id}/cancel/`),
  cancelScreeningOnSuccess
);

export function* cancelScreeningOnSuccess(
  request: IndirectIDAPIRequest<Screening>,
  response: APIResult<Screening>
) {
  yield put({
    type: types.screenings.GET.success,
    request,
    response,
  });
}

/******************************************************************************
 * Problem Template
 *****************************************************************************/
export const getProblemTemplate = takeAPIRequestSaga<
  IndirectIDAPIRequest<ProblemTemplate>,
  ProblemTemplate
>(
  types.screenings.PROBLEM_TEMPLATE,
  ({ id }: IndirectIDAPIRequest<ProblemTemplate>) =>
    call(Api.get, `/techscreenings/${id}/problem_template/`),
  getProblemTemplateOnSuccess
);

export function* getProblemTemplateOnSuccess(
  { id }: IndirectIDAPIRequest<ProblemTemplate>,
  { data }: APIResult<ProblemTemplate>
) {
  if (!data) {
    return;
  }

  yield all(
    data.templates.map((template) =>
      put({
        type: templatesTypes.TEMPLATE_VERSION_LATEST.success,
        request: new TemplateRequest({ id: template.template_id }),
        response: {
          url: "",
          errorData: {},
          success: true,
          data: template,
        },
      })
    )
  );
}

/******************************************************************************
 * Download
 *****************************************************************************/
export function* downloadOnSuccess(
  { id }: IndirectIDAPIRequest<Blob>,
  response: APIResult<Blob>
) {
  yield call(Api.downloadResult, response);
}

export const downloadProblem = takeAPIRequestSaga<
  IndirectIDAPIRequest<Blob>,
  Blob
>(
  types.screenings.download.PROBLEM,
  ({ id }: IndirectIDAPIRequest<Blob>) =>
    call(Api.get, `/techscreenings/${id}/problem/`),
  downloadOnSuccess
);

export const downloadSubmission = takeAPIRequestSaga<
  IndirectIDAPIRequest<Blob>,
  Blob
>(
  types.screenings.download.SUBMISSION,
  ({ id }: IndirectIDAPIRequest<Blob>) =>
    call(Api.get, `/techscreenings/${id}/submission/`),
  downloadOnSuccess
);

export const downloadReport = takeAPIRequestSaga<
  IndirectIDAPIRequest<Blob>,
  Blob
>(
  types.screenings.download.REPORT,
  ({ id }: IndirectIDAPIRequest<Blob>) =>
    call(Api.get, `/techscreenings/${id}/report/`),
  downloadOnSuccess
);

/******************************************************************************
 * Product GET/LIST
 *****************************************************************************/
export const getProduct = takeAPIRequestSaga<
  IndirectIDAPIRequest<Product>,
  Product
>(types.products.GET, ({ id }: IndirectIDAPIRequest<Product>) =>
  call(Api.get, `/techscreeningproducts/${id}/`)
);
export const listProducts = takePaginationSaga(
  types.products.LIST,
  (request: IndirectPaginatedIDAPIRequest) => "/techscreeningproducts/",
  selectors.products.pages
);

/******************************************************************************
 * Product Create
 *****************************************************************************/
export const createProduct = takeAPIRequestSaga(
  types.products.CREATE,
  (request?: CreateProductRequest) =>
    call(Api.post, "/techscreeningproducts/", request),
  createProductOnSuccess
);

export function* createProductOnSuccess(
  request: CreateProductRequest,
  response: APIResult<Product>
) {
  const { data } = response;
  if (!data) {
    return;
  }

  const { id } = data;
  yield put({
    type: types.products.GET.success,
    request: new IndirectIDAPIRequest<Product>({ id }),
    response,
  });
}

/******************************************************************************
 * Product Update
 *****************************************************************************/
export const updateProduct = takeAPIRequestSaga(
  types.products.UPDATE,
  (request?: UpdateProductRequest) =>
    call(Api.put, `/techscreeningproducts/${request?.id}/`, request),
  updateProductOnSuccess
);

export function* updateProductOnSuccess(
  request: UpdateProductRequest,
  response: APIResult<Product>
) {
  const { data } = response;
  if (!data) {
    return;
  }

  const { id } = data;
  yield put({
    type: types.products.GET.success,
    request: new IndirectIDAPIRequest<Product>({ id }),
    response,
  });
}

/******************************************************************************
 * Candidate Challenge Status
 *****************************************************************************/
export const candidateStatus = takeAPIRequestSaga<
  IndirectIDAPIRequest<CandidateChallengeStatus>,
  CandidateChallengeStatus
>(
  types.candidate.STATUS,
  ({ id }: IndirectIDAPIRequest<CandidateChallengeStatus>) =>
    call(Api.get, `/candidates/techscreenings/${id}/status`)
);

/******************************************************************************
 * Candidate Challenge Problem
 *****************************************************************************/
export const candidateProblem = takeAPIRequestSaga<
  IndirectIDAPIRequest<ProblemTemplate>,
  ProblemTemplate
>(
  types.candidate.PROBLEM,
  ({ id }: IndirectIDAPIRequest<ProblemTemplate>) =>
    call(Api.get, `/candidates/techscreenings/${id}/problem`),
  getProblemTemplateOnSuccess
);

/******************************************************************************
 * Candidate Challenge Download
 *****************************************************************************/
export const candidateDownload = takeAPIRequestSaga<
  IndirectIDAPIRequest<Blob>,
  Blob
>(
  types.candidate.DOWNLOAD,
  ({ id }: IndirectIDAPIRequest<Blob>) =>
    call(Api.get, `/candidates/techscreenings/${id}/download`),
  candidateDownloadOnSuccess
);

export function* candidateDownloadOnSuccess(
  { id }: IndirectIDAPIRequest<Blob>,
  response: APIResult<Blob>
) {
  yield call(Api.downloadResult, response);

  // Only re-fetch status if we're in the "download" state as we should
  // progress to the "waiting" state as a result.
  const challengeStatus: APIData<CandidateChallengeStatus> = yield select(
    selectors.candidate.status,
    { id }
  );
  if (
    !challengeStatus.isFilled ||
    challengeStatus?.data?.status === "download"
  ) {
    yield put(actions.candidate.status({ id }));
  }
}

/******************************************************************************
 * Candidate Challenge Submit
 *****************************************************************************/
export const candidateSubmit = takeAPIRequestSaga<SubmitChallengeRequest, null>(
  types.candidate.SUBMIT,
  ({ id, submission }: SubmitChallengeRequest) =>
    call(Api.fetchApiRaw, `/candidates/techscreenings/${id}/submit`, {
      method: "POST",
      body: submission,
    }),
  candidateSubmitOnSuccess
);

export function* candidateSubmitOnSuccess(
  { id }: SubmitChallengeRequest,
  response: APIResult<null>
) {
  // Only re-fetch status if we're in the "waiting" state as we should
  // progress to the "submitted" state as a result.
  const challengeStatus: APIData<CandidateChallengeStatus> = yield select(
    selectors.candidate.status,
    { id }
  );
  if (
    !challengeStatus.isFilled ||
    challengeStatus?.data?.status === "waiting"
  ) {
    yield put(actions.candidate.status({ id }));
  }
}

export default all([
  // Screenings
  getScreening,
  cancelScreening,
  getProblemTemplate,
  downloadProblem,
  downloadSubmission,
  downloadReport,

  // Products
  getProduct,
  listProducts,
  createProduct,
  updateProduct,

  // Candidates
  candidateStatus,
  candidateSubmit,
  candidateProblem,
  candidateDownload,
]);
