import { Property } from 'csstype';

export interface TypographyProps {
  fontFamily?: Property.FontFamily;
  fontSize?: Property.FontSize<number>;
  fontStyle?: Property.FontStyle;
  fontWeight?: Property.FontWeight;
  letterSpacing?: Property.LetterSpacing<number>;
  lineHeight?: Property.LineHeight<number>;
  textAlign?: Property.TextAlign;
}

export type TypographyStyles = TypographyProps;

// Unfortunately in Typescript, using the spread operator doesn't remove properties that aren't on the interface
export const TypographyStyleGenerator = (
  props: TypographyProps
): TypographyStyles => ({
  fontFamily: props.fontFamily,
  fontSize: props.fontSize,
  fontStyle: props.fontStyle,
  fontWeight: props.fontWeight,
  letterSpacing: props.letterSpacing,
  lineHeight: props.lineHeight,
  textAlign: props.textAlign,
});

const representative: TypographyProps = {
  fontFamily: undefined,
  fontSize: undefined,
  fontStyle: undefined,
  fontWeight: undefined,
  letterSpacing: undefined,
  lineHeight: undefined,
  textAlign: undefined,
};
export const TypographyPropsKeys = Object.keys(representative);
