import React from 'react';
import MuiTypography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import {
  MuiProps,
  MuiStyleGenerator,
  getElementProps,
} from '../../../types/MaterialUI';
import classNames from 'classnames';

/**
 * `Variant` and `TypographyColor` mirror one-to-one the exact values supported
 * by MuiTypography
 */

export type Variant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'button'
  | 'overline'
  | 'srOnly'
  | 'inherit';

type TypographyColor =
  | 'inherit'
  | 'initial'
  | 'primary'
  | 'secondary'
  | 'textPrimary'
  | 'textSecondary'
  | 'error';

export interface Props extends MuiProps {
  /** A custom color other than primary, secondary, etc. */
  customcolor?: string;

  /**
   * MuiProps overrides
   */

  /** Overriding MuiProps color because Typography is more strongly typed than other MUI types */
  color?: TypographyColor;
  /** Overriding MuiProps display because Typography is more strongly typed than other MUI types */
  display?: 'initial' | 'block' | 'inline';

  /**
   * Typography specific props
   */

  component?: React.ElementType;
  /** If true, the text will have a bottom margin.  */
  gutterBottom?: boolean;
  /** If true, the text will not wrap, but instead will truncate with a text overflow ellipsis.
	Note that text overflow can only happen with block or inline-block level elements (the element needs to have a width in order to overflow). */
  noWrap?: boolean;
  variant?: Variant;
}

const useStyles = makeStyles((theme) => ({
  root: (props: Props) => ({
    ...MuiStyleGenerator(theme, props),
    color: props.customcolor,
  }),
}));

const TypographyComponent = React.forwardRef<any, Props>(
  (props: Props, ref: Ref<any>): JSX.Element => {
    const { customcolor, color, className, ...potentialPassThroughProps } =
      props;
    const { root } = useStyles(props);

    const passThroughProps = getElementProps(potentialPassThroughProps);

    return (
      <MuiTypography
        {...passThroughProps}
        ref={ref}
        className={classNames(root, className)}
        color={color || 'textPrimary'}
        data-test="typography-atom"
      />
    );
  }
);

TypographyComponent.displayName = 'Typography';

export const Typography = TypographyComponent;
