import React, { Suspense, SyntheticEvent, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";

import { retry } from "utils/retry";
import { APIData } from "utils/apiData";
import { actions, selectors } from "reducers/users";

import { Button, Card, ContentLoader, Div, Flex, InputField } from "Common";

import CardSection from "components/lib/CardSection";
import APIRequestFormInput from "components/lib/APIRequestFormInput";
import APIRequestSuccessToast from "components/lib/APIRequestSuccessToast";

const PasswordStrengthBar = React.lazy(() =>
  retry(() => import("react-password-strength-bar"))
);

export type SecurityProps = {
  updatePasswordResults: APIData<any>;
};

export const Security: React.FC<SecurityProps> = ({
  updatePasswordResults,
}) => {
  const dispatch = useDispatch();
  const { isLoading } = updatePasswordResults;
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const newPasswordMatches =
    !newPassword || !newPasswordConfirm || newPassword === newPasswordConfirm;

  const canSubmit = password && newPassword && newPasswordMatches && !isLoading;

  const callback = () => {
    setPassword("");
    setNewPassword("");
    setNewPasswordConfirm("");
  };

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    dispatch(
      actions.updatePassword({
        password,
        newPassword,
        callback,
      })
    );
  };

  // 0 - Clear out the updatePassword results
  useEffect(() => {
    return () => {
      dispatch(actions.clearUpdatePassword());
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <Card display="block" p="30px">
        <CardSection title="Update Password">
          <form onSubmit={onSubmit}>
            <Flex direction="column" justifyContent="center" width="300px">
              <APIRequestFormInput
                id="password"
                results={updatePasswordResults}
                errorField="password"
                placeholder="Current Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                required
              />

              <Div mb={newPassword ? 1 : 2}>
                <APIRequestFormInput
                  id="new_password"
                  mt={2}
                  results={updatePasswordResults}
                  errorField="new_password"
                  placeholder="New Password"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  fullWidth
                  required
                />
                <Suspense
                  fallback={
                    newPassword ? (
                      <Div height={26}>
                        <ContentLoader height="2px" width="300px" />
                      </Div>
                    ) : null
                  }
                >
                  {newPassword && (
                    <PasswordStrengthBar password={newPassword} minLength={8} />
                  )}
                </Suspense>
              </Div>
              <InputField
                id="newPasswordConfirm"
                fullWidth
                placeholder="Confirm New Password"
                type="password"
                value={newPasswordConfirm}
                error={!newPasswordMatches}
                helperText={
                  newPasswordMatches ? null : "Passwords do not match."
                }
                onChange={(e) => setNewPasswordConfirm(e.target.value)}
                required
              />
              <Button
                mt={2}
                variant="contained"
                color="primary"
                type="submit"
                disabled={!canSubmit}
                width="40px"
                p="5px"
              >
                Update
              </Button>
            </Flex>
          </form>
        </CardSection>
      </Card>
      <APIRequestSuccessToast
        results={updatePasswordResults}
        message="Success! Your password has been updated."
      />
    </React.Fragment>
  );
};

function mapStateToProps(state: any, ownProps: {}) {
  const updatePasswordResults = selectors.updatePassword(state);
  return { updatePasswordResults };
}

export default connect(mapStateToProps)(Security);
