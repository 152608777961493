import React from 'react';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';
import {
  ElementProps,
  SpacingProps,
  SpacingPropsKeys,
  SizingProps,
  SizingPropsKeys,
  TypographyProps,
  TypographyPropsKeys,
} from '../../../types/MaterialUI';
import { Spacing, Sizing, Typography } from '../../../styles/MaterialUI';

export interface Props
  extends ElementProps,
    SpacingProps,
    SizingProps,
    TypographyProps {
  placeholder?: string;
  checked?: boolean;
  children?: React.ReactNode;
  type?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const propsNotToForward = [
  ...SpacingPropsKeys,
  ...SizingPropsKeys,
  ...TypographyPropsKeys,
  'theme',
];

const StyledInput = styled.input.withConfig({
  shouldForwardProp: (prop, _) => !propsNotToForward.includes(prop),
})<Props>`
  ${Spacing}
  ${Sizing}
${Typography}
font-size: ${(props) =>
    props.fontSize || props.theme.typography.body1.fontSize};
  color: ${(props) => props.theme.palette.text.primary};
  background-color: ${(props) => props.theme.palette.background.paper};
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  border-radius: 4px;
  &:focus {
    border: 1px solid ${(props) => props.theme.palette.secondary.main} !important;
    outline: none;
    box-shadow: 0 0 1px 4px ${(props) => props.theme.palette.secondary.dim};
    transition: 0.2s;
  }
`;

const InputComponent = React.forwardRef<HTMLInputElement, Props>(
  (props: Props, ref: Ref<HTMLInputElement>): JSX.Element => {
    const theme = useTheme();
    return (
      <StyledInput {...props} theme={theme} ref={ref} data-test="input-atom" />
    );
  }
);

InputComponent.displayName = 'Input';
InputComponent.defaultProps = {
  p: '0 0 0 10px',
  width: '300px',
  height: '38px',
};

export const Input = InputComponent;
