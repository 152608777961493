import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { sizing, spacing } from '@mui/system';
import { TextField, InputAdornment, MenuItem } from '@mui/material';

const StyledTextField = styled(TextField)`
  ${sizing};
  ${spacing};
`;

const useStyles = makeStyles((theme) => {
  return {
    root: (props) => ({
      '& input, & label, & .MuiSelect-select, & textarea': {
        fontSize: props.fontSize || theme.typography.body1.fontSize,
      },
    }),
    menu: {
      zIndex: `${theme.zIndex.snackbar} !important`,
    },
  };
});

export function InputField(props) {
  const {
    shrink,
    startIcon,
    endIcon,
    selectOptions,
    inputProps,
    inputLabelProps,
    formHelperTextProps,
    dataTest,
    ...ownProps
  } = props;
  const classes = useStyles(ownProps);
  return (
    <StyledTextField
      className={classes.root}
      InputLabelProps={{ shrink: shrink, ...inputLabelProps }}
      InputProps={{
        startAdornment: startIcon ? (
          <InputAdornment position="start">{startIcon}</InputAdornment>
        ) : null,
        endAdornment: endIcon ? (
          <InputAdornment position="end">{endIcon}</InputAdornment>
        ) : null,
        ...inputProps,
      }}
      FormHelperTextProps={formHelperTextProps}
      {...ownProps}
      SelectProps={{
        MenuProps: {
          className: classes.menu,
        },
      }}
      data-test={dataTest || 'inputfield-atom'}
    >
      {selectOptions &&
        selectOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </StyledTextField>
  );
}

InputField.defaultProps = {
  variant: 'outlined',
  size: 'small',
  color: 'secondary',
};

InputField.propTypes = {
  /** element id */
  id: PropTypes.string.isRequired,
  /** input label */
  label: PropTypes.string,
  /** specify a variant; leave blank to use default outlined */
  variant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
  /** specify a size; leave blank to use default small */
  size: PropTypes.oneOf(['small', 'normal']),
  /** specify a focus color from theme */
  color: PropTypes.oneOf(['primary', 'secondary']),
  /** optional image or icon to display at start of input */
  startIcon: PropTypes.node,
  /** optional image or icon to display at end of input */
  endIcon: PropTypes.node,
  /** force the label to shrink above the input */
  shrink: PropTypes.bool,
  /** Override or extend the styles applied to the component. See https://material-ui.com/components/text-fields/#customized-inputs */
  classes: PropTypes.object,
  theme: PropTypes.object,
  /** object containing optional InputProps per Material-UI docs */
  inputProps: PropTypes.object,
  /** object containing optional InputLabelProps per Material-UI docs */
  inputLabelProps: PropTypes.object,
  formHelperTextProps: PropTypes.object,
  /** make the input read-only */
  readOnly: PropTypes.bool,
  /** disable input */
  disabled: PropTypes.bool,
  /** turn the input into a select box */
  select: PropTypes.bool,
  /** array of select options */
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  /** Width props also include: minWidth, maxWidth.
   * https://material-ui.com/system/sizing/ */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Height props also include: minHeight, maxHeight
   * https://material-ui.com/system/sizing/ */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Margin; can also use mt, mr, mb, and ml for specific directions */
  m: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Padding; can also use pt, pr, pb, and pl for specific directions */
  p: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  multiline: PropTypes.bool,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoComplete: PropTypes.string,
  onKeyUp: PropTypes.func,
  dataTest: PropTypes.string,
};
