import React from 'react';
import {
  ElementProps,
  SpacingProps,
  SpacingPropsKeys,
  TypographyProps,
  TypographyPropsKeys,
} from '../../../types/MaterialUI';
import { Link as MuiLink } from '@mui/material';
import styled from 'styled-components';
import { Spacing, Typography } from '../../../styles/MaterialUI';

const StyledLink = styled(MuiLink).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !SpacingPropsKeys.includes(prop) &&
    !TypographyPropsKeys.includes(prop) &&
    defaultValidatorFn(prop),
})<Props>`
  && {
    ${Spacing}
    ${Typography}
    color: ${(props) => props.customcolor};
    cursor: pointer;
    text-decoration: ${(props) => props.textDecoration};
  }
`;

type Variant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'button'
  | 'overline';

type Display = 'initial' | 'block' | 'inline';

export type Color =
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'textPrimary'
  | 'textSecondary'
  | 'error';

export interface Props extends ElementProps, SpacingProps, TypographyProps {
  variant?: Variant;
  display?: Display;
  color?: Color;
  customcolor?: string; // added for backwards compatibility
  href?: string;
  target?: string;
  rel?: string;
  /**
   * When href is set and onClick is provided, this boolean prevents default link behavior.
   * Instead of navigating to the href, this invokes only onClick. This allows our links
   * to have an href (e.g. right click) but controlled behavior (e.g. react-router navigation)
   */
  shouldRedirectOnClick?: boolean;
  textDecoration?: string;
}

const LinkComponent = React.forwardRef<any, Props>(
  (props: Props, ref: Ref<any>): JSX.Element => {
    const {
      children,
      color,
      variant,
      display,
      href,
      target,
      rel,
      onClick,
      shouldRedirectOnClick,
      ...passThroughProps
    } = props;

    const colorProp = !props.customcolor ? color || 'secondary' : undefined;
    const relProp =
      rel === undefined
        ? target === '_blank'
          ? 'noopener noreferrer'
          : undefined
        : rel;

    return (
      <StyledLink
        {...passThroughProps}
        ref={ref}
        href={href}
        target={target}
        rel={relProp}
        onClick={
          onClick
            ? (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
                // prevent navigating away onClick if specified
                if (!shouldRedirectOnClick) {
                  event.preventDefault();
                }
                onClick(event);

                return shouldRedirectOnClick;
              }
            : undefined
        }
        variant={variant}
        display={display}
        color={colorProp}
        data-test="link-atom"
      >
        {children}
      </StyledLink>
    );
  }
);

LinkComponent.displayName = 'Link';
LinkComponent.defaultProps = {
  shouldRedirectOnClick: true,
};

export const Link = LinkComponent;
