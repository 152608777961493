/**
 * Package for abstracting out pagination strategies. This takes the form of a
 * state machine of the type PaginatedAPIData that records each page in turn
 * and fills out its fields. The handlePageData function is responsible for
 * manipulating this data.
 *
 * In order to automatically call this function whenever the relevant APIType
 * actions are called, the pageStore function can be used like:
 *
 *  const types = new APITypes("MY_PAGINATED_ACTION");
 *  type MyType = {
 *    id: string;
 *   };
 *  const store = pageStore<PaginatedAPIRequest, MyType>(types);
 *
 * And the resulting store can be passed into combineReducers to maintain the
 * state for the given types. In order to automatically handle requests, the
 * takePaginationSaga function can be used to generate a saga for the
 * redux-saga pagckage like:
 *
 *  takePaginationSaga(types, `/initial/api/path/`, (state: any) => state.path.to.page.data);
 *
 * This library also supports indirect paginated APIs for when you're
 * requesting paginated data for a unique request key. The request type for
 * this must extend IndirectPaginatedAPIRequest and must implement the getKey
 * function:
 *
 *  type MyArgs = PaginatedAPIRequest & { id?: string; };
 *  class MyRequest extends IndirectPaginatedAPIRequest {
 *    id?: string;
 *    constructor(args?: MyArgs) {
 *      super(args);
 *      this.id = id;
 *    }
 *    public getKey(): string | undefined {
 *      return this.id;
 *    }
 *  }
 *
 * Once you have the request, you can create the store:
 *
 *  const store = indirectPageStore<MyRequest, MyType>(types);
 */
import PropTypes from "prop-types";
import { call, put, select, takeEvery } from "redux-saga/effects";

import {
  APITypes,
  APIAction,
  IDAPIArgs,
  Identifier,
  emptyResults,
  storeResults,
  APIResultProp,
} from "./apiData";
import Api, { APIResult } from "./Api";

export type PaginatedAPIResultFilled<T> = {
  next: string;
  results: Array<T>;
};

/**
 * Result from a paginated API. This can either be a filled result with new
 * paginated data or an "any" result in the case of an error as the data could
 * potentially be anything in that case.
 */
export type PaginatedAPIResult<T> = PaginatedAPIResultFilled<T> | any;

/** Type representing the state machine of this package */
export type PaginatedAPIData<T> = {
  pageQueries: Array<string>;
  pageResults: {
    [key: string]: PaginatedAPIResult<T>;
  };
  resultsCount: number;
  success?: boolean;
  nextPage: string | null;
  hasData: boolean;
  updateCount: number;
};

/**
 * Constant containing empty paginated data so a new object doesn't need to be
 * created when it's needed
 */
export const emptyPaginatedAPIData: PaginatedAPIData<any> = {
  pageQueries: [],
  pageResults: {},
  resultsCount: 0,
  nextPage: null,
  hasData: false,
  updateCount: 0,
};

/**
 * Store given results page and return the next pagination state
 *
 * @param stateData - previous state data
 * @param result - new page result
 */
export function handlePageData<T>(
  stateData: PaginatedAPIData<T>,
  result: APIResult<PaginatedAPIResult<T>>
): PaginatedAPIData<T> {
  const pageQueries = stateData.pageQueries || [];
  const pageResults = stateData.pageResults || {};
  const existingPage = pageResults[result.url] || emptyResults;
  const newPage = storeResults(result);
  let nextPage = stateData.nextPage;

  let resultsCount = getResultsCount(stateData);
  const newPageQueries = [...pageQueries];

  // If we haven't already, add this page to our query list
  if (!newPageQueries.includes(result.url)) {
    newPageQueries.push(result.url);
  }

  // Add in the new results
  if (newPage.success) {
    const pageData = newPage.data as PaginatedAPIResultFilled<T>;

    // First, we have to subtract out the old ones
    if (existingPage.success) {
      resultsCount -= existingPage.data.results.length;
    }
    resultsCount += pageData.results.length;

    // If it's the last element, and it was successful, we can use it to set
    // the next page data.
    // TODO: We might want to just always set this value. Go ahead and keep the
    // existing functionality for now until there's more information on its
    // usage.
    if (newPageQueries[newPageQueries.length - 1] === result.url) {
      nextPage = pageData.next;
    }
  }

  return {
    ...stateData,
    pageQueries: newPageQueries,
    pageResults: {
      ...pageResults,
      [result.url]: newPage,
    },
    resultsCount: resultsCount,
    success: newPage.success,
    nextPage,
    hasData: true,
    updateCount: stateData.updateCount + 1,
  };
}

/** Query and return the number of page entries for the given state data */
export function getResultsCount<T>(stateData: PaginatedAPIResult<T>): number {
  return stateData.resultsCount || 0;
}

/** Generate PropTypes of paginated API results for the given shape */
export function paginatedAPIResultsProp(shape: any = {}): any {
  return PropTypes.shape({
    pageQueries: PropTypes.arrayOf(PropTypes.string).isRequired,
    pageResults: PropTypes.objectOf(
      APIResultProp({
        next: PropTypes.string,
        results: PropTypes.arrayOf(PropTypes.shape(shape)),
      })
    ),
    resultsCount: PropTypes.number.isRequired,
    success: PropTypes.bool,
    nextPage: PropTypes.string,
    hasData: PropTypes.bool.isRequired,
  });
}

/**
 * Query paginated result entry data by the given index
 *
 * @param paginatedData - page data structure
 * @param index - index to query
 */
export function getEntryByIndex<T>(
  paginatedData: PaginatedAPIResult<T>,
  index: number
): T | null {
  if (index < 0) {
    return null;
  }
  const { pageQueries } = paginatedData;
  const { pageResults } = paginatedData;
  let pageOffset = 0;
  for (let i in pageQueries) {
    const page = pageQueries[i];
    const resultData = pageResults[page];
    if (!resultData.success) {
      return null;
    }

    const pageData = resultData.data as PaginatedAPIResultFilled<T>;
    const nextOffset = pageOffset + pageData.results.length;
    if (index < nextOffset) {
      return pageData.results[index - pageOffset];
    }
    pageOffset = nextOffset;
  }
  return null;
}

/** Request to fetch a paginated API */
export type PaginatedAPIRequest = {
  /** page to explicitly fetch */
  page?: string;
  /** whether or not to fetch all pages at once */
  fetchAll?: boolean;
  /** reset "next" state and fetch the first page again */
  reset?: boolean;
};

/** Action for requesting paginated APIs */
export type PaginatedAPIAction<R, T> = APIAction<R, PaginatedAPIResult<T>>;

/** Store function for manipulating PaginatedAPIData state */
export type PageStoreFunction<R, T> = (
  state: any,
  action: APIAction<R, T>
) => PaginatedAPIData<T>;

/** Create a PageStoreFunction for the given APITypes */
export function pageStore<R, T>(types: APITypes): PageStoreFunction<R, T> {
  return (
    state: PaginatedAPIData<T> = emptyPaginatedAPIData,
    action: PaginatedAPIAction<R, T>
  ) => {
    switch (action.type) {
      case types.clear: {
        return emptyPaginatedAPIData;
      }

      case types.request:
        // There's nothing to do here as we don't have a "loading" state for
        // paginated data.
        return state;

      case types.failure:
      case types.success: {
        const { response } = action;
        if (!response) {
          return state;
        }

        return handlePageData(state, response);
      }

      default: {
        return state;
      }
    }
  };
}

/** Selector for returning PaginatedAPIData state */
export type PaginationSelector<R, T> = (
  state: any,
  request: R
) => PaginatedAPIData<T>;

/**
 * Create a takeEvery to be used with the redux-saga package for handling
 * paginated API requests.
 */
export function takePaginationSaga<R extends PaginatedAPIRequest, T>(
  types: APITypes,
  getInitialUrl: (request: R) => string,
  selector: PaginationSelector<R, T>
) {
  return takeEvery(
    types.request,

    function* (action: PaginatedAPIAction<R, T>) {
      const { request } = action;
      const initialUrl = getInitialUrl(request);
      const { fetchAll, reset } = request;
      let page = request.page;
      if (!page && !reset) {
        const pages: PaginatedAPIData<T> = yield select(selector, request);

        // There isn't a next page, bail.
        if (pages.hasData && !pages.nextPage) {
          return;
        }
        page = pages.nextPage || initialUrl;
      } else if (reset) {
        page = initialUrl;
      }

      // This conditional is actually superfluous as there's no way for page to
      // be null or undefined at this point. I added it so that Typescript
      // stops complaining about it.
      if (!page) {
        return;
      }

      const response: PaginatedAPIResult<T> = yield call<typeof Api.get>(
        Api.get,
        page
      );
      if (!response.success) {
        yield put({
          type: types.failure,
          request,
          response,
        });
        return;
      }
      yield put({
        type: types.success,
        request,
        response,
      });

      if (fetchAll) {
        const next = response?.data?.next;
        if (next) {
          request.page = next;

          // We wouldn't want to reset on subsequent requests - only the first
          // one.
          request.reset = false;
          yield put({
            type: types.request,
            request,
          });
        }
      }
    }
  );
}

/**
 * Type representing an indirect pagination store mapping a string key to
 * multiple PaginatedAPIData stores
 */
export type IndirectPaginatedAPIData<T> = {
  [key: string]: PaginatedAPIData<T>;
};

/** Empty IndirectPaginatedAPIData */
export const emptyIndirectPaginatedAPIData = {};

/** Type representing an action for an IndirectPageStoreFunction */
export type IndirectPaginatedAPIAction<R extends Identifier, T> = APIAction<
  R,
  PaginatedAPIResult<T>
>;

/** Type representing an IndirectPaginatedAPIData store */
export type IndirectPageStoreFunction<R extends Identifier, T> = (
  state: any,
  action: IndirectPaginatedAPIAction<R, T>
) => IndirectPaginatedAPIData<T>;

/** Class for requesting an indirect paginated API */
export abstract class IndirectPaginatedAPIRequest implements Identifier {
  /** page to explicitly fetch */
  page?: string;
  /** whether or not to fetch all pages at once */
  fetchAll?: boolean;
  /** reset "next" state and fetch the first page again */
  reset?: boolean;

  constructor(args?: PaginatedAPIRequest) {
    this.page = args?.page;
    this.fetchAll = args?.fetchAll;
    this.reset = args?.reset;
  }

  public abstract getKey(): string | undefined;
}

export type IndirectPaginatedIDAPIArgs = PaginatedAPIRequest & IDAPIArgs;

export class IndirectPaginatedIDAPIRequest extends IndirectPaginatedAPIRequest {
  id?: string;

  constructor(args?: IndirectPaginatedIDAPIArgs) {
    super(args);
    this.id = args?.id;
  }

  public getKey(): string | undefined {
    return this.id;
  }
}

export function indirectPaginatedIDAPIAction(typeString: string) {
  return (args?: IndirectPaginatedIDAPIArgs) => ({
    type: typeString,
    request: new IndirectPaginatedIDAPIRequest(args),
  });
}

/**
 * Store for taking indirect paginated actions and modifying indirect paginated
 * API state
 */
export function indirectPageStore<R extends Identifier, T>(
  types: APITypes
): IndirectPageStoreFunction<R, T> {
  const store = pageStore<R, T>(types);
  return (
    state: IndirectPaginatedAPIData<T> = emptyIndirectPaginatedAPIData,
    action: IndirectPaginatedAPIAction<R, T>
  ) => {
    switch (action.type) {
      case types.clear: {
        const { request } = action;
        const key = request.getKey();
        if (!key) {
          return emptyIndirectPaginatedAPIData;
        }

        const paginatedAPIData = state[key] || emptyPaginatedAPIData;
        return {
          ...state,
          [key]: store(paginatedAPIData, action),
        };
      }

      case types.request:
        // There's nothing to do here as we don't have a "loading" state for
        // paginated data.
        return state;

      case types.failure:
      case types.success: {
        const { request, response } = action;
        const key = request.getKey();
        if (!key || !response) {
          return state;
        }

        const paginatedAPIData = state[key] || emptyPaginatedAPIData;
        return {
          ...state,
          [key]: store(paginatedAPIData, action),
        };
      }

      default: {
        return state;
      }
    }
  };
}
