import React from 'react';
import { Flex } from '../../Atoms/Flex';
import { Link, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Tabs, Tab as MuiTab } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import styled from 'styled-components';

const StyledTab = styled(MuiTab)`
  font-size: 16px;
  font-weight: ${(props) => props.theme.typography.fontWeightSemiBold};
  color: ${(props) => props.theme.palette.grey[400]};
  margin-right: ${(props) => props.theme.spacing(3)};
  padding: 0;
  min-width: 0;
  position: relative;
  text-transform: none;
  &:not(.Mui-selected):hover {
    color: ${(props) => props.theme.palette.grey[500]};
    &::after {
      content: '';
      height: 4px;
      width: 100%;
      background: ${(props) => props.theme.palette.secondary.main};
      opacity: 0.5;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
`;

interface TabProps {
  label: React.ReactElement;
  component: React.ElementType;
  disableRipple: boolean;
  to: string;
}

const Tab = (props: TabProps): JSX.Element => {
  const theme = useTheme();
  return <StyledTab {...props} theme={theme} />;
};

Tab.displayName = 'Tab';

interface NavItem {
  to: string;
  title: React.ReactElement;
  component: React.ReactElement;
  exact?: boolean;
}

interface Props {
  items: NavItem[];
  headerPadding?: number;
  hideHeaderBottomMargin?: boolean;
  children?: React.ReactElement;
  onChange?: () => void;
}

const NavTabsComponent: React.FC<Props> = ({
  items,
  headerPadding,
  hideHeaderBottomMargin,
  children,
  onChange,
}: Props) => {
  const theme = useTheme();
  const location = useLocation();
  let activeTabIndex = items.findIndex((item) => item.to === location.pathname);
  // if there's no matching tab, use the first
  if (activeTabIndex < 0) {
    activeTabIndex = 0;
  }

  return (
    <Flex direction="column" height="100%">
      <Flex
        marginBottom={hideHeaderBottomMargin ? 0 : 3}
        p={headerPadding || 0}
        borderBottom={`1px solid ${theme.palette.divider}`}
        justifyContent="space-between"
      >
        <Tabs
          value={activeTabIndex}
          indicatorColor="secondary"
          textColor="secondary"
          TabIndicatorProps={{
            style: {
              height: '4px',
            },
          }}
          onChange={onChange}
        >
          {items.map((item) => (
            <Tab
              key={item.to}
              component={Link}
              to={item.to}
              label={item.title}
              disableRipple
            />
          ))}
        </Tabs>
        {children && <div>{children}</div>}
      </Flex>
      <Switch>
        {items.map((item) => (
          <Route key={item.to} path={item.to} exact={item.exact || false}>
            {item.component}
          </Route>
        ))}
        <Redirect to={items[0].to} />
      </Switch>
    </Flex>
  );
};

NavTabsComponent.displayName = 'NavTabs';

export const NavTabs = NavTabsComponent;
