import React from 'react';

interface Props {
  children: React.ReactNode;
  renderOnError?: React.ReactNode;
  onComponentDidCatch?: (error: Error) => void;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error): void {
    if (this.props.onComponentDidCatch) {
      this.props.onComponentDidCatch(error);
    } else {
      console.error(error);
    }
  }

  public render(): React.ReactNode {
    if (this.state.hasError) {
      return this.props.renderOnError || null;
    }

    return this.props.children;
  }
}
