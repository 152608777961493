import { createTheme, Theme } from '@mui/material/styles';
import {
  red,
  deepPurple,
  green,
  amber,
  orange,
  blueGrey,
} from '@mui/material/colors';
import { Property } from 'csstype';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const typography = {
  fontWeightSemiBold: 600,
  fontFamily: [
    'Open Sans',
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Helvetica Neue',
    'Ubuntu',
    'sans-serif',
  ].join(','),
  display1: {
    fontSize: '2.25rem',
    fontWeight: 400,
    lineHeight: 1.167,
  },
  display2: {
    fontSize: '1.125rem',
    fontWeight: 400,
    lineHeight: 1.2,
  },
  h1: {
    fontSize: '1.75rem',
    fontWeight: 600,
  },
  h2: {
    fontSize: '1.375rem',
    fontWeight: 600,
  },
  h3: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  h4: {
    fontSize: '0.875rem',
    fontWeight: 600,
  },
  h5: {
    fontSize: '0.75rem',
    fontWeight: 600,
  },
  body1: {
    fontSize: '0.875rem',
    fontWeight: 400,
  },
  body2: {
    fontSize: '0.75rem',
    fontWeight: 400,
  },
  subtitle: {
    fontSize: '0.75rem',
    fontWeight: 600,
    textTransform: 'uppercase' as Property.TextTransform,
    lineHeight: 1.334,
  },
  caption: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: 1.66,
  },
};

const shape = {
  borderRadius: 4,
};

export const MuiTheme = createTheme({
  palette: {
    primary: {
      dim: blueGrey[50],
      main: '#27557A',
    },
    secondary: {
      dim: '#e9f4fb',
      main: '#4AA4E0',
      contrastText: '#FFFFFF',
    },
    addition: {
      main: 'rgba(151, 234, 151, 0.25)',
      light: '#acf1bd',
    },
    deletion: {
      main: 'rgba(251, 175, 175, 0.25)',
      light: '#fdb7c0',
    },
    error: {
      dim: red[50],
      main: red[500],
    },
    warning: {
      dim: orange[50],
      main: orange[500],
    },
    highlight: {
      dim: amber[50],
      main: amber[500],
    },
    info: {
      dim: '#e9f4fb',
      main: '#4AA4E0',
    },
    success: {
      dim: green[50],
      main: green[500],
    },
    merged: {
      dim: deepPurple[50],
      main: deepPurple[500],
    },
  },
  typography,
  shape,
  zIndex: {
    overlay: 1400,
    bar: 1000,
  },
});

export const MuiDarkTheme = createTheme({
  palette: {
    mode: 'dark',
    text: {
      primary: '#bac2cc',
      secondary: '#8d96a1',
    },
    background: {
      paper: '#353b44',
      default: '#22272e',
    },
    primary: {
      dim: '#0099ff33',
      main: '#00a3ff',
    },
    secondary: {
      dim: '#0099ff33',
      main: '#00a3ff',
      contrastText: '#FFFFFF',
    },
    addition: {
      main: 'rgba(70, 149, 74, 0.15)',
      light: 'rgba(70, 149, 74, 0.4)',
    },
    deletion: {
      main: 'rgba(229, 83, 75, 0.15)',
      light: 'rgba(229, 83, 75, 0.4)',
    },
    error: {
      dim: '#5c0d0f',
      main: '#e8494d',
    },
    warning: {
      dim: '#643d03',
      main: '#d2820f',
    },
    info: {
      dim: '#0099ff33',
      main: '#00a3ff',
    },
    success: {
      dim: '#0e4e1a',
      main: '#39b354',
    },
    highlight: {
      dim: '#584820',
      main: '#cba033',
    },
    merged: {
      dim: '#26124a',
      main: '#b082ff',
    },
  },
  typography,
  shape,
  zIndex: {
    overlay: 1400,
    bar: 1000,
  },
});
