/** Package for injecting debug into markdown parsing */
const visit = require("unist-util-visit");

export function debug(options = {}) {
  return (tree: any, file: any) => {
    visit(tree, (node: any) => {
      console.log(node);
    });
  };
}
