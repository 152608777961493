import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  types,
  UserAuth,
  selectors,
  InviteInfo,
  CurrentUser,
  ALLOW_LOGIN,
  LogInRequest,
  FeatureFlags,
  InviteRequest,
  SignUpRequest,
  UpdateUserRequest,
  OauthLogInRequest,
  VerifyEmailRequest,
  ResetPasswordRequest,
  UpdatePasswordRequest,
  CreateOrganizationRequest,
  ResetPasswordRequestRequest,
} from "reducers/users";
import Api, { APIResult, setAuthToken, clearAuthToken } from "utils/Api";
import {
  APIData,
  APIAction,
  APIRequest,
  FilledAPIData,
  takeAPIRequestSaga,
} from "utils/apiData";

// Transform the given *currentUser* so that it can be more easily digested
// by the application. For the most part, this function translates the
// feature_flags values from arrays to mappings of the flag text to whether or
// not it's set.
export function transformCurrentUser(currentUser: any): CurrentUser {
  const { feature_flags, organizations } = currentUser;
  return {
    ...currentUser,

    // Simplify user feature flags as a mapping between the flag name
    // and the value "true".
    feature_flags:
      feature_flags &&
      feature_flags.reduce((acc: FeatureFlags, feature_flag: string) => {
        acc[feature_flag] = true;
        return acc;
      }, {}),

    // Modify organizations
    organizations:
      organizations &&
      organizations.map((organization: any) => {
        const { feature_flags } = organization;
        return {
          ...organization,

          // Like with the user feature flags, go ahead and reduce the
          // organization flags to be a mapping of the flag name and the
          // value "true".
          feature_flags:
            feature_flags &&
            feature_flags.reduce((acc: FeatureFlags, feature_flag: string) => {
              acc[feature_flag] = true;
              return acc;
            }, {}),
        };
      }),
  };
}

// Finish the login by setting the authentication *token* and *expiry* in the
// Api module and localstorage. Also, go ahead and set the current user with
// the given *user* data.
export function* finishLogin({ data }: APIResult<UserAuth>) {
  if (!data) {
    return;
  }

  const { user, token, expiry } = data;
  yield all([
    call(setAuthToken, { token, expiry }),
    put({
      type: types.CURRENT_USER.success,
      response: {
        success: true,
        data: transformCurrentUser(user),
      },
    }),
  ]);
}

// Finish the logout by clearing out the authentication token from the Api
// module. Also, set that the current user failed to request. This is to
// trigger the route that takes us back to the /login page.
export function* finishLogout() {
  yield all([
    call(clearAuthToken),
    put({
      type: types.CURRENT_USER.failure,
      response: {
        success: false,
        data: null,
      },
    }),
  ]);
}

export function* signup({ request }: APIAction<SignUpRequest, UserAuth>) {
  const { name, email, password, companyName, inviteKey } = request;
  const response: APIResult<UserAuth> = yield call(
    Api.postUnauthed,
    "/signup",
    {
      name,

      // Let this be null if inviteKey is given as codeportal API doesn't expect
      // a username if organization_invite is given.
      username: inviteKey ? null : email,
      password,
      company_name: companyName,
      organization_invite: inviteKey,
    }
  );
  if (!response.success) {
    // Fail the signup action and mark us as logged out.
    yield all([
      put({
        type: types.SIGNUP.failure,
        response,
      }),

      // If our signup fails, just make sure we know we're logged out.
      call(finishLogout),
    ]);
    return;
  }

  yield all([
    put({
      type: types.SIGNUP.success,
      response,
    }),
    call(finishLogin, response),
  ]);
}

export function* login({ request }: APIAction<LogInRequest, UserAuth>) {
  const { email, password } = request;
  const response: APIResult<UserAuth> = yield call(Api.postUnauthed, "/login", {
    username: email,
    password,
  });
  if (!response.success) {
    // Fail the oauth_login action and mark us as logged out.
    yield all([
      put({
        type: types.LOGIN.failure,
        response,
      }),

      // If our login fails, just make sure we know we're logged out.
      call(finishLogout),
    ]);
    return;
  }

  yield all([
    put({
      type: types.LOGIN.success,
      response,
    }),
    call(finishLogin, response),
  ]);
}

export function* loginWithOauthCallback({
  request,
}: APIAction<OauthLogInRequest, UserAuth>) {
  const { code, state } = request;
  const response: APIResult<UserAuth> = yield call(
    Api.getUnauthed,
    `/oauth/login?code=${code}&state=${state}`
  );
  if (!response.success) {
    // Fail the oauth_login action and mark us as logged out.
    yield all([
      put({
        type: types.OAUTH_LOGIN.failure,
        response,
      }),

      // If our login fails, just make sure we know we're logged out.
      call(finishLogout),
    ]);
    return;
  }

  yield all([
    put({
      type: types.OAUTH_LOGIN.success,
      response,
    }),
    call(finishLogin, response),
  ]);
}

export function* resetPassword({
  request,
}: APIAction<ResetPasswordRequest, UserAuth>) {
  const response: APIResult<UserAuth> = yield call(
    Api.postUnauthed,
    "/user/reset-password",
    request
  );
  if (!response.success) {
    yield put({
      type: types.RESET_PASSWORD.failure,
      response,
    });
    return;
  }

  yield all([
    put({
      type: types.RESET_PASSWORD.success,
      response,
    }),
    call(finishLogin, response),
  ]);
}

export function* logout() {
  const user: APIData<CurrentUser> = yield select(selectors.currentUser);
  const response: APIResult<null> = yield call(Api.post, "/logout");

  // Regardless of whether or not logout succeeded or failed, go ahead and
  // clear out all of our login information and finish the logout. This will
  // remove the token from the user's localstorage.
  const type = response.success ? types.LOGOUT.success : types.LOGOUT.failure;
  yield all([
    put({ type, response }),
    put({ type: types.LOGIN.clear }),
    put({ type: types.OAUTH_LOGIN.clear }),
    call(finishLogout),
  ]);
}

export function* fetchCurrentUser() {
  const response: APIResult<CurrentUser> = yield call(Api.get, "/user");
  if (!response.success) {
    yield put({
      type: types.CURRENT_USER.failure,
      response,
    });
    return;
  }

  const { data } = response;
  yield put({
    type: types.CURRENT_USER.success,
    response: {
      ...response,
      data: transformCurrentUser(data),
    },
  });
}

export function* updateUserInfo({
  request,
}: APIAction<UpdateUserRequest, CurrentUser>) {
  const { name } = request;
  const response: APIResult<CurrentUser> = yield call(Api.put, "/user", {
    profile: { name },
  });
  if (!response.success) {
    yield put({
      type: types.UPDATE_USER.failure,
      response,
    });
    return;
  }

  const { data } = response;
  yield all([
    put({
      type: types.UPDATE_USER.success,
      response,
    }),

    // The current user info was just updated, and we can go ahead and
    // replace it with what we get back from the server.
    put({
      type: types.CURRENT_USER.success,
      response: {
        success: true,
        data: transformCurrentUser(data),
      },
    }),
  ]);
}

export function* updatePassword({
  request,
}: APIAction<UpdatePasswordRequest, UserAuth>) {
  const { password, newPassword, callback } = request;
  const response: APIResult<UserAuth> = yield call(
    Api.post,
    "/user/change-password",
    {
      password,
      new_password: newPassword,
    }
  );
  if (!response.success) {
    yield put({
      type: types.UPDATE_PASSWORD.failure,
      response,
    });
    return;
  }

  yield all([
    put({
      type: types.UPDATE_PASSWORD.success,
      response,
    }),
    call(finishLogin, response),
  ]);

  if (callback) {
    yield call<typeof callback>(callback, response);
  }
}

export function* verifyEmail({
  request,
}: APIAction<VerifyEmailRequest, UserAuth>) {
  const { token } = request;
  const response: APIResult<UserAuth> = yield call(
    Api.post,
    "/user/verify-email",
    { token }
  );
  if (!response.success) {
    yield put({
      type: types.VERIFY_EMAIL.failure,
      response,
    });
    return;
  }

  const { data } = response;
  yield all([
    put({
      type: types.VERIFY_EMAIL.success,
      response,
    }),

    // The current user info was just updated, and we can go ahead and
    // replace it with what we get back from the server.
    put({
      type: types.CURRENT_USER.success,
      response: {
        success: true,
        data: transformCurrentUser(data),
      },
    }),
  ]);
}

export function* resetEmailVerification() {
  const response: APIResult<null> = yield call(
    Api.post,
    "/user/reset-verify-email"
  );
  if (!response.success) {
    yield put({
      type: types.RESET_EMAIL_VERIFICATION.failure,
      response,
    });
    return;
  }

  yield put({
    type: types.RESET_EMAIL_VERIFICATION.success,
    response,
  });
}

export function* inviteInfo({
  request,
}: APIAction<InviteRequest<InviteInfo>, InviteInfo>) {
  const { inviteKey } = request;
  const response: APIResult<InviteInfo> = yield call(
    Api.getUnauthed,
    `/organization_invite/${inviteKey}`
  );
  if (!response.success) {
    yield put({
      type: types.INVITE_INFO.failure,
      response,
    });
    return;
  }

  yield put({
    type: types.INVITE_INFO.success,
    response,
  });
}

export function* acceptInvite({
  request,
}: APIAction<InviteRequest<null>, null>) {
  const { inviteKey } = request;
  const response: APIResult<null> = yield call(
    Api.post,
    `/organization_invite/${inviteKey}`
  );
  if (!response.success) {
    yield put({
      type: types.ACCEPT_INVITE.failure,
      response,
    });
    return;
  }

  yield put({
    type: types.ACCEPT_INVITE.success,
    response,
  });
}

/******************************************************************************
 * Create Organization
 *****************************************************************************/
export const createOrganization = takeAPIRequestSaga<
  CreateOrganizationRequest,
  null
>(
  types.user.CREATE_ORGANIZATION,
  (request?: CreateOrganizationRequest) =>
    call(Api.post, "/organizations/request_new/", request || {}),
  createOrganizationOnSuccess
);

export function* createOrganizationOnSuccess(
  request: CreateOrganizationRequest,
  response: APIResult<null>
) {
  yield put({
    type: types.CURRENT_USER.request,
  });
}

export default all([
  takeEvery(types.CURRENT_USER.request, fetchCurrentUser),
  takeEvery(types.SIGNUP.request, signup),

  takeEvery(types.LOGIN.request, login),
  takeEvery(types.LOGOUT.request, logout),
  takeEvery(types.OAUTH_LOGIN.request, loginWithOauthCallback),

  takeAPIRequestSaga<ResetPasswordRequestRequest, null>(
    types.RESET_PASSWORD_REQUEST,
    ({ email }) =>
      call(Api.postUnauthed, "/user/reset-password-request", { email })
  ),
  takeEvery(types.RESET_PASSWORD.request, resetPassword),

  takeEvery(types.UPDATE_USER.request, updateUserInfo),
  takeEvery(types.UPDATE_PASSWORD.request, updatePassword),
  takeEvery(types.VERIFY_EMAIL.request, verifyEmail),
  takeEvery(types.RESET_EMAIL_VERIFICATION.request, resetEmailVerification),
  takeEvery(types.INVITE_INFO.request, inviteInfo),
  takeEvery(types.ACCEPT_INVITE.request, acceptInvite),

  createOrganization,
]);
