import React from 'react';
import { Div } from '../../Atoms/Div';
import { Text } from '../../Atoms/Text';
import { MarkdownInput } from '../../Molecules/MarkdownInput';
import { CompletedIcon } from '../CompletedIcon';

export type Props = {
  label?: string;
  value?: string;
  onChange?: (value: string) => void;
  isEditing?: boolean;
  onEditStateChange?: (isEditing: boolean) => void;
  editingStorageKey?: string;
  optional?: boolean;
};

export const Markdown: React.FC<Props> = ({
  label,
  value = '',
  onChange,
  isEditing,
  onEditStateChange,
  editingStorageKey,
  optional,
}) => {
  const readonly = !onChange;

  const leftMargin = optional ? 4 : 2;
  return (
    <Div width="100%">
      {label && (
        <Text ml={readonly ? '2px' : 4} mb={1} variant="h3">
          {label}
        </Text>
      )}
      <Div display="flex" alignItems="center">
        {/* TODO: add min limit into completion validation */}
        {!readonly && !optional && <CompletedIcon completed={Boolean(value)} />}
        <Div ml={readonly ? undefined : leftMargin} width="100%">
          <MarkdownInput
            value={value}
            onSave={(input) => onChange && onChange(input)}
            // disable editing state if readonly is true
            isEditing={readonly ? false : isEditing}
            onEditingStateChange={onEditStateChange}
            editingStorageKey={editingStorageKey}
            placeholder={
              optional ? 'Optional feedback...' : 'Leave feedback...'
            }
          />
        </Div>
      </Div>
    </Div>
  );
};
