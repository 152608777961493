import React, { useState, useEffect, useRef } from 'react';
import { Button } from '../../Atoms/Button/index';
import { Flex } from '../../Atoms/Flex/index';
import { FontIcon } from '../../Atoms/FontIcon';
import { faMinus } from '@fortawesome/pro-solid-svg-icons';
import { Theme, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
  digit: {
    background: 'transparent',
    borderRadius: '4px',
    width: '40px',
    height: '52px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: '0.25s',
    fontSize: '24px',
    border: `solid 1px ${theme.palette.grey[300]}`,
    color: theme.palette.text.primary,
  },
  active: {
    boxShadow: `0 0 1px 3px #a5d2f0`,
    border: `1px solid #4aa4e0`,
  },
  input: {
    width: '20px',
    height: '52px',
    position: 'absolute',
    zIndex: 6,
    '& input': {
      border: 'none',
      background: 'transparent',
      outline: 'none',
      fontSize: '24px',
      color: theme.palette.grey[500],
      marginLeft: '10px',
    },
  },
}));

export interface Props {
  handleSubmitCallback: (twoFactorToken: string) => void;
  autoFocus: boolean;
  onChange?: (twoFactorToken: string) => void;
}

const TwoFactorAuthComponent = React.forwardRef<HTMLDivElement, Props>(
  (props: Props, ref: Ref<HTMLDivElement>): JSX.Element => {
    const { handleSubmitCallback, autoFocus, onChange, ...passThroughProps } =
      props;
    const classes = useStyles(props);
    const theme = useTheme();

    const [twoFactorToken, setTwoFactorToken] = useState<string[]>([]);
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const tokenLength = twoFactorToken.length;

    useEffect(() => {
      if (autoFocus && inputRef.current) {
        inputRef.current.focus();
      }
    }, [autoFocus]);

    const updateTwoFactorToken = (token: string[]) => {
      setTwoFactorToken(token);
      if (token.length === 6) {
        handleSubmitCallback(token.join(''));
      }
    };
    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      const input = event.target.value;

      if (!event.target.validity.valid || tokenLength >= 6) {
        return;
      }

      const pastedChars = input.split('');
      const allChars = [...twoFactorToken, ...pastedChars];
      const length = Math.min(6, allChars.length);

      const newToken = allChars.slice(0, length);

      updateTwoFactorToken(newToken);
      if (onChange) {
        onChange(newToken.join(''));
      }
    };

    const handleOtherKeys = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Backspace') {
        const temp = [...twoFactorToken];
        temp.pop();
        updateTwoFactorToken(temp);
        if (onChange) {
          onChange(temp.join(''));
        }
      } else if (event.key === 'Enter' && !isSubmitDisabled()) {
        return handleSubmitCallback(twoFactorToken.join(''));
      }
      return;
    };

    const handleFocus = () => {
      setIsFocused(true);
    };

    const handleBlur = () => {
      setIsFocused(false);
    };

    // Increment/Decrements left padding of the two factor field based on index of input digit.
    const getLeftPadding = () => {
      if (tokenLength === 3) {
        return 53 * tokenLength + 'px';
      } else if (tokenLength === 4) {
        return 50 * tokenLength + 'px';
      } else if (tokenLength === 5) {
        return 48 * tokenLength + 'px';
      } else {
        return 43 * tokenLength + 'px';
      }
    };

    const isSubmitDisabled = () => {
      return tokenLength !== 6;
    };

    return (
      <Flex
        {...passThroughProps}
        ref={ref}
        center
        position="relative"
        dataTest="2fa-molecule"
      >
        <Flex width="284px" justifyContent="space-between">
          {[...Array(6)].map((digit, key) => (
            <React.Fragment key={key}>
              <Flex
                className={classNames(classes.digit, {
                  [classes.active]: key === tokenLength && isFocused,
                })}
                mr={[2, 5].includes(key) ? 0 : '5px'}
                dataTest="2fa-digit"
              >
                {twoFactorToken[key]}
              </Flex>
              {key === 2 && (
                <Flex
                  width="40px"
                  height="52px"
                  justifyContent="center"
                  alignItems="center"
                >
                  <FontIcon
                    icon={faMinus}
                    color={theme.palette.text.secondary}
                  />
                </Flex>
              )}
            </React.Fragment>
          ))}
          <Flex className={classes.input} pl={getLeftPadding()}>
            <input
              id="authInput"
              ref={inputRef}
              type="tel"
              pattern="[0-9]*"
              autoComplete="off"
              onChange={handleInput}
              onKeyDown={handleOtherKeys}
              onBlur={handleBlur}
              onFocus={handleFocus}
              autoFocus={autoFocus}
              value=""
            />
          </Flex>
        </Flex>
        <Button
          variant="contained"
          color="primary"
          disabled={isSubmitDisabled()}
          onClick={() => handleSubmitCallback(twoFactorToken.join(''))}
          ml="20px"
          style={{ zIndex: 7 }}
        >
          Verify
        </Button>
      </Flex>
    );
  }
);

TwoFactorAuthComponent.defaultProps = {
  autoFocus: true,
};
TwoFactorAuthComponent.displayName = 'TwoFactor';

export const TwoFactor = TwoFactorAuthComponent;
