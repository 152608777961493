import React from "react";
import withStyles from "@mui/styles/withStyles";
import withTheme from "@mui/styles/withTheme";
import greenhouseLogo from "resources/greenhouse-logo.svg";
import { Card, Div, Flex, Tooltip } from "Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

const styles = (theme) => {
  return {
    card: {
      "&:hover": {
        backgroundColor: "rgba(74, 164, 224, 0.15)",
      },
    },
  };
};

export function GreenhouseLogo() {
  return (
    <Div width="142px" height="32px">
      <Div
        width="100%"
        height="100%"
        style={{
          background: `transparent url(${greenhouseLogo}) no-repeat`,
        }}
      />
    </Div>
  );
}

export function GreenhouseCard({ classes, theme, installed, onClick }) {
  return (
    <Card m={1} className={classes.card} onClick={onClick}>
      <Tooltip
        title="Integrate code challenges into your Greenhouse applicant pipeline"
        placement="right"
      >
        <Flex
          direction="column"
          width="200px"
          height="200px"
          justifyContent="center"
          center
        >
          <GreenhouseLogo />
          {installed && (
            <Div pt="20px">
              Installed&nbsp;
              <FontAwesomeIcon
                icon={faCheck}
                color={theme.palette.success.main}
                size="1x"
              />
            </Div>
          )}
        </Flex>
      </Tooltip>
    </Card>
  );
}

export default withStyles(styles)(withTheme(GreenhouseCard));
