import { combineReducers } from "redux";
import organizationsV2 from "./organizationsV2";
import organizations from "./organizations";
import challengesV2 from "./challengesV2";
import challenges from "./challenges";
import templates from "./templates";
import users from "./users";

export default combineReducers({
  organizationsV2,
  organizations,
  challengesV2,
  challenges,
  templates,
  users,
});
