import React from 'react';
import { MuiProps, MuiPropsKeys } from '../../../types/MaterialUI';
import styled from 'styled-components';
import { MaterialUI } from '../../../styles/MaterialUI';
import { Theme, useTheme } from '@mui/material/styles';
import classNames from 'classnames';

export type Variant =
  | 'display1'
  | 'display2'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'subtitle'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'inherit';

export interface Props extends MuiProps {
  variant?: Variant;
  /** alternate component to render as */
  component?: React.ElementType<any>;
}

const getTextColor = (theme: Theme, color?: string, defaultColor?: string) => {
  if (!color && !defaultColor) {
    return theme.palette.text.primary;
  }
  if (!color && defaultColor) {
    return defaultColor;
  }
  switch (color) {
    case 'primary':
      return theme.palette.primary.main;
    case 'secondary':
      return theme.palette.secondary.main;
    case 'textPrimary':
      return theme.palette.text.primary;
    case 'textSecondary':
      return theme.palette.text.secondary;
    case 'error':
      return theme.palette.error.main;
    case 'success':
      return theme.palette.success.main;
    case 'warning':
      return theme.palette.warning.main;
    case 'info':
      return theme.palette.info.main;
    default:
      return color;
  }
};

const StyledCaption = styled.p.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !MuiPropsKeys.includes(prop) && defaultValidatorFn(prop),
})<MuiProps>`
  &&& {
    ${MaterialUI}
    color: ${(props) =>
      getTextColor(
        props.theme,
        props.color,
        props.theme.palette.text.secondary
      )};
    font-size: ${(props) =>
      props.fontSize || props.theme.typography.caption.fontSize};
    font-weight: ${(props) =>
      props.fontWeight || props.theme.typography.fontWeightRegular};
    line-height: ${(props) =>
      props.lineHeight || props.theme.typography.caption.lineHeight};
  }
  &.text-atom {
    margin: 0;
  }
`;

const StyledBody1 = styled.p.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !MuiPropsKeys.includes(prop) && defaultValidatorFn(prop),
})<MuiProps>`
  &&& {
    ${MaterialUI}
    color: ${(props) => getTextColor(props.theme, props.color)};
    font-size: ${(props) => props.fontSize || '0.875rem'};
    font-weight: ${(props) =>
      props.fontWeight || props.theme.typography.fontWeightRegular};
    line-height: ${(props) =>
      props.lineHeight || props.theme.typography.body1.lineHeight};
  }
  &.text-atom {
    margin: 0;
  }
`;

const StyledBody2 = styled.p.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !MuiPropsKeys.includes(prop) && defaultValidatorFn(prop),
})<MuiProps>`
  &&& {
    ${MaterialUI}
    color: ${(props) => getTextColor(props.theme, props.color)};
    font-size: ${(props) => props.fontSize || '0.75rem'};
    font-weight: ${(props) =>
      props.fontWeight || props.theme.typography.fontWeightRegular};
    line-height: ${(props) =>
      props.lineHeight || props.theme.typography.body2.lineHeight};
  }
  &.text-atom {
    margin: 0;
  }
`;

const StyledH1 = styled.h1.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !MuiPropsKeys.includes(prop) && defaultValidatorFn(prop),
})<MuiProps>`
  &&& {
    ${MaterialUI}
    color: ${(props) =>
      getTextColor(props.theme, props.color, props.theme.palette.primary.main)};
    font-size: ${(props) => props.fontSize || '1.75rem'};
    font-weight: ${(props) =>
      props.fontWeight || props.theme.typography.fontWeightSemiBold};
    line-height: ${(props) =>
      props.lineHeight || props.theme.typography.h1.lineHeight};
  }
  &.text-atom {
    margin: 0;
  }
`;

const StyledH2 = styled.h2.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !MuiPropsKeys.includes(prop) && defaultValidatorFn(prop),
})<MuiProps>`
  &&& {
    ${MaterialUI}
    color: ${(props) => getTextColor(props.theme, props.color)};
    font-size: ${(props) => props.fontSize || '1.375rem'};
    font-weight: ${(props) =>
      props.fontWeight || props.theme.typography.fontWeightSemiBold};
    line-height: ${(props) =>
      props.lineHeight || props.theme.typography.h2.lineHeight};
  }
  &.text-atom {
    margin: 0;
  }
`;

const StyledH3 = styled.h3.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !MuiPropsKeys.includes(prop) && defaultValidatorFn(prop),
})<MuiProps>`
  &&& {
    ${MaterialUI}
    color: ${(props) => getTextColor(props.theme, props.color)};
    font-size: ${(props) => props.fontSize || '1rem'};
    font-weight: ${(props) =>
      props.fontWeight || props.theme.typography.fontWeightSemiBold};
    line-height: ${(props) =>
      props.lineHeight || props.theme.typography.h3.lineHeight};
  }
  &.text-atom {
    margin: 0;
  }
`;

const StyledH4 = styled.h4.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !MuiPropsKeys.includes(prop) && defaultValidatorFn(prop),
})<MuiProps>`
  &&& {
    ${MaterialUI}
    color: ${(props) => getTextColor(props.theme, props.color)};
    font-size: ${(props) => props.fontSize || '0.875rem'};
    font-weight: ${(props) =>
      props.fontWeight || props.theme.typography.fontWeightSemiBold};
    line-height: ${(props) =>
      props.lineHeight || props.theme.typography.h4.lineHeight};
  }
  &.text-atom {
    margin: 0;
  }
`;

const StyledH5 = styled.h5.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !MuiPropsKeys.includes(prop) && defaultValidatorFn(prop),
})<MuiProps>`
  &&& {
    ${MaterialUI}
    color: ${(props) => getTextColor(props.theme, props.color)};
    font-size: ${(props) => props.fontSize || '0.75rem'};
    font-weight: ${(props) =>
      props.fontWeight || props.theme.typography.fontWeightSemiBold};
    line-height: ${(props) =>
      props.lineHeight || props.theme.typography.h5.lineHeight};
  }
  &.text-atom {
    margin: 0;
  }
`;

const StyledSubtitle = styled.h5.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !MuiPropsKeys.includes(prop) && defaultValidatorFn(prop),
})<MuiProps>`
  &&& {
    ${MaterialUI}
    color: ${(props) =>
      getTextColor(
        props.theme,
        props.color,
        props.theme.palette.text.secondary
      )};
    font-size: ${(props) => props.fontSize || '0.75rem'};
    font-weight: ${(props) =>
      props.fontWeight || props.theme.typography.fontWeightSemiBold};
    line-height: ${(props) =>
      props.lineHeight || props.theme.typography.h5.lineHeight};
    text-transform: uppercase;
  }
  &.text-atom {
    margin: 0;
  }
`;

const StyledDisplay1 = styled.h1.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !MuiPropsKeys.includes(prop) && defaultValidatorFn(prop),
})<MuiProps>`
  &&& {
    ${MaterialUI}
    color: ${(props) => getTextColor(props.theme, props.color)};
    font-size: ${(props) => props.fontSize || '2.25rem'};
    font-weight: ${(props) =>
      props.fontWeight || props.theme.typography.fontWeightRegular};
    line-height: ${(props) =>
      props.lineHeight || props.theme.typography.h1.lineHeight};
  }
  &.text-atom {
    margin: 0;
  }
`;

const StyledDisplay2 = styled.h2.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !MuiPropsKeys.includes(prop) && defaultValidatorFn(prop),
})<MuiProps>`
  &&& {
    ${MaterialUI}
    color: ${(props) => getTextColor(props.theme, props.color)};
    font-size: ${(props) => props.fontSize || '1.125rem'};
    font-weight: ${(props) =>
      props.fontWeight || props.theme.typography.fontWeightRegular};
    line-height: ${(props) =>
      props.lineHeight || props.theme.typography.h2.lineHeight};
  }
  &.text-atom {
    margin: 0;
  }
`;

const TextComponent = React.forwardRef<
  HTMLParagraphElement | HTMLHeadingElement,
  Props
>(
  (
    props: Props,
    ref: Ref<HTMLParagraphElement> | Ref<HTMLHeadingElement>
  ): JSX.Element => {
    const { variant, className, component, ...passThroughProps } = props;
    const theme = useTheme();

    switch (variant) {
      case 'display1':
        return (
          <StyledDisplay1
            theme={theme}
            {...passThroughProps}
            ref={ref}
            className={classNames('text-atom', className)}
            data-test="display1-atom"
            as={component}
          />
        );
      case 'display2':
        return (
          <StyledDisplay2
            theme={theme}
            {...passThroughProps}
            ref={ref}
            className={classNames('text-atom', className)}
            data-test="display2-atom"
            as={component}
          />
        );
      case 'h1':
        return (
          <StyledH1
            theme={theme}
            {...passThroughProps}
            ref={ref}
            className={classNames('text-atom', className)}
            data-test="h1-atom"
            as={component}
          />
        );
      case 'h2':
        return (
          <StyledH2
            theme={theme}
            {...passThroughProps}
            ref={ref}
            className={classNames('text-atom', className)}
            data-test="h2-atom"
            as={component}
          />
        );
      case 'h3':
        return (
          <StyledH3
            theme={theme}
            {...passThroughProps}
            ref={ref}
            className={classNames('text-atom', className)}
            data-test="h3-atom"
            as={component}
          />
        );
      case 'h4':
        return (
          <StyledH4
            theme={theme}
            {...passThroughProps}
            ref={ref}
            className={classNames('text-atom', className)}
            data-test="h4-atom"
            as={component}
          />
        );
      case 'h5':
        return (
          <StyledH5
            theme={theme}
            {...passThroughProps}
            ref={ref}
            className={classNames('text-atom', className)}
            data-test="h5-atom"
            as={component}
          />
        );
      case 'subtitle':
        return (
          <StyledSubtitle
            theme={theme}
            {...passThroughProps}
            ref={ref}
            className={classNames('text-atom', className)}
            data-test="subtitle-atom"
            as={component}
          />
        );
      case 'body1':
        return (
          <StyledBody1
            theme={theme}
            {...passThroughProps}
            ref={ref}
            className={classNames('text-atom', className)}
            data-test="body1-atom"
            as={component}
          />
        );
      case 'body2':
        return (
          <StyledBody2
            theme={theme}
            {...passThroughProps}
            ref={ref}
            className={classNames('text-atom', className)}
            data-test="body2-atom"
            as={component}
          />
        );
      case 'caption':
        return (
          <StyledCaption
            theme={theme}
            {...passThroughProps}
            ref={ref}
            className={classNames('text-atom', className)}
            data-test="caption-atom"
            as={component}
          />
        );
      default:
        return (
          <StyledBody1
            theme={theme}
            {...passThroughProps}
            ref={ref}
            className={classNames('text-atom', className)}
            data-test="body1-atom"
            as={component}
          />
        );
    }
  }
);

TextComponent.displayName = 'Text';

export const Text = TextComponent;
