import React from 'react';
import { spacing } from '@mui/system';
import styled from 'styled-components';
import { Div } from '../../Atoms/Div';
import { Flex } from '../../Atoms/Flex';
import { Text } from '../../Atoms/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchengin } from '@fortawesome/free-brands-svg-icons';
import PropTypes from 'prop-types';
import withTheme from '@mui/styles/withTheme';

const StaticResultDiv = styled(Div)`
  ${spacing};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  border-radius: ${(props) => props.borderRadius};
  background: ${(props) => props.background};
  border: 1px solid ${(props) => props.borderColor};
  margin-bottom: ${(props) => (props.lastElement ? '0px' : '8px')};
`;

export const severityLevels = {
  low: 'Low',
  medium: 'Medium',
  high: 'High',
};

export const severityLevelToNumMap = {
  low: 30,
  medium: 50,
  high: 70,
};

export function calcSeverityLevel(severity) {
  switch (true) {
    case 0 <= severity && severity <= 30:
      return severityLevels.low;
    case 70 <= severity:
      return severityLevels.high;
    default:
      return severityLevels.medium;
  }
}

export function generateStaticResultColors(severity, theme) {
  const severityLevel = calcSeverityLevel(severity);
  switch (severityLevel) {
    case severityLevels.low:
      return theme.palette.secondary.main;
    case severityLevels.high:
      return theme.palette.error.main;
    default:
      return theme.palette.highlight.main;
  }
}

/** @component */
export class StaticResult extends React.Component {
  getBackgroundColor(severity) {
    const { theme } = this.props;
    const severityLevel = calcSeverityLevel(severity);
    switch (severityLevel) {
      case severityLevels.low:
        return theme.palette.secondary.dim;
      case severityLevels.high:
        return theme.palette.error.dim;
      default:
        return theme.palette.highlight.dim;
    }
  }

  render() {
    const {
      resultName,
      resultDescription,
      resultSeverity,
      borderColor,
      engineUsed,
      footer,
      disabled,
      isPending,
      theme,
      ...props
    } = this.props;
    const resultColor = disabled
      ? theme.palette.grey[500]
      : generateStaticResultColors(resultSeverity, theme);
    return (
      <StaticResultDiv
        borderColor={resultColor}
        background={
          isPending
            ? theme.palette.highlight.dim
            : this.getBackgroundColor(resultSeverity)
        }
        {...props}
      >
        <Flex>
          <FontAwesomeIcon icon={faSearchengin} size="2x" color={resultColor} />
          {/** Using substring here to remove outer quotation marks around results */}
          <Flex ml="15px" direction="column" width="100%" minWidth="0">
            <Text variant="h4" mb="3px">
              Automation Result: {resultName}
            </Text>
            <Text variant="body1">{resultDescription}</Text>
            <Flex mt="10px" alignItems="center">
              <Text variant="caption">
                {`Severity Level (${calcSeverityLevel(resultSeverity)})`}
              </Text>
              <Text variant="caption" p="0 3px">
                {' '}
                &#183;{' '}
              </Text>
              <Text variant="caption">{`Powered by ${engineUsed}`}</Text>
            </Flex>
            {footer}
          </Flex>
        </Flex>
      </StaticResultDiv>
    );
  }
}

StaticResult.propTypes = {
  /** Name of the Static Result rule */
  resultName: PropTypes.string,
  /** Description of the Static Result rule */
  resultDescription: PropTypes.string,
  /** Value out of 100 that indicates severity level of the issue. The color of the static result will adjust based on this value. */
  resultSeverity: PropTypes.number,
  /** Indicates the static engine used to generate this result. */
  engineUsed: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  /** Margin; can also use mt, mr, mb, and ml for specific directions */
  m: PropTypes.string,
  /** Padding; can also use pt, pr, pb, and pl for specific directions */
  p: PropTypes.string,
  borderRadius: PropTypes.string,
  footer: PropTypes.element,
  disabled: PropTypes.bool,
  theme: PropTypes.object,
};

StaticResult.defaultProps = {
  m: '0px 10px 8px 10px',
  p: '10px 15px',
  borderRadius: '5px',
};

export default withTheme(StaticResult);
