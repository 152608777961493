import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useTheme } from "@mui/material/styles";

import { APIData } from "utils/apiData";
import { actions, selectors, CurrentUser } from "reducers/users";

import { Card, Div, Flex, Button, Banner } from "Common";
import { Text } from "Common/components/Atoms/Text";

import APIRequestFormInput from "components/lib/APIRequestFormInput";

export type Props = {
  currentUser: CurrentUser;
  createOrganizationResults: APIData<null>;
};

export const Create: React.FC<Props> = ({
  currentUser,
  createOrganizationResults,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [name, setName] = useState(currentUser.profile.company_name || "");
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const onSubmit = () => {
    dispatch(actions.user.createOrganization({ name: name || undefined }));
    setSubmitDisabled(true);
  };

  // 0 - clear the "create organization" state on unmount
  useEffect(() => {
    return () => {
      dispatch(actions.user.clearCreateOrganization());
    };
  }, [dispatch]);

  return (
    <Flex direction="column" justifyContent="center" alignItems="center">
      <Text variant="h1" mt="141px">
        Create your organization
      </Text>
      <Card width="400px" mr="auto" ml="auto" mt="55px" p="32px 38px">
        <Div style={{ textAlign: "center" }}>
          <Text mb="32px">
            Welcome, <b>{currentUser.first_name}</b>!
          </Text>
          <Text mb="32px">
            Create a new organization for sending and managing code challenges
            for your candidates.
          </Text>
          <Text mb="32px">Enter your organization name here:</Text>
          <Div mb="32px">
            <APIRequestFormInput
              id="name"
              results={createOrganizationResults}
              errorField="name"
              placeholder="Your Organization"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Div>
        </Div>
        <Button
          width="100%"
          variant="contained"
          color="primary"
          rel="noopener noreferrer"
          onClick={onSubmit}
          disabled={submitDisabled}
        >
          Create Organization
        </Button>
      </Card>
    </Flex>
  );
};

function mapStateToProps(state: any, ownProps: {}) {
  const createOrganizationResults = selectors.user.createOrganization(state);
  return {
    createOrganizationResults,
  };
}

export default connect(mapStateToProps)(Create);
