import React from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import githubDarkStyle from '../../../../code-rendering/parsers/highlightStyles/githubDarkStyle';
import githubLightStyle from '../../../../code-rendering/parsers/highlightStyles/githubLightStyle';
import { useTheme } from '@mui/material/styles';
import { registerSyntaxLanguages } from '../../../../code-rendering/parsers/fileTypeHelpers';

// Register our minimal number of syntax languages defined in the
// code-rendering package. The check for existence here is because our
// unittests don't register the import as valid - yet the compiled js does.
registerSyntaxLanguages && registerSyntaxLanguages();

interface Input {
  value: string;
  language?: string;
  inline?: boolean;
}

export function CodeBlock({
  value,
  language,
  inline,
}: Input): JSX.Element | null {
  const theme = useTheme();

  // Inline code isn't highlighted. Just use a standard code block.
  if (inline) {
    return React.createElement('code', { inline: true }, value);
  }

  return (
    <SyntaxHighlighter
      language={language}
      style={theme.palette.mode === 'dark' ? githubDarkStyle : githubLightStyle}
    >
      {value}
    </SyntaxHighlighter>
  );
}

CodeBlock.displayName = 'CodeBlock';
