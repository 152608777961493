export default {
  hljs: {
    display: 'block',
    overflowX: 'auto',
    padding: '0.5em',
    color: 'black',
  },
  'hljs-comment': {
    //"color": "#006a00"
  },
  'hljs-quote': {
    color: '#006a00',
  },
  'hljs-keyword': {
    color: '#aa0d91',
  },
  'hljs-selector-tag': {
    color: '#aa0d91',
  },
  'hljs-literal': {
    color: '#aa0d91',
  },
  'hljs-name': {
    color: '#008',
  },
  'hljs-variable': {
    color: '#660',
  },
  'hljs-template-variable': {
    color: '#660',
  },
  'hljs-string': {
    color: '#c41a16',
  },
  'hljs-regexp': {
    color: '#080',
  },
  'hljs-link': {
    color: '#080',
  },
  'hljs-title': {
    color: '#005cc5',
  },
  'hljs-tag': {
    color: '#005cc5',
  },
  'hljs-symbol': {
    color: '#005cc5',
  },
  'hljs-bullet': {
    color: '#005cc5',
  },
  'hljs-number': {
    color: '#005cc5',
  },
  'hljs-meta': {
    color: '#005cc5',
  },
  'hljs-section': {
    color: '#5c2699',
  },
  'hljs-class .hljs-title': {
    color: '#5c2699',
  },
  'hljs-type': {
    color: '#5c2699',
  },
  'hljs-attr': {
    color: '#5c2699',
  },
  'hljs-built_in': {
    color: '#5c2699',
  },
  'hljs-builtin-name': {
    color: '#5c2699',
  },
  'hljs-params': {
    color: '#5c2699',
  },
  'hljs-attribute': {
    color: '#000',
  },
  'hljs-subst': {
    color: '#000',
  },
  'hljs-formula': {
    fontStyle: 'italic',
  },
  'hljs-addition': {},
  'hljs-deletion': {},
  'hljs-selector-id': {
    color: '#9b703f',
  },
  'hljs-selector-class': {
    color: '#9b703f',
  },
  'hljs-doctag': {
    fontWeight: 'bold',
  },
  'hljs-strong': {
    fontWeight: 'bold',
  },
  'hljs-emphasis': {
    fontStyle: 'italic',
  },

  // Pygments style, used for server-rendered code.
  // See Chroma documentation for a description of each class.
  // https://github.com/alecthomas/chroma/blob/e5d9650a20f5a71ead51b9cd995cd98921b8eeaf/types.go#L221
  // TODO: improve base style

  w: {},
  err: {},
  x: {},

  k: { color: '#d73a49' },
  kc: { color: '#d73a49' },
  kd: { color: '#d73a49' },
  kn: { color: '#d73a49' },
  kp: { color: '#d73a49' },
  kr: { color: '#d73a49' },

  kt: { color: '#053c7d' },

  n: { color: '#24292e' },

  na: { color: '#0154b3' },
  nb: { color: '#5c2699' },
  nc: { color: '#5c2699' },

  no: { color: '#24292e' },
  nd: { color: '#24292e' },
  ni: { color: '#24292e' },
  ne: { color: '#24292e' },
  nf: { color: '#005cc5' },
  nl: { color: '#24292e' },
  nn: { color: '#24292e' },
  nx: { color: '#24292e' },
  nt: { color: '#24292e' },
  nv: { color: '#053c7d' },

  bp: { color: '#5c2699' },

  fm: { color: '#008' },

  py: { color: '#008' },

  vc: { color: '#660' },
  vg: { color: '#660' },
  vi: { color: '#660' },
  vm: { color: '#660' },

  l: { color: '#aa0d91' },
  ld: { color: '#aa0d91' },

  s: { color: '#053c7d' },
  sa: { color: '#053c7d' },
  sb: { color: '#053c7d' },
  sc: { color: '#053c7d' },
  sd: { color: '#6a737d' },
  s2: { color: '#053c7d' },
  se: { color: '#053c7d' },
  sh: { color: '#053c7d' },
  si: { color: '#053c7d' },
  sx: { color: '#053c7d' },
  sr: { color: '#053c7d' },
  s1: { color: '#053c7d' },
  ss: { color: '#053c7d' },

  dl: { color: '#c41a16' },

  m: { color: '#005cc5' },
  mb: { color: '#005cc5' },
  mf: { color: '#005cc5' },
  mh: { color: '#005cc5' },
  mi: { color: '#005cc5' },
  il: { color: '#005cc5' },
  mo: { color: '#005cc5' },

  o: { color: '#005cc5' },
  ow: {},

  p: { color: '#005cc5' },

  c: {},
  ch: {},
  cm: {},
  cp: {},
  cpf: {},

  c1: { color: '#6a737d' },

  cs: {},

  g: {},
  gd: {},
  ge: {},
  gr: {},
  gh: {},
  gi: {},
  go: {},
  gp: {},
  gs: {},
  gu: {},
  gt: {},
  gl: {},
};
