import { call, put, select, takeEvery } from "redux-saga/effects";
import { createSelector } from "reselect";

import Api from "utils/Api";
import { emptyResults, loadingResults, storeResults } from "utils/apiData";
import { handlePageMapData, getMapEntries } from "utils/pageMap";
import { emptyPaginatedAPIData } from "utils/pagination";

// Create an object to generate reducer state machine types of the form:
//
//  {
//      clear: <clear_type>,
//      request: <request_type>,
//      success: <success_type>,
//      failure: <failure_type>
//  }
// TODO: Deprecate this in favor of that in "utils/apiData"
export function apiRequestTypes(prefix) {
  const clear = `${prefix}_CLEAR`;
  const request = `${prefix}_REQUEST`;
  const success = `${prefix}_SUCCESS`;
  const failure = `${prefix}_FAILURE`;
  return {
    clear,
    request,
    success,
    failure,
  };
}

// Create a store for global requests
// TODO: Deprecate this in favor of that in "utils/apiData"
export function apiRequestStore(types) {
  return (state = emptyResults, action) => {
    switch (action.type) {
      case types.clear: {
        return emptyResults;
      }

      case types.request: {
        return loadingResults;
      }

      case types.failure:
      case types.success: {
        const { response } = action;
        return storeResults(response);
      }

      default:
        return state;
    }
  };
}

// Create a store for requests to an object. This action takes an "id" and
// maintains a store of results under that id from the state object. Like:
//
//  {
//      <id>: <result>,
//      ...
//  }
// TODO: Deprecate this in favor of that in "utils/apiData"
export function indirectApiRequestStore(types) {
  const store = apiRequestStore(types);
  return (state = {}, action) => {
    switch (action.type) {
      case types.clear: {
        const { id } = action;

        // Only clear out the one ID if given. If not, clear out the whole
        // state
        if (id) {
          return {
            ...state,
            [id]: store({}, action),
          };
        }
        return {};
      }

      case types.request:
      case types.failure:
      case types.success: {
        const { id } = action;
        return {
          ...state,
          [id]: store({}, action),
        };
      }

      default:
        return state;
    }
  };
}

// Generate a selector for selecting an indirect API request from the state
// discovered by *findState.
// TODO: Deprecate this in favor of that in "utils/apiData"
export function indirectApiRequestSelector(findState) {
  return (state, id) => {
    return findState(state)[id] || emptyResults;
  };
}

// Create a takeEvery entry that can be used to handle the "request" action
// type. The *submitRequest* should return a proper redux-saga effect (probably
// the call effect), which returns a response that can be used with the apiData
// library. Depending on whether this call succeeds or fails, the success or
// failure action will be created. If the request succeeds, and a postSuccess
// function is given, go ahead and issue that action.
// TODO: Deprecate this function in favor of that in "utils/apiData"
export function takeApiRequestSaga(types, submitRequest, postSuccess) {
  return takeEvery(
    // Configure the given request to be handled by this saga
    types.request,

    // The body of the handler saga
    function* (action) {
      const { callback } = action;
      const response = yield submitRequest(action);
      if (!response.success) {
        yield put({
          ...action,
          type: types.failure,
          response,
        });
        if (callback) {
          yield call(callback, response);
        }
        return;
      }
      yield put({
        ...action,
        type: types.success,
        response,
      });

      if (postSuccess) {
        yield postSuccess(action, response);
      }

      if (callback) {
        yield call(callback, response);
      }
    }
  );
}

// Create a takeEvery entry that can be used to handle the "request" action
// type for an indirect pagination store. The *initialUrl* should return the
// initial URL that would be queried by a "fresh" request of the underlying
// paginated data. The *selector* should be the selector generated via
// indirectPaginationSelectors.
//
// This saga also handles the *fetchAll* boolean that indicates that all pages
// are to be fetched up front. The *reset* boolean indicates that we should
// re-query all pages starting at the initial page and overwrite the existing
// results.
export function takeIndirectPaginationSaga(types, initialUrl, selector) {
  return takeEvery(
    types.request,

    function* (action) {
      const { id, fetchAll, reset } = action;
      let page = action.page;
      if (!page && !reset) {
        const pages = yield select(selector.pages, id);

        // There isn't a next page, bail.
        if (pages.hasData && !pages.nextPage) {
          return;
        }
        page = pages.nextPage || initialUrl(action);
      } else if (reset) {
        page = initialUrl(action);
      }
      const response = yield call(Api.get, page);
      if (!response.success) {
        yield put({
          type: types.failure,
          id,
          response,
        });
        return;
      }
      yield put({
        type: types.success,
        id,
        response,
      });

      if (fetchAll) {
        const next = response?.data?.next;
        if (next) {
          yield put({
            ...action,
            type: types.request,
            page: next,

            // We wouldn't want to reset on subsequent requests - only the first
            // one.
            reset: false,
          });
        }
      }
    }
  );
}

export const indirectPaginationInitialState = {
  pages: {},
  entries: {},
};

// Generate a store for an indirect pagination schema whereby we're querying
// results for a specific parent object by id in a pagination structure. Then,
// those results come in with their own ids in a pageMap structure. This state
// machine looks like:
//
//  {
//      pages: {
//          parent_id: <pagination>,
//          ...
//      },
//      entries: {
//          id1: <entry1>,
//          id2: <entry2>,
//          ...
//      },
//      ...
//  }
//
// It's main use is when a parent object has a paginated API that returns
// sub-objects. Each parent object has its own set of pages, but each
// sub-object can also be accessed by its unique ID. With this state machine,
// you can utilize the "utils/pagination" library to access the sub-object
// pages and the "utils/pageMap" library to combine the pages and the entries.
export function indirectPaginationStore(types) {
  return (state = indirectPaginationInitialState, action) => {
    switch (action.type) {
      case types.request: {
        const { id } = action;
        const { pages } = state;
        const paginatedData = pages[id] || emptyPaginatedAPIData;
        return {
          ...state,
          pages: {
            ...pages,
            [id]: {
              ...paginatedData,
            },
          },
        };
      }
      case types.failure:
      case types.success: {
        const { id, response } = action;
        const { pages, entries } = state;
        const paginatedData = pages[id];

        // Update our entries page map structure.
        const { pageData, pageMapData } = handlePageMapData(
          paginatedData,
          entries,
          emptyResults,
          response
        );

        return {
          ...state,
          pages: {
            ...pages,
            [id]: pageData,
          },
          entries: pageMapData,
        };
      }

      // TODO: Support requesting and updating single entries - including
      // manually overriding fields.

      default: {
        return state;
      }
    }
  };
}

// Create selectors for querying the various parts of the indirect pagination
// state using the *findState* function to query the top-level state object.
export function indirectPaginationSelectors(findState) {
  // Selector for querying the sub-object pages for the parent object
  // represented by "id"
  const pages = (state, id) => {
    return findState(state).pages[id] || emptyPaginatedAPIData;
  };

  // Selector for querying the entries, which maps the sub-object id to its
  // object data
  const entries = (state) => findState(state).entries || {};

  // Selector for querying a single sub-object entry by its "id"
  const entry = (state, id) => entries(state)[id] || emptyResults;

  // Memoized selector for generating a list of entries in order from the store
  // itself. This selector should be used sparingly - only if we know the set
  // of entries will be fairly small as it has to re-memoize the underlying
  // data every time a page is queried and that page query succeeds or fails.
  const list = createSelector([pages, entries], (pages, entries) =>
    getMapEntries(pages, entries)
  );

  return {
    pages,
    entries,
    entry,
    list,
  };
}
