import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Property } from 'csstype';
import MuiTooltip from '@mui/material/Tooltip';
import classNames from 'classnames';

export type Placement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

export interface Props extends TooltipStyleProps {
  children: React.ReactElement<any, any>;
  title?: string | React.ReactElement<any, any>;
  placement?: Placement;
  followCursor?: boolean;
}

export interface TooltipStyleProps {
  className?: string;
  width?: string | number;
  maxWidth?: string | number;
  fontSize?: string;
  textAlign?: Property.TextAlign;
  color?: string;
  p?: string;
  hideArrow?: boolean;
  children: React.ReactElement<any, any>;
  fontColor?: string;
  title?: string | React.ReactElement<any, any>;
  shadow?: string;
  enterDelayMillis?: number;
}

const useStyles = makeStyles((theme) => ({
  tooltip: (props: Props) => ({
    borderRadius: theme.shape.borderRadius,
    border: '1px solid transparent',
    backgroundColor: props.color || theme.palette.background.paper,
    width: props.width || 'auto',
    maxWidth: props.maxWidth || props.width,
    padding: props.p || `${theme.spacing(1)} ${theme.spacing(2)}`,
    fontSize:
      props.fontSize ||
      (theme.typography.body2 && theme.typography.body2.fontSize),
    textAlign: props.textAlign || 'left',
    boxShadow: props.shadow || theme.shadows[5],
    color: `${props.fontColor || theme.palette.text.primary} !important`,
  }),
  arrow: (props: Props) => ({
    color: props.color || theme.palette.background.paper,
  }),
}));

export function Tooltip(props: Props): JSX.Element {
  const {
    hideArrow,
    placement,
    title,
    children,
    enterDelayMillis,
    className,
    followCursor,
  } = props;
  const classes = useStyles(props);

  return (
    <MuiTooltip
      className={classNames(className)}
      classes={classes}
      arrow={!hideArrow}
      placement={placement}
      title={title || ''}
      enterDelay={enterDelayMillis}
      followCursor={followCursor || false}
    >
      {children}
    </MuiTooltip>
  );
}

export interface ConditionalTooltipProps extends Props {
  showTooltip: boolean;
}

export function ConditionalTooltip(
  props: ConditionalTooltipProps
): JSX.Element {
  const { showTooltip, ...rest } = props;
  return showTooltip ? <Tooltip {...rest} /> : props.children;
}
