import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Theme } from "@mui/material/styles";

import withTheme from "@mui/styles/withTheme";

import { Flex, Modal } from "Common";

import { APIResult } from "utils/Api";
import { APIData } from "utils/apiData";
import { actions, selectors, Template } from "reducers/templates";

import APIRequestFormInput from "components/lib/APIRequestFormInput";
import APIRequestSuccessToast from "components/lib/APIRequestSuccessToast";

export type NewTemplateModalProps = {
  theme: Theme;
  isOpen: boolean;
  onRequestClose: () => void;
  createTemplateResults: APIData<Template>;
};

export const NewTemplateModal: React.FC<NewTemplateModalProps> = ({
  theme,
  isOpen,
  onRequestClose,
  createTemplateResults,
}) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const primaryButtonDisabled = createTemplateResults.isLoading;

  const callback = (result: APIResult<Template>) => {
    if (result.success) {
      onRequestClose();
      setName("");
    }
  };

  const onClickPrimaryButton = () => {
    dispatch(actions.createTemplate({ name, callback }));
  };

  // 0 - Schedule the clearing of "create template" on unmount
  useEffect(() => {
    return () => {
      dispatch(actions.clearCreateTemplate());
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <Modal
        width={400}
        variant="generic"
        title="New Template"
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        primaryButtonLabel="Create"
        primaryButtonColor={theme.palette.secondary.main}
        primaryButtonTextColor={theme.palette.common.white}
        primaryButtonDisabled={primaryButtonDisabled}
        onClickPrimaryButton={onClickPrimaryButton}
      >
        <Flex direction="column">
          <APIRequestFormInput
            m="12px 0 0 0"
            id="name"
            errorField="name"
            results={createTemplateResults}
            fullWidth
            placeholder="template-name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Flex>
      </Modal>
      <APIRequestSuccessToast
        results={createTemplateResults}
        message="Success! A new template was created."
      />
    </React.Fragment>
  );
};

function mapStateToProps(state: any, ownProps: {}) {
  const createTemplateResults = selectors.createTemplate(state);
  return {
    createTemplateResults,
  };
}

export default withTheme<
  Theme,
  React.JSXElementConstructor<NewTemplateModalProps>
>(connect(mapStateToProps)(NewTemplateModal)) as any;
