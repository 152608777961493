import React, { useState } from "react";
import { match as Match, useLocation, useHistory } from "react-router";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  faHome,
  faCog,
  faFileInvoiceDollar,
  faUserFriends,
  faMagic,
} from "@fortawesome/pro-solid-svg-icons";

import { Div, Flex, SidebarNav } from "Common";

import { selectors, UserOrganization } from "reducers/users";

import ChallengeDetail from "./ChallengeDetail";
import OrganizationSettings from "components/settings/organization";
import Challenges from "./Challenges";

export type Props = {
  match: Match;
  organization?: UserOrganization;
  integrationsAllowed: boolean;
};

export const Dashboard: React.FC<Props> = ({
  match,
  organization,
  integrationsAllowed,
}) => {
  const theme = useTheme();

  // TODO: I'd like to not have to pass these down to SidebarNav below. Remove
  // them when that component can handle these hooks on its own.
  const history = useHistory();
  const location = useLocation();

  const [isCollapsed, setIsCollapsed] = useState(false);
  if (!organization) {
    return null;
  }

  const submenu = [
    {
      title: "Usage and Billing",
      url: `${match.path}/settings/billing`,
      icon: faFileInvoiceDollar,
    },
    {
      title: "Membership",
      url: `${match.path}/settings/membership`,
      icon: faUserFriends,
    },
  ];

  if (integrationsAllowed) {
    submenu.push({
      title: "Integrations",
      url: `${match.path}/settings/integrations`,
      icon: faMagic,
    });
  }

  const menuItems = [
    {
      title: "Code Challenges",
      url: `${match.path}/challenges`,
      icon: faHome,
      exact: false,
    },
    {
      title: "Settings",
      url: `${match.path}/settings/`,
      icon: faCog,
      exact: false,
      submenu,
    },
  ];

  return (
    <Flex height="100%" style={{ overflow: "auto" }}>
      <SidebarNav
        homeUrl={match.path}
        headerName={organization.display_name}
        menuItems={menuItems}
        onCollapse={() => setIsCollapsed(!isCollapsed)}
        collapsed={isCollapsed}
        history={history}
        location={location}
      />
      <Div width="100%" style={{ overflow: "auto" }}>
        <Switch>
          <Route
            exact
            path={`${match.path}/challenges`}
            component={Challenges}
          />
          <Route
            exact
            path={`${match.path}/challenges/:challengeId`}
            render={(props) => {
              return <ChallengeDetail organization={organization} {...props} />;
            }}
          />
          <Route
            path={`${match.path}/settings`}
            render={(props) => {
              return (
                <OrganizationSettings
                  organization={organization}
                  allowIntegrations={integrationsAllowed}
                  {...props}
                />
              );
            }}
          />
          <Redirect to={`${match.path}/challenges`} />
        </Switch>
      </Div>
    </Flex>
  );
};

function mapStateToProps(state: any, ownProps: {}) {
  const organization = selectors.user.organization(state);
  const integrationsAllowed = selectors.user.integrationsAllowed(state);

  return {
    organization,
    integrationsAllowed,
  };
}

export default connect(mapStateToProps)(Dashboard);
