import React from 'react';
import { FontIcon } from '../../Atoms/FontIcon';
import { faCircle, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { useTheme } from '@mui/material/styles';

export type Props = {
  completed: boolean;
};

export const CompletedIcon = ({ completed }: Props) => {
  const theme = useTheme();
  return (
    <FontIcon
      icon={completed ? faCheck : faCircle}
      size={completed ? 'sm' : 'xs'}
      color={
        completed ? theme.palette.success.main : theme.palette.warning.main
      }
    />
  );
};
