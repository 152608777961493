import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp as FontAwesomeIconProp } from '@fortawesome/fontawesome-svg-core';
import MuiAlert from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';
import { Snackbar } from '@mui/material';
import {
  getElementProps,
  MuiProps,
  MuiStyleGenerator,
} from '../../../types/MaterialUI';
import classNames from 'classnames';

interface SnackbarProps {
  showToast: boolean;
  vertical?: 'top' | 'bottom';
  horizontal?: 'left' | 'center' | 'right';
  duration?: number;
  handleClose: (event: Event | React.SyntheticEvent<any, Event>) => void;
}

interface AlertProps extends MuiProps {
  color?: 'success' | 'info' | 'warning' | 'error'; // Alert colors are more specific than the rest of MUI
  severity: 'success' | 'info' | 'warning' | 'error';
  message?: string;
  background?: string;
  textcolor?: string;
  icon?: IconProp;
}

export type ToastProps = SnackbarProps & AlertProps;

type IconProp = FontAwesomeIconProp | false | undefined;
type AlertIconProp = React.ReactNode | false | undefined;

// set a custom icon if one is specified
const getAlertIconProp = (icon: IconProp): AlertIconProp => {
  if (icon) {
    return <FontAwesomeIcon icon={icon} />;
  }

  if (icon === false) {
    // false means no icon
    return false;
  }

  // undefined means use the severity level's default icon
  return undefined;
};

const useStyles = makeStyles((theme) => ({
  alert: (props: AlertProps) => ({
    ...MuiStyleGenerator(theme, props),
    backgroundColor: props.background || props.backgroundColor || props.bgcolor,
    color: props.textcolor || props.color,
  }),
  snackbar: {
    zIndex: theme.zIndex.snackbar,
  },
}));

const ToastComponent = (props: ToastProps): JSX.Element => {
  const {
    showToast,
    handleClose,
    severity,
    vertical,
    horizontal,
    message,
    children,
    background,
    textcolor,
    color,
    icon,
    duration,
    className,
    ...potentialPassThroughProps
  } = props;

  const classes = useStyles(props);
  const passThroughProps = getElementProps(potentialPassThroughProps);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: vertical || 'top',
        horizontal: horizontal || 'left',
      }}
      open={showToast}
      autoHideDuration={duration || 5000}
      onClose={handleClose}
      className={classes.snackbar}
      data-test="toast-molecule"
    >
      <MuiAlert
        {...passThroughProps}
        color={color}
        className={classNames(classes.alert, className)}
        icon={getAlertIconProp(icon)}
        onClose={handleClose}
        severity={severity}
        elevation={6}
        variant="filled"
      >
        {message}
        {children}
      </MuiAlert>
    </Snackbar>
  );
};

ToastComponent.displayName = 'Toast';

export const Toast = ToastComponent;
