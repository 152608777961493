/** Package for turning directives into templates */
import { DirectiveType, DirectiveArgumentType } from "./directive";

const visit = require("unist-util-visit");

export function templates(options = {}) {
  return (tree: any, file: any) => {
    visit(tree, DirectiveType, (templateNode: any) => {
      if (templateNode.directive !== "template") {
        return;
      }

      templateNode.type = "template";
      templateNode.variables = {};
      visit(templateNode, DirectiveArgumentType, (arg: any) => {
        if (arg.name === "variable" || arg.name === "var") {
          const toks = arg.value.split("=", 2);
          if (toks.length < 2) {
            return;
          }
          templateNode.variables[toks[0]] = toks[1];
        }
      });
    });
  };
}

export function rehypeTemplate(h: any, node: any) {
  const { variables, value } = node;
  return h(node, "template", { variables, value });
}
