import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import "./index.css";
import App from "components/App";
import * as serviceWorker from "./serviceWorker";
import { MuiTheme } from "Common";
import { Router, Route } from "react-router-dom";
import {
  ThemeProvider as MuiThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import StylesProvider from "@mui/styles/StylesProvider";
import history from "./history";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

ReactDOM.render(
  <StylesProvider injectFirst>
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={MuiTheme}>
        <Provider store={store}>
          <Router history={history}>
            <Route path="/" component={App} />
          </Router>
        </Provider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  </StylesProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
