import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Theme } from "@mui/material/styles";
import withTheme from "@mui/styles/withTheme";
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";

import { Div, Flex, Card, Link, Button, Banner } from "Common";
import { Text } from "Common/components/Atoms/Text";

import { IndirectIDAPIRequest } from "utils/apiData";

import {
  types,
  actions,
  selectors,
  CandidateChallengeStatus,
} from "reducers/challengesV2";

export type DownloadProps = {
  theme: Theme;
  challengeId: string;
  challengeStatus?: CandidateChallengeStatus;
};

export const Download: React.FC<DownloadProps> = ({
  theme,
  challengeId,
  challengeStatus,
}) => {
  const dispatch = useDispatch();
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [showTermsBanner, setShowTermsBanner] = useState(false);

  if (!challengeStatus) {
    // This is an invalid case as this route should only be available if the
    // challenge status has been queried.
    return null;
  }

  const { name, company, product_type } = challengeStatus;
  const onClickDownload = () => {
    if (!agreeToTerms) {
      setShowTermsBanner(true);
      return;
    }

    if (product_type === "view") {
      window.open(`/candidates/codechallenge/${challengeId}/problem`, "_blank");

      // HACK: Override the status so that we advance to the next page. The
      // above "window.open" should open the problem in a new tab. If this
      // doesn't work, the next page still allows the candidate to download the
      // problem.
      dispatch({
        type: types.candidate.STATUS.success,
        request: new IndirectIDAPIRequest({ id: challengeId }),
        response: {
          url: "",
          errorData: {},
          success: true,
          data: {
            ...challengeStatus,
            status: "waiting",
          },
        },
      });
      return;
    }

    dispatch(actions.candidate.download({ id: challengeId }));
  };

  return (
    <Flex direction="column" justifyContent="center" alignItems="center">
      <Text variant="h1" color="primary" mt="141px">
        {product_type === "view"
          ? "View your challenge"
          : "Download your challenge"}
      </Text>
      <Card width="400px" mr="auto" ml="auto" mt="55px" p="32px 38px">
        <Flex direction="column" textAlign="center" center>
          <Text mb="32px">
            Hi, <b>{name}</b>!
          </Text>
          <Text mb="32px">
            {`You've been invited to complete a code challenge for `}
            <b>{company}</b>.
          </Text>
          <Text mb="32px">
            {product_type === "view"
              ? "Click below to get started."
              : "Download the code challenge specifications below to get started."}
          </Text>
          <Flex mb="32px">
            <Div>
              <input
                type="checkbox"
                name="terms"
                id="terms"
                checked={agreeToTerms}
                onChange={() => setAgreeToTerms(!agreeToTerms)}
              />
            </Div>
            <Div>
              <Text variant="caption">
                I agree to the PullRequest code challenge{" "}
                <Link
                  href="https://www.pullrequest.com/terms/"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  Terms of Use
                </Link>
              </Text>
            </Div>
          </Flex>
        </Flex>
        {showTermsBanner && (
          <Banner
            icon={faExclamationCircle}
            color={theme.palette.error.main}
            background={theme.palette.grey[100]}
            mb="20px"
            style={{ padding: "5px 10px" }}
          >
            <Div ml="10px">
              <Text variant="caption" fontSize="12px">
                <b>Oops!</b> You must accept the Terms of Use before proceeding.
              </Text>
            </Div>
          </Banner>
        )}
        <Button
          width="100%"
          variant="contained"
          color="primary"
          rel="noopener noreferrer"
          onClick={onClickDownload}
        >
          {product_type === "view"
            ? "View Code Challenge"
            : "Download Code Challenge"}
        </Button>
      </Card>
    </Flex>
  );
};

export type OwnProps = {
  challengeId: string;
};

function mapStateToProps(state: any, ownProps: OwnProps) {
  const { challengeId } = ownProps;
  const challengeStatus = selectors.candidate.status(state, {
    id: challengeId,
  });
  return {
    challengeId,
    challengeStatus: challengeStatus.isFilled
      ? challengeStatus.data
      : undefined,
  };
}

export default withTheme<Theme, React.JSXElementConstructor<DownloadProps>>(
  connect(mapStateToProps)(Download)
);
