import React from 'react';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';

interface StyledBlockquoteProps {
  borderColor: string;
  color: string;
}

const StyledBlockquote = styled.blockquote<StyledBlockquoteProps>`
  border-left: ${(props) => `0.25em solid ${props.borderColor}`};
  margin: 1em 0;
  padding-left: 1em;
  color: ${(props) => props.color};
`;

export interface Props {
  children?: React.ReactNode;
}

const BlockquoteComponent: React.FC<Props> = (props: Props) => {
  const theme = useTheme();
  return (
    <StyledBlockquote
      borderColor={theme.palette.grey[300]}
      color={theme.palette.text.secondary}
    >
      {props.children}
    </StyledBlockquote>
  );
};

BlockquoteComponent.displayName = 'Blockquote';

export const Blockquote = BlockquoteComponent;
