import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { match as Match } from "react-router";
import { Switch, Route, Redirect } from "react-router-dom";

import { actions } from "reducers/templates";

import ViewTemplate from "./ViewTemplate";
import TemplateList from "./TemplateList";

export type TemplatesProps = {
  match: Match;
  parentPath: string;
};

export const Templates: React.FC<TemplatesProps> = ({ match, parentPath }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(actions.clearTemplates());
      dispatch(actions.clearTemplate());
    };
  }, [dispatch]);

  return (
    <Switch>
      <Route
        exact
        path={match.path}
        render={(props) => {
          return <TemplateList parentPath={parentPath} {...props} />;
        }}
      />
      <Route
        exact
        path={`${match.path}/:templateId`}
        render={(props) => {
          return <ViewTemplate parentPath={parentPath} {...props} />;
        }}
      />
      <Redirect to={match.path} />
    </Switch>
  );
};

export default Templates;
