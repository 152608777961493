import React, { useEffect, useState, useCallback } from 'react';
import { Flex } from '../../Atoms/Flex';
import PropTypes from 'prop-types';
import { MuiTheme as theme } from '../../../theme';
import makeStyles from '@mui/styles/makeStyles';
import { Tabs as MuiTabs, Tab } from '@mui/material';
import Box from '@mui/material/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  tab: {
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightSemiBold,
    color: theme.palette.grey[400],
    marginRight: '20px',
    padding: 0,
    minWidth: 'initial',
    position: 'relative',
    '&:not(.Mui-selected):hover': {
      color: theme.palette.grey[500],
      '&::after': {
        content: "''",
        height: '4px',
        width: '100%',
        background: theme.palette.secondary.main,
        opacity: 0.5,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
  },
}));

export const Tabs = ({
  items,
  headerPadding,
  hideHeaderBottomMargin,
  children,
  onChange,
  selection,
}) => {
  const classes = useStyles({});
  const [selected, setSelected] = useState(selection || 0);
  const [prevSelection, setPrevSelection] = useState(selection);

  const onChangeTab = useCallback(
    (e, selection) => {
      setSelected(selection);
      if (onChange) {
        onChange(e, selection);
      }
    },
    [setSelected, onChange]
  );

  useEffect(() => {
    if (prevSelection !== selection) {
      setPrevSelection(selection);
      onChangeTab(null, selection);
    }
  }, [prevSelection, selection, onChangeTab]);

  return (
    <Flex direction="column" height="100%">
      <Flex
        marginBottom={hideHeaderBottomMargin ? '0px' : '25px'}
        p={headerPadding || 0}
        borderBottom={`1px solid ${theme.palette.grey[300]}`}
        justifyContent="space-between"
      >
        <MuiTabs
          value={selected}
          indicatorColor="secondary"
          textColor="secondary"
          TabIndicatorProps={{
            style: {
              height: '4px',
            },
          }}
          onChange={(e, selection) => onChangeTab(e, selection)}
        >
          {items.map((item, index) => (
            <Tab
              key={index}
              label={item.title}
              disableRipple
              className={classes.tab}
            />
          ))}
        </MuiTabs>
        {children && <div>{children}</div>}
      </Flex>
      {items.map((item, index) => (
        <TabPanel key={index} value={selected} index={index}>
          {item.component}
        </TabPanel>
      ))}
    </Flex>
  );
};

Tabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.object.isRequired,
      ]),
      exact: PropTypes.bool,
      component: PropTypes.object.isRequired,
    })
  ).isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  /** Used to remove the default bottom margin spacing from the TabsHeader component above */
  hideHeaderBottomMargin: PropTypes.bool,
  /** Used to inject custom padding to the TabsHeader */
  headerPadding: PropTypes.string,
  onChange: PropTypes.func,
  selection: PropTypes.number,
};
