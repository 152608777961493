import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Switch, Redirect, Route } from "react-router-dom";

import {
  actions as organizationsActions,
  selectors as organizationsSelectors,
} from "reducers/organizations";

import Greenhouse from "./Greenhouse";
import Integrations from "./Integrations";

export class IntegrationsRoute extends PureComponent {
  componentDidMount() {
    const { organization, fetchIntegrationsForOrgPage } = this.props;
    fetchIntegrationsForOrgPage({
      id: organization.id,
      fetchAll: true,
    });
  }
  render() {
    const { organization, match, greenhouseInstalled } = this.props;

    return (
      <Switch>
        <Route
          exact
          path={match.path}
          render={(props) => {
            return (
              <Integrations
                organization={organization}
                greenhouseInstalled={greenhouseInstalled}
              />
            );
          }}
        />

        <Route
          exact
          path={`${match.path}/greenhouse`}
          render={(props) => {
            return (
              <Greenhouse
                organization={organization}
                greenhouseInstalled={greenhouseInstalled}
              />
            );
          }}
        />

        <Redirect to={match.path} />
      </Switch>
    );
  }
}

IntegrationsRoute.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  organization: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  fetchIntegrationsForOrgPage: PropTypes.func.isRequired,
};

export function mapStateToProps(state, ownProps) {
  const { organization } = ownProps;
  return {
    greenhouseInstalled: organizationsSelectors.greenhouseInstalled(
      state,
      organization.id
    ),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(organizationsActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationsRoute);
