import React from "react";
import PropTypes from "prop-types";
import { Div } from "Common";
import { Text } from "Common/components/Atoms/Text";
import withTheme from "@mui/styles/withTheme";

const CardSection = (props) => {
  const { theme, title, children, mb } = props;
  return (
    <Div mb={mb}>
      <Text
        variant="h3"
        mb="20px"
        pb={1}
        borderBottom={`solid 1px ${theme.palette.divider}`}
      >
        {title}
      </Text>
      {children}
    </Div>
  );
};

CardSection.defaultProps = {
  mb: "40px",
};

CardSection.propTypes = {
  mb: PropTypes.string,
  classes: PropTypes.object,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default withTheme(CardSection);
