import React, { useState, useEffect } from "react";
import { match as Match, useHistory } from "react-router";
import { connect, useDispatch } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { faComment } from "@fortawesome/pro-regular-svg-icons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";

import { IndirectAPIData } from "utils/apiData";
import { getMapEntryByIndex } from "utils/pageMapV2";
import { PaginatedAPIData } from "utils/pagination";
import { selectors as usersSelectors, UserOrganization } from "reducers/users";
import {
  actions as challengesActions,
  selectors as challengesSelectors,
  ScreeningKey,
} from "reducers/challengesV2";
import {
  actions as organizationsActions,
  selectors as organizationsSelectors,
  Screening,
} from "reducers/organizationsV2";

import {
  Div,
  Card,
  Banner,
  Button,
  FontIcon,
  PageContainer,
  InfiniteLoadingTable,
} from "Common";
import { Text } from "Common/components/Atoms/Text";

import ChallengeCell from "./ChallengeCell";
import ChallengeOrderModal from "../ChallengeOrderModal";

const TABLE_ROW_HEIGHT = 60;
const TABLE_HEADER_HEIGHT = 48;

const COLUMNS = [
  {
    label: "CANDIDATE NAME",
    dataKey: "candidate_name",
    width: 100,
    minWidth: 100,
  },
  {
    label: "EMAIL",
    dataKey: "email",
    width: 200,
    minWidth: 200,
  },
  {
    label: "SCREENING TYPE",
    dataKey: "screening_type",
    width: 100,
  },
  {
    label: "UPDATED",
    dataKey: "updated",
    width: 100,
  },
  {
    label: "STATUS",
    dataKey: "status",
    width: 200,
  },
  {
    label: "",
    dataKey: "action",
    width: 50,
    minWidth: 50,
  },
];

export type Props = {
  match: Match;
  organization?: UserOrganization;
  challengesPages?: PaginatedAPIData<Screening>;
  challengesEntries: IndirectAPIData<Screening>;
};

export const Challenges: React.FC<Props> = ({
  match,
  organization,
  challengesPages,
  challengesEntries,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showChallengeCreationModal, setShowChallengeCreationModal] =
    useState(false);

  // 0 - Fetch the first page of code challenges on mount and clear it and
  // the cancel/report download state on unmount.
  useEffect(() => {
    if (!organization) {
      return;
    }

    dispatch(organizationsActions.screenings.list({ id: organization.id }));
    return () => {
      dispatch(
        organizationsActions.screenings.clearList({ id: organization.id })
      );
      dispatch(challengesActions.screenings.clearCancel());
      dispatch(challengesActions.screenings.download.clearReport());
    };
  }, [dispatch, organization]);

  if (!organization || !challengesPages || !challengesPages.hasData) {
    return null;
  }

  const rowGetter = ({ index }: { index: number }) => {
    return getMapEntryByIndex(
      challengesPages,
      challengesEntries,
      ScreeningKey,
      index
    );
  };

  const isRowLoaded = ({ index }: { index: number }) => {
    return Boolean(rowGetter({ index }));
  };

  const loadMoreRows = () => {
    if (!challengesPages.nextPage) {
      return;
    }

    dispatch(organizationsActions.screenings.list({ id: organization.id }));
  };

  const renderCellWithDataKey = ({
    dataKey,
    rowIndex,
  }: {
    dataKey: string;
    rowIndex: number;
  }) => {
    const challenge = rowGetter({ index: rowIndex });
    if (!challenge || !challenge.isFilled || !challenge.data) {
      return null;
    }

    return <ChallengeCell challengeId={challenge.data.id} dataKey={dataKey} />;
  };

  const linkForChallenge = (challenge: Screening) => {
    let url = match.url;
    while (url.endsWith("/")) {
      url = url.substring(0, url.length - 1);
    }
    return url + `/${challenge.id}`;
  };

  const goToSelectedChallenge = (index: number) => {
    const challenge = rowGetter({ index: index });
    if (!challenge || !challenge.isFilled || !challenge.data) {
      return;
    }

    const challengeUrl = linkForChallenge(challenge.data);
    history.push({
      pathname: challengeUrl,
      state: { organization },
    });
  };

  const breadcrumbs = [
    {
      name: organization.display_name,
      url: "/dash",
    },
    {
      name: "Code Challenges",
    },
  ];

  return (
    <PageContainer
      pagetitle="Code Challenges"
      titleChildren={
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setShowChallengeCreationModal(true)}
        >
          <FontIcon icon={faPlus} style={{ marginRight: "10px" }} />
          Invite a Candidate
        </Button>
      }
      breadcrumbs={breadcrumbs}
    >
      {challengesPages.resultsCount > 0 && (
        <Card
          height="100vh"
          maxHeight={`${
            TABLE_HEADER_HEIGHT +
            challengesPages.resultsCount * TABLE_ROW_HEIGHT
          }px`}
          display="block"
        >
          <InfiniteLoadingTable
            columns={COLUMNS}
            rowHeight={TABLE_ROW_HEIGHT}
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMoreRows}
            rowCount={challengesPages.resultsCount}
            rowGetter={rowGetter}
            cellRenderer={renderCellWithDataKey}
            onRowClick={({ index }: { index: number }) =>
              goToSelectedChallenge(index)
            }
          />
        </Card>
      )}
      {challengesPages.resultsCount < 1 && (
        <Banner icon={faComment} color={theme.palette.secondary.main}>
          <Div ml="15px">
            <Text variant="body1">
              <b>View report statuses and download completed reports here.</b>{" "}
              It looks like you haven&apos;t ordered any code challenge reports
              yet. Order a screening to get started!
            </Text>
          </Div>
        </Banner>
      )}
      <ChallengeOrderModal
        organization={organization}
        isOpen={showChallengeCreationModal}
        onRequestClose={() => setShowChallengeCreationModal(false)}
      />
    </PageContainer>
  );
};

function mapStateToProps(state: any, ownProps: {}) {
  const organization = usersSelectors.user.organization(state);
  const challengesPages = organization
    ? organizationsSelectors.screenings.pages(state, { id: organization.id })
    : undefined;
  const challengesEntries = challengesSelectors.screenings.entries(state);
  return {
    organization,
    challengesPages,
    challengesEntries,
  };
}

export default connect(mapStateToProps)(Challenges);
