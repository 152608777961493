import queryString from "query-string";

/** Type representing a querystring */
export type QueryString = {
  [key: string]: string | undefined | null;
};

/** Parse a QueryString object out of the given search string */
function parse(search: string): QueryString {
  const obj = queryString.parse(search);
  const ret: QueryString = {};
  return Object.entries(obj).reduce((acc, [key, val]) => {
    if (val instanceof Array) {
      acc[key] = val[0];
    } else {
      acc[key] = val;
    }

    return acc;
  }, ret);
}

export default {
  parse,
};
