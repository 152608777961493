import React from 'react';
import styled from 'styled-components';
import Textarea from 'react-textarea-autosize';
import { useTheme } from '@mui/material/styles';
import {
  SpacingProps,
  SpacingPropsKeys,
  SizingProps,
  SizingPropsKeys,
  TypographyProps,
  TypographyPropsKeys,
} from '../../../types/MaterialUI';
import { Spacing, Sizing, Typography } from '../../../styles/MaterialUI';
import { Property } from 'csstype';

export interface Props extends SpacingProps, SizingProps, TypographyProps {
  placeholder?: string;
  minRows?: number;
  maxRows?: number;
  resize?: Property.Resize;
  children?: React.ReactNode;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  autoFocus?: boolean;
  inputRef?:
    | ((node: HTMLTextAreaElement) => void)
    | React.RefObject<HTMLTextAreaElement>;
}

const propsNotToForward = [
  ...SpacingPropsKeys,
  ...SizingPropsKeys,
  ...TypographyPropsKeys,
  'theme',
];

const StyledInputArea = styled(Textarea).withConfig({
  shouldForwardProp: (prop, _) => !propsNotToForward.includes(prop),
})<Props>`
  ${Spacing}
  ${Sizing}
${Typography}
resize: ${(props) => props.resize};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) =>
    props.fontSize || props.theme.typography.body1.fontSize};
  color: ${(props) => props.theme.palette.text.primary};
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-radius: 4px;
  background: ${(props) => props.theme.palette.background.paper};
  &:focus {
    border: 1px solid ${(props) => props.theme.palette.secondary.main} !important;
    outline: none;
    box-shadow: 0 0 1px 4px ${(props) => props.theme.palette.secondary.dim};
    transition: 0.2s;
  }
  &::-webkit-input-placeholder {
    color: ${(props) => props.theme.palette.text.secondary};
  }
`;

const InputAreaComponent = React.forwardRef<any, Props>(
  (props: Props, ref: Ref<any>): JSX.Element => {
    const theme = useTheme();
    return (
      <StyledInputArea
        {...props}
        ref={ref}
        theme={theme}
        data-test="inputarea-atom"
      />
    );
  }
);

InputAreaComponent.displayName = 'InputArea';
InputAreaComponent.defaultProps = {
  p: '10px 10px 10px 10px',
  minRows: 4,
  resize: 'none',
  width: '100%',
  height: 'auto',
  boxSizing: 'border-box',
};

export const InputArea = InputAreaComponent;
