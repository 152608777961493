import {
  border,
  borderColor,
  borderBottom,
  borderTop,
  borderLeft,
  borderRight,
  borderRadius,
  display,
  flexbox,
  grid,
  positions,
  shadows,
  sizing,
  spacing,
  typography,
} from '@mui/system';
import { MuiProps } from '../types/MaterialUI';
import { BorderProps } from '../types/MaterialUI/BorderProps';
import { PaletteProps } from '../types/MaterialUI/PaletteProps';
import { css } from 'styled-components';

export const Border = css<BorderProps>`
  ${border}
  ${borderTop}
  ${borderRight}
  ${borderBottom}
  ${borderLeft}
  ${borderColor}
  ${borderRadius}
`;

export const Display = display;
export const Flex = flexbox;
export const Grid = grid;

export const Palette = css<PaletteProps>`
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor || props.bgcolor};
`;

export const Positions = positions;
export const Shadow = shadows;
export const Sizing = sizing;
export const Spacing = spacing;
export const Typography = typography;

export const MaterialUI = css<MuiProps>`
  ${Border}
  ${Display}
  ${Flex}
  ${Grid}
  ${Palette}
  ${Positions}
  ${Shadow}
  ${Sizing}
  ${Spacing}
  ${Typography}
`;
