import React, { PureComponent } from "react";
import {
  Button,
  Card,
  Div,
  Flex,
  FontIcon,
  InfiniteLoadingTable,
  PageContainer,
  Tooltip,
  UserAvatar,
} from "Common";
import PropTypes from "prop-types";
import withTheme from "@mui/styles/withTheme";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { faPencil, faPlus } from "@fortawesome/pro-solid-svg-icons";

import {
  actions as organizationsActions,
  selectors as organizationsSelectors,
} from "reducers/organizations";
import { APIResultProp } from "utils/apiData";
import { getEntryByIndex } from "utils/pagination";

import APIRequestSuccessToast from "components/lib/APIRequestSuccessToast";

import UpdateRoleModal from "./UpdateRoleModal";
import InviteTeammateModal from "./InviteTeammateModal";

const styles = (theme) => {
  return {
    label: {
      fontSize: "13px",
      color: theme.palette.text.secondary,
      fontWeight: "600",
      marginBottom: "7px",
      marginTop: "10px",
    },
    input: {
      border: `solid 1px ${theme.palette.grey[300]}`,
      borderRadius: "4px",
      padding: "10px 15px",
      fontSize: "16px",
      color: theme.palette.text.primary,
      width: "100%",
      boxSizing: "border-box",
    },
  };
};

const COLUMNS = [
  {
    label: "",
    dataKey: "icon",
    minWidth: 25,
    maxWidth: 50,
  },
  {
    label: "NAME",
    dataKey: "name",
  },
  {
    label: "ROLE",
    dataKey: "role",
    maxWidth: 150,
  },
];

const TABLE_ROW_HEIGHT = 60;
const TABLE_HEADER_HEIGHT = 48;

export class Membership extends PureComponent {
  state = {
    isTeammateModalOpen: false,
    email: null,
    name: null,
    memberId: null,
  };

  componentDidMount() {
    const { fetchMembersForOrgPage, inviteMemberClear, organization } =
      this.props;
    fetchMembersForOrgPage({
      id: organization.id,
      fetchAll: true,
      reset: true,
    });
    inviteMemberClear();
  }

  renderIcon = (item) => {
    const { theme } = this.props;
    return (
      <Div p="0 16px" fontSize="14px">
        <UserAvatar
          imageUrl={item.photo_url}
          altText={`${item.first_name} ${item.last_name}`}
          text={`${item.first_name.charAt(0)}${item.last_name.charAt(0)}`}
          textBackground={theme.palette.info.dim}
          fontSize="13px"
          shadow="none"
        />
      </Div>
    );
  };

  renderName = (item) => {
    return (
      <Div p="0 16px" fontSize="14px">
        {item.first_name} {item.last_name}
      </Div>
    );
  };

  renderRole = (item) => {
    const { organization } = this.props;
    return (
      <Flex>
        <Div
          p="0 16px"
          width="50px"
          fontSize="14px"
          style={{ textTransform: "capitalize" }}
        >
          {item.role}
        </Div>
        {organization.role === "owner" &&
        item.id !== organization.membership_id ? (
          <Tooltip title="Edit this member's role." placement="right">
            <FontIcon
              icon={faPencil}
              size="xs"
              onClick={() =>
                this.setState({
                  memberId: item.id,
                })
              }
              cursor="pointer"
            />
          </Tooltip>
        ) : null}
      </Flex>
    );
  };

  renderCells = {
    icon: this.renderIcon,
    name: this.renderName,
    role: this.renderRole,
  };

  isRowLoaded = ({ index }) => {
    return Boolean(this.rowGetter({ index }));
  };

  loadMoreRows = () => {};

  rowGetter = ({ index }) => {
    const { members } = this.props;
    return getEntryByIndex(members, index);
  };

  renderCellWithDataKey = ({ dataKey, rowIndex }) => {
    const member = this.rowGetter({ index: rowIndex });
    return this.renderCells[dataKey](member);
  };

  render() {
    const {
      organization,
      inviteMemberResults,
      members,
      fetchMembersForOrgPage,
    } = this.props;
    const { isTeammateModalOpen, memberId } = this.state;

    const onInvite = () => {
      this.setState({ isTeammateModalOpen: true });
    };

    const Subtitle = () => {
      return (
        <Flex justifyContent="space-between">
          <Div width="75%" mt="15px">
            Members of your organization can send code challenge invitiations to
            candidates, cancel pending invitations, and view code challenge
            results.
          </Div>
          {organization.role === "owner" && (
            <Button variant="contained" color="secondary" onClick={onInvite}>
              <FontIcon icon={faPlus} mr={2} />
              Invite a Teammate
            </Button>
          )}
        </Flex>
      );
    };

    const breadcrumbs = [
      {
        name: organization.display_name,
        url: `/dash`,
      },
      {
        name: "Settings",
        url: `/dash/settings`,
      },
      {
        name: "Membership",
      },
    ];

    return (
      <React.Fragment>
        <PageContainer
          pagetitle="Membership"
          subtitle={<Subtitle />}
          breadcrumbs={breadcrumbs}
        >
          <Card
            height="100vh"
            maxHeight={`${
              TABLE_HEADER_HEIGHT + members.resultsCount * TABLE_ROW_HEIGHT
            }px`}
            display="block"
          >
            <InfiniteLoadingTable
              columns={COLUMNS}
              rowHeight={TABLE_ROW_HEIGHT}
              isRowLoaded={this.isRowLoaded}
              loadMoreRows={this.loadMoreRows}
              rowCount={members.resultsCount}
              rowGetter={this.rowGetter}
              cellRenderer={this.renderCellWithDataKey}
              // Force a re-render every time the page table updates
              updateCount={members.updateCount}
              disableRowHover
            />
          </Card>
        </PageContainer>
        <APIRequestSuccessToast
          results={inviteMemberResults}
          message="Success! We've sent your teammate an email invitation to join Qualified by PullRequest."
        />
        <UpdateRoleModal
          isOpen={Boolean(memberId)}
          memberId={memberId}
          onRequestClose={() => {
            this.setState({ memberId: null });
            fetchMembersForOrgPage({
              id: organization.id,
              fetchAll: true,
              reset: true,
            });
          }}
        />
        <InviteTeammateModal
          isOpen={isTeammateModalOpen}
          organization={organization}
          onRequestClose={() => this.setState({ isTeammateModalOpen: false })}
        />
      </React.Fragment>
    );
  }
}

Membership.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.string.isRequired,
    membership_id: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
  }).isRequired,
  fetchMembersForOrgPage: PropTypes.func.isRequired,
  inviteMemberClear: PropTypes.func.isRequired,
  inviteMemberResults: APIResultProp(),
  members: PropTypes.object,
  theme: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  const { organization } = ownProps;
  const inviteMemberResults = organizationsSelectors.inviteMemberRequest(
    state,
    organization.id
  );
  return {
    inviteMemberResults,
    members: organizationsSelectors.members.pages(state, organization.id),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(organizationsActions, dispatch);
}

export default withTheme(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Membership))
);
