import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import withTheme from "@mui/styles/withTheme";

import {
  Button,
  Card,
  Div,
  Flex,
  InputField,
  Link,
  RouteLink,
  PageContainer,
  Toast,
} from "Common";
import { Text } from "Common/components/Atoms/Text";

import { APIResultProp } from "utils/apiData";
import pullrequestIcon from "resources/pullrequest-icon.svg";
import {
  actions as usersActions,
  selectors as usersSelectors,
} from "reducers/users";
import ResetPasswordModal from "./ResetPasswordModal";

export function LogIn({ theme, login, loginResponse }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resetPasswordModalIsOpen, setResetPasswordModalIsOpen] =
    useState(false);
  const error = loginResponse.success === false;

  const onSubmit = (e) => {
    e.preventDefault();
    login({ email, password });
  };

  return (
    <React.Fragment>
      <Toast
        color="error"
        severity="error"
        message="Invalid credentials."
        vertical="top"
        horizontal="center"
        showToast={error}
      />
      <PageContainer>
        <Flex direction="column" justifyContent="center" center>
          <Text variant="h1">Sign in</Text>
          <Card mt="10px" width="350px" p="30px">
            <Flex direction="column" justifyContent="center" center>
              <form onSubmit={onSubmit}>
                <InputField
                  id="email"
                  fullWidth
                  placeholder="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <InputField
                  id="password"
                  mt={2}
                  mb={2}
                  fullWidth
                  placeholder="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <Link
                  onClick={() => setResetPasswordModalIsOpen(true)}
                  fontSize="12px"
                >
                  Forgot your password?
                </Link>
                <Button
                  mt={2}
                  variant="contained"
                  color="secondary"
                  type="submit"
                  fullWidth
                >
                  Sign in
                </Button>
              </form>
              <Text
                variant="h4"
                fontWeight="normal"
                m="10px 0 10px 0"
                color={theme.palette.grey[500]}
              >
                or
              </Text>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  window.location = "/oauth/login";
                }}
                fullWidth
              >
                <Div width="30px" height="30px">
                  <Div
                    width="100%"
                    height="100%"
                    style={{
                      background: `transparent url(${pullrequestIcon}) no-repeat`,
                    }}
                  />
                </Div>
                <Div ml="10px">Sign in with PullRequest.com</Div>
              </Button>
            </Flex>
          </Card>
          <Flex
            mt="20px"
            width="350px"
            fontSize="14px"
            border={`solid 1px ${theme.palette.grey[400]}`}
            borderRadius="10px"
            p="20px 30px 20px 30px"
            justifyContent="center"
            center
          >
            New to PullRequest Code Challenges?
            <RouteLink ml="5px" to="/signup" textDecoration="none">
              Sign up
            </RouteLink>
          </Flex>
        </Flex>
      </PageContainer>
      <ResetPasswordModal
        isOpen={resetPasswordModalIsOpen}
        onRequestClose={() => setResetPasswordModalIsOpen(false)}
      />
    </React.Fragment>
  );
}

LogIn.propTypes = {
  login: PropTypes.func.isRequired,
  loginResponse: APIResultProp().isRequired,
  theme: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const loginResponse = usersSelectors.login(state);
  return {
    loginResponse,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(usersActions, dispatch);
}

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(LogIn));
