import React, { PureComponent } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import withTheme from "@mui/styles/withTheme";

import { Flex, PageContainer, RouteLink } from "Common";
import { Loading } from "Common/components/Atoms/Loading";

import { paginatedAPIResultsProp } from "utils/pagination";
import { getMapEntries } from "utils/pageMap";
import { selectors as organizationsSelectors } from "reducers/organizations";

import GreenhouseCard from "./GreenhouseCard";

export class Integrations extends PureComponent {
  render() {
    const { organization, integrationEntries, greenhouseInstalled, theme } =
      this.props;
    const { hasData } = integrationEntries;
    const breadcrumbs = [
      {
        name: organization.display_name,
        url: `/dash`,
      },
      {
        name: "Settings",
        url: `/dash/settings`,
      },
      {
        name: "Integrations",
      },
    ];
    return (
      <PageContainer pagetitle="Integrations" breadcrumbs={breadcrumbs}>
        {!hasData && (
          // TODO: Maybe a better loading graphic?
          <Loading size={24} />
        )}
        {hasData && (
          <Flex>
            <RouteLink
              to="/dash/settings/integrations/greenhouse"
              color={theme.palette.text.primary}
              textDecoration={"none"}
            >
              <GreenhouseCard installed={greenhouseInstalled} />
            </RouteLink>
          </Flex>
        )}
      </PageContainer>
    );
  }
}

Integrations.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.string.isRequired,
    display_name: PropTypes.string.isRequired,
  }).isRequired,
  integrationEntries: paginatedAPIResultsProp().isRequired,
  greenhouseInstalled: PropTypes.bool,
  theme: PropTypes.object.isRequired,
};

export function mapStateToProps(state, ownProps) {
  const { organization } = ownProps;
  const integrationEntries = organizationsSelectors.integrations.pages(
    state,
    organization.id
  );

  return {
    integrationEntries,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(Integrations));
