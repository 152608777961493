import React, { useState } from 'react';
import { Div } from '../../Atoms/Div';
import { FormItem, SingleFormItem, isEditableItem } from '../index';
import {
  faPlusSquare,
  faEdit,
  faArrowUp,
  faArrowDown,
} from '@fortawesome/pro-solid-svg-icons';
import { FontIcon } from '../../Atoms/FontIcon';
import { useTheme } from '@mui/material/styles';
import { FormItemEditor } from './FormItemEditor';

export type Props = {
  form?: FormItem[];
  onUpdateForm: (form: FormItem[]) => void;
};

// The Builder component is meant for editing/creating the form itself,
// not individual fields of an existing form.
export const Builder: React.FC<Props> = ({
  form = [],
  onUpdateForm,
}: Props) => {
  const theme = useTheme();
  const [pendingItemType, setPendingItemType] = useState<string>();
  const [pendingItemIndex, setPendingItemIndex] = useState<number>();

  const addNewPendingItem = (): void => {
    setPendingItemType('header');
    setPendingItemIndex(undefined);
  };

  const editExistingItem = (item: FormItem, idx: number): void => {
    setPendingItemType(item.type);
    setPendingItemIndex(idx);
  };

  const deletePendingItem = (): void => {
    setPendingItemType(undefined);
    setPendingItemIndex(undefined);
  };

  const deleteItemAtIndex = (idx: number): void => {
    deletePendingItem();
    onUpdateForm(form.filter((item, i) => i !== idx));
  };

  const onSavePendingItem = (item: FormItem): string | undefined => {
    // if this is an editable item, then we need to make sure the name is unique
    // amongst this form's items.
    if (isEditableItem(item)) {
      // filter out the index of the item we're editing and check if there is
      // another editable item with the same name.
      const isDuplicateName = form.some(
        (existingItem, idx) =>
          idx !== pendingItemIndex &&
          isEditableItem(existingItem) &&
          existingItem.name === item.name
      );
      if (isDuplicateName) {
        return 'Name duplicates another in this form';
      }
    }

    // clear the pending item being edited since we are saving it
    deletePendingItem();

    // if we aren't editing a specific index, then just append the new item to the end
    if (pendingItemIndex === undefined) {
      const newForm = [...form, item];
      onUpdateForm(newForm);
      return;
    }

    const newForm = form.map((existingItem, idx) => {
      if (idx === pendingItemIndex) {
        return item;
      }
      return existingItem;
    });
    onUpdateForm(newForm);
  };

  const moveItemUp = (idx: number): void => {
    // move item at idx up by one
    const item = form[idx];
    const prevItem = form[idx - 1];
    const newForm = [
      ...form.slice(0, idx - 1),
      item,
      prevItem,
      ...form.slice(idx + 1),
    ];
    onUpdateForm(newForm);
  };

  const moveItemDown = (idx: number): void => {
    // move item at idx down by one
    const item = form[idx];
    const nextItem = form[idx + 1];
    const newForm = [
      ...form.slice(0, idx),
      nextItem,
      item,
      ...form.slice(idx + 2),
    ];
    onUpdateForm(newForm);
  };

  return (
    <Div width="100%">
      {form?.map((item, idx) => {
        if (idx === pendingItemIndex) {
          return (
            <FormItemEditor
              key={idx}
              item={item}
              type={pendingItemType}
              onCancel={deletePendingItem}
              onChangeType={setPendingItemType}
              onSaveItem={onSavePendingItem}
              onDeleteItem={() => deleteItemAtIndex(idx)}
            />
          );
        }
        const showEditButton = !pendingItemType;
        const showUpButton = !pendingItemType && idx > 0;
        const showDownButton = !pendingItemType && idx < form.length - 1;

        // we disable onclick and use opacity for hiding the icons to keep the spacing consistent.
        return (
          <Div key={idx} display="flex" alignItems="baseline" width="100%">
            <FontIcon
              icon={faArrowUp}
              mr={2}
              color="gray"
              // disable buttons if another pending item is in progress or can't be moved up
              opacity={showUpButton ? 1 : 0}
              onClick={() => showUpButton && moveItemUp(idx)}
            />
            <FontIcon
              icon={faArrowDown}
              mr={2}
              color="gray"
              // disable buttons if another pending item is in progress or can't be moved down
              opacity={showDownButton ? 1 : 0}
              onClick={() => showDownButton && moveItemDown(idx)}
            />
            <FontIcon
              icon={faEdit}
              mr={2}
              color="gray"
              // disable buttons if another pending item is in progress
              opacity={showEditButton ? 1 : 0}
              onClick={() => showEditButton && editExistingItem(item, idx)}
            />
            <SingleFormItem item={item} />
          </Div>
        );
      })}
      {pendingItemType && pendingItemIndex === undefined && (
        <FormItemEditor
          type={pendingItemType}
          onCancel={deletePendingItem}
          onChangeType={setPendingItemType}
          onSaveItem={onSavePendingItem}
          onDeleteItem={deletePendingItem}
        />
      )}
      {!pendingItemType && (
        <FontIcon
          mt={2}
          icon={faPlusSquare}
          onClick={addNewPendingItem}
          color={theme.palette.primary.main}
          size="lg"
        />
      )}
    </Div>
  );
};
