import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import HeaderBar from "components/lib/HeaderBar";

import LogIn from "./LogIn";
import SignUp from "./SignUp";
import ResetPassword from "./ResetPassword";

export type UnauthedProps = {};

export const UnauthedApp: React.FC<UnauthedProps> = ({}) => {
  return (
    <>
      <HeaderBar />
      <Switch>
        <Route path="/login/" exact component={LogIn} />
        <Route path="/signup/" exact component={SignUp} />
        <Route path="/reset-password/" exact component={ResetPassword} />
        <Redirect to="/login/" />
      </Switch>
    </>
  );
};

export default UnauthedApp;
