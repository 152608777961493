import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Text } from '../Text';
import { Div } from '../Div';
import Switch from 'react-switch';

export interface Props {
  onTextColor?: string;
  offTextColor?: string;
  checkedIconText?: string;
  uncheckedIconText?: string;
  label?: string;
  checked: boolean;
  onChange: (
    checked: boolean,
    event: React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent,
    id: string
  ) => void;
  disabled?: boolean;
  offColor?: string;
  onColor?: string;
  offHandleColor?: string;
  onHandleColor?: string;
  handleDiameter?: number;
  uncheckedIcon?: JSX.Element | boolean;
  checkedIcon?: JSX.Element | boolean;
  boxShadow?: string;
  activeBoxShadow?: string;
  height?: number;
  width?: number;
}

export const ToggleComponent = React.forwardRef<Switch, Props>(
  (props: Props, ref: Ref<Switch>): JSX.Element => {
    const theme = useTheme();

    const toggleTextStyles = {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: theme.typography.fontWeightSemiBold as number,
      fontSize: '0.8125rem',
      height: '100%',
    };

    const {
      checkedIcon,
      uncheckedIcon,
      onColor,
      offColor,
      onTextColor,
      offTextColor,
      onHandleColor,
      height,
      width,
      checkedIconText,
      uncheckedIconText,
      label,
      ...passThroughProps
    } = props;

    return (
      <>
        {label && (
          <Text variant="body1" mr="8px">
            {label}
          </Text>
        )}
        <Switch
          {...passThroughProps}
          ref={ref}
          checkedIcon={
            checkedIcon || (
              <Div
                {...toggleTextStyles}
                data-testid="toggle-on-icon"
                color={onTextColor || theme.palette.primary.dark}
                m={'-1px 8px'}
              >
                {checkedIconText ?? 'On'}
              </Div>
            )
          }
          uncheckedIcon={
            uncheckedIcon || (
              <Div
                {...toggleTextStyles}
                data-testid="toggle-off-icon"
                color={offTextColor || theme.palette.primary.main}
                m={'-1px 4px'}
              >
                {uncheckedIconText ?? 'Off'}
              </Div>
            )
          }
          onHandleColor={onHandleColor || theme.palette.common.white}
          onColor={onColor || theme.palette.success.main}
          offColor={offColor || theme.palette.grey[300]}
        />
      </>
    );
  }
);

ToggleComponent.displayName = 'Toggle';
ToggleComponent.defaultProps = {
  handleDiameter: 21,
  height: 25,
};

export const Toggle = ToggleComponent;
