import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";

import { PageContainer } from "Common";

import { APIData } from "utils/apiData";
import { actions, selectors, Template } from "reducers/templates";
import MarkdownTemplate from "components/lib/Template";

export type ViewTemplateProps = {
  templateId: string;
  template: APIData<Template>;
  parentPath: string;
};

export const ViewTemplate: React.FC<ViewTemplateProps> = ({
  template,
  parentPath,
  templateId,
}) => {
  const dispatch = useDispatch();

  // 0 - Fetch the template
  useEffect(() => {
    dispatch(actions.fetchTemplate({ id: templateId }));
  }, [dispatch, templateId]);

  const templateData = template.isFilled && template.data;
  if (!templateData) {
    // TODO: Return some sort of loading state here instead.
    return null;
  }

  // TODO: Maybe make these breadcrumbs passed in and just extend with the
  // one representing the current page.
  const breadcrumbs = [
    {
      name: "Configure",
      url: parentPath,
    },
    {
      name: "Templates",
      url: `${parentPath}/templates`,
    },
    {
      name: templateData.name,
    },
  ];

  return (
    <PageContainer
      pagetitle={templateData.name}
      subtitle="Click the rendered markdown text to edit"
      breadcrumbs={breadcrumbs}
    >
      <MarkdownTemplate templateId={templateId} editable={true} />
    </PageContainer>
  );
};

type OwnProps = {
  match: {
    params: {
      templateId: string;
    };
  };
};

function mapStateToProps(state: any, ownProps: OwnProps) {
  const templateId = ownProps.match.params.templateId;
  const template = selectors.template(state, { id: templateId });
  return {
    templateId,
    template,
  };
}

export default connect(mapStateToProps)(ViewTemplate);
