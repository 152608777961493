import React from "react";
import { connect } from "react-redux";

import { selectors } from "reducers/templates";

import BaseTemplate from "./BaseTemplate";

type SubTemplateProps = {
  value: string;
  externalRenderers?: {
    [key: string]: any;
  };
  variables?: {
    [key: string]: string;
  };
};

export const SubTemplate: React.FC<SubTemplateProps> = ({
  value,
  externalRenderers,
  ...remainder
}) => {
  return (
    <BaseTemplate
      {...remainder}
      templateId={value}
      externalRenderers={{
        template: SubTemplate,
        ...externalRenderers,
      }}
    />
  );
};

export default SubTemplate;
