/**
 * This module ties a paginated list into a mapping of each entry's ID to its
 * object data. In this way, we can modify members of the singleton entry
 * without having to constantly traverse over the data (but retain the order of
 * the entries in question).
 */
import { storeResults } from "./apiData";
import { handlePageData, getEntryByIndex, getResultsCount } from "./pagination";

// Take the previous state of the page map mechanism and update it with a new
// paginated response.
export function handlePageMapData(pageData, pageMapData, emptyEntry, response) {
  // For failed requests, just update the paginated state.
  if (!response.success) {
    return {
      pageData: handlePageData(pageData, response),
      pageMapData,
    };
  }

  // Populate the singleton data from the page data.
  const newEntries = {};
  for (let i in response.data.results) {
    const newEntryData = response.data.results[i];
    const entry = pageMapData[newEntryData.id] || emptyEntry;
    const entryData = entry.data || {};
    newEntries[newEntryData.id] = storeResults({
      // Legacy entry data
      ...entry,

      // List data
      data: {
        // Old data (in case the paginated data has incomplete info)
        ...entryData,

        // New data from pagination endpoint
        ...newEntryData,
      },

      success: true,
    });
  }

  return {
    pageData: {
      ...pageData,
      ...handlePageData(pageData, response),
    },
    pageMapData: {
      ...pageMapData,
      ...newEntries,
    },
  };
}

// Indirectly look up the page map data by the given index in the page data.
// TODO: Deprecate this in favor of the one in "utils/pageMapV2"
export function getMapEntryByIndex(pageData, pageMapData, index) {
  const pageEntry = getEntryByIndex(pageData, index);
  return pageEntry && pageMapData[pageEntry.id];
}

// Create and return an array of all entries in the mapping. Note that this
// function creates an array of entries every time it's called.
export function getMapEntries(pageData, pageMapData) {
  const entries = [];
  const resultsCount = getResultsCount(pageData);
  for (let i = 0; i < resultsCount; i++) {
    entries.push(getMapEntryByIndex(pageData, pageMapData, i));
  }
  return entries;
}
