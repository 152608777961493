import { combineReducers } from "redux";

import {
  APITypes,
  APIRequest,
  indirectIDAPIAction,
  IndirectIDAPIRequest,
  indirectAPIRequestStore,
  indirectIDAPIRequestSelector,
} from "utils/apiData";
import {
  indirectPageStore,
  emptyPaginatedAPIData,
  IndirectPaginatedIDAPIArgs,
  IndirectPaginatedIDAPIRequest,
} from "utils/pagination";
import { indirectPageMapStore, PageMapKeyFunction } from "utils/pageMapV2";

export const types = {
  members: {
    GET: new APITypes("ORGANIZATIONS/MEMBERS/GET"),
    LIST: new APITypes("ORGANIZATIONS/MEMBERS/LIST"),
    UPDATE: new APITypes("ORGANIZATIONS/MEMBERS/UPDATE"),
    INVITE: new APITypes("ORGANIZATIONS/MEMBERS/INVITE"),
  },
  screenings: {
    LIST: new APITypes("ORGANIZATIONS/SCREENINGS/LIST"),
  },
  integrations: {
    GET: new APITypes("ORGANIZATIONS/INTEGRATIONS/GET"),
    LIST: new APITypes("ORGANIZATIONS/INTEGRATIONS/LIST"),
    greenhouse: {
      CREATE: new APITypes("ORGANIZATIONS/INTEGRATIONS/GREENHOUSE/CREATE"),
      DELETE: new APITypes("ORGANIZATIONS/INTEGRATIONS/GREENHOUSE/DELETE"),
      RESET: new APITypes("ORGANIZATIONS/INTEGRATIONS/GREENHOUSE/RESET"),
    },
  },
};

/******************************************************************************
 * Types
 *****************************************************************************/
export type Member = {
  id: string;
};

export const MemberKey: PageMapKeyFunction<Member> = (entry: Member) => {
  return entry.id;
};

export type Integration = {
  id: string;
};

export type Screening = {
  id: string;

  candidate_name: string;
  candidate_email: string;

  skill_level: "Junior" | "Mid-Level" | "Senior";
  status:
    | "Processing order"
    | "Invitation sent"
    | "Waiting on candidate"
    | "Reviewing submission"
    | "Finalizing report"
    | "Report complete"
    | "Canceled";
  challenge_type: string;
  product_type: "download" | "view";
  product_language: string;

  created_at: string;
  updated_at: string;
};

/******************************************************************************
 * State
 *****************************************************************************/
export default combineReducers({
  screenings: combineReducers({
    pages: indirectPageStore<IndirectPaginatedIDAPIRequest, Screening>(
      types.screenings.LIST
    ),
  }),
});

/******************************************************************************
 * Actions
 *****************************************************************************/
export const actions = {
  screenings: {
    clearList: (args?: IndirectPaginatedIDAPIArgs) => ({
      type: types.screenings.LIST.clear,
      request: new IndirectPaginatedIDAPIRequest(args),
    }),
    list: (args?: IndirectPaginatedIDAPIArgs) => ({
      type: types.screenings.LIST.request,
      request: new IndirectPaginatedIDAPIRequest(args),
    }),
  },
};

/******************************************************************************
 * Selectors
 *****************************************************************************/
export const selectors = {
  screenings: {
    pages: (state: any, args: IndirectPaginatedIDAPIArgs) =>
      (args.id ? state.organizationsV2.screenings.pages[args.id] : null) ||
      emptyPaginatedAPIData,
  },
};
