import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Theme } from "@mui/material/styles";
import withTheme from "@mui/styles/withTheme";
import withStyles from "@mui/styles/withStyles";
import Dropzone from "react-dropzone";
import ReactLoading from "react-loading";
import { faUpload, faFileArchive } from "@fortawesome/pro-solid-svg-icons";
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";

import { Div, Flex, Card, Modal, Button, Banner, FontIcon } from "Common";
import { Text } from "Common/components/Atoms/Text";

import { APIData } from "utils/apiData";
import {
  actions,
  selectors,
  CandidateChallengeStatus,
} from "reducers/challengesV2";

interface OwnProps {
  challengeId: string;
  theme: Theme;
}

export interface WaitingProps extends OwnProps {
  submitResponse: APIData<null>;
  challengeStatus?: CandidateChallengeStatus;
  classes: any;
}

export const UPLOAD_SIZE_LIMIT = 20 * 1024 * 1024; // 20MiB

// these mime types can vary across OS, so be sure to test carefully if you
// change this list
const SUPPORTED_MIME_TYPES = [
  "application/zip-compressed",
  "application/x-zip-compressed",
  "application/zip",
  "multipart/x-zip",
  "application/octet-stream",
  "application/x-gzip",
  "application/gzip",
];

const styles = (theme: Theme) => {
  return {
    dropzone: {
      "&:hover": {
        backgroundColor: "rgba(74, 164, 224, 0.15)",
      },
      cursor: "pointer",
    },
  };
};

export const Waiting: React.FC<WaitingProps> = ({
  theme,
  classes,
  challengeId,
  challengeStatus,
  submitResponse,
}) => {
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const [submission, setSubmission] = useState<any>(null);
  const [uploadError, setUploadError] = useState("");
  const [showModal, setShowModal] = useState(false);
  if (!challengeStatus) {
    // This is an invalid case as this route should only be available if the
    // challenge status has been queried.
    return null;
  }

  const { company, product_type } = challengeStatus;
  const uploadFailed = submitResponse.isFilled && !submitResponse.success;
  const handleDrop = (acceptedFiles: any, rejectedFiles: any) => {
    if (!acceptedFiles.length && rejectedFiles.length) {
      if (rejectedFiles[0].size > UPLOAD_SIZE_LIMIT) {
        // notify of file being too large
        setUploadError("File must be under 20 MB");
        return;
      }

      setUploadError(
        "An error occurred with this file. Please make sure it is a .zip under 20MB."
      );
      return;
    }

    // Is this a valid state? Just no-op here and let them try again.
    if (!acceptedFiles.length) {
      return;
    }

    setSubmission(acceptedFiles[0]);
  };

  const onClickSubmit = () => {
    setShowModal(true);
  };

  const onClickModalSubmit = () => {
    const formData = new FormData();
    formData.set("submission", submission);
    dispatch(
      actions.candidate.submit({ id: challengeId, submission: formData })
    );
  };

  const onClickDownload = () => {
    if (product_type === "view") {
      window.open(`/candidates/codechallenge/${challengeId}/problem`, "_blank");
      return;
    }

    dispatch(actions.candidate.download({ id: challengeId }));
  };

  return (
    <>
      <Flex direction="column" justifyContent="center" alignItems="center">
        <Text variant="h1" color="primary" mt="141px">
          Submit your solution
        </Text>
        <Card width="400px" mr="auto" ml="auto" mt="55px" p="32px 38px">
          <Div style={{ textAlign: "center" }}>
            <Text variant="body1" mb="32px">
              Upload a .zip file of your code challenge solution for{" "}
              <b>{company}</b> below.
            </Text>
          </Div>
          <Div
            width="100%"
            height="200px"
            boxSizing="border-box"
            border={`dashed 2px ${theme.palette.grey[300]}`}
            borderRadius="8px"
            mb="30px"
            p="40px"
            backgroundColor={theme.palette.grey[50]}
            className={classes.dropzone}
          >
            <Dropzone
              accept={SUPPORTED_MIME_TYPES.join(", ")}
              onDrop={handleDrop}
              multiple={false}
              maxSize={UPLOAD_SIZE_LIMIT}
              disabled={isUploading}
            >
              {({ getRootProps, getInputProps }) => (
                <Flex
                  direction="column"
                  height="100%"
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                  {...getRootProps()}
                >
                  {!isUploading && (
                    <React.Fragment>
                      <input {...getInputProps()} />
                      <Flex
                        background={theme.palette.primary.dim}
                        boxSizing="border-box"
                        borderRadius="8px"
                        height="100px"
                        width="100px"
                        justifyContent="center"
                        alignItems="center"
                        mb="25px"
                      >
                        <FontIcon
                          icon={submission ? faFileArchive : faUpload}
                          size="3x"
                          color={theme.palette.primary.main}
                        />
                      </Flex>
                      <Text variant="body2">
                        {submission
                          ? submission.name
                          : `Drop your file here or click to browse`}
                      </Text>
                    </React.Fragment>
                  )}
                  {isUploading && (
                    <Flex direction="column">
                      <ReactLoading
                        type="spin"
                        color={theme.palette.info.main}
                        height="64px"
                        width="64px"
                      />
                      <Text variant="body2" mt="25px">
                        Uploading...
                      </Text>
                    </Flex>
                  )}
                </Flex>
              )}
            </Dropzone>
          </Div>
          {uploadError && (
            <Banner
              icon={faExclamationCircle}
              color={theme.palette.error.main}
              mb="25px"
              p="5px 10px"
            >
              <Div ml="15px">
                <Text variant="caption" fontSize="12px">
                  Upload error: {uploadError}
                </Text>
              </Div>
            </Banner>
          )}
          {uploadFailed && (
            <Banner
              icon={faExclamationCircle}
              color={theme.palette.error.main}
              mb="25px"
              p="5px 10px"
            >
              <Div ml="15px">
                <Text variant="caption" fontSize="12px">
                  Upload failed. Try again?
                </Text>
              </Div>
            </Banner>
          )}
          <Button
            width="100%"
            variant="contained"
            color="secondary"
            onClick={onClickSubmit}
            disabled={!submission}
          >
            Submit my solution
          </Button>
          <Button
            width="100%"
            variant="contained"
            color="primary"
            rel="noopener noreferrer"
            mt="10px"
            onClick={onClickDownload}
          >
            {product_type === "view"
              ? "View Code Challenge"
              : "Re-Download Code Challenge"}
          </Button>
        </Card>
      </Flex>
      <Modal
        width={458}
        variant="alert"
        isOpen={showModal}
        title="Are you sure you want to submit?"
        primaryButtonColor={theme.palette.success.main}
        primaryButtonLabel="Yes, submit"
        onClickPrimaryButton={onClickModalSubmit}
        showSecondaryButton={true}
        secondaryButtonLabel="No, exit"
        onClickSecondaryButton={() => setShowModal(false)}
        onRequestClose={() => setShowModal(false)}
      >
        Submissions are final and cannot be undone. You {`won't`} be able to
        submit another version of your solution later.
      </Modal>
    </>
  );
};

function mapStateToProps(state: any, ownProps: OwnProps) {
  const { challengeId } = ownProps;
  const challengeStatus = selectors.candidate.status(state, {
    id: challengeId,
  });
  const submitResponse = selectors.candidate.submit(state, {
    id: challengeId,
  });
  return {
    submitResponse,
    challengeStatus: challengeStatus.isFilled
      ? challengeStatus.data
      : undefined,
  };
}

export default withTheme<Theme, React.JSXElementConstructor<OwnProps>>(
  withStyles(styles)(connect(mapStateToProps)(Waiting))
);
