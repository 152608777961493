import React, { useEffect, useState } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect, useDispatch } from "react-redux";
import { Theme } from "@mui/material/styles";

import withTheme from "@mui/styles/withTheme";

import { Flex, Modal } from "Common";
import { Text } from "Common/components/Atoms/Text";

import { APIResult } from "utils/Api";
import { APIData } from "utils/apiData";
import { actions, selectors } from "reducers/users";
import APIRequestFormInput from "components/lib/APIRequestFormInput";
import APIRequestSuccessToast from "components/lib/APIRequestSuccessToast";

export type ResetPasswordModalProps = {
  theme: Theme;
  isOpen: boolean;
  onRequestClose: () => void;
  resetPasswordRequestResults: APIData<any>;
};

export const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({
  theme,
  isOpen,
  onRequestClose,
  resetPasswordRequestResults,
}) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const primaryButtonDisabled = resetPasswordRequestResults.isLoading;

  const callback = (result: APIResult<any>) => {
    if (result.success) {
      onRequestClose();
      setEmail("");
    }
  };

  const onClickPrimaryButton = () => {
    dispatch(
      actions.resetPasswordRequest({
        email,
        callback,
      })
    );
  };

  // 0 - Schedule the clearing of "reset password request" on unmount
  useEffect(() => {
    return () => {
      dispatch(actions.clearResetPasswordRequest());
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <Modal
        width={400}
        variant="generic"
        title="Reset Password"
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        primaryButtonLabel="Send Reset Email"
        primaryButtonColor={theme.palette.secondary.main}
        primaryButtonDisabled={primaryButtonDisabled}
        onClickPrimaryButton={onClickPrimaryButton}
      >
        <Flex direction="column">
          <Text variant="body2">
            Enter your email address in the box below and click {'"'}Send Reset
            Email{'"'} to receive an email with instructions for resetting your
            password.
          </Text>
          <APIRequestFormInput
            m="12px 0 0 0"
            id="email"
            errorField="email"
            results={resetPasswordRequestResults}
            fullWidth
            placeholder="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Flex>
      </Modal>
      <APIRequestSuccessToast
        results={resetPasswordRequestResults}
        message={
          "Success! An email has been sent with instructions on resetting your password."
        }
      />
    </React.Fragment>
  );
};

function mapStateToProps(state: any, ownProps: {}) {
  const resetPasswordRequestResults = selectors.resetPasswordRequest(state);
  return {
    resetPasswordRequestResults,
  };
}

export default withTheme<
  Theme,
  React.JSXElementConstructor<ResetPasswordModalProps>
>(connect(mapStateToProps)(ResetPasswordModal));
