import { Property } from 'csstype';

export interface GridProps {
  gridGap?: Property.GridGap<number>;
  gridColumnGap?: Property.GridColumnGap<number>;
  gridRowGap?: Property.GridRowGap<number>;
  gridColumn?: Property.GridColumn;
  gridRow?: Property.GridRow;
  gridAutoFlow?: Property.GridAutoFlow;
  gridAutoColumns?: Property.GridAutoColumns<number>;
  gridAutoRows?: Property.GridAutoRows<number>;
  gridTemplateColumns?: Property.GridTemplateColumns<number>;
  gridTemplateRows?: Property.GridTemplateRows<number>;
  gridTemplateAreas?: Property.GridTemplateAreas;
  gridArea?: Property.GridArea;
}

export type GridStyles = GridProps;

// Unfortunately in Typescript, using the spread operator doesn't remove properties that aren't on the interface
export const GridStyleGenerator = (props: GridProps): GridStyles => ({
  gridGap: props.gridGap,
  gridColumnGap: props.gridColumnGap,
  gridRowGap: props.gridRowGap,
  gridColumn: props.gridColumn,
  gridRow: props.gridRow,
  gridAutoFlow: props.gridAutoFlow,
  gridAutoColumns: props.gridAutoColumns,
  gridAutoRows: props.gridAutoRows,
  gridTemplateColumns: props.gridTemplateColumns,
  gridTemplateRows: props.gridTemplateRows,
  gridTemplateAreas: props.gridTemplateAreas,
  gridArea: props.gridArea,
});

const representative: GridProps = {
  gridGap: undefined,
  gridColumnGap: undefined,
  gridRowGap: undefined,
  gridColumn: undefined,
  gridRow: undefined,
  gridAutoFlow: undefined,
  gridAutoColumns: undefined,
  gridAutoRows: undefined,
  gridTemplateColumns: undefined,
  gridTemplateRows: undefined,
  gridTemplateAreas: undefined,
  gridArea: undefined,
};
export const GridPropsKeys = Object.keys(representative);
