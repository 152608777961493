import React, { useState, useEffect } from "react";

import { Toast } from "Common/components/Molecules/Toast";

import { APIData } from "utils/apiData";

export interface Props {
  results: APIData<any>;
  message: string;
}

export const APIRequestSuccessToast: React.FC<Props> = ({
  results,
  message,
  ...ownProps
}) => {
  const [showToast, setShowToast] = useState(false);

  // 0 - Show toast when we get a success
  useEffect(() => {
    setShowToast(results.isFilled && results.success === true);
  }, [results]);

  return (
    <Toast
      color="success"
      severity="success"
      message={message}
      vertical="bottom"
      horizontal="center"
      showToast={showToast}
      handleClose={() => setShowToast(false)}
      {...ownProps}
    />
  );
};

export default APIRequestSuccessToast;
