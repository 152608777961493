import React from "react";

import { Card, Flex } from "Common";

import CardSection from "components/lib/CardSection";

import { CurrentUser } from "reducers/users";

import ContactInfo from "./ContactInfo";
import ProfilePhoto from "./ProfilePhoto";

export type Props = {
  currentUser: CurrentUser;
};

export const UserInfo: React.FC<Props> = ({ currentUser }) => {
  return (
    <Card display="block" p="30px">
      <CardSection title="User Information">
        <Flex>
          <ProfilePhoto currentUser={currentUser} />
          <ContactInfo currentUser={currentUser} />
        </Flex>
      </CardSection>
    </Card>
  );
};

export default UserInfo;
