import { Property } from 'csstype';

export interface SizingProps {
  width?: Property.Width<number>;
  maxWidth?: Property.MaxWidth<number>;
  minWidth?: Property.MinWidth<number>;
  height?: Property.Height<number>;
  maxHeight?: Property.MaxHeight<number>;
  minHeight?: Property.MinHeight<number>;
  sizeWidth?: string | number;
  sizeHeight?: string | number;
  boxSizing?: Property.BoxSizing;
}

export type SizingStyles = SizingProps;

// Unfortunately in Typescript, using the spread operator doesn't remove properties that aren't on the interface
export const SizingStyleGenerator = (props: SizingProps): SizingStyles => ({
  width: props.width,
  maxWidth: props.maxWidth,
  minWidth: props.minWidth,
  height: props.height,
  maxHeight: props.maxHeight,
  minHeight: props.minHeight,
  sizeWidth: props.sizeWidth,
  sizeHeight: props.sizeHeight,
  boxSizing: props.boxSizing,
});

const representative: SizingProps = {
  width: undefined,
  maxWidth: undefined,
  minWidth: undefined,
  height: undefined,
  maxHeight: undefined,
  minHeight: undefined,
  sizeWidth: undefined,
  sizeHeight: undefined,
  boxSizing: undefined,
};
export const SizingPropsKeys = Object.keys(representative);
