import React, { PureComponent } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";

import withTheme from "@mui/styles/withTheme";
import withStyles from "@mui/styles/withStyles";

import { faInfoCircle, faWifi1 } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStripe } from "@fortawesome/free-brands-svg-icons";
import amex from "payment-icons/min/flat/amex.svg";
import generic from "payment-icons/min/flat/default.svg";
import mastercard from "payment-icons/min/flat/mastercard.svg";
import visa from "payment-icons/min/flat/visa.svg";

import {
  Banner,
  Card,
  ContentLoader,
  Div,
  Flex,
  Link,
  PageContainer,
} from "Common";
import { Text } from "Common/components/Atoms/Text";

import CardSection from "components/lib/CardSection";

import {
  actions as challengesActions,
  selectors as challengesSelectors,
} from "reducers/challenges";
import { formatDollarAmount } from "utils/formatNumber";

const REPORT_COST = 250;

const styles = (theme) => {
  return {
    input: {
      border: `solid 1px ${theme.palette.grey[300]}`,
      borderRadius: "4px",
      padding: "10px 15px",
      fontSize: "16px",
      color: theme.palette.text.primary,
      width: "100%",
    },
    static: {
      backgroundColor: theme.palette.grey[100],
      border: "none",
      width: "100%",
      boxSizing: "border-box",
    },
    label: {
      display: "block",
      fontSize: "13px",
      color: theme.palette.text.secondary,
      fontWeight: "600",
      marginBottom: "7px",
    },
    infoCard: {
      textAlign: "center",
      minHeight: "120px",
      boxSizing: "border-box",
      width: "100%",
      padding: "20px",
    },
    spacer: {
      minWidth: "40px",
    },
    ccIcon: {
      maxHeight: "18px",
      maxWidth: "35px",
      marginRight: "10px",
    },
  };
};

function CardNumberChunk() {
  const theme = useTheme();
  const style = { marginBottom: "5px" };
  return (
    <span style={{ marginRight: "0.5rem", color: theme.palette.grey[500] }}>
      <FontAwesomeIcon icon={faWifi1} size="xs" style={style} />
      <FontAwesomeIcon icon={faWifi1} size="xs" style={style} />
      <FontAwesomeIcon icon={faWifi1} size="xs" style={style} />
      <FontAwesomeIcon icon={faWifi1} size="xs" style={style} />
    </span>
  );
}

class BillingInfo extends PureComponent {
  componentDidMount() {
    const { fetchBillingForOrg, organization } = this.props;
    fetchBillingForOrg(organization.id);
  }

  render() {
    const { billing, organization, classes, theme } = this.props;
    const breadcrumbs = [
      {
        name: organization.display_name,
        url: `/dash`,
      },
      {
        name: "Settings",
        url: `/dash/settings`,
      },
      {
        name: "Billing and Usage",
      },
    ];

    const hasBillingData = Boolean(billing.data);
    const isInvoice = hasBillingData && billing.data.is_invoice;
    const hasCardInfo = Boolean(hasBillingData && billing.data.card);

    let cardType;
    const lower =
      hasCardInfo && billing.data.card.brand
        ? billing.data.card.brand.toLowerCase()
        : "";
    switch (lower) {
      case "visa":
        cardType = (
          <img
            src={visa}
            alt="visa"
            className={classes.ccIcon}
            height="18"
            width="35"
          />
        );
        break;
      case "mastercard":
        cardType = (
          <img
            src={mastercard}
            alt="mastercard"
            className={classes.ccIcon}
            height="18"
            width="35"
          />
        );
        break;
      case "amex":
        cardType = (
          <img
            src={amex}
            alt="amex"
            className={classes.ccIcon}
            height="18"
            width="35"
          />
        );
        break;
      case "stripe":
        cardType = <FontAwesomeIcon icon={faStripe} />;
        break;
      default:
        cardType = (
          <img
            src={generic}
            alt="credit card"
            className={classes.ccIcon}
            height="18"
            width="35"
          />
        );
    }

    return (
      <PageContainer pagetitle="Billing and Usage" breadcrumbs={breadcrumbs}>
        <Card display="block" p="30px 48px">
          {billing.isLoading && (
            <ContentLoader width="100%" height="107px" mb="40px" />
          )}
          {hasBillingData && !billing.isLoading && (
            <CardSection title="Cardholder Information">
              {(!hasBillingData || !hasCardInfo) && !billing.isLoading && (
                <Banner
                  icon={faInfoCircle}
                  color={theme.palette.warning.main}
                  background={theme.palette.grey[100]}
                >
                  <Div ml="15px">
                    <Text variant="body1">
                      <b>No billing information on file.</b> Contact us at{" "}
                      <Link fsize="14px" href="mailto:accounts@pullrequest.com">
                        accounts@pullrequest.com
                      </Link>{" "}
                      to set up billing.
                    </Text>
                  </Div>
                </Banner>
              )}

              {hasCardInfo && !billing.isLoading && (
                <Flex justifyContent="stretch">
                  <Div width="100%">
                    <label className={classes.label}>Cardholder Name</label>
                    <div className={`${classes.input} ${classes.static}`}>
                      {billing.data.card.name}
                    </div>
                  </Div>
                  <div className={classes.spacer} />
                  <Div width="100%">
                    <label className={classes.label}>Card Number</label>
                    <div className={`${classes.input} ${classes.static}`}>
                      <Flex>
                        {cardType}
                        <Div>
                          <CardNumberChunk />
                          <CardNumberChunk />
                          <CardNumberChunk />
                          {hasCardInfo && billing.data.card.last4}
                          {!hasCardInfo && <CardNumberChunk />}
                        </Div>
                      </Flex>
                    </div>
                  </Div>
                </Flex>
              )}
            </CardSection>
          )}

          {billing.isLoading && (
            <ContentLoader width="100%" height="107px" mb="40px" />
          )}
          {hasBillingData &&
            (hasCardInfo || (!hasCardInfo && isInvoice)) &&
            !billing.isLoading && (
              <CardSection title="Invoice Information">
                <Flex justifyContent="stretch">
                  <Div width="100%">
                    <label className={classes.label}>
                      Billing Contact Name
                    </label>
                    <div className={`${classes.input} ${classes.static}`}>
                      {billing.data.name}
                    </div>
                  </Div>
                  <div className={classes.spacer} />
                  <Div width="100%">
                    <label className={classes.label}>
                      Billing Contact Email
                    </label>
                    <div className={`${classes.input} ${classes.static}`}>
                      {billing.data.email}
                    </div>
                  </Div>
                </Flex>
              </CardSection>
            )}

          <CardSection title="Usage">
            <Flex justifyContent="stretch">
              <Card className={classes.infoCard}>
                {billing.isLoading && (
                  <ContentLoader height="107px" width="100%" />
                )}
                {hasBillingData && !billing.isLoading && (
                  <Flex direction="column">
                    <Text variant="body1" mb="15px">
                      <b>Reports</b>
                    </Text>
                    <Text variant="h2" mb="15px">
                      {billing.data.num_reports.month}
                    </Text>
                    <Text variant="body1">month-to-date</Text>
                  </Flex>
                )}
              </Card>
              <div className={classes.spacer} />
              <Card className={classes.infoCard}>
                {billing.isLoading && (
                  <ContentLoader height="107px" width="100%" />
                )}
                {hasBillingData && !billing.isLoading && (
                  <Flex direction="column">
                    <Text variant="body1" mb="15px">
                      <b>Rate</b>
                    </Text>
                    <Text variant="h2" mb="15px">
                      {formatDollarAmount(REPORT_COST, 0)}
                    </Text>
                    <Text variant="body1">per report</Text>
                  </Flex>
                )}
              </Card>
              <div className={classes.spacer} />
              <Card className={classes.infoCard}>
                {billing.isLoading && (
                  <ContentLoader height="107px" width="100%" />
                )}
                {hasBillingData && !billing.isLoading && (
                  <Flex direction="column">
                    <Text variant="body1" mb="15px">
                      <b>Total</b>
                    </Text>
                    <Text variant="h2" mb="15px">
                      {formatDollarAmount(
                        billing.data.num_reports.month * REPORT_COST,
                        0
                      )}
                    </Text>
                    <Text variant="body1">month-to-date</Text>
                  </Flex>
                )}
              </Card>
            </Flex>
          </CardSection>
          <Div mt="80px">
            <Text variant="body1" color="textSecondary">
              Need to update your payment source or billing contact? Email{" "}
              <Link href="mailto:accounts@pullrequest.com" fsize="14px">
                accounts@pullrequest.com
              </Link>
            </Text>
          </Div>
        </Card>
      </PageContainer>
    );
  }
}

BillingInfo.propTypes = {
  fetchBillingForOrg: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  billing: PropTypes.object,
  classes: PropTypes.object,
  theme: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  const { organization } = ownProps;
  return {
    billing: challengesSelectors.billingForOrg(state, organization.id),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(challengesActions, dispatch);
}

export default withTheme(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(BillingInfo))
);
