import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import { Theme } from "@mui/material";

import withTheme from "@mui/styles/withTheme";

import { Flex, Modal } from "Common";
import { Text } from "Common/components/Atoms/Text";

import APIRequestFormInput from "components/lib/APIRequestFormInput";

import { APIResult } from "utils/Api";
import { APIData, FilledAPIData } from "utils/apiData";
import { actions, selectors } from "reducers/organizations";

export type Props = {
  theme: Theme;
  isOpen: boolean;
  organization: {
    id: string;
  };
  onRequestClose: () => void;
  inviteMemberResults: APIData<any>;
};

export const InviteTeammateModal: React.FC<Props> = ({
  theme,
  isOpen,
  organization,
  onRequestClose,
  inviteMemberResults,
}) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  const inviteCallback = (result: APIResult<any>, keepOpen: boolean) => {
    if (result.success) {
      if (!keepOpen) {
        onRequestClose();
      }
      setEmail("");
    }
  };

  const sendInvite = (keepOpen: boolean) => {
    dispatch(
      actions.inviteMember({
        id: organization.id,
        email,
        callback: (result: APIResult<any>) => inviteCallback(result, keepOpen),
      })
    );
  };

  return (
    <Modal
      width={400}
      variant="generic"
      title="Invite Teammate"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      primaryButtonLabel="Send and Invite Another"
      primaryButtonColor={theme.palette.secondary.main}
      primaryButtonDisabled={inviteMemberResults.isLoading}
      onClickPrimaryButton={() => sendInvite(true)}
      showSecondaryButton={true}
      secondaryButtonLabel="Send Invite"
      secondaryButtonColor={theme.palette.primary.main}
      secondaryButtonDisabled={inviteMemberResults.isLoading}
      onClickSecondaryButton={() => sendInvite(false)}
    >
      <Flex direction="column">
        <Text variant="body2">
          Invite other members of your organization so they can view and
          download code challenge reports.
        </Text>
        <APIRequestFormInput
          m="12px 0 0 0"
          id="email"
          errorField="email"
          results={inviteMemberResults}
          fullWidth
          placeholder="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </Flex>
    </Modal>
  );
};

function mapStateToProps(state: any, ownProps: any) {
  const { organization } = ownProps;
  const inviteMemberResults = selectors.inviteMemberRequest(
    state,
    organization.id
  );
  return {
    inviteMemberResults,
  };
}

export default withTheme(connect(mapStateToProps)(InviteTeammateModal));
