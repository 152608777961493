import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";

import { APIData } from "utils/apiData";
import { actions, selectors } from "reducers/challengesV2";

import PrintTemplate from "components/lib/Template/PrintTemplate";

export interface CandidateProblem {
  template_id: string;
}

export interface CandidateStatus {
  language: string;
}

export type ViewCodeChallengeProps = {
  challengeId: string;
  candidateProblemResults: APIData<CandidateProblem>;
  candidateStatusResults: APIData<CandidateStatus>;
};

export const Problem: React.FC<ViewCodeChallengeProps> = ({
  challengeId,
  candidateProblemResults,
  candidateStatusResults,
}) => {
  const dispatch = useDispatch();

  // 0 - Fetch the relevant code challenge data
  useEffect(() => {
    if (candidateStatusResults.isEmpty) {
      dispatch(actions.candidate.status({ id: challengeId }));
    }
    if (candidateProblemResults.isEmpty) {
      dispatch(actions.candidate.problem({ id: challengeId }));
    }
  }, [candidateStatusResults, candidateProblemResults, dispatch, challengeId]);

  const problemData =
    candidateProblemResults.isFilled && candidateProblemResults.data;
  const statusData =
    candidateStatusResults.isFilled && candidateStatusResults.data;
  if (!problemData || !statusData) {
    return null;
  }

  return (
    <PrintTemplate
      readOnly
      templateId={problemData.template_id}
      variables={{
        LANGUAGE: statusData.language,
      }}
    />
  );
};

export type OwnProps = {
  match: {
    params: {
      challengeId: string;
    };
  };
};

function mapStateToProps(state: any, ownProps: OwnProps) {
  const challengeId = ownProps.match.params.challengeId;
  const candidateProblemResults = selectors.candidate.problem(state, {
    id: challengeId,
  });
  const candidateStatusResults = selectors.candidate.status(state, {
    id: challengeId,
  });
  return {
    challengeId,
    candidateProblemResults,
    candidateStatusResults,
  };
}

export default connect(mapStateToProps)(Problem);
