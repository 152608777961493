import React from "react";

import { InputField } from "Common";

import { getApiFieldError } from "utils/Api";
import { APIData, FilledAPIData } from "utils/apiData";

export interface Props {
  id: string;
  results: APIData<any>;
  errorField: string;
  fullWidth?: boolean;
  width?: string;
  type?: string;
  value?: string;
  onChange?: (e: { target: { value: string } }) => void;
  m?: string | number;
  mt?: string | number;
  placeholder?: string;
  required?: boolean;
}

export const APIRequestFormInput: React.FC<Props> = ({
  results,
  errorField,
  ...ownProps
}) => {
  const hasError = results.isFilled && results.success === false;
  const helperText = results.isFilled
    ? getApiFieldError(results.errorData, errorField)
    : null;
  return <InputField error={hasError} helperText={helperText} {...ownProps} />;
};

export default APIRequestFormInput;
