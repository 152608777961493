import React, { SyntheticEvent } from "react";
import { connect, useDispatch } from "react-redux";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { statusColor, statuses } from "utils/statusHelpers";
import { faDownload } from "@fortawesome/pro-solid-svg-icons";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";

import { Div, Flex, Label, FontIcon } from "Common";
import { Text } from "Common/components/Atoms/Text";

import { actions, selectors } from "reducers/challengesV2";
import { Screening } from "reducers/organizationsV2";

dayjs.extend(relativeTime);

export type Props = {
  dataKey: string;
  challengeId: string;
  challenge?: Screening;
};

export const ChallengeCell: React.FC<Props> = ({
  dataKey,
  challengeId,
  challenge,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  if (!challenge) {
    return null;
  }

  const cancelScreening = (e: SyntheticEvent) => {
    e.stopPropagation();
    dispatch(actions.screenings.cancel({ id: challengeId }));
  };

  const downloadReport = (e: SyntheticEvent) => {
    e.stopPropagation();
    dispatch(actions.screenings.download.report({ id: challengeId }));
  };

  switch (dataKey) {
    case "candidate_name":
      return <Text p="0 16px">{challenge.candidate_name}</Text>;
    case "email":
      return <Text p="0 16px">{challenge.candidate_email}</Text>;
    case "screening_type":
      return <Text p="0 16px">{challenge.challenge_type}</Text>;
    case "updated":
      return <Text p="0 16px">{dayjs(challenge.updated_at).fromNow()}</Text>;

    case "status": {
      const scheme = statusColor(challenge.status, theme);

      return (
        <Div p="0 16px">
          <Label background={scheme.background} color={scheme.color}>
            <b>{challenge.status}</b>
          </Label>
        </Div>
      );
    }

    case "action": {
      return (
        <Flex
          width="100%"
          justifyContent="center"
          alignItems="center"
          fontSize="14px"
        >
          {challenge.status === statuses.REPORT_COMPLETE && (
            <FontIcon
              icon={faDownload}
              color={theme.palette.secondary.main}
              size="lg"
              onClick={downloadReport}
            />
          )}
          {(challenge.status === statuses.INVITATION_SENT ||
            challenge.status === statuses.CREATED ||
            challenge.status === statuses.WAITING_ON_CANDIDATE) && (
            <FontIcon icon={faTimes} onClick={cancelScreening} />
          )}
        </Flex>
      );
    }

    default:
      return null;
  }

  return null;
};

type OwnProps = {
  challengeId: string;
};

function mapStateToProps(state: any, { challengeId }: OwnProps) {
  const challenge = selectors.screenings.entry(state, { id: challengeId });
  return {
    challenge: challenge.isFilled ? challenge.data : undefined,
  };
}

export default connect(mapStateToProps)(ChallengeCell);
