import React from 'react';
import MuiRadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import { Radio } from '../../Atoms/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import styled from 'styled-components';
import { SpacingProps } from '../../../types/MaterialUI';
import { Spacing } from '../../../styles/MaterialUI';
import { Text } from '../../Atoms/Text';

interface Option {
  label: React.ReactNode;
  value: string;
}

export interface Props extends Omit<RadioGroupProps, 'onChange'>, SpacingProps {
  options: Option[];
  onChange?: (value: string) => void;
}

const StyledRadioGroup = styled(MuiRadioGroup)`
  ${Spacing}
`;

const RadioGroupComponent: React.FC<Props> = (props: Props) => {
  const { options, onChange, ...passThruProps } = props;
  return (
    <StyledRadioGroup
      {...passThruProps}
      data-test="radiogroup-molecule"
      onChange={(event) => {
        if (onChange) {
          onChange(event.target.value);
        }
      }}
    >
      {options.map((option) => {
        return (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio color="secondary" />}
            label={<Text>{option.label}</Text>}
          />
        );
      })}
    </StyledRadioGroup>
  );
};

RadioGroupComponent.displayName = 'RadioGroup';

export const RadioGroup = RadioGroupComponent;
