import React from 'react';
import { Flex } from '../../Atoms/Flex';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';

interface Props {
  children: React.ReactNode;
  dataTest: string;
}

const WrapperComponent = React.forwardRef<HTMLDivElement, Props>(
  (props: Props, ref: Ref<HTMLDivElement>) => {
    return (
      <Flex center mb={4} ref={ref} dataTest={props.dataTest}>
        <MuiBreadcrumbs>{props.children}</MuiBreadcrumbs>
      </Flex>
    );
  }
);

WrapperComponent.displayName = 'BreadcrumbWrapper';

export const BreadcrumbWrapper = WrapperComponent;
