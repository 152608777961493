/**
 * Package for storing APIResult data as state. At its base, this package
 * defines the basic state mechanisms. Each API has an APIData instance
 * representing not only the lifecycle of the API request itself but also the
 * results.
 *
 * This APIData can either be an EmptyAPIData, LoadingAPIData, or a
 * FilledAPIData. An EmptyAPIData represents an API that has never been called.
 * A LoadingAPIData represents an API that's currently in progress (loading).
 * Once an API has succeeded or failed, its data will be replaced with a
 * FilledAPIData structure. To check for each type, simply use "isEmpty",
 * "isLoading", and "isFilled".
 *
 * This package also contains a mechanism to maintain an API's state via redux.
 * This is accomplished via the apiRequestStore and indirectAPIRequestStore
 * functions. However, you must first create an APITypes instance in order to
 * generate the type strings that the aforementioned stores use:
 *
 *  type MyRequest = {
 *    request: string;
 *  };
 *  type MyType = {
 *    response: string;
 *  };
 *  const types = new APITypes('MY_ACTION');
 *  const store = apiRequestStore<MyRequest, MyType>(types);
 *
 * This store can now be used with combineReducers in order to inject the state
 * lifecycle of your action into a store - the results of which will be an
 * APIData of your MyType type.
 *
 * In order to generate a saga for your action, you can also use the
 * takeAPIRequestSaga function. For this, your request needs to extend
 * APIRequest in order to pull in the "callback" property. This saga will
 * request the configured API via a "call" effect and either issue the success
 * or failure action depending on the result:
 *
 *  const mySaga = takeAPIRequestSaga<APIRequest<MyType>, MyType>(
 *    types,
 *    (request: APIRequest<MyType>) => call(Api.get, '/my_url/'),
 *  );
 *
 * If your action can have multiple execution "contexts" eg. it's an action
 * that can be run on one or more specific object instance, you can use the
 * indirectAPIStore function. This function requires that the request type
 * implement the Identifier interface and have a getKey method that returns a
 * string identifiying the object for which the action is being run.
 *
 *  class MyRequest implements Identifier {
 *    id: string;
 *    constructor(id: string) {
 *      this.id = id;
 *    }
 *    getKey(): string | undefined {
 *      return this.id;
 *    }
 *  }
 *
 *  const store = indirectAPIRequestStore<MyRequest, MyType>(types);
 */

import { APIResult } from "./Api";
import PropTypes from "prop-types";
import { call, put, takeEvery } from "redux-saga/effects";
import { CallEffect } from "@redux-saga/core/effects";

/******************************************************************************
 * Basic Functionality
 *****************************************************************************/

/** Type representing an API request that hasn't even been called. */
export type EmptyAPIData = {
  isEmpty: true;
  isLoading: false;
  isFilled: false;
};

/** State representing that the API hasn't been called */
export const emptyResults: EmptyAPIData = {
  isEmpty: true,
  isLoading: false,
  isFilled: false,
};

/** Type representing an API request that is currently in progress. */
export type LoadingAPIData = {
  isLoading: true;
  isFilled: false;
  isEmpty: false;
};

/** State representing an API that's loading */
export const loadingResults: LoadingAPIData = {
  isLoading: true,
  isFilled: false,
  isEmpty: false,
};

/**
 * Type representing a filled API request. It wraps an APIResult with extra
 * state so that its status can be tracked.
 */
export type FilledAPIData<T> = APIResult<T> & {
  /** Whether the request is in progress */
  isLoading: false;
  isFilled: true;

  /** Whether the request has even been called */
  isEmpty: false;
};

/**
 * State representing an API request. It can either be blank or filled with
 * results from the Api project.
 */
export type APIData<T> = EmptyAPIData | LoadingAPIData | FilledAPIData<T>;

/**
 * Store results from an API
 *
 * @param result - result to store
 */
export function storeResults<T>(result: APIResult<T>): FilledAPIData<T> {
  return {
    ...result,
    isLoading: false,
    isFilled: true,
    isEmpty: false,
  };
}

/**
 * Generate PropTypes object representing results with the given shape
 *
 * @param shape - shape to expect
 */
export function APIResultProp(shape: any): any {
  return PropTypes.shape({
    data: PropTypes.oneOfType([PropTypes.shape(shape), PropTypes.string]),
    success: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
    isEmpty: PropTypes.bool.isRequired,
  });
}

/**
 * Check to see if the action denoted by *oldResults* and *newResults* has
 * finished loading based on the past and current state.
 *
 * @param oldResults - old results
 * @param newResults - new results
 */
export function finishedLoading<T>(
  oldResults: APIData<T>,
  newResults: APIData<T>
): boolean {
  return oldResults.isLoading === true && newResults.isLoading === false;
}

/******************************************************************************
 * Redux integration
 *****************************************************************************/

/**
 * Class encapsulting common redux action types to be used to generating state
 * and saga logic
 *
 * @param prefix - prefix to prepend to each type
 */
export class APITypes {
  public clear: string;
  public request: string;
  public success: string;
  public failure: string;

  constructor(prefix: string) {
    this.clear = `${prefix}_CLEAR`;
    this.request = `${prefix}_REQUEST`;
    this.success = `${prefix}_SUCCESS`;
    this.failure = `${prefix}_FAILURE`;
  }
}

/** Interface used by the apiRequestStore for representing an action */
export interface APIAction<R, T> {
  type: string;
  request: R;
  response?: APIResult<T>;
}

/** Function representing the return type of apiRequestStore */
export type APIRequestStoreFunction<R, T> = (
  state: APIData<T>,
  action: APIAction<R, T>
) => APIData<T>;

/** Create a store that handles actions for the given types */
export function apiRequestStore<R, T>(
  types: APITypes
): APIRequestStoreFunction<R, T> {
  return (state: APIData<T> = emptyResults, action: APIAction<R, T>) => {
    switch (action.type) {
      case types.clear: {
        return emptyResults;
      }

      case types.request: {
        return loadingResults;
      }

      case types.failure:
      case types.success: {
        const { response } = action;

        // We shouldn't hit this in practice as the "response" value should
        // always be filled out. However, in the case where we do get an empty
        // response, go ahead and just return the state as we can't pass
        // undefined into storeResults.
        if (!response) {
          return state;
        }
        return storeResults(response);
      }

      default:
        return state;
    }
  };
}

/**
 * Function for finding the apiRequestStore state from the top-level state
 * object.
 */
export type APIFindState<T> = (state: any) => APIData<T> | undefined;

/** Selector that can be used to retrieve an action's state from the store. */
export type APISelector<T> = (state: any) => APIData<T>;

/** Create an APISelector using the given findState function */
export function apiRequestSelector<T>(
  findState: APIFindState<T>
): APISelector<T> {
  return (state: any) => {
    return findState(state) || emptyResults;
  };
}

/** Arguments to provide to APIRequest instances */
export type APIRequest<T> = {
  /** Function to call when the API request saga completes */
  callback?: (entry: APIResult<T>) => void;
};

/**
 * Function to generate a takeEvery that handles the "request" of the given
 * APITypes.
 *
 * @param types - APITypes to generate for
 * @param submitRequest - effect representing the request
 */
export function takeAPIRequestSaga<R extends APIRequest<T>, T>(
  types: APITypes,
  submitRequest: (request: R) => CallEffect,
  onSuccess?: (request: R, response: APIResult<T>) => void
) {
  return takeEvery(
    // Configure the given request to be handled by this saga
    types.request,

    // The body of the handler saga
    function* (action: APIAction<R, T>) {
      const { request } = action;
      const { callback } = request;
      const response: APIResult<T> = yield submitRequest(request);
      if (!response.success) {
        yield put({
          ...action,
          type: types.failure,
          response,
        });
        if (callback) {
          yield call(callback, response);
        }
        return;
      }
      yield put({
        ...action,
        type: types.success,
        response,
      });

      if (callback) {
        yield call(callback, response);
      }

      if (onSuccess) {
        yield call(onSuccess, request, response);
      }
    }
  );
}

/******************************************************************************
 * "indirect" extension of redux integration
 *****************************************************************************/

/**
 * Interface that must be implemented by a request type to use the
 * indirectAPIRequestStore function
 */
export interface Identifier {
  getKey(): string | undefined;
}

export abstract class IndirectAPIRequest<T> implements Identifier {
  callback?: (entry: APIResult<T>) => void;

  constructor(args?: APIRequest<T>) {
    this.callback = args?.callback;
  }

  public abstract getKey(): string | undefined;
}

/** Arguments that just ask for an id */
export type IDAPIArgs = {
  id?: string;
};

/** Arguments to provide an indirect request that takes an "id" */
export type IndirectIDAPIArgs<T> = APIRequest<T> & IDAPIArgs;

/** Request for an indirect API that takes an "id" */
export class IndirectIDAPIRequest<T> extends IndirectAPIRequest<T> {
  id?: string;

  constructor(args?: IndirectIDAPIArgs<T>) {
    super(args);
    this.id = args?.id;
  }

  public getKey(): string | undefined {
    return this.id;
  }
}

/** Indirect action for APIs that simply take an "id" field */
export function indirectIDAPIAction<T>(typeString: string) {
  return (args?: IndirectIDAPIArgs<T>) => ({
    type: typeString,
    request: new IndirectIDAPIRequest(args),
  });
}

/** Type representing the store data of the indirectAPIRequestStore function */
export type IndirectAPIData<T> = {
  [key: string]: APIData<T>;
};

/** Empty results of the indirectAPIRequestStore function */
export const emptyIndirectAPIData = {};

/**
 * Function representing the return value of the indirectAPIRequestStore
 * function
 */
export type IndirectAPIRequestStoreFunction<R, T> = (
  state: IndirectAPIData<T>,
  action: APIAction<R, T>
) => IndirectAPIData<T>;

/**
 * Store function that take a request type and can maintain many of the same
 * type of action that each affect a different object.
 */
export function indirectAPIRequestStore<R extends Identifier, T>(
  types: APITypes
): IndirectAPIRequestStoreFunction<R, T> {
  const store = apiRequestStore<R, T>(types);
  return (
    state: IndirectAPIData<T> = emptyIndirectAPIData,
    action: APIAction<R, T>
  ) => {
    switch (action.type) {
      case types.clear: {
        const { request } = action;
        const key = request.getKey();

        // Only clear out the one key if given. If not, clear out the whole
        // state
        if (key) {
          const apiState = state[key] || emptyResults;
          return {
            ...state,
            [key]: store(apiState, action),
          };
        }
        return emptyIndirectAPIData;
      }

      case types.request:
      case types.failure:
      case types.success: {
        const { request } = action;
        const key = request.getKey();
        if (!key) {
          return state;
        }

        const apiState = state[key] || emptyResults;
        return {
          ...state,
          [key]: store(apiState, action),
        };
      }

      default:
        return state;
    }
  };
}

/**
 * Function for finding the indirectAPIRequestStore state from the top-level
 * state object.
 */
export type IndirectAPIFindState<T> = (state: any) => IndirectAPIData<T>;

/** Selector that can be used to retrieve an action's state from the store. */
export type IndirectAPISelector<R, T> = (state: any, request: R) => APIData<T>;

/** Create an IndirectAPISelector using the given findState function */
export function indirectAPIRequestSelector<R extends Identifier, T>(
  findState: IndirectAPIFindState<T>
): IndirectAPISelector<R, T> {
  return (state: any, request: R) => {
    const key = request.getKey();
    if (!key) {
      return emptyResults;
    }
    return findState(state)[key] || emptyResults;
  };
}

/** Indirect selector for APIs that simply take an "id" */
export function indirectIDAPIRequestSelector<T>(
  findState: IndirectAPIFindState<T>
) {
  const selector = indirectAPIRequestSelector<IndirectIDAPIRequest<T>, T>(
    findState
  );
  return (state: any, args: IndirectIDAPIArgs<T>) => {
    const request = new IndirectIDAPIRequest(args);
    return selector(state, request);
  };
}
