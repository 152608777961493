import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import { Theme } from "@mui/material";
import withTheme from "@mui/styles/withTheme";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";

import { Div, Flex, Modal, UserAvatar } from "Common";
import { Text } from "Common/components/Atoms/Text";
import { DialogSelect, Option } from "Common/components/Molecules/DialogSelect";

import APIRequestFormInput from "components/lib/APIRequestFormInput";

import { APIResult } from "utils/Api";
import { APIData, FilledAPIData } from "utils/apiData";
import { actions, selectors } from "reducers/organizations";

export type Props = {
  theme: Theme;
  isOpen: boolean;
  member?: {
    id: string;
    role: string;
    first_name: string;
    last_name: string;
    photo_url?: string;
  };
  onRequestClose: () => void;
  updateMemberResults?: APIData<any>;
};

export const ownerOption = { value: "owner", label: "Owner" };
export const memberOption = { value: "member", label: "Member" };

const roleMap: { [key: string]: Option } = {
  owner: ownerOption,
  member: memberOption,
};

const roleOptions = [ownerOption, memberOption];

export const UpdateRoleModal: React.FC<Props> = ({
  theme,
  isOpen,
  member,
  onRequestClose,
  updateMemberResults,
}) => {
  const dispatch = useDispatch();
  const [role, setRole] = useState<Option | null>(null);

  // 0 - set role to the given member's role when it updates
  useEffect(() => {
    if (!member) {
      return;
    }

    // If we get a new member, go ahead and set the current role option to that
    // member's current role and clear out any stale actions.
    setRole(roleMap[member.role] || null);
    dispatch(actions.updateMemberClear());
  }, [setRole, member, dispatch]);

  const updateCallback = (result: APIResult<any>) => {
    if (result.success) {
      onRequestClose();
    }
  };

  const updateMemberRole = () => {
    if (!member || !role) {
      return;
    }

    dispatch(
      actions.updateMember({
        id: member.id,
        role: role.value,
        callback: updateCallback,
      })
    );
  };

  if (!member) {
    return null;
  }

  return (
    <Modal
      icon={faPencil}
      variant="generic"
      title="Update Organization Role"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      primaryButtonLabel="Update"
      primaryButtonColor={theme.palette.secondary.main}
      primaryButtonDisabled={updateMemberResults?.isLoading}
      onClickPrimaryButton={updateMemberRole}
    >
      <Flex mt={2} width="100%" justifyContent="center" alignItems="center">
        <Div p="0 16px" fontSize="14px">
          <UserAvatar
            imageUrl={member.photo_url}
            altText={`${member.first_name} ${member.last_name}`}
            text={`${member.first_name.charAt(0)}${member.last_name.charAt(0)}`}
            textBackground={theme.palette.info.dim}
            fontSize="13px"
            shadow="none"
          />
        </Div>
        <Div p="0 16px" fontSize="14px">
          {member.first_name} {member.last_name}
        </Div>
        <DialogSelect
          width="200px"
          placeholder="Select role"
          options={roleOptions}
          value={role || undefined}
          onChange={(opt) => {
            setRole(opt);
          }}
        />
      </Flex>
    </Modal>
  );
};

interface OwnProps {
  memberId?: string;
}

function mapStateToProps(state: any, ownProps: OwnProps) {
  const { memberId } = ownProps;
  const updateMemberResults = selectors.updateMemberRequest(state, memberId);
  const member = selectors.members.entry(state, memberId)?.data;
  return {
    member,
    updateMemberResults,
  };
}

export default withTheme<Theme, React.JSXElementConstructor<Props>>(
  connect(mapStateToProps)(UpdateRoleModal)
);
