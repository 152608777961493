import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import React from 'react';
import { Card, Props as CardProps } from '../../Atoms/Card';
import { Flex } from '../../Atoms/Flex';
import { FontIcon } from '../../Atoms/FontIcon';

type BannerProps = Omit<CardProps, 'borderLeft' | 'display'>;

export interface Props extends BannerProps {
  icon?: IconDefinition;
}

const BannerComponent = React.forwardRef<HTMLDivElement, Props>(
  (props: Props, ref: Ref<HTMLDivElement>): JSX.Element => {
    const { icon, children, color, ...passThroughProps } = props;
    return (
      <Card
        {...passThroughProps}
        ref={ref}
        color={color}
        borderLeft={`5px solid ${color}`}
        display="block"
        dataTest="banner-molecule"
      >
        <Flex justifyContent="flex-start">
          {icon && <FontIcon icon={icon} color={color} size="2x" />} {children}
        </Flex>
      </Card>
    );
  }
);

BannerComponent.defaultProps = {
  p: 4,
};
BannerComponent.displayName = 'Banner';

export const Banner = BannerComponent;
