import React from 'react';
import { Div } from '../../Atoms/Div';
import { Col, Flex } from '../../Atoms/Flex';
import { Text } from '../../Atoms/Text';
import { Breadcrumbs } from '../../Molecules/Breadcrumbs';
import { ControlledBreadcrumbs } from '../../Molecules/Breadcrumbs/ControlledBreadcrumbs';
import {
  Breadcrumb,
  ControlledBreadcrumb,
} from '../../Molecules/Breadcrumbs/Breadcrumb';
import { SpacingProps } from '../../../types/MaterialUI';

export interface Props extends SpacingProps {
  /** A title string/node for the page */
  pagetitle?: React.ReactNode;
  /** Adds a component to the right-hand side of the title block */
  titleChildren?: React.ReactNode;
  /** Adds a link at the end of the title, for project pages to link to repo */
  titleLink?: React.ReactNode;
  subtitle?: React.ReactNode;
  height?: string;
  width?: string;
  breadcrumbs?: Breadcrumb[];
  controlledBreadcrumbs?: ControlledBreadcrumb[];
  children?: React.ReactNode;
  maxWidth?: string | number;
  className?: string;
}

const PageContainer = (props: Props): JSX.Element => {
  const {
    pagetitle,
    subtitle,
    children,
    titleChildren,
    breadcrumbs,
    controlledBreadcrumbs,
    titleLink,
    ...passThroughProps
  } = props;
  return (
    <Col dataTest="pagecontainer" boxSizing="border-box" {...passThroughProps}>
      {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      {controlledBreadcrumbs && (
        <ControlledBreadcrumbs breadcrumbs={controlledBreadcrumbs} />
      )}
      {pagetitle && (
        <Div mb={4}>
          <Flex>
            <Flex alignItems="center" flexGrow={1}>
              <Text variant="h2" mr="20px">
                {pagetitle}
              </Text>
              {titleLink}
            </Flex>
            {titleChildren}
          </Flex>
          {subtitle && React.isValidElement(subtitle) && subtitle}
          {subtitle && !React.isValidElement(subtitle) && (
            <Text pt={2} variant="body1">
              {subtitle}
            </Text>
          )}
        </Div>
      )}
      {children}
    </Col>
  );
};

PageContainer.displayName = 'PageContainer';

PageContainer.defaultProps = {
  py: '30px',
  px: '48px',
  maxWidth: 1093,
  width: '100%',
  ml: 'auto',
  mr: 'auto',
};

export { PageContainer };
