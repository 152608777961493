import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useTheme } from "@mui/material/styles";

import { Modal } from "Common";
import { DialogSelect, Option } from "Common/components/Molecules/DialogSelect";

import { IndirectAPIData } from "utils/apiData";
import { actions, Product, selectors } from "reducers/challengesV2";

export type ImportFormModalProps = {
  isOpen: boolean;
  products: IndirectAPIData<Product>;
  productList: Array<Product>;
  onUpdate?: (product: Product | null) => void;
  onRequestClose?: () => void;
};

export const ImportFormModal: React.FC<ImportFormModalProps> = ({
  isOpen,
  products,
  productList,
  onUpdate,
  onRequestClose,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  // 0 - fetch products to list here
  useEffect(() => {
    dispatch(actions.products.list({ fetchAll: true, reset: true }));
  }, [dispatch]);

  const options: Array<Option> = productList.map((product) => ({
    value: product.id,
    label: product.name,
  }));

  const selectedOption = options.find(
    (option) => selectedProduct && option.value === selectedProduct.id
  );

  const onClose = () => {
    setSelectedProduct(null);
    onRequestClose && onRequestClose();
  };

  const onClickPrimaryButton = () => {
    onUpdate && onUpdate(selectedProduct);
    onRequestClose && onRequestClose();
    setSelectedProduct(null);
  };

  const onSelectProduct = (option: Option | null) => {
    const product = option && products[option.value];
    const productData = product && product.isFilled && product.data;
    setSelectedProduct(productData || null);
  };

  return (
    <Modal
      width={600}
      variant="generic"
      title="Pick Product to Import From"
      isOpen={isOpen}
      onRequestClose={onClose}
      primaryButtonLabel="Pick"
      primaryButtonColor={theme.palette.primary.main}
      onClickPrimaryButton={onClickPrimaryButton}
      showSecondaryButton={true}
      secondaryButtonLabel="Cancel"
      secondaryButtonColor={theme.palette.secondary.main}
      onClickSecondaryButton={onClose}
    >
      <DialogSelect
        mt={2}
        width="400px"
        placeholder="Select product"
        options={options}
        value={selectedOption || undefined}
        onChange={(option: Option | null) => onSelectProduct(option)}
      />
    </Modal>
  );
};

function mapStateToProps(state: any, ownProps: {}) {
  const products = selectors.products.entries(state);
  const productList = selectors.products.list(state);
  return {
    products,
    productList,
  };
}

export default connect(mapStateToProps)(ImportFormModal);
