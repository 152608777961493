import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { faTimes, faPencil } from "@fortawesome/pro-solid-svg-icons";

import { Div, Flex, Modal, Button, FontIcon, RouteLink } from "Common";
import { DialogSelect, Option } from "Common/components/Molecules/DialogSelect";

import { APIData, FilledAPIData, IndirectAPIData } from "utils/apiData";
import { getMapEntries } from "utils/pageMapV2";
import { PaginatedAPIData } from "utils/pagination";
import { Template, TemplateKey } from "reducers/templates";
import { Product } from "reducers/challengesV2";
import {
  actions as templateActions,
  selectors as templateSelectors,
} from "reducers/templates";

export type ChallengeTemplateProps = {
  title: string;
  template: Template | null;
  templates: IndirectAPIData<Template>;
  onChange?: (template: Template | null) => void;
  templateList: Array<Template>;
};

export const ChallengeTemplate: React.FC<ChallengeTemplateProps> = ({
  title,
  template,
  onChange,
  templates,
  templateList,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
    template
  );

  const options: Array<Option> = templateList.map((template) => ({
    value: template.id,
    label: template.name,
  }));

  const onClickEdit = () => {
    dispatch(templateActions.fetchTemplates({ fetchAll: true, reset: true }));
    setIsOpen(true);
  };

  const selectedOption =
    selectedTemplate &&
    options.find((option) => option.value === selectedTemplate.id);

  const onClearTemplate = () => {
    setSelectedTemplate(null);
    onChange && onChange(null);
  };

  const onSelectTemplate = (option: Option | null) => {
    const template = option && templates[option.value];
    const templateData = template && template.isFilled && template.data;
    setSelectedTemplate(templateData || null);
  };

  const onRequestClose = () => {
    setSelectedTemplate(template);
    setIsOpen(false);
  };
  const onClickPrimaryButton = () => {
    onChange && onChange(selectedTemplate);
    setIsOpen(false);
  };

  return (
    <>
      <Flex center>
        <Div fontWeight="bold" fontSize="1.375rem" width="200px">
          {title}
        </Div>
        <Div
          borderRadius="5px"
          color={theme.palette.primary.dim}
          background={theme.palette.primary.main}
          ml={2}
          p="5px"
          style={{ cursor: "pointer" }}
          onClick={onClickEdit}
        >
          <FontIcon icon={faPencil} />
        </Div>
        {template ? (
          <Div
            borderRadius="5px"
            color={theme.palette.error.dim}
            background={theme.palette.error.main}
            ml={1}
            p="5px 8px 5px 8px"
            style={{ cursor: "pointer" }}
            onClick={onClearTemplate}
          >
            <FontIcon icon={faTimes} />
          </Div>
        ) : null}
        <Div ml={2}>
          {template ? (
            <RouteLink
              to={`/staff/configure/templates/${template.id}`}
              textDecoration="none"
              target="_blank"
            >
              {template.name}
            </RouteLink>
          ) : (
            "Unset"
          )}
        </Div>
      </Flex>
      <Modal
        width={600}
        variant="generic"
        title="Pick Template"
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        primaryButtonLabel="Pick Template"
        primaryButtonColor={theme.palette.primary.main}
        primaryButtonTextColor={theme.palette.primary.dim}
        onClickPrimaryButton={onClickPrimaryButton}
        showSecondaryButton={true}
        secondaryButtonLabel="Cancel"
        secondaryButtonColor={theme.palette.secondary.main}
        secondaryButtonTextColor={theme.palette.secondary.dim}
        onClickSecondaryButton={onRequestClose}
      >
        <DialogSelect
          mt={2}
          width="400px"
          placeholder="Select template"
          options={options}
          value={selectedOption || undefined}
          onChange={onSelectTemplate}
        />
      </Modal>
    </>
  );
};

function mapStateToProps(state: any, ownProps: {}) {
  const templateList = templateSelectors.templateList(state);
  const templates = templateSelectors.templates(state);
  return {
    templateList,
    templates,
  };
}

export default connect(mapStateToProps)(ChallengeTemplate);
