/** Package for parsing out directives */

const visit = require("unist-util-visit");

export const DirectiveType = "directive";
export const DirectiveArgumentType = "directiveArgument";

export const directiveRegexp = /^\.\. (\w+):: ?(.*)/;
export const directiveArgRegexp = / *:(\w+): ?(.*)/;

/**
 * Parse the given text node and transform it into a directive node with its
 * children as argument nodes. These come in as:
 *
 *  .. <directive>::[ <value>]
 *      [:<arg_name>:[ <arg_value>]]
 *
 * For example:
 *
 *  .. template:: template_id
 *      :passargs:
 *      :version: 1
 *
 * Would become:
 *
 *  {
 *    "type": "directive",
 *    "directive": "template",
 *    "value": "template_id",
 *    "children": [
 *      {
 *        "type": "directiveArgument",
 *        "name": "passargs",
 *        "value": undefined
 *      },
 *      {
 *        "type": "directiveArgument",
 *        "name": "version",
 *        "value": "1"
 *      },
 *    ]
 *  }
 */
export function parseTextNode(node: any) {
  const { children = [], value } = node;
  const match = value.match(directiveRegexp);
  if (!match) {
    return;
  }

  node.type = DirectiveType;
  node.directive = match[1];
  node.value = match[2];

  const args = [];
  const lines = value.split(/\r?\n/);
  const argLines = lines.slice(1);
  for (let line of argLines) {
    const match = line.match(directiveArgRegexp);
    if (!match) {
      continue;
    }

    args.push({
      type: DirectiveArgumentType,
      name: match[1],
      value: match[2],
    });
  }

  node.children = [...args, ...children];
}

export function directives(options = {}) {
  return (tree: any, file: any) => {
    visit(tree, "text", parseTextNode);
  };
}

function skipNode(node: any, index: number, parent: any) {
  parent.children.splice(index, 1);
  return [visit.SKIP, index];
}

export function skipDirectives(options = {}) {
  return (tree: any, file: any) => {
    visit(tree, DirectiveType, skipNode);
    visit(tree, DirectiveArgumentType, skipNode);
  };
}
