import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { useTheme } from '@mui/material/styles';
import { typography, sizing, spacing } from '@mui/system';

const borderStyles = css`
  border: 1px solid;
  border-color: ${(props) => props.borderColor || props.theme.palette.divider};
  border-radius: 4px;
`;

const focuswithShadow = css`
  border: 1px solid ${(props) => props.theme.palette.secondary.main} !important;
  outline: none;
  box-shadow: 0 0 1px 4px ${(props) => props.theme.palette.secondary.dim};
  transition: 0.2s;
`;

const StyledAsyncSelect = styled(AsyncSelect)`
  font-size: ${(props) =>
    props.fontSize || props.theme.typography.body1.fontSize};
  ${typography}
  .fetch-select__control {
    ${borderStyles};
    ${sizing};
    ${spacing};
    background-color: ${(props) => props.theme.palette.background.paper};
    padding: ${(props) => props.theme.spacing(1)};
    white-space: nowrap;
    cursor: text;
    &--is-focused {
      ${focuswithShadow};
    }
    &:hover {
      ${borderStyles};
    }
    .fetch-select__dropdown-indicator {
      display: none;
    }
  }
  .fetch-select__placeholder {
    color: ${(props) => props.theme.palette.grey[500]};
  }
  .fetch-select__indicator {
    cursor: pointer;
  }
  .fetch-select__indicator-separator {
    display: none;
  }
  .fetch-select__menu {
    color: ${(props) => props.theme.palette.text.primary};
    background-color: ${(props) => props.theme.palette.background.paper};
    width: ${(props) => props.width}px;
    margin-top: ${(props) => props.theme.spacing(1)};
    padding-top: ${(props) => props.theme.spacing(0.75)};
    padding-bottom: ${(props) => props.theme.spacing(0.75)};
  }
  .fetch-select__menu-list {
    cursor: pointer;
    padding: 0 ${(props) => props.theme.spacing(0.5)};
  }
  .fetch-select__option {
    border-radius: 4px;
    padding: ${(props) => props.theme.spacing(1)};
    &--is-focused {
      background: ${(props) => props.theme.palette.action.selected} !important;
    }
    &--is-selected {
      background: ${(props) => props.theme.palette.action.selected};
      color: ${(props) => props.theme.palette.text.primary};
      &:hover {
        background: ${(props) => props.theme.palette.action.hover} !important;
      }
    }
    &:hover {
      cursor: pointer;
      background: ${(props) => props.theme.palette.action.hover};
    }
  }
  .fetch-select__input {
    color: ${(props) => props.theme.palette.text.primary};
    width: 100%;
    input {
      width: 100% !important;
      opacity: 1 !important;
    }
  }
`;

export function FetchSelect(props) {
  const theme = useTheme();
  return (
    <StyledAsyncSelect
      theme={theme}
      {...props}
      className="fetch-select-container"
      classNamePrefix="fetch-select"
    ></StyledAsyncSelect>
  );
}

FetchSelect.propTypes = {
  placeholder: PropTypes.string,
  /** Handle change events on the input */
  onInputChange: PropTypes.func,
  /** Function that returns a promise or callback, which is the set of options to be used once the promise resolves. */
  loadOptions: PropTypes.func,
  /** The default set of options to show before the user starts searching.
   * When set to true, the results for loadOptions('') will be autoloaded. */
  defaultOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  /** If cacheOptions is truthy, then the loaded data will be cached. The cache will remain until cacheOptions changes value. */
  cacheOptions: PropTypes.bool,
  isClearable: PropTypes.bool,
  /** Width props also include: minWidth, maxWidth.
   * https://material-ui.com/system/sizing/ */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Height props also include: minHeight, maxHeight
   * https://material-ui.com/system/sizing/ */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Margin props also include: mt, mr, mb, ml, mx, my
   * https://material-ui.com/system/spacing/  */
  m: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Typography props also include: fontFamily, fontStyle, fontWeight, letterSpacing, lineHeight, and textAlign
   *  https://material-ui.com/system/typography/ */
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Border color of container */
  borderColor: PropTypes.string,
  inputValue: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.string,
    }),
  ]),
  onChange: PropTypes.func,
  components: PropTypes.object,
  menuIsOpen: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  blurInputOnSelect: PropTypes.bool,
};
