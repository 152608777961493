/** Package for interacting with the Codeportal API. */

/**
 * Global values pertinant to this Api package. These are private to the
 * package itself but can be modified with the setAuthToken and clearAuthToken
 * functions.
 */
const apiGlobal: {
  auth: {
    token: string | null;
    expiry: string | null;
  };
} = {
  auth: {
    token: localStorage.getItem("auth.token"),
    expiry: localStorage.getItem("auth.expiry"),
  },
};

/**
 * Set the authentication token and expiry values to be used.
 *
 * @param token - the token to set
 * @param expiry - the expiry datestring to set
 */
export function setAuthToken({
  token,
  expiry,
}: {
  token: string;
  expiry: string;
}): void {
  localStorage.setItem("auth.token", token);
  localStorage.setItem("auth.expiry", expiry);
  apiGlobal.auth.token = token;
  apiGlobal.auth.expiry = expiry;
}

/**
 * Clear the authentication token and expiry
 */
export function clearAuthToken(): void {
  localStorage.removeItem("auth.token");
  localStorage.removeItem("auth.expiry");
  apiGlobal.auth.token = null;
  apiGlobal.auth.expiry = null;
}

const BASE_URL = "/api/";

const host = window.location.protocol + "//" + window.location.host;
const hostMinusPort =
  window.location.protocol + "//" + window.location.hostname;

/**
 * Normalize the given URL with respect to the backend API server. In this way,
 * the package can take absolute URLs, paths, and URLs with invalid schemas.
 *
 * @param url - url to normalize
 */
function normalizeURL(url: string): string {
  if (url.startsWith(host)) {
    // make absolute urls relative if needed
    url = url.substr(host.length);
  } else if (url.startsWith(hostMinusPort)) {
    // Sometimes, we get "next_page" URLs that don't include the port. That
    // seems to be a flaw in our PHP pagination layer. Just do the same thing
    // if port isn't given.
    url = url.substr(hostMinusPort.length);
  }

  if (url.startsWith(BASE_URL)) {
    return url;
  } else if (url.startsWith("/")) {
    return BASE_URL + url.slice(1);
  } else {
    return BASE_URL + url;
  }
}

/** Error for a single field */
export type APIErrorEntry = Array<string> | string;

/** Type representing error data from Codeportal server */
export type APIErrorObj = {
  [key: string]: APIErrorObj | APIErrorEntry;
};

export type APIErrorData = APIErrorObj | APIErrorEntry;

export function isAPIErrorObj(
  errorData: APIErrorData
): errorData is APIErrorObj {
  return (errorData as APIErrorObj).type !== undefined;
}

/**
 * Find the error string from the given errorData using the given path, which
 * is a "."-separated list of strings to the final erorr.
 */
export function getApiFieldError(errorData: any, path: string): string | null {
  const getErrorForPathToks = (
    entry: any,
    pathToks: Array<string>
  ): string | null => {
    // We have nothing left in our path. The entry should either be an array of
    // strings or a string itself. If it's a string, just return it. If not,
    // attempt to return the first element.
    if (pathToks.length <= 0) {
      if (typeof entry === "string") {
        return entry;
      }

      if (entry && entry.length && entry.length > 0) {
        const error = entry[0];
        return typeof error === "string" ? error : null;
      }

      return null;
    }

    // We still have more to go. Find the sub-entry and discover the error from
    // it.
    if (typeof entry === "object") {
      const subEntry = entry[pathToks[0]];
      if (!subEntry) {
        return null;
      }

      return getErrorForPathToks(subEntry, pathToks.slice(1));
    }

    return null;
  };
  const pathToks = path.split(".");
  return getErrorForPathToks(errorData, pathToks);
}

/** Interface encapsulating the result of an API call */
export interface APIResult<T> {
  /** URL of the request */
  url: string;

  /** Whether or not the call succeeded */
  success: boolean;

  /** HTTP status of the response */
  status?: number;

  /** Content-Type header of the response */
  contentType?: string;

  /** Content-Disposition header of the response */
  contentDisposition?: string;

  /** Error data for failed responses */
  errorData: APIErrorData;

  /** Specific data of the response */
  data?: T;
}

function getCookieValue(key: string): string | null | undefined {
  const results = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return results ? results.pop() : "";
}

type Headers = { [key: string]: string };
type Method = "GET" | "POST" | "DELETE" | "PUT" | "PATCH";

/**
 * Generate common headers related to the given method. If the method is of a
 * type that can be used to update data on the server, go ahead and add in the
 * csrf token header.
 *
 * @param method - HTTP method that's being used
 */
function getHeaders(method: Method): Headers {
  const headersForRequest: Headers = {};
  switch (method) {
    case "POST":
    case "PUT":
    case "DELETE":
    case "PATCH": {
      const cookie = getCookieValue("csrftoken");
      if (cookie) {
        headersForRequest["X-CSRFTOKEN"] = decodeURIComponent(cookie);
      }
      break;
    }
  }
  return headersForRequest;
}

/**
 * Interface representing options that can be given for the underlying API
 * commands in this package
 */
export interface FetchOpts {
  /** Method to issue with the request */
  method?: Method;

  /** Headers to set explicitly */
  headers?: Headers;

  /** The underlying body of the request */
  body?: string | FormData;
}

/**
 * Issue a raw fetch operation
 *
 * @param url - url to request
 * @param opts - options to use
 */
export function fetchApiRaw<T>(
  url: string,
  opts: FetchOpts
): Promise<APIResult<T>> {
  url = normalizeURL(url);
  const result: APIResult<T> = {
    success: false,
    url,
    errorData: {},
  };
  const method = opts.method || "GET";
  const headers = opts.headers || {};
  return fetch(url, {
    credentials: "include",
    ...opts,
    headers: {
      ...getHeaders(method),
      ...headers,
    },
  })
    .then((response) => {
      result.status = response.status;
      result.success = response.ok;

      // No content
      if (result.status === 204) {
        return null;
      }

      const gen = response.headers.entries();
      for (let entry = gen.next(); !entry.done; entry = gen.next()) {
        const [headerName, headerValue] = entry.value;
        if (headerName === "content-type") {
          result.contentType = headerValue;
          continue;
        }
        if (headerName === "content-disposition") {
          result.contentDisposition = headerValue;
          continue;
        }
      }

      if (result.contentType === "application/json") {
        return response.json();
      }

      // Deal with the blob however you like.
      return response.blob();
    })
    .then((data) => {
      if (result.success) {
        result.data = data;
      } else {
        result.errorData = data;
      }
      return result;
    });
}

/**
 * Issue an unauthenticated fetch operation
 *
 * @param url - url to fetch
 * @param opts - options to use
 * @param body - body of the request
 */
function fetchApiUnauthed<T>(
  url: string,
  opts: FetchOpts = {},
  body?: Object
): Promise<APIResult<T>> {
  const { headers = {} } = opts;
  const newHeaders: Headers = {};
  if (body) {
    newHeaders["Content-Type"] = "application/json";
  }
  return fetchApiRaw(url, {
    body: body && JSON.stringify(body),
    ...opts,
    headers: {
      ...newHeaders,
      ...headers,
    },
  });
}

/**
 * Issue an authenticated fetch operation
 *
 * @param url - url to fetch
 * @param opts - options to use
 * @param body - body of the request
 */
function fetchApi<T>(
  url: string,
  opts: FetchOpts = {},
  body?: Object
): Promise<APIResult<T>> {
  const { headers = {} } = opts;
  const newHeaders: Headers = {};
  if (apiGlobal.auth.token) {
    newHeaders["Authorization"] = `Token ${apiGlobal.auth.token}`;
  }
  return fetchApiUnauthed<T>(
    url,
    {
      ...opts,
      headers: {
        ...newHeaders,
        ...headers,
      },
    },
    body
  ).then((result) => {
    // Received "Unauthorized". Remove our token.
    if (result.status === 401) {
      clearAuthToken();
    }
    return result;
  });
}

/**
 * Issue a get
 *
 * @param url - url to fetch
 */
function get<T>(url: string): Promise<APIResult<T>> {
  return fetchApi<T>(url, { method: "GET" });
}

/**
 * Issue an unauthenticated get
 *
 * @param url - url to fetch
 */
function getUnauthed<T>(url: string): Promise<APIResult<T>> {
  return fetchApiUnauthed<T>(url, { method: "GET" });
}

/**
 * Issue a post
 *
 * @param url - url to fetch
 * @param body - body to send in the request
 */
function post<T>(url: string, body?: Object): Promise<APIResult<T>> {
  return fetchApi<T>(url, { method: "POST" }, body);
}

/**
 * Issue an unauthenticated post
 *
 * @param url - url to fetch
 * @param body - body to send in the request
 */
function postUnauthed<T>(url: string, body?: Object): Promise<APIResult<T>> {
  return fetchApiUnauthed<T>(url, { method: "POST" }, body);
}

/**
 * Issue a put
 *
 * @param url - url to fetch
 * @param body - body to send in the request
 */
function put<T>(url: string, body?: Object): Promise<APIResult<T>> {
  return fetchApi<T>(url, { method: "PUT" }, body);
}

/**
 * Issue a delete
 *
 * @param url - url to fetch
 */
function del<T>(url: string): Promise<APIResult<T>> {
  return fetchApi<T>(url, { method: "DELETE" });
}

/**
 * Download a result as a file
 *
 * @param result - result to download
 */
function downloadResult(result: APIResult<Blob>) {
  if (!result.data) {
    return;
  }

  // This looks funky, but this is the "accepted" way to download a file in
  // JavaScript. The given result should have a blob for data, now use it to
  // create a "link" and "click it".
  let downloadURL = window.URL.createObjectURL(result.data);
  let a = document.createElement("a");
  a.href = downloadURL;

  // Now, we need to find the filename and set the "download" value of the link

  // We need this value to figure out the filename. If we don't have it, just
  // return and do nothing.
  if (!result.contentDisposition) {
    return;
  }

  // Like 'inline; filename="filename.pdf"'
  const dispositionToks = result.contentDisposition.split(" ");
  for (let i in dispositionToks) {
    const tok = dispositionToks[i];
    if (tok.startsWith('filename="')) {
      a.download = tok.substr(10, tok.length - 11);
      break;
    }
  }
  a.click();
}

export default {
  get,
  getUnauthed,
  post,
  postUnauthed,
  put,

  // The word "delete" is reserved in Javascript, so we use "del" instead
  del,
  fetchApiRaw,
  fetchApiUnauthed,
  fetchApi,
  downloadResult,
};
