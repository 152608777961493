import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import makeStyles from '@mui/styles/makeStyles';
import { Blockquote } from './Blockquote/index';
import { Table } from './Table/index';
import { Text } from '../../Atoms/Text';
import { CodeBlock } from './CodeBlock/index';
import { ErrorBoundary } from '../../../react/ErrorBoundary';
import dompurify from 'dompurify';
import remarkGfm from 'remark-gfm';

const useStyles = makeStyles((theme) => ({
  markdown: {
    background: 'transparent',
    color: theme.palette.text.primary,
    display: 'block',
    overflowX: 'auto',
    overflowY: 'hidden',
    wordBreak: 'break-word',
    padding: '2px', // just a couple of pixels of padding, otherwise sometimes the left part of a leading 'J' will get cut off.
    '& img': {
      maxWidth: '80%', // to allow for roughly the same amount of right margin
      objectFit: 'scale-down',
      '&:hover': {
        cursor: 'zoom-in',
        maxWidth: '125%',
        maxHeight: '125%',
        transitionDelay: '0.5s',
      },
    },
    '& p:first-child': {
      margin: 0,
    },
    '& a': {
      cursor: 'pointer',
      color: theme.palette.secondary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    // syntax highlighted code is rendered in these code blocks
    '& code': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.action.selected,
      padding: '2px 4px',
      borderRadius: '4px',
    },
    // multi-line code blocks are wrapped in a pre
    '& pre': {
      margin: `${theme.spacing(1)} 0`,
      padding: `${theme.spacing(2)} !important`,
      backgroundColor: theme.palette.action.selected,
      borderRadius: '4px',
      '& code': {
        // since the pre wraps the code already with a background color, we don't want to add another
        // background color to the code block within a pre.
        backgroundColor: 'transparent',
      },
    },
  },
}));

const DOMPURIFY_OPTIONS = {
  // Only include minimal set of allowed tags that we need here.
  // without passing this, XSS should still be safe but there are a lot of unexpected tags
  // and surface area people could be using. for example <style> tags can change the color
  // of elements on the entire page.
  ALLOWED_TAGS: [
    'a',
    'b',
    'blockquote',
    'button',
    'code',
    'div',
    'img',
    'p',
    'strike',
    'table',
    'tbody',
    'thead',
    'td',
    'th',
    'tr',
  ],
};

let linkReference = function (reference) {
  // workaround mishandling of [][] in ReactMarkdown
  if (!reference || !reference.children || !reference.children[0]) {
    console.log('skipping markdown link rendering from missing children');
    return '';
  }
  // workaround mishandling of [foobar] in ReactMarkdown
  // https://github.com/rexxars/react-markdown/issues/115#issuecomment-357953459
  if (!reference.href || !reference.url) {
    return `[ ${reference.children[0].props.value} ]`;
  }

  return (
    <a
      href={reference.href || reference.url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {reference.children}
    </a>
  );
};

// Use dompurify to sanitize all html before passing to dangerouslySetInnerHtml
function HtmlRenderer(props) {
  const tag = props.isBlock ? 'div' : 'span';
  const sanitized = dompurify.sanitize(props.value, DOMPURIFY_OPTIONS);

  const nodeProps = {
    dangerouslySetInnerHTML: { __html: sanitized },
  };
  return React.createElement(tag, nodeProps);
}

export function rehypeHtml(h, node) {
  const { value } = node;
  const ret = h(node, 'html', { isBlock: false, value });
  return ret;
}

export function rehypeCode(h, node) {
  const { lang, value } = node;
  return h(node, 'code', { language: lang, value });
}

export function rehypeInlineCode(h, node) {
  const { lang, value } = node;
  return h(node, 'code', { language: lang, value, inline: true });
}

const renderers = {
  // TODO: Removed in favor of the stock flavor as the format changed. Either
  // support the new format or remove this entirely.
  code: CodeBlock,

  blockquote: Blockquote,
  table: Table,
  html: HtmlRenderer,
  // for links in the form of [text](url)
  link: linkReference,
  // for link references in the form of [text]
  linkReference: linkReference,
};

/** @component */
export function Markdown(props) {
  const classes = useStyles();
  const markdownProps = {
    remarkPlugins: props.externalPlugins
      ? [...props.externalPlugins, remarkGfm]
      : [remarkGfm],
    remarkRehypeOptions: {
      handlers: {
        // Create our own "handler" for html nodes. The default handler of
        // remark-rehype will turn all html into escaped text. We have our own
        // sanitization mechanism, so ensure we fall back to that rather than
        // perform the default action here.
        html: rehypeHtml,

        code: rehypeCode,
        inlineCode: rehypeInlineCode,
        ...props.rehypeHandlers,
      },
    },
    components:
      props && props.externalRenderers
        ? { ...renderers, ...props.externalRenderers }
        : { ...renderers },
    className: classes.markdown,
  };
  return (
    <ErrorBoundary
      renderOnError={
        <div>
          <Text color="error">Error Rendering Markdown with HTML</Text>
          <ReactMarkdown {...markdownProps}>{props.children}</ReactMarkdown>
        </div>
      }
    >
      <ReactMarkdown {...markdownProps}>
        {props.source ?? props.children}
      </ReactMarkdown>
    </ErrorBoundary>
  );
}

Markdown.propTypes = {
  children: PropTypes.node,
  externalPlugins: PropTypes.array,
  externalRenderers: PropTypes.object,
  rehypeHandlers: PropTypes.object,
  source: PropTypes.string,
};
