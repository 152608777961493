import { Theme } from '@mui/material/styles';
import { MuiTheme } from '../../theme';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export interface CondensedMarginProps {
  m?: string | number;
  mt?: string | number;
  mr?: string | number;
  mb?: string | number;
  ml?: string | number;
  mx?: string | number;
  my?: string | number;
}

export interface VerboseMarginProps {
  margin?: string | number;
  marginTop?: string | number;
  marginBottom?: string | number;
  marginLeft?: string | number;
  marginRight?: string | number;
  marginX?: string | number;
  marginY?: string | number;
}

export interface CondensedPaddingProps {
  p?: string | number;
  pt?: string | number;
  pr?: string | number;
  pb?: string | number;
  pl?: string | number;
  px?: string | number;
  py?: string | number;
}

export interface VerbosePaddingProps {
  padding?: string | number;
  paddingTop?: string | number;
  paddingBottom?: string | number;
  paddingLeft?: string | number;
  paddingRight?: string | number;
  paddingX?: string | number;
  paddingY?: string | number;
}

export type MarginProps = CondensedMarginProps & VerboseMarginProps;
export type PaddingProps = CondensedPaddingProps & VerbosePaddingProps;
export type SpacingProps = MarginProps & PaddingProps;

const representative: SpacingProps = {
  m: undefined,
  mt: undefined,
  mr: undefined,
  mb: undefined,
  ml: undefined,
  mx: undefined,
  my: undefined,
  margin: undefined,
  marginTop: undefined,
  marginRight: undefined,
  marginBottom: undefined,
  marginLeft: undefined,
  marginX: undefined,
  marginY: undefined,
  p: undefined,
  pt: undefined,
  pr: undefined,
  pb: undefined,
  pl: undefined,
  px: undefined,
  py: undefined,
  padding: undefined,
  paddingTop: undefined,
  paddingRight: undefined,
  paddingBottom: undefined,
  paddingLeft: undefined,
  paddingX: undefined,
  paddingY: undefined,
};
export const SpacingPropsKeys = Object.keys(representative);

export type SpacingFieldsToRemove =
  | 'paddingX'
  | 'paddingY'
  | 'marginX'
  | 'marginY';
export type SpacingStyles = Omit<
  VerbosePaddingProps & VerboseMarginProps,
  SpacingFieldsToRemove
>;

/**
 * To preserve backward compatibility and ensure the app doesn't blow up,
 * we have to handle padding and margin spacing using shorthands and number values.
 * When m={1}, for example, we really want margin to equal 8px.
 *
 * This code does two things: 1) gives a reasonable order of precedence for spacing fields
 * and 2) handles applying theme values for spacing when a number is passed in to spacing fields
 */
export const SpacingStyleGenerator = (
  theme: Theme,
  props: SpacingProps
): SpacingStyles => {
  const padding = props.padding || props.p;
  const paddingTop = props.paddingTop || props.pt || props.paddingY || props.py;
  const paddingBottom =
    props.paddingBottom || props.pb || props.paddingY || props.py;
  const paddingLeft =
    props.paddingLeft || props.pl || props.paddingX || props.px;
  const paddingRight =
    props.paddingRight || props.pr || props.paddingX || props.px;
  const margin = props.margin || props.m;
  const marginTop = props.marginTop || props.mt || props.marginY || props.my;
  const marginBottom =
    props.marginBottom || props.mb || props.marginY || props.my;
  const marginLeft = props.marginLeft || props.ml || props.marginX || props.mx;
  const marginRight =
    props.marginRight || props.mr || props.marginX || props.mx;

  const evaluate = (field?: string | number) => toStringOrThemedNumber(field);

  return {
    padding: evaluate(padding),
    paddingTop: evaluate(paddingTop),
    paddingBottom: evaluate(paddingBottom),
    paddingLeft: evaluate(paddingLeft),
    paddingRight: evaluate(paddingRight),
    margin: evaluate(margin),
    marginTop: evaluate(marginTop),
    marginBottom: evaluate(marginBottom),
    marginLeft: evaluate(marginLeft),
    marginRight: evaluate(marginRight),
  };
};

function toStringOrThemedNumber(field?: string | number) {
  if (field && Number.isFinite(field)) {
    return MuiTheme.spacing(Number(field));
  }

  return field;
}
