import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Div } from '../../Atoms/Div';
import { Flex } from '../../Atoms/Flex';
import { Card } from '../../Atoms/Card';
import { Button } from '../../Atoms/Button';
import { Text } from '../../Atoms/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { Markdown } from '../Markdown';
// import { TextEditor } from '../TextEditor';
import { InputArea } from '../../Atoms/InputArea';

const useStyles = makeStyles((theme) => ({
  editButton: {
    borderRadius: '100%',
    padding: '0 7px',
    outline: 'none',
    border: 'none',
    background: theme.palette.secondary.dim,
    color: theme.palette.secondary.main,
    '&:hover': {
      background: theme.palette.secondary.light,
      color: theme.palette.secondary.main,
      cursor: 'pointer',
    },
  },
}));

const PlainTextEditor = ({ content, setContent }) => {
  return (
    <InputArea
      placeholder=""
      minRows={5}
      maxRows={20}
      value={content}
      onChange={(e) => {
        setContent(e.target.value);
      }}
    />
  );
};

function MarkDownEditor({
  title,
  value,
  onSave,
  editable,
  hasCheckbox,
  checkboxValue,
  checkboxTitle,
  externalPlugins,
  externalRenderers,
}) {
  const theme = useTheme();
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  // TODO: we shouldn't only read the value on the first render for the content and edit content...
  const [content, setContent] = useState(value);
  const [checkbox, setCheckbox] = useState(checkboxValue);
  const [editContent, setEditContent] = useState(value);
  const [err, setErr] = useState(null);

  // const handleChange = (value) => {
  //   setEditContent(value);
  // };
  const cancel = () => {
    setEditMode(false);
    setErr(null);
    setEditContent(content);
  };
  const save = () => {
    // Checks for empty lines
    if (/^\s*$/.test(editContent)) {
      setEditContent(null);
      setContent(null);
      setEditMode(false);
      return;
    }
    const { success, error } = onSave(editContent, checkbox);
    if (success) {
      setContent(editContent);
      setEditMode(false);
    } else if (error) {
      setErr(error);
    } else {
      console.error(
        `Callback \`onSave\` should return an object with a \`success\` or \`error\` key.`
      );
    }
  };

  return (
    <Card display="table" width="100%">
      <Flex
        justifyContent="space-between"
        background={theme.palette.action.selected}
        p="8px 16px"
        borderBottom={`1px solid ${theme.palette.divider}`}
        borderRadius="4px 4px 0 0"
      >
        <Text variant="h4">{title}</Text>
        {!editMode && editable && (
          <button
            onClick={() => setEditMode(true)}
            className={classes.editButton}
          >
            <FontAwesomeIcon icon={faPencil} cursor="pointer" />
          </button>
        )}
      </Flex>
      <Div>
        {editMode && (
          <React.Fragment>
            <Div p={1}>
              <PlainTextEditor
                content={editContent}
                setContent={setEditContent}
              />
              {/* TODO: this needs to sanitize better before we can use again
               <TextEditor
                message={content}
                showToolbar={true}
                onTextUpdate={handleChange}
                autoFocus={true}
              /> */}
            </Div>
            <Flex
              justifyContent="space-between"
              p="8px 16px"
              background={theme.palette.action.selected}
            >
              <Div>
                {err && (
                  <Text
                    variant="body1"
                    p="4px"
                    color={theme.palette.error.main}
                  >
                    {err}
                  </Text>
                )}
              </Div>
              <Flex>
                {hasCheckbox && (
                  <Div p="1px 50px">
                    <label htmlFor="markdown-editor-checkbox">
                      <input
                        id="markdown-editor-checkbox"
                        type="checkbox"
                        onClick={() => setCheckbox(!checkbox)}
                        checked={checkbox}
                      />
                      {' ' + checkboxTitle}
                    </label>
                  </Div>
                )}
                <Button
                  variant="contained"
                  customcolor={theme.palette.highlight.main}
                  textcolor={theme.palette.text.primary}
                  mr={2}
                  p="2px 12px"
                  onClick={cancel}
                  disableElevation
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={save}
                  p="2px 12px"
                  disableElevation
                >
                  Save
                </Button>
              </Flex>
            </Flex>
          </React.Fragment>
        )}
        {!editMode && content && content.length && (
          <Div p="8px 16px">
            <Markdown
              externalPlugins={externalPlugins}
              externalRenderers={externalRenderers}
            >
              {content}
            </Markdown>
          </Div>
        )}
        {!editMode && (!content || !content.length) && (
          <Text variant="body2" p="8px 16px">
            Click the edit icon to add content
          </Text>
        )}
      </Div>
    </Card>
  );
}

MarkDownEditor.propTypes = {
  value: PropTypes.string,
  onSave: PropTypes.func,
  editable: PropTypes.bool,
  editMode: PropTypes.bool,
  title: PropTypes.string,
  externalPlugins: PropTypes.array,
  externalRenderers: PropTypes.object,
  hasCheckbox: PropTypes.bool,
  checkboxValue: PropTypes.bool,
  checkboxTitle: PropTypes.string,
};

MarkDownEditor.defaultProps = {
  title: 'Markdown Editor',
  content: '',
  hasCheckbox: false,
  onSave: () => {},
};

export { MarkDownEditor };
