import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex } from '../../Atoms/Flex';
import { ContentLoader } from '../../Atoms/ContentLoader';
import { MuiTheme as theme } from '../../../theme';

export const Container = styled(Flex)`
  cursor: ${(props) => props.cursor};
  text-transform: uppercase;
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.color};
  box-shadow: ${(props) => props.shadow};
  box-sizing: border-box;
  transition: width 0.25s, height 0.25s;
  overflow: hidden;
`;

export function UserAvatar(props) {
  const {
    imageUrl,
    altText,
    text,
    shadow,
    size,
    imageBackground,
    textBackground,
  } = props;
  const [showUserIcon, setShowUserIcon] = useState(false);
  const [isLoading, setIsLoading] = useState(Boolean(imageUrl));

  useEffect(() => {
    if (imageUrl) {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        setShowUserIcon(true);
        setIsLoading(false);
      };
      img.onerror = () => {
        setIsLoading(false);
      };
    } else {
      setIsLoading(false);
    }
  }, [imageUrl]);

  const background = showUserIcon ? imageBackground : textBackground;

  return (
    <Container
      {...props}
      width={size}
      minWidth={size}
      maxWidth={size}
      height={size}
      minHeight={size}
      maxHeight={size}
      justifyContent="center"
      alignItems="center"
      shadow={shadow}
      background={background}
    >
      {isLoading ? (
        <ContentLoader
          height="100%"
          width="100%"
          borderRadius="50%"
          border="none"
        />
      ) : showUserIcon ? (
        <img src={imageUrl} alt={altText} height={size} width={size} />
      ) : (
        <div>{text}</div>
      )}
    </Container>
  );
}

UserAvatar.propTypes = {
  /** Padding; can also use pt, pr, pb, and pl for specific directions */
  p: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Margin; can also use mt, mr, mb, and ml for specific directions */
  m: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** URL of avatar image */
  imageUrl: PropTypes.string,
  /** Alternative image text */
  altText: PropTypes.string,
  /** Text to use for fallback, usually initials or single letter */
  text: PropTypes.string.isRequired,
  /** border  */
  border: PropTypes.string,
  /** adjustable border radius */
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** adjustable shadow */
  shadow: PropTypes.string,
  /** text avatar background color */
  textBackground: PropTypes.string,
  /** avatar image background color  */
  imageBackground: PropTypes.string,
  /** avatar height and width, always equal */
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** text color */
  color: PropTypes.string,
  /** font size */
  fontSize: PropTypes.string,
  cursor: PropTypes.string,
};

UserAvatar.defaultProps = {
  imageBackground: theme.palette.common.white,
  textBackground: theme.palette.primary.dark,
  shadow: 'none',
  color: theme.palette.text.primary,
  borderRadius: '50%',
  size: 28,
  border: 'none',
  fontSize: '18px',
};
