import { Theme } from '@mui/material/styles';
import {
  BorderProps,
  BorderStyleGenerator,
  BorderPropsKeys,
  BorderStyles,
} from './BorderProps';
import {
  DisplayProps,
  DisplayStyleGenerator,
  DisplayPropsKeys,
  DisplayStyles,
} from './DisplayProps';
import {
  FlexProps,
  FlexStyleGenerator,
  FlexPropsKeys,
  FlexStyles,
} from './FlexProps';
import {
  GridProps,
  GridStyleGenerator,
  GridPropsKeys,
  GridStyles,
} from './GridProps';
import {
  PaletteProps,
  PaletteStyleGenerator,
  PalettePropsKeys,
  PaletteStyles,
} from './PaletteProps';
import {
  PositionsProps,
  PositionsStyleGenerator,
  PositionsPropsKeys,
  PositionsStyles,
} from './PositionsProps';
import {
  ShadowProps,
  ShadowStyleGenerator,
  ShadowPropsKeys,
  ShadowStyles,
} from './ShadowProps';
import {
  SizingProps,
  SizingStyleGenerator,
  SizingPropsKeys,
  SizingStyles,
} from './SizingProps';
import {
  SpacingProps,
  SpacingStyleGenerator,
  SpacingPropsKeys,
  SpacingStyles,
} from './SpacingProps';
import {
  TypographyProps,
  TypographyStyleGenerator,
  TypographyPropsKeys,
  TypographyStyles,
} from './TypographyProps';

type CombinedProps = BorderProps &
  DisplayProps &
  FlexProps &
  GridProps &
  PaletteProps &
  PositionsProps &
  ShadowProps &
  SizingProps &
  SpacingProps &
  TypographyProps;

type ElementProps = Omit<
  React.HTMLAttributes<HTMLElement>,
  keyof CombinedProps
>;

export type MuiProps = CombinedProps & ElementProps;

type MuiGeneratedStyles = BorderStyles &
  DisplayStyles &
  FlexStyles &
  GridStyles &
  PaletteStyles &
  PositionsStyles &
  ShadowStyles &
  SizingStyles &
  SpacingStyles &
  TypographyStyles;

/**
 * Can be used within MaterialUI's makeStyle in order to enable any component
 * to support the pervasive MUI props within JSS. This MuiStyleGenerator combines
 * the generators for each of the supporting CSS composite parts (e.g. Border, Display, etc.)
 * @param theme A MaterialUI theme used to convert Spacing props based on the theme
 */
export const MuiStyleGenerator = (
  theme: Theme,
  props: CombinedProps
): MuiGeneratedStyles => ({
  ...BorderStyleGenerator(props),
  ...DisplayStyleGenerator(props),
  ...FlexStyleGenerator(props),
  ...GridStyleGenerator(props),
  ...PaletteStyleGenerator(props),
  ...PositionsStyleGenerator(props),
  ...ShadowStyleGenerator(theme, props),
  ...SizingStyleGenerator(props),
  ...SpacingStyleGenerator(theme, props),
  ...TypographyStyleGenerator(props),
});

export type {
  ElementProps,
  FlexProps,
  SizingProps,
  SpacingProps,
  TypographyProps,
};
export { SizingPropsKeys, SpacingPropsKeys, TypographyPropsKeys };

export { SpacingStyleGenerator };

/**
 * There's no native way in Typescript to get the keys off of an interface at run time.
 * Therefore, I mirrored the keys in all of the interfaces and combined them into this object,
 * so that I could filter these keys out of objects passed to `getElementProps`
 */
export const MuiPropsKeys = [
  ...BorderPropsKeys,
  ...DisplayPropsKeys,
  ...FlexPropsKeys,
  ...GridPropsKeys,
  ...PalettePropsKeys,
  ...PositionsPropsKeys,
  ...ShadowPropsKeys,
  ...SizingPropsKeys,
  ...SpacingPropsKeys,
  ...TypographyPropsKeys,
];

/**
 * The purpose of this function is to return only `ElementProps` fields (defined above),
 * rather than all fields on an object, in order to prevent extra or erroneous props
 * being passed to children components. For example, "m" or "px" will not be passed as props
 * directly to the DOM.
 */
export const getElementProps = (muiProps: {
  [key: string]: any;
}): ElementProps => {
  const elementProps: { [key: string]: any } = {};

  for (const key in muiProps) {
    if (!MuiPropsKeys.includes(key)) {
      elementProps[key] = muiProps[key];
    }
  }

  return elementProps;
};
