import React from 'react';
import { Div } from '../../../../Atoms/Div';
import { SelectFormItem, SelectFormOption } from '../../../../DynamicForm';
import { TextField } from '@mui/material';
import { Text } from '../../../../Atoms/Text';
import { SelectOptionEditor } from './SelectOptionEditor';
import { faMinusCircle, faPlusSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontIcon } from '../../../../Atoms/FontIcon';
import { useTheme } from '@mui/material/styles';

export interface Props {
  item?: SelectFormItem;
  onUpdate: (item: SelectFormItem, isComplete: boolean) => void;
}

const createEmptyItem = (): SelectFormItem => ({
  type: 'select',
  name: '',
  options: [],
});

const checkIfDuplicateExists = (strings: string[]) => {
  return new Set(strings).size !== strings.length;
};

export const SelectItemEditor: React.FC<Props> = ({
  item = createEmptyItem(),
  onUpdate,
}: Props) => {
  const theme = useTheme();

  const isComplete = (name: string, options: SelectFormOption[]) => {
    // make sure the required fields are filled out
    if (name.length === 0 || options.length === 0) {
      return false;
    }
    // make sure all options are filled out
    if (options.some((o) => o.value === '' || o.label === '')) {
      return false;
    }
    // make sure there are no duplicate values or labels
    const values = options.map((o) => o.value);
    if (checkIfDuplicateExists(values)) {
      return false;
    }
    const labels = options.map((o) => o.label);
    if (checkIfDuplicateExists(labels)) {
      return false;
    }
    return true;
  };

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onUpdate(
      {
        ...item,
        name: value,
      },
      isComplete(value, item.options)
    );
  };

  const onChangeLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onUpdate(
      {
        ...item,
        label: value,
      },
      isComplete(item.name, item.options)
    );
  };

  const onUpdateOptions = (options: SelectFormOption[]) => {
    onUpdate(
      {
        ...item,
        options,
      },
      isComplete(item.name, options)
    );
  };

  // add a new option
  const addOption = () => {
    const options = [...item.options, { label: '', value: '' }];
    onUpdateOptions(options);
  };

  // edit an existing option
  const editOption = (option: SelectFormOption, index: number) => {
    const options = item.options.map((existingOption, idx) => {
      if (idx === index) {
        return option;
      }
      return existingOption;
    });
    onUpdateOptions(options);
  };

  // delete an existing option
  const deleteOption = (index: number) => {
    const options = item.options.filter((option, idx) => idx !== index);
    onUpdateOptions(options);
  };

  return (
    <Div mt={2} display="flex" flexDirection="column">
      <TextField
        required
        style={{ width: '100%' }}
        label="Name"
        value={item.name}
        onChange={onChangeName}
      />
      <TextField
        style={{ width: '100%' }}
        label="Label"
        value={item.label ?? ''}
        onChange={onChangeLabel}
      />
      <Text variant="h4" mt={4}>
        Options
      </Text>
      {item.options.map((option, index) => {
        const isDuplicateValue = item.options.some(
          (o, idx) => idx !== index && o.value === option.value
        );
        const isDuplicateLabel = item.options.some(
          (o, idx) => idx !== index && o.label === option.label
        );
        return (
          <Div key={index} display="flex" alignItems="center" mt={2}>
            <FontIcon
              icon={faMinusCircle}
              color={theme.palette.error.main}
              mr={2}
              onClick={() => deleteOption(index)}
            />
            <SelectOptionEditor
              option={option}
              onUpdate={(updatedOption) => editOption(updatedOption, index)}
            />
            {(isDuplicateLabel || isDuplicateValue) && (
              <Text
                variant="body1"
                color={theme.palette.error.main}
                my={2}
                ml={1}
              >
                {isDuplicateValue ? 'Duplicate Value' : 'Duplicate Label'}
              </Text>
            )}
          </Div>
        );
      })}
      <FontIcon
        my={2}
        icon={faPlusSquare}
        color={theme.palette.primary.main}
        onClick={addOption}
      />
    </Div>
  );
};
