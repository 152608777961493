import { Property } from 'csstype';

export interface PositionsProps {
  zIndex?: Property.ZIndex;
  position?: Property.Position;
  top?: Property.Top<number>;
  bottom?: Property.Bottom<number>;
  left?: Property.Left<number>;
  right?: Property.Right<number>;
}

export type PositionsStyles = PositionsProps;

// Unfortunately in Typescript, using the spread operator doesn't remove properties that aren't on the interface
export const PositionsStyleGenerator = (
  props: PositionsProps
): PositionsStyles => ({
  zIndex: props.zIndex,
  position: props.position,
  top: props.top,
  bottom: props.bottom,
  left: props.left,
  right: props.right,
});

const representative: PositionsProps = {
  zIndex: undefined,
  position: undefined,
  top: undefined,
  bottom: undefined,
  left: undefined,
  right: undefined,
};
export const PositionsPropsKeys = Object.keys(representative);
