import { Property } from 'csstype';

export interface BorderProps {
  border?: Property.Border<number>;
  borderTop?: Property.BorderTop<number>;
  borderRight?: Property.BorderRight<number>;
  borderBottom?: Property.BorderBottom<number>;
  borderLeft?: Property.BorderLeft<number>;
  borderColor?: Property.BorderColor;
  borderRadius?: Property.BorderRadius<number>;
}

export type BorderStyles = BorderProps;

// Unfortunately in Typescript, using the spread operator doesn't remove properties that aren't on the interface
export const BorderStyleGenerator = (props: BorderProps): BorderStyles => ({
  border: props.border,
  borderTop: props.borderTop,
  borderRight: props.borderRight,
  borderBottom: props.borderBottom,
  borderLeft: props.borderLeft,
  borderColor: props.borderColor,
  borderRadius: props.borderRadius,
});

const representative: BorderProps = {
  border: undefined,
  borderTop: undefined,
  borderRight: undefined,
  borderBottom: undefined,
  borderLeft: undefined,
  borderColor: undefined,
  borderRadius: undefined,
};
export const BorderPropsKeys = Object.keys(representative);
