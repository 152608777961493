import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Switch, Redirect, Route } from "react-router-dom";
import BillingInfo from "./BillingInfo";
import Membership from "./Membership";
import IntegrationsRoute from "../integrations";

export class Organization extends PureComponent {
  render() {
    const { organization, match, allowIntegrations } = this.props;

    return (
      <Switch>
        <Route
          exact
          path={`${match.path}/billing`}
          render={(props) => {
            return <BillingInfo organization={organization} {...props} />;
          }}
        />

        <Route
          exact
          path={`${match.path}/membership`}
          render={(props) => {
            return <Membership organization={organization} {...props} />;
          }}
        />

        {allowIntegrations && (
          <Route
            path={`${match.path}/integrations`}
            render={(props) => {
              return (
                <IntegrationsRoute organization={organization} {...props} />
              );
            }}
          />
        )}
        <Redirect to={`${match.path}/billing`} />
      </Switch>
    );
  }
}

Organization.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  organization: PropTypes.object.isRequired,
  allowIntegrations: PropTypes.bool,
};

export default Organization;
