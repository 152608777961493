/**
 * Package for wrapping indirectAPIRequestStore functions and adding support
 * for handling "success" actions from a paginated API and using the resulting
 * data to fill out this store. To use:
 *
 *  const types = new APITypes("MY_ACTION");
 *  const paginationTypes = new APITypes("MY_PAGINATED_ACTION");
 *  type MyType = {
 *    id: string;
 *   };
 *  const store = indirectPageMapStore<TemplateRequest, Template>(
 *    types,
 *    paginationTypes,
 *    (entry: MyType) => entry.id,
 *  );
 */
import {
  APIData,
  APITypes,
  APIAction,
  Identifier,
  emptyResults,
  storeResults,
  IndirectAPIData,
  emptyIndirectAPIData,
  indirectAPIRequestStore,
  IndirectAPIRequestStoreFunction,
} from "./apiData";
import {
  PaginatedAPIAction,
  PaginatedAPIData,
  getEntryByIndex,
  getResultsCount,
} from "./pagination";

/**
 * Function for turning a resulting entry into the equivalent key for the store
 */
export type PageMapKeyFunction<T> = (entry: T) => string | undefined;

/** Function for handling an indirectPageMapStore */
export type PageMapRequestStoreFunction<R, T> = (
  state: IndirectAPIData<T>,
  action: APIAction<R, T> | PaginatedAPIAction<any, T>
) => IndirectAPIData<T>;

/**
 * Generate a PageMapStoreFunction for handling paginated "success" actions and
 * turning them into regular indirectAPIRequestStore state.
 */
export function indirectPageMapStore<R extends Identifier, T>(
  apiTypes: APITypes,
  paginationTypes: APITypes,
  pageMapKey: PageMapKeyFunction<T>
): PageMapRequestStoreFunction<R, T> {
  const store = indirectAPIRequestStore<R, T>(apiTypes);
  return (state = emptyIndirectAPIData, action) => {
    switch (action.type) {
      case paginationTypes.success: {
        const paginatedAction = action as PaginatedAPIAction<any, T>;
        const { response } = paginatedAction;
        if (!response) {
          return state;
        }

        // Populate the singleton data from the page data.
        const newEntries: IndirectAPIData<T> = {};
        for (let newEntryData of response.data.results) {
          const key = pageMapKey(newEntryData);
          if (!key) {
            continue;
          }

          const entry = state[key] || emptyResults;
          const entryData = entry.isFilled ? entry.data : {};
          newEntries[key] = storeResults({
            // Default values needed by APIResult
            url: "",
            errorData: {},

            // Legacy entry data
            ...entry,

            // List data
            data: {
              // Old data (in case the paginated data has incomplete info)
              ...entryData,

              // New data from pagination endpoint
              ...newEntryData,
            },

            success: true,
          });
        }

        return {
          ...state,
          ...newEntries,
        };
      }

      default:
        return store(state, action as APIAction<R, T>);
    }
  };
}

/**
 * Indirectly look up the page map data by the given index in the page data.
 */
export function getMapEntryByIndex<T>(
  pageData: PaginatedAPIData<T>,
  pageMapData: IndirectAPIData<T>,
  pageMapKey: PageMapKeyFunction<T>,
  index: number
): APIData<T> | null {
  const pageEntry = getEntryByIndex<T>(pageData, index);
  if (!pageEntry) {
    return null;
  }

  const key = pageMapKey(pageEntry);
  if (!key) {
    return null;
  }

  return pageMapData[key] || null;
}

/**
 * Create and return an array of all entries in the mapping. Note that this
 * function creates an array of entries every time it's called.
 */
export function getMapEntries<T>(
  pageData: PaginatedAPIData<T>,
  pageMapData: IndirectAPIData<T>,
  pageMapKey: PageMapKeyFunction<T>
): Array<APIData<T>> {
  const entries: Array<APIData<T>> = [];
  const resultsCount = getResultsCount(pageData);
  for (let i = 0; i < resultsCount; i++) {
    const entry = getMapEntryByIndex(pageData, pageMapData, pageMapKey, i);
    if (!entry) {
      continue;
    }
    entries.push(entry);
  }
  return entries;
}
