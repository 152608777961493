import { Property } from 'csstype';

export interface FlexProps {
  flexBasis?: Property.FlexBasis<number>;
  flexDirection?: Property.FlexDirection;
  flexWrap?: Property.FlexWrap;
  justifyContent?: Property.JustifyContent;
  alignItems?: Property.AlignItems;
  alignContent?: Property.AlignContent;
  order?: number | '-moz-initial' | 'inherit' | 'initial' | 'revert' | 'unset';
  flex?: Property.Flex<number>;
  flexGrow?:
    | number
    | 'inherit'
    | 'initial'
    | '-moz-initial'
    | 'revert'
    | 'unset';
  flexShrink?:
    | number
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset';
  alignSelf?: Property.AlignSelf;
  justifyItems?: Property.JustifyItems;
  justifySelf?: Property.JustifySelf;
}

export type FlexStyles = FlexProps;

// Unfortunately in Typescript, using the spread operator doesn't remove properties that aren't on the interface
export const FlexStyleGenerator = (props: FlexProps): FlexStyles => ({
  flexBasis: props.flexBasis,
  flexDirection: props.flexDirection,
  flexWrap: props.flexWrap,
  justifyContent: props.justifyContent,
  alignItems: props.alignItems,
  alignContent: props.alignContent,
  order: props.order,
  flex: props.flex,
  flexGrow: props.flexGrow,
  flexShrink: props.flexShrink,
  alignSelf: props.alignSelf,
  justifyItems: props.justifyItems,
  justifySelf: props.justifySelf,
});

const representative: FlexProps = {
  flexBasis: undefined,
  flexDirection: undefined,
  flexWrap: undefined,
  justifyContent: undefined,
  alignItems: undefined,
  order: undefined,
  flex: undefined,
  flexGrow: undefined,
  flexShrink: undefined,
  alignSelf: undefined,
  justifyItems: undefined,
  justifySelf: undefined,
};
export const FlexPropsKeys = Object.keys(representative);
