import React from 'react';
import styled from 'styled-components';
import Select, {
  CommonProps,
  components,
  GroupTypeBase,
  MenuListComponentProps,
  OptionProps,
  OptionTypeBase,
  StylesConfig,
} from 'react-select';
import { darken, lighten } from 'polished';
import { Div } from '../../Atoms/Div';
import { Flex } from '../../Atoms/Flex';
import { FontIcon } from '../../Atoms/FontIcon';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { Spacing } from '../../../styles/MaterialUI';
import { SpacingProps } from '../../../types/MaterialUI';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { useTheme } from '@mui/material/styles';

const MAX_OPTS_IN_COL = 8;
const GROUP_WIDTH = 170;

export interface Option {
  label: string;
  value: string;
  color: string;
}

interface OptionGroup {
  label?: string;
  options?: Option[];
  color?: string;
  icon?: IconDefinition;
}

export interface Props extends SpacingProps {
  /** Allows user to clear value input by pressing the X */
  isClearable?: boolean;
  /** Opens dropdown menu when this component is focused */
  openMenuOnFocus?: boolean;
  /** Renders custom styles for group label */
  formatGroupLabel?: () => void;
  /** Function that is run when a selection is made. Use this to grab the value. */
  onChange?: (value: Option | null) => void;
  value?: Option | null;
  /** Text that appears (greyed out) when no input is detected */
  placeholder?: string;
  /** Populates the dropdown with options. See source code on how to structure data with groups/categories. */
  options: OptionGroup[];
  /** Opens dropdown menu hanging to the left */
  hangLeft?: boolean;
}

const StyledFeedbackSelect = styled(Select)<Props>`
  ${Spacing};
  .dialog-select__control {
    font-size: ${(props) => props.theme.typography.body1.fontSize};
    border: ${(props) => `1px solid ${props.theme.palette.divider}`};
    width: 230px;
    height: 40px;
    cursor: pointer;
    border-radius: 4px;
    background: ${(props) => props.theme.palette.background.paper};
    &--menu-is-focused {
      border: ${(props) => `1px solid ${props.theme.palette.secondary.main}`};
    }
    &--menu-is-open {
      .dialog-select__dropdown-indicator {
        transform: rotate(180deg);
      }
    }
  }
  .dialog-select__value-container {
    padding: 2px 8px;
  }
  .dialog-select__indicator {
    padding: 8px;
    color: ${(props) => props.theme.palette.text.secondary};
  }
  .dialog-select__indicator-separator {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .dialog-select__input {
    color: ${(props) => props.theme.palette.text.primary};
  }
  .dialog-select__single-value {
    color: ${(props) => props.theme.palette.text.primary};
  }
  .dialog-select__placeholder {
    font-size: ${(props) => props.theme.typography.body2.fontSize};
    color: ${(props) => props.theme.palette.text.secondary};
  }
  .dialog-select__group {
    height: fit-content;
    width: ${GROUP_WIDTH}px;
    padding-top: ${(props) => props.theme.spacing(1)};
    padding-bottom: ${(props) => props.theme.spacing(1)};
  }
  .dialog-select__menu {
    width: unset;
    right: ${(props) => (props.hangLeft ? 0 : 'auto')};
    z-index: ${(props) => props.theme.zIndex.modal};
    margin-top: ${(props) => props.theme.spacing(2)};
    background: ${(props) => props.theme.palette.background.paper};
  }
  .dialog-select__menu-list {
    max-height: 352px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .dialog-select__menu-notice--no-options {
    padding: ${(props) => props.theme.spacing(1)};
  }
`;

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

type IsMulti = false;

// styles for extended components that require the 'data' prop based on react-select state
const componentStyles: StylesConfig<Option, IsMulti> = {
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({
    ...styles,
    ...dot(),
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    ...dot(data.color),
  }),
};

function getBackgroundColor(
  data: Option,
  isFocused: boolean,
  isSelected: boolean,
  isDisabled: boolean,
  theme: any
) {
  if (isDisabled) {
    return;
  } else if (isSelected) {
    return data.color;
  } else if (isFocused) {
    return theme.palette.mode === 'dark'
      ? darken(0.35, data.color)
      : lighten(0.35, data.color);
  } else {
    return theme.palette.background.paper;
  }
}

const OptionComponent = (props: OptionProps<OptionTypeBase, IsMulti>) => {
  const { data, isFocused, isSelected, isDisabled } = props;
  const theme = useTheme();
  return (
    <components.Option {...props}>
      <Div
        backgroundColor={getBackgroundColor(
          data,
          isFocused,
          isSelected,
          isDisabled,
          theme
        )}
        fontSize={theme.typography.body2.fontSize}
        color={
          isSelected ? theme.palette.common.white : theme.palette.text.primary
        }
        p={1}
        style={{ cursor: 'pointer' }}
      >
        {data.label}
      </Div>
    </components.Option>
  );
};

interface GroupHeadingProps
  extends CommonProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>> {
  children: React.ReactNode;
  selectProps: {
    options: OptionGroup[];
  };
}

const GroupHeading = (props: GroupHeadingProps) => {
  const theme = useTheme();
  const groupLabel = props.children;
  let group =
    props.selectProps &&
    props.selectProps.options &&
    props.selectProps.options.find(
      (group: OptionGroup) => group.label === groupLabel
    );
  group = group || { color: theme.palette.grey[500], icon: faPlusCircle };
  return (
    <components.GroupHeading {...props}>
      <Flex
        direction="row"
        justifyContent="start"
        alignItems="center"
        ml="12px"
        px={1}
        color={theme.palette.text.secondary}
        fontWeight={900}
        fontSize={theme.typography.body2.fontSize}
      >
        <FontIcon icon={faCircle} color={group.color} size="xs" mr={1} />
        {group.label}
        {group.icon && (
          <FontIcon
            icon={group.icon}
            color={theme.palette.text.secondary}
            size="xs"
            ml={1}
          />
        )}
      </Flex>
    </components.GroupHeading>
  );
};

const getNumCols = (numOptsInGroups: number[]) => {
  let numCols = 0;
  let inCol = 0;
  for (let i = 0; i < numOptsInGroups.length; i++) {
    inCol += numOptsInGroups[i];
    if (inCol >= MAX_OPTS_IN_COL) {
      numCols++;
      inCol = numOptsInGroups[i];
    }
  }

  return inCol > 0 ? numCols + 1 : numCols;
};

const MenuList = (
  props: MenuListComponentProps<
    OptionTypeBase,
    IsMulti,
    GroupTypeBase<OptionTypeBase>
  >
) => {
  const groups = props.options;

  if (!Array.isArray(groups)) {
    return <components.MenuList {...props} />;
  }
  const numOptsInGroups = groups.map((group) => {
    return group?.options.length;
  });
  const numCols = getNumCols(numOptsInGroups);

  return (
    <Div width={numCols ? numCols * GROUP_WIDTH : 'auto'}>
      <components.MenuList {...props} />
    </Div>
  );
};

export const FeedbackSelectComponent = (props: Props): JSX.Element => {
  const theme = useTheme();
  return (
    <StyledFeedbackSelect
      styles={componentStyles}
      {...props}
      className="dialog-select-container"
      classNamePrefix="dialog-select"
      components={{ GroupHeading, MenuList, Option: OptionComponent }}
      theme={theme}
    />
  );
};

FeedbackSelectComponent.defaultProps = {
  width: '150px',
};
FeedbackSelectComponent.displayName = 'FeedbackSelect';

export const FeedbackSelect = FeedbackSelectComponent;
