import { Property } from 'csstype';

export interface DisplayProps {
  display?: Property.Display;
  displayPrint?: string;
  overflow?: Property.Overflow;
  textOverflow?: Property.TextOverflow;
  visibility?: Property.Visibility;
  whiteSpace?: Property.WhiteSpace;
}

export type DisplayStyles = DisplayProps;

// Unfortunately in Typescript, using the spread operator doesn't remove properties that aren't on the interface
export const DisplayStyleGenerator = (props: DisplayProps): DisplayStyles => ({
  display: props.display,
  displayPrint: props.displayPrint,
  overflow: props.overflow,
  textOverflow: props.textOverflow,
  visibility: props.visibility,
  whiteSpace: props.whiteSpace,
});

const representative: DisplayProps = {
  display: undefined,
  displayPrint: undefined,
  overflow: undefined,
  textOverflow: undefined,
  visibility: undefined,
  whiteSpace: undefined,
};
export const DisplayPropsKeys = Object.keys(representative);
