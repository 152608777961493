import React, { Suspense, SyntheticEvent, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { useLocation } from "react-router";
import { connect, useDispatch } from "react-redux";
import { Theme } from "@mui/material/styles";
import withTheme from "@mui/styles/withTheme";
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";
import queryString from "utils/queryString";

import {
  Banner,
  Button,
  Card,
  ContentLoader,
  Div,
  Flex,
  InputField,
  PageContainer,
  RouteLink,
} from "Common";
import { Text } from "Common/components/Atoms/Text";

import { APIResult, getApiFieldError } from "utils/Api";
import { retry } from "utils/retry";
import { APIData } from "utils/apiData";
import { actions, selectors } from "reducers/users";
import APIRequestFormInput from "components/lib/APIRequestFormInput";

const PasswordStrengthBar = React.lazy(() =>
  retry(() => import("react-password-strength-bar"))
);

export type ResetPasswordProps = {
  theme: Theme;
  resetPasswordResults: APIData<any>;
};

export const ResetPassword: React.FC<ResetPasswordProps> = ({
  theme,
  resetPasswordResults,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { token, email } = queryString.parse(location.search);
  const hasTokenError =
    resetPasswordResults.isFilled &&
    resetPasswordResults.success === false &&
    Boolean(getApiFieldError(resetPasswordResults.errorData, "token"));
  const invalidInputs = !token || !email || hasTokenError;

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const passwordMatches =
    !password || !passwordConfirm || password === passwordConfirm;

  const { isLoading } = resetPasswordResults;
  const canSubmit = password && passwordMatches && !isLoading;

  const callback = (results: APIResult<any>) => {
    if (results.success) {
      setPassword("");
      setPasswordConfirm("");
    }
  };

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    dispatch(
      actions.resetPassword({
        email: email || "",
        token: token || "",
        password,
        callback,
      })
    );
  };

  // 0 - Clear out the resetPassword results on unmount
  useEffect(() => {
    return () => {
      dispatch(actions.clearResetPassword());
    };
  }, [dispatch]);

  return (
    <PageContainer>
      <Flex direction="column" justifyContent="center" center>
        <Text variant="h1">Reset Password</Text>
        <Card mt="10px" width="350px" p="30px">
          {invalidInputs ? (
            <Banner
              icon={faExclamationCircle}
              color={theme.palette.error.main}
              background={theme.palette.grey[100]}
              mb="20px"
              style={{ padding: "5px 10px" }}
            >
              <Text variant="body2" fontSize="14px" ml={2}>
                This link doesn{"'"}t contain the required information or
                contains invalid information for resetting your password.
              </Text>
            </Banner>
          ) : (
            <Flex direction="column" justifyContent="center" center>
              <form onSubmit={onSubmit}>
                <Div mb={password ? 1 : 2}>
                  <APIRequestFormInput
                    id="password"
                    results={resetPasswordResults}
                    errorField="password"
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    required
                  />
                  <Suspense
                    fallback={
                      password ? (
                        <Div height={26}>
                          <ContentLoader height="2px" width="300px" />
                        </Div>
                      ) : null
                    }
                  >
                    {password && (
                      <PasswordStrengthBar password={password} minLength={8} />
                    )}
                  </Suspense>
                </Div>
                <InputField
                  id="passwordConfirm"
                  fullWidth
                  placeholder="Confirm New Password"
                  type="password"
                  value={passwordConfirm}
                  error={!passwordMatches}
                  helperText={
                    passwordMatches ? null : "Passwords do not match."
                  }
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                  required
                />
                <Button
                  mt={2}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!canSubmit}
                  p="5px"
                  fullWidth
                >
                  Update
                </Button>
              </form>
            </Flex>
          )}
        </Card>
        <Flex
          mt="20px"
          width="350px"
          fontSize="14px"
          border={`solid 1px ${theme.palette.grey[400]}`}
          borderRadius="10px"
          p="20px 30px 20px 30px"
          justifyContent="center"
          center
        >
          Remember your password?
          <RouteLink ml="5px" to="/login" textDecoration="none">
            Sign in
          </RouteLink>
        </Flex>
      </Flex>
    </PageContainer>
  );
};

function mapStateToProps(state: any, ownProps: {}) {
  const resetPasswordResults = selectors.resetPassword(state);
  return {
    resetPasswordResults,
  };
}

export default withTheme<
  Theme,
  React.JSXElementConstructor<ResetPasswordProps>
>(connect(mapStateToProps)(ResetPassword));
