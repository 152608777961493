import React, { useEffect, useState, Suspense } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import PropTypes from "prop-types";
import withTheme from "@mui/styles/withTheme";
import queryString from "query-string";

import {
  Button,
  Card,
  ContentLoader,
  Div,
  Flex,
  Input,
  InputField,
  Link,
  PageContainer,
  RouteLink,
  Toast,
} from "Common";
import { Text } from "Common/components/Atoms/Text";

import { retry } from "utils/retry";
import { APIResultProp } from "utils/apiData";
import pullrequestIcon from "resources/pullrequest-icon.svg";
import {
  actions as usersActions,
  selectors as usersSelectors,
} from "reducers/users";

const PasswordStrengthBar = React.lazy(() =>
  retry(() => import("react-password-strength-bar"))
);

function findError(data) {
  if (data && data.length > 0) {
    return data[0];
  }
  return null;
}

export function SignUp({
  theme,
  signup,
  inviteInfo,
  signupResponse,
  inviteInfoResponse,
}) {
  const location = useLocation();
  const { search } = location;
  const inviteKey = search && queryString.parse(search).inviteKey;
  const canEditEmail = !Boolean(inviteKey);

  const [name, setName] = useState("");
  const [stateEmail, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const email = inviteInfoResponse?.data?.email || stateEmail;

  const passwordMatches =
    !password || !passwordConfirm || password === passwordConfirm;

  const [companyName, setCompanyName] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);

  const { isLoading, errorData } = signupResponse;
  const error = signupResponse.success === false;

  // Parse these errors out from the data from the API. Each field's errors are
  // given, organized by that field's name.
  const nameError = error ? findError(errorData.name) : null;
  const emailError = error ? findError(errorData.username) : null;
  const passwordError = error ? findError(errorData.password) : null;
  const companyNameError = error ? findError(errorData.company_name) : null;
  const inviteKeyError =
    Boolean(inviteKey) && inviteInfoResponse.success === false
      ? "Invalid invitation key."
      : null;

  // 0 - Call inviteInfo on inviteKey
  useEffect(() => {
    if (Boolean(inviteKey)) {
      inviteInfo({ inviteKey });
    }
  }, [inviteInfo, inviteKey]);

  // 1 - Update on successful inviteInfoResponse
  useEffect(() => {
    if (inviteInfoResponse.success === true) {
      const { data } = inviteInfoResponse;
      const { organization_name, email } = data;
      setCompanyName(organization_name);
    }
  }, [inviteInfoResponse]);

  const canSubmit =
    name &&
    email &&
    password &&
    passwordMatches &&
    companyName &&
    termsAccepted &&
    !isLoading;

  const onSubmit = (e) => {
    e.preventDefault();

    signup({
      name,
      email,
      password,
      companyName,
      inviteKey: inviteInfoResponse.success ? inviteKey : null,
    });
  };

  return (
    <React.Fragment>
      <Toast
        color="error"
        severity="error"
        message={inviteKeyError}
        vertical="top"
        horizontal="center"
        showToast={Boolean(inviteKeyError)}
      />
      <PageContainer>
        <Flex direction="column" justifyContent="center" center>
          <Text variant="h1">Create your account</Text>
          <Card mt="10px" width="350px" p="30px">
            <Flex direction="column" justifyContent="center" center>
              <form onSubmit={onSubmit}>
                <InputField
                  id="name"
                  fullWidth
                  placeholder="Name"
                  type="text"
                  value={name}
                  error={Boolean(nameError)}
                  helperText={nameError}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <InputField
                  id="email"
                  mt={2}
                  fullWidth
                  placeholder="Email"
                  type="email"
                  value={email}
                  variant={canEditEmail ? "outlined" : "filled"}
                  disabled={!canEditEmail}
                  error={Boolean(emailError)}
                  helperText={emailError}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />

                <Div mb={password ? 1 : 2}>
                  <InputField
                    id="password"
                    mt={2}
                    fullWidth
                    placeholder="Password (at least 8 characters)"
                    type="password"
                    minLength="8"
                    value={password}
                    error={Boolean(passwordError)}
                    helperText={passwordError}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <Suspense
                    fallback={
                      password ? (
                        <Div height={26}>
                          <ContentLoader height="2px" width="350px" />
                        </Div>
                      ) : null
                    }
                  >
                    {password && (
                      <PasswordStrengthBar password={password} minLength={8} />
                    )}
                  </Suspense>
                </Div>

                <InputField
                  id="passwordConfirm"
                  fullWidth
                  placeholder="Confirm Password"
                  type="password"
                  value={passwordConfirm}
                  error={!passwordMatches}
                  helperText={
                    passwordMatches ? null : "Passwords do not match."
                  }
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                  required
                />
                <InputField
                  id="companyName"
                  mt={2}
                  fullWidth
                  placeholder="Company Name"
                  type="text"
                  value={companyName}
                  error={Boolean(companyNameError)}
                  helperText={companyNameError}
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                />
                <Flex alignItems="center" mt={2}>
                  <Input
                    ml={2}
                    height="15px"
                    width="15px"
                    id="termsAccepted"
                    type="checkbox"
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(!termsAccepted)}
                    required
                  />
                  <Div ml={1} color="textSecondary" fontSize="12px">
                    {`I agree to the `}
                    <Link
                      href="https://www.pullrequest.com/terms"
                      target="_blank"
                    >
                      Terms of Service
                    </Link>
                    {` and `}
                    <Link
                      href="https://www.pullrequest.com/privacy"
                      target="_blank"
                    >
                      Privacy Policy
                    </Link>
                  </Div>
                </Flex>
                <Button
                  mt={2}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!canSubmit}
                  fullWidth
                >
                  Create Account
                </Button>
              </form>
            </Flex>
          </Card>
          <Flex
            mt="20px"
            width="350px"
            fontSize="14px"
            border={`solid 1px ${theme.palette.grey[400]}`}
            borderRadius="10px"
            p="20px 30px 20px 30px"
            justifyContent="center"
            center
          >
            Already have an account?
            <RouteLink ml="5px" to="/login" textDecoration="none">
              Sign in
            </RouteLink>
          </Flex>
        </Flex>
      </PageContainer>
    </React.Fragment>
  );
}

SignUp.propTypes = {
  signup: PropTypes.func.isRequired,
  inviteInfo: PropTypes.func.isRequired,
  signupResponse: APIResultProp({
    name: PropTypes.arrayOf(PropTypes.string),
    username: PropTypes.arrayOf(PropTypes.string),
    password: PropTypes.arrayOf(PropTypes.string),
    company_name: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  inviteInfoResponse: APIResultProp({
    organization_name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  theme: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const signupResponse = usersSelectors.signup(state);
  const inviteInfoResponse = usersSelectors.inviteInfo(state);
  return {
    signupResponse,
    inviteInfoResponse,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(usersActions, dispatch);
}

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(SignUp));
