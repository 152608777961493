import React from 'react';
import { RouteLink } from '../../../Atoms/Link';
import { Text } from '../../../Atoms/Text';
import { MuiTheme as theme } from '../../../../theme';
import { BreadcrumbWrapper } from '../Wrapper';
import styled from 'styled-components';
import { Breadcrumb } from '../Breadcrumb';

export interface Props extends StyledProps {
  /** array of objects containing "url" and "name" properties */
  breadcrumbs: Breadcrumb[];
  /** color of breadcrumb text elements */
  color?: string;
}

interface StyledProps {
  hover?: string;
}

const StyledRouteLink = styled(RouteLink)<StyledProps>`
  text-decoration: none;
  &:hover {
    color: ${(props) => props.hover};
  }
`;

export const BreadcrumbsComponent = React.forwardRef<HTMLDivElement, Props>(
  (props: Props, ref: Ref<HTMLDivElement>): JSX.Element => {
    const { breadcrumbs, color, hover } = props;

    return (
      <BreadcrumbWrapper dataTest="breadcrumbs-molecule" ref={ref}>
        {breadcrumbs.map((item, index) => {
          if (item.url) {
            return (
              <StyledRouteLink
                hover={hover}
                key={`${item.name}${index}`}
                to={item.url}
                fontSize={theme.typography.body1.fontSize}
                lineHeight="20px"
                color={props.color}
              >
                {item.name}
              </StyledRouteLink>
            );
          }

          return (
            <Text key={item.name} variant="body1" color={color}>
              <b>{item.name}</b>
            </Text>
          );
        })}
      </BreadcrumbWrapper>
    );
  }
);

BreadcrumbsComponent.displayName = 'Breadcrumbs';

BreadcrumbsComponent.defaultProps = {
  color: theme.palette.grey[500],
  hover: theme.palette.grey[800],
};

export const Breadcrumbs = BreadcrumbsComponent;
