import React from 'react';
import { Div } from '../../../../Atoms/Div';
import { Text } from '../../../../Atoms/Text';
import { MarkdownFormItem } from '../../../../DynamicForm';
import { TextField, Checkbox, FormControlLabel } from '@mui/material';

export interface Props {
  item?: MarkdownFormItem;
  onUpdate: (item: MarkdownFormItem, isComplete: boolean) => void;
}

const createEmptyItem = (): MarkdownFormItem => ({
  type: 'markdown',
  name: '',
});

export const MarkdownItemEditor: React.FC<Props> = ({
  item = createEmptyItem(),
  onUpdate,
}: Props) => {
  const isComplete = (name: string) => name.length > 0;

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onUpdate(
      {
        ...item,
        name: value,
      },
      isComplete(value)
    );
  };

  const onChangeLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onUpdate(
      {
        ...item,
        label: value,
      },
      isComplete(item.name)
    );
  };

  const onChangeOptional = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    onUpdate(
      {
        ...item,
        optional: value ? true : undefined,
      },
      isComplete(item.name)
    );
  };

  return (
    <Div mt={2} display="flex" flexDirection="column">
      <TextField
        required
        style={{ width: '100%' }}
        label="Name"
        value={item.name}
        onChange={onChangeName}
      />
      <TextField
        style={{ width: '100%', marginBottom: 20 }}
        label="Label"
        value={item.label ?? ''}
        onChange={onChangeLabel}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(item.optional)}
            onChange={onChangeOptional}
          />
        }
        label={<Text>Optional</Text>}
      />
    </Div>
  );
};
