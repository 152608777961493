import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useTheme } from "@mui/material/styles";

import { Button, Div } from "Common";
import { Text } from "Common/components/Atoms/Text";

import APIRequestFormInput from "components/lib/APIRequestFormInput";
import APIRequestSuccessToast from "components/lib/APIRequestSuccessToast";

import { APIData } from "utils/apiData";
import { actions, selectors, CurrentUser } from "reducers/users";

export type Props = {
  currentUser: CurrentUser;
  updateUserResponse: APIData<CurrentUser>;
};

export const ContactInfo: React.FC<Props> = ({
  currentUser,
  updateUserResponse,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [name, setName] = useState(currentUser.profile.name);

  // 0 - clear the update user API on unmount
  useEffect(() => {
    return () => {
      dispatch(actions.clearUpdateUser());
    };
  }, [dispatch]);

  const onSave = () => {
    dispatch(actions.updateUser({ name }));
  };
  const updateEnabled = !updateUserResponse.isLoading;

  const labelProps = {
    fontSize: "13px",
    color: theme.palette.text.secondary,
    fontWeight: 600,
    mb: "7px",
    mt: "10px",
  };
  const inputProps = {
    borderRadius: "4px",
    p: "10px 15px",
    fontSize: "16px",
    color: theme.palette.text.primary,
    width: "100%",
  };
  return (
    <>
      <Div ml="75px" width="50%">
        <Div>
          <Div {...labelProps}>Email</Div>
          <Div
            {...inputProps}
            boxSizing="border-box"
            backgroundColor={theme.palette.grey[100]}
            border="none"
          >
            {currentUser.email}
          </Div>
        </Div>
        <Div>
          <Div {...labelProps}>Name</Div>
          <APIRequestFormInput
            id="name"
            results={updateUserResponse}
            errorField="profile.name"
            placeholder="First Last"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            required
          />
        </Div>
        <Button
          variant="contained"
          color="primary"
          height="40px"
          mt="40px"
          onClick={onSave}
          disabled={!updateEnabled}
        >
          Update
        </Button>
      </Div>
      <APIRequestSuccessToast
        results={updateUserResponse}
        message="Success! Your user account has been updated!"
      />
    </>
  );
};

function mapStateToProps(state: any, ownProps: {}) {
  const updateUserResponse = selectors.updateUser(state);
  return { updateUserResponse };
}

export default connect(mapStateToProps)(ContactInfo);
