import React from 'react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { SpacingProps, SpacingPropsKeys } from '../../../types/MaterialUI';
import { Spacing } from '../../../styles/MaterialUI';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import styled from 'styled-components';

export interface Props extends FontAwesomeIconProps, SpacingProps {
  icon: IconDefinition;
  size?: Size;
  dataTest?: string;
}

export type Size =
  | 'xs'
  | 'lg'
  | 'sm'
  | '1x'
  | '2x'
  | '3x'
  | '4x'
  | '5x'
  | '6x'
  | '7x'
  | '8x'
  | '9x'
  | '10x';

const StyledFontAwesomeIcon = styled(FontAwesomeIcon).withConfig({
  shouldForwardProp: (prop) => !SpacingPropsKeys.includes(prop),
})<SpacingProps>`
  ${Spacing}
`;

const FontIconComponent = React.forwardRef<any, Props>(
  (props: Props, ref: any) => {
    const { icon, size, color, dataTest, ...passThroughProps } = props;

    return (
      <StyledFontAwesomeIcon
        {...passThroughProps}
        data-test={dataTest || 'fonticon-atom'}
        forwardedRef={ref}
        icon={icon}
        size={size}
        color={color}
      />
    );
  }
);

FontIconComponent.displayName = 'FontIcon';

export const FontIcon = FontIconComponent;
