import { combineReducers } from "redux";
import { createSelector } from "reselect";

import {
  APITypes,
  Identifier,
  APIRequest,
  apiRequestStore,
  IndirectAPIRequest,
  apiRequestSelector,
  emptyIndirectAPIData,
  IndirectAPIFindState,
  indirectAPIRequestStore,
  indirectAPIRequestSelector,
} from "utils/apiData";
import {
  getMapEntries,
  indirectPageMapStore,
  PageMapKeyFunction,
} from "utils/pageMapV2";
import {
  pageStore,
  PaginatedAPIRequest,
  emptyPaginatedAPIData,
} from "utils/pagination";

export const types = {
  TEMPLATE: new APITypes("TEMPLATES/TEMPLATE"),
  TEMPLATE_CREATE: new APITypes("TEMPLATES/TEMPLATE_CREATE"),

  TEMPLATES: new APITypes("TEMPLATES/TEMPLATES"),

  TEMPLATE_VERSION_LATEST: new APITypes("TEMPLATES/TEMPLATE_VERSION_LATEST"),
  TEMPLATE_VERSION_CREATE: new APITypes("TEMPLATES/TEMPLATE_VERSION_CREATE"),
};

/******************************************************************************
 * Types
 *****************************************************************************/
export type Template = {
  id: string;
  name: string;
  max_version?: number | null;
  created_at: string;
  updated_at: string;
};

export const TemplateKey: PageMapKeyFunction<Template> = (entry: Template) => {
  return entry.id;
};

export type TemplateVersion = {
  id: string;
  template_id: string;
  data?: string;
  created_at: string;
};

/******************************************************************************
 * Requests
 *****************************************************************************/
export type CreateTemplateRequest = APIRequest<Template> & {
  name?: string;
};

export type TemplateRequestArgs<T> = APIRequest<T> & {
  id?: string;
};

export class TemplateRequest<T> extends IndirectAPIRequest<T> {
  id?: string;

  constructor(args?: TemplateRequestArgs<T>) {
    super(args);
    if (!args) {
      return;
    }

    this.id = args.id;
  }

  getKey(): string | undefined {
    return this.id;
  }
}

export type TemplateVersionRequestArgs<T> = TemplateRequestArgs<T> & {
  data?: string;
  filename?: string;
};

export class TemplateVersionRequest<T> extends TemplateRequest<T> {
  data?: string;

  constructor(args?: TemplateVersionRequestArgs<T>) {
    super(args);
    if (!args) {
      return;
    }

    this.data = args.data;
  }
}

/******************************************************************************
 * Stores
 *****************************************************************************/
export default combineReducers({
  createTemplate: apiRequestStore<CreateTemplateRequest, Template>(
    types.TEMPLATE_CREATE
  ),
  templates: indirectPageMapStore<TemplateRequest<Template>, Template>(
    types.TEMPLATE,
    types.TEMPLATES,
    TemplateKey
  ),
  templatePages: pageStore<PaginatedAPIRequest, Template>(types.TEMPLATES),
  latestTemplateVersion: indirectAPIRequestStore<
    TemplateRequest<TemplateVersion>,
    TemplateVersion
  >(types.TEMPLATE_VERSION_LATEST),
  createTemplateVersion: indirectAPIRequestStore<
    TemplateVersionRequest<TemplateVersion>,
    TemplateVersion
  >(types.TEMPLATE_VERSION_CREATE),
});

/******************************************************************************
 * Actions
 *****************************************************************************/
function templateAction<T>(typeString: string) {
  return (args?: TemplateRequestArgs<T>) => ({
    type: typeString,
    request: new TemplateRequest(args),
  });
}

export const actions = {
  clearCreateTemplate: () => ({
    type: types.TEMPLATE_CREATE.clear,
  }),
  createTemplate: (request?: CreateTemplateRequest) => ({
    type: types.TEMPLATE_CREATE.request,
    request,
  }),

  clearTemplate: templateAction<Template>(types.TEMPLATE.clear),
  fetchTemplate: templateAction<Template>(types.TEMPLATE.request),
  clearTemplates: (request?: PaginatedAPIRequest) => ({
    type: types.TEMPLATES.clear,
    request,
  }),
  fetchTemplates: (request?: PaginatedAPIRequest) => ({
    type: types.TEMPLATES.request,
    request,
  }),
  clearLatestTemplateVersion: templateAction<TemplateVersion>(
    types.TEMPLATE_VERSION_LATEST.clear
  ),
  fetchLatestTemplateVersion: templateAction<TemplateVersion>(
    types.TEMPLATE_VERSION_LATEST.request
  ),
  clearCreateTemplateVersion: (
    args?: TemplateVersionRequestArgs<TemplateVersion>
  ) => ({
    type: types.TEMPLATE_VERSION_CREATE.clear,
    request: new TemplateVersionRequest(args),
  }),
  createTemplateVersion: (
    args?: TemplateVersionRequestArgs<TemplateVersion>
  ) => ({
    type: types.TEMPLATE_VERSION_CREATE.request,
    request: new TemplateVersionRequest(args),
  }),
};

/******************************************************************************
 * Selectors
 *****************************************************************************/
function templateSelector<T>(findState: IndirectAPIFindState<T>) {
  const selector = indirectAPIRequestSelector<TemplateRequest<T>, T>(findState);
  return (state: any, { id }: { id: string }) => {
    const request = new TemplateRequest({ id });
    return selector(state, request);
  };
}

const templateEntries = (state: any) =>
  state.templates.templates || emptyIndirectAPIData;
const templatesPages = (state: any) =>
  state.templates.templatePages || emptyPaginatedAPIData;
const templateList = createSelector(
  [templateEntries, templatesPages],
  (entries, pages) => {
    const list: Array<Template> = [];
    for (let template of getMapEntries(pages, entries, TemplateKey)) {
      if (!template.isFilled || !template.data) {
        continue;
      }
      list.push(template.data);
    }
    return list;
  }
);

export const selectors = {
  templates: templateEntries,
  createTemplate: apiRequestSelector<Template>(
    (state: any) => state.templates.createTemplate
  ),
  template: templateSelector<Template>(
    (state: any) => state.templates.templates
  ),
  templatesPages,
  templateList,
  latestTemplateVersion: templateSelector<TemplateVersion>(
    (state: any) => state.templates.latestTemplateVersion
  ),
  createTemplateVersion: templateSelector<TemplateVersion>(
    (state: any) => state.templates.createTemplateVersion
  ),
};
