import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Theme } from "@mui/material/styles";
import withTheme from "@mui/styles/withTheme";
import { Switch, Route, Redirect } from "react-router-dom";
import { faFileAlt, faWrench } from "@fortawesome/pro-regular-svg-icons";
import { faStars } from "@fortawesome/pro-duotone-svg-icons";

import { Div, Flex, SidebarNav } from "Common";

import Templates from "./templates";
import Challenges from "./challenges";

export type ConfigureProps = {
  theme: Theme;
  match: {
    path: string;
  };
};

export const Configure: React.FC<ConfigureProps> = ({ match }) => {
  const history = useHistory();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const subMenu = [
    {
      title: "Challenges",
      url: `${match.path}/challenges`,
      icon: faStars,
    },
    {
      title: "Templates",
      url: `${match.path}/templates`,
      icon: faFileAlt,
    },
  ];

  return (
    <Flex height="100%" style={{ overflowY: "auto" }}>
      <SidebarNav
        homeUrl={match.path}
        headerIcon={faWrench}
        headerName="Configure"
        menuItems={subMenu}
        onCollapse={() => setIsCollapsed(!isCollapsed)}
        collapsed={isCollapsed}
        history={history}
      />
      <Div width="100%" style={{ overflowY: "auto" }}>
        <Switch>
          <Route
            path={`${match.path}/challenges`}
            render={(props) => {
              return <Challenges parentPath={match.path} {...props} />;
            }}
          />
          <Route
            path={`${match.path}/templates`}
            render={(props) => {
              return <Templates parentPath={match.path} {...props} />;
            }}
          />
          <Redirect to={`${match.path}/challenges`} />
        </Switch>
      </Div>
    </Flex>
  );
};

function mapStateToProps(state: any, ownProps: {}) {
  return {};
}

export default withTheme<Theme, React.JSXElementConstructor<ConfigureProps>>(
  connect(mapStateToProps)(Configure)
);
