import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";

import { APIData } from "utils/apiData";
import {
  actions,
  selectors,
  CandidateChallengeStatus,
} from "reducers/challengesV2";

import StateMachine from "./StateMachine";
import Problem from "./Problem";

export type CodeChallengeProps = {
  match: {
    path: string;
    url: string;
  };
  challengeId: string;
  challengeStatus: APIData<CandidateChallengeStatus>;
};

export const CodeChallenge: React.FC<CodeChallengeProps> = ({
  match,
  challengeId,
  challengeStatus,
}) => {
  const dispatch = useDispatch();

  // 0 - fetch challenge status on load
  useEffect(() => {
    dispatch(actions.candidate.status({ id: challengeId }));
    return () => {
      dispatch(actions.candidate.clearStatus({ id: challengeId }));
    };
  }, [dispatch, challengeId]);

  // 1 - redirect to not_found if the challenge turns out to be cancelled
  useEffect(() => {
    const isInvalid = challengeStatus.isFilled && !challengeStatus.success;
    const isCancelled =
      challengeStatus.isFilled && challengeStatus?.data?.status === "canceled";
    if (isInvalid || isCancelled) {
      // TODO: We really need a client-side 404 page, so we can just render
      // that at this route rather than redirecting.
      window.location.href = "/not_found";
    }
  }, [challengeStatus]);

  if (!challengeStatus.isFilled) {
    return null;
  }

  return (
    <Switch>
      <Route exact path={match.path} component={StateMachine} />
      <Route exact path={`${match.path}/problem`} component={Problem} />
      <Redirect to={match.url} />
    </Switch>
  );
};

export type OwnProps = {
  match?: {
    params?: {
      challengeId?: string;
    };
  };
};

function mapStateToProps(state: any, ownProps: OwnProps) {
  const { match } = ownProps;
  const challengeId = (match && match.params && match.params.challengeId) || "";
  const challengeStatus = selectors.candidate.status(state, {
    id: challengeId,
  });
  return {
    challengeId,
    challengeStatus,
  };
}

export default connect(mapStateToProps)(CodeChallenge);
