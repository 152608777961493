export const statuses = {
  INVITATION_SENT: "Invitation sent",
  CREATED: "Processing order",
  WAITING_ON_CANDIDATE: "Waiting on candidate",
  REVIEWING_SUBMISSION: "Reviewing submission",
  FINALIZING_REPORT: "Finalizing report",
  REPORT_COMPLETE: "Report complete",
  CANCELED: "Canceled",
  EXPIRED: "Expired",
};

export const statusStrings = {
  [statuses.CREATED]: {
    bold: "Your order is being processed.",
    main: "We'll send the code challenge to your candidate as soon as it's ready!",
  },
  [statuses.INVITATION_SENT]: {
    bold: "We've sent the code challenge invitation to your candidate.",
    main: "We'll notify you via email when they access the code challenge and again when they submit a solution.",
  },
  [statuses.WAITING_ON_CANDIDATE]: {
    bold: "Candidate is working on code challenge.",
    main: "We'll notify you via email when they submit a solution.",
  },
  [statuses.REVIEWING_SUBMISSION]: {
    bold: "We're reviewing your candidate's submission.",
    main: "Reviewers from the PullRequest network are analyzing the submission. We'll notify you via email when the results are ready.",
  },
  [statuses.FINALIZING_REPORT]: {
    bold: "We're finalizing your candidate's report.",
    main: "The solution has been reviewed and the results are being finalize into a report. We'll notify you via email when it's ready.",
  },
  [statuses.REPORT_COMPLETE]: {
    bold: "Report complete!",
    main: "Download the code challenge requirements that were sent, the candidate's submission, and the finalized report below.",
  },
  [statuses.CANCELED]: {
    bold: "This code challenge was canceled.",
    main: 'To send a code challenge to this candidate again, go back and click "Invite a Candidate"',
  },
  [statuses.EXPIRED]: {
    bold: "Code challenge is expired.",
    main: "Over 30 days lapsed since the code challenge invitation was sent to this candidate.",
  },
};

export const statusColor = (status, theme) => {
  let color = theme.palette.primary.main;
  let background = theme.palette.primary.dim;
  switch (status) {
    case statuses.INVITATION_SENT:
      color = theme.palette.secondary.main;
      background = theme.palette.secondary.dim;
      break;
    case statuses.WAITING_ON_CANDIDATE:
      color = theme.palette.info.main;
      background = theme.palette.info.dim;
      break;
    case statuses.REVIEWING_SUBMISSION:
      color = theme.palette.highlight.main;
      background = theme.palette.highlight.dim;
      break;
    case statuses.FINALIZING_REPORT:
      color = theme.palette.merged.main;
      background = theme.palette.merged.dim;
      break;
    case statuses.REPORT_COMPLETE:
      color = theme.palette.success.main;
      background = theme.palette.success.dim;
      break;
    case statuses.CANCELED:
      color = theme.palette.error.main;
      background = theme.palette.error.dim;
      break;
    case statuses.EXPIRED:
      color = theme.palette.grey[500];
      background = theme.palette.grey[50];
      break;
    case statuses.CREATED:
    default:
      color = theme.palette.primary.main;
      background = theme.palette.primary.dim;
  }

  return { color: color, background: background };
};
