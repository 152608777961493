import merge from 'lodash/merge';
import { uriTransformer } from 'react-markdown';

export default function plugin(opts) {
  const settings = opts || {};
  const userTypes = settings.nodeTypes || {};
  const nodeTypes = merge(defaultNodeTypes, userTypes);
  this.Compiler = function compiler(node) {
    let rootChildren = forceBlankLines(node.children);
    const transformed = rootChildren.map((c) => {
      return transform(c, merge(settings, { nodeTypes }));
    });
    return normalize(transformed);
  };
}

function transform(node, opts) {
  const settings = opts || {};
  const userTypes = settings.nodeTypes || {};
  const types = merge(defaultNodeTypes, userTypes);
  let children = [];
  // Transforms code blocks into slate format
  if (node.type === 'code' && node.value) {
    children = node.value.split('\n').map((line) => {
      return transform({
        type: 'paragraph',
        children: [{ type: 'text', value: line }],
      });
    });
  }

  if (Array.isArray(node.children) && node.children.length > 0) {
    if (
      node.type === 'paragraph' &&
      node.children.some((child) => child.value && child.value.includes('\n'))
    ) {
      node.children.forEach((child, idx) => {
        const transformedChild = transform(child);
        /** Remark parses multiple lines of text as one paragraph node
         * This condition transforms the single paragraph into multiple paragraph nodes to match what Slate produces */
        if (child.value && child.value.includes('\n') && idx === 0) {
          children = children.concat(
            child.value.split('\n').map((line, i) => {
              return transform({
                type: 'paragraph',
                children: [{ type: 'text', value: line }],
              });
            })
          );
        } else {
          /** Handles case where a new line is produced within a text leaf
           * The split('\n') will separate the text from the previous line (i === 0) from the text in the new line
           * New line means a new paragraph node is created */
          if (transformedChild.text && transformedChild.text.includes('\n')) {
            transformedChild.text.split('\n').forEach((text, i) => {
              if (i === 0) {
                children[children.length - 1].children.push({ text: text });
              } else {
                children.push(
                  transform({
                    type: 'paragraph',
                    children: [{ type: 'text', value: text }],
                  })
                );
              }
            });
          } else {
            /**  If there's no new line detected within the leaf,
             * we simply push new text to the children of the current paragraph node */
            if (children && children.length) {
              children[children.length - 1].children.push(transformedChild);
            } else {
              // Creates new paragraph node if there's no "current" paragraph to add to
              children.push({
                type: 'paragraph',
                children: [transformedChild],
              });
            }
          }
        }
      });
    } else {
      // Sets children of the non-paragraph block elements (heading, lists, quotes, code, etc.)
      let blockChildren = forceBlankLines(node.children);
      children = blockChildren.map(function (c) {
        return transform(
          merge(c, {
            parentNode: node,
            ordered: node.ordered || false,
          }),
          settings
        );
      });
    }
  }
  switch (node.type) {
    case 'heading':
      return {
        type: 'heading',
        children: children,
      };
    case 'list':
      return {
        type: node.ordered ? types.ol_list : types.ul_list,
        children: children,
      };
    case 'listItem':
      children = normalizeListItemChildren(children);
      return {
        type: node.parentNode.ordered ? types.ol_list_item : types.ul_list_item,
        children: children,
      };
    case 'inlineCode':
      return merge({ text: node.value }, { code: true });
    case 'emphasis':
      if (node.children && node.children[0].type === 'strong') {
        return merge(forceLeafNode(children), { bold: true, italic: true });
      }
      return merge(forceLeafNode(children), { italic: true });
    case 'strong':
      if (node.children && node.children[0].type === 'emphasis') {
        return merge(forceLeafNode(children), { bold: true, italic: true });
      }
      return merge(forceLeafNode(children), { bold: true });
    case 'delete':
      return merge(forceLeafNode(children), { strikeThrough: true });
    case 'paragraph':
      return {
        type: types.paragraph,
        children: children,
      };
    case 'link':
      return merge(forceLeafNode(children), {
        link: true,
        type: types.link,
        url: uriTransformer(node.url),
      });
    case 'image':
      return {
        type: types.image,
        children: [{ text: '' }],
        url: uriTransformer(node.url),
      };
    case 'blockquote':
      return {
        type: types.block_quote,
        children: children,
      };
    case 'code':
      return {
        type: types.code_block,
        children,
      };
    case 'text':
    default:
      return {
        text: node.value || '',
      };
  }
}

function forceLeafNode(children) {
  return { text: children.map((k) => k.text).join('') };
}

// Since remark-parse does not tokenize empty lines between paragraphs.
// This adds empty paragraph nodes in those positions.
function forceBlankLines(nodes) {
  let updatedNodes = nodes.slice();
  let emptyLinesCount = 0;
  nodes.forEach((c, i) => {
    const prevPosition = c.position;
    const nextPosition = nodes[i + 1] ? nodes[i + 1].position : null;
    const isAdjacentParagraphs =
      c.type === 'paragraph' &&
      nextPosition &&
      nodes[i + 1].type === 'paragraph';
    if (isAdjacentParagraphs && prevPosition && nextPosition) {
      // Signifies that there's an empty line between these two paragraphs
      if (nextPosition.start.line - prevPosition.end.line > 1) {
        updatedNodes.splice(i + emptyLinesCount + 1, 0, {
          type: 'paragraph',
          children: [{ type: 'text', value: '' }],
        });
        emptyLinesCount = emptyLinesCount + 1;
      }
    }
  });
  return updatedNodes;
}

/** While the transform code updates the children within paragraph nodes, the resulting value contains nested paragraphs
 * This function checks for nested paragraphs and normalizes them
 */
function normalize(routes) {
  let normalized = [];
  if (routes) {
    normalized = routes.reduce((acc, r) => {
      // Recursively checks each paragraph node for paragraph nodes in its children array
      if (
        r.type === 'paragraph' &&
        r.children &&
        r.children.length &&
        r.children.some((child) => child.type && child.type === 'paragraph')
      ) {
        r.children.forEach((child) => {
          if (
            child.type === 'paragraph' &&
            child.children &&
            child.children.some((leaf) => leaf.type === 'paragraph')
          ) {
            acc = acc.concat(normalize(child.children));
          } else {
            /** In order to handle new lines within paragraphs, the transform above checks for a \n character and splits on it
             * One side effect of this split, is that it adds empty strings at the end and beginnings of each paragraph nodes
             * This check removes these unnecessary, empty strings from paragraph children */
            if (
              child.type === 'paragraph' &&
              child.children &&
              child.children.some((leaf) => leaf.text === '')
            ) {
              let newLeaves = [];
              child.children.forEach((leaf) => {
                if (leaf.text !== '') {
                  newLeaves.push(leaf);
                }
              });
              acc = acc.concat({ type: 'paragraph', children: newLeaves });
            } else {
              acc = acc.concat(child);
            }
          }
        });
      }
      // Normalization of non-paragraph nodes
      else {
        // Block quotes contain paragraph nodes so we need to normalize those as well
        if (r.type === 'block-quote' && r.children) {
          var quote = [
            {
              type: 'block-quote',
              children: normalize(r.children),
            },
          ];
          acc = acc.concat(quote);
        } else if (
          r.type === 'paragraph' &&
          r.children.length === 1 &&
          r.children[0].type === 'image'
        ) {
          acc = acc.concat(r.children);
        } else {
          acc = acc.concat(r);
        }
      }
      return acc;
    }, []);
  }
  return normalized;
}

/** For lists, remark parse will insert a paragraph within each list item
 * This normalizes the list item elements such that the direct child of a list item
 * is just a text leaf (to match Slate editor value)
 */
function normalizeListItemChildren(children) {
  if (children && children.length) {
    return children.map((child) => {
      return child.children[0];
    });
  }
  return children;
}

const defaultNodeTypes = {
  paragraph: 'paragraph',
  block_quote: 'block-quote',
  code_block: 'code-block',
  link: 'link',
  image: 'image',
  ul_list: 'bulleted-list',
  ul_list_item: 'bulleted-list-item',
  ol_list: 'numbered-list',
  ol_list_item: 'numbered-list-item',
  listItem: 'list-item',
  heading: {
    1: 'heading_one',
    2: 'heading_two',
    3: 'heading_three',
    4: 'heading_four',
    5: 'heading_five',
    6: 'heading_three',
  },
};
