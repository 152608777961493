import React from 'react';
import { IconButton as MuiIconButton } from '@mui/material';
import styled from 'styled-components';
import {
  ElementProps,
  SpacingProps,
  SpacingPropsKeys,
  SizingProps,
  SizingPropsKeys,
  TypographyProps,
  TypographyPropsKeys,
} from '../../../types/MaterialUI';
import { Spacing, Sizing, Typography } from '../../../styles/MaterialUI';
import { useTheme } from '@mui/material/styles';

export type ButtonColor = 'inherit' | 'primary' | 'secondary' | 'default';
export type Edge = 'start' | 'end' | false;

export interface Props extends StyleProps {
  /** Determines if button is clickable or not. */
  disabled?: boolean;
  color?: ButtonColor;
  disableRipple?: boolean;
  children?: React.ReactNode;
  edge?: Edge;
  size?: 'small' | 'medium';
  href?: string;
  target?: string;
  rel?: string;
}

interface StyleProps
  extends ElementProps,
    SpacingProps,
    SizingProps,
    TypographyProps {
  border?: string;
}

const propsNotToForward = [
  ...SpacingPropsKeys,
  ...SizingPropsKeys,
  ...TypographyPropsKeys,
];

const StyledButton = styled(MuiIconButton).withConfig({
  shouldForwardProp: (prop, _) => !propsNotToForward.includes(prop),
})<StyleProps>`
  && {
    ${Spacing}
    ${Sizing}
    ${Typography}
    border: ${(props) => props.border || 'none'}
  }
`;

const ButtonComponent = React.forwardRef<HTMLButtonElement, Props>(
  (props: Props, ref: Ref<HTMLButtonElement>): JSX.Element => {
    const theme = useTheme();
    return (
      <StyledButton
        {...props}
        theme={theme}
        ref={ref}
        data-test="iconbutton-atom"
      />
    );
  }
);

ButtonComponent.displayName = 'IconButton';

export const IconButton = ButtonComponent;
