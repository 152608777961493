import React from "react";
import { connect, useDispatch } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";

import { Flex } from "Common";

import { actions, selectors, CurrentUser } from "reducers/users";

import HeaderBar from "components/lib/HeaderBar";

import UserSettings from "./settings/user";
import Configure from "./configure";
import VerifyEmail from "./VerifyEmail";
import Dashboard from "./dashboard";
import CreateOrganization from "./settings/organization/create";

export type LoggedInAppProps = {
  currentUser?: CurrentUser;
};

export const LoggedInApp: React.FC<LoggedInAppProps> = ({ currentUser }) => {
  const dispatch = useDispatch();

  if (!currentUser) {
    // This isn't a valid case, but it's necessary to avoid type errors.
    return null;
  }

  const organizations = currentUser?.organizations;
  const organization =
    organizations && organizations.length > 0 && organizations[0];

  return (
    <Flex direction="column" height="100%">
      <HeaderBar />
      <Switch>
        <Route path="/user" component={UserSettings} />
        {currentUser.is_staff ? (
          <Route path="/staff/configure" component={Configure} />
        ) : null}
        {!currentUser.profile.email_verified ? (
          <Route
            path="/verify-email"
            render={(props) => {
              return <VerifyEmail {...props} />;
            }}
          />
        ) : !organization ? (
          <Route path="/create-org" component={CreateOrganization} />
        ) : (
          <Route path="/dash" component={Dashboard} />
        )}
        <Route
          path="/"
          exact
          render={() => (
            <Redirect
              to={
                !currentUser.profile.email_verified
                  ? "/verify-email"
                  : !organization
                  ? "/create-org"
                  : "/dash"
              }
            />
          )}
        />

        {/* TODO: Redirect to a 404 page rather than "/"? */}
        <Redirect to="/" />
      </Switch>
    </Flex>
  );
};

function mapStateToProps(state: any, ownProps: {}) {
  const currentUser = selectors.currentUser(state);
  return {
    currentUser: currentUser.isFilled ? currentUser.data : undefined,
  };
}

export default connect(mapStateToProps)(LoggedInApp);
