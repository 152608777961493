import React, { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { faComment } from "@fortawesome/pro-regular-svg-icons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";

import {
  Button,
  Card,
  FontIcon,
  PageContainer,
  InfiniteLoadingTable,
} from "Common";

import { FilledAPIData, IndirectAPIData } from "utils/apiData";
import { getEntryByIndex, PaginatedAPIData } from "utils/pagination";
import { getMapEntryByIndex } from "utils/pageMapV2";
import { actions, selectors, Template, TemplateKey } from "reducers/templates";

import TemplateCell, { COLUMNS } from "./TemplateCell";
import NewTemplateModal from "./NewTemplateModal";

export type TemplateListProps = {
  templates: IndirectAPIData<Template>;
  templatesPages: PaginatedAPIData<Template>;
  parentPath: string;
  match: {
    path: string;
  };
};

const TABLE_ROW_HEIGHT = 60;
const TABLE_HEADER_HEIGHT = 48;

export const TemplateList: React.FC<TemplateListProps> = ({
  templates,
  templatesPages,
  parentPath,
  match,
}) => {
  const dispatch = useDispatch();
  const [newTemplateModalIsOpen, setNewTemplateModalIsOpen] = useState(false);

  useEffect(() => {
    dispatch(actions.fetchTemplates({ fetchAll: true, reset: true }));
  }, [dispatch]);

  const breadcrumbs = [
    {
      name: "Configure",
      url: parentPath,
    },
    {
      name: "Templates",
    },
  ];

  const { hasData, resultsCount, updateCount } = templatesPages;

  const rowGetter = ({ index }: { index: number }) => {
    return getMapEntryByIndex(templatesPages, templates, TemplateKey, index);
  };

  const isRowLoaded = ({ index }: { index: number }) => {
    return Boolean(rowGetter({ index }));
  };

  const renderCellWithDataKey = ({
    dataKey,
    rowIndex,
  }: {
    dataKey: string;
    rowIndex: number;
  }) => {
    const template = rowGetter({ index: rowIndex });
    return <TemplateCell dataKey={dataKey} template={template} match={match} />;
  };

  const onRowClick = ({
    event,
    index,
  }: {
    event: SyntheticEvent;
    index: number;
  }) => {
    return;
  };

  return (
    <React.Fragment>
      <PageContainer
        breadcrumbs={breadcrumbs}
        pagetitle="Templates"
        titleChildren={
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setNewTemplateModalIsOpen(true)}
          >
            <FontIcon icon={faPlus} mr={2} />
            New Template
          </Button>
        }
      >
        {hasData && resultsCount > 0 && (
          <Card
            height="100vh"
            maxHeight={`${
              TABLE_HEADER_HEIGHT + resultsCount * TABLE_ROW_HEIGHT
            }px`}
            display="block"
          >
            <InfiniteLoadingTable
              columns={COLUMNS}
              rowHeight={TABLE_ROW_HEIGHT}
              isRowLoaded={isRowLoaded}
              loadMoreRows={() => {}}
              rowCount={resultsCount}
              rowGetter={rowGetter}
              cellRenderer={renderCellWithDataKey}
              // Force a re-render every time the page table updates
              updateCount={updateCount}
              onRowClick={onRowClick}
            />
          </Card>
        )}
      </PageContainer>
      <NewTemplateModal
        isOpen={newTemplateModalIsOpen}
        onRequestClose={() => {
          setNewTemplateModalIsOpen(false);

          // Re-fetch templates so the new one shows up in the list
          dispatch(actions.fetchTemplates({ fetchAll: true, reset: true }));
        }}
      />
    </React.Fragment>
  );
};

function mapStateToProps(state: any, ownProps: {}) {
  const templatesPages = selectors.templatesPages(state);
  const templates = selectors.templates(state);
  return {
    templates,
    templatesPages,
  };
}

export default connect(mapStateToProps)(TemplateList);
