import React from 'react';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import PropTypes from 'prop-types';
import { Text } from '../../Atoms/Text';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: (props) => props.width || 'unset',
    minWidth: (props) => props.width || 120,
    maxWidth: (props) => props.width || 300,
  },
  chips: {
    display: 'flex',
  },
  chip: {
    margin: 5,
    width: '100%',
  },
  selectedValue: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  unselectedValue: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  placeholder: {
    color: theme.palette.text.disabled,
  },
  dropdownStyle: {
    '&::-webkit-scrollbar': {
      WebkitAppearance: 'none !important',
      width: theme.spacing(1.25),
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: theme.spacing(1.25),
      backgroundColor: theme.palette.grey[500],
      border: `1px solid white`,
    },
  },
}));

export function MultiSelect({
  items,
  placeholder,
  onChange,
  renderCustomValues,
  dontDisplaySelected,
  width,
  outlined,
}) {
  const theme = useTheme();
  const classes = useStyles({ width });
  const [value, setValue] = React.useState([]);

  const handleChange = (event) => {
    setValue(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const getClass = (itemValue) => {
    return value.indexOf(itemValue) === -1
      ? classes.unselectedValue
      : classes.selectedValue;
  };

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        marginTop: ITEM_HEIGHT - ITEM_PADDING_TOP + 2,
      },
    },
    classes: { paper: classes.dropdownStyle },
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        multiple
        displayEmpty
        value={dontDisplaySelected ? [] : value}
        onChange={handleChange}
        input={<Input />}
        MenuProps={menuProps}
        placeholder={placeholder}
        variant={outlined ? 'outlined' : 'standard'}
        renderValue={
          renderCustomValues ||
          ((selected) => {
            if (selected.length === 0) {
              return (
                <Text
                  variant="body1"
                  color={theme.palette.text.disabled}
                  pl={theme.spacing(1)}
                >
                  {placeholder}
                </Text>
              );
            }
            return selected.join(', ');
          })
        }
      >
        {items.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            className={getClass(item.value, value)}
          >
            <Text variant="body1">{item.label}</Text>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

MultiSelect.propTypes = {
  /** The options for the select */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
  /** Text that appears (greyed out) when no input is detected (optional) */
  placeholder: PropTypes.string,
  /** A function that is called whenever an option is selected (optional) */
  onChange: PropTypes.func,
  /** A function returning a component that holds a value selected (optional) */
  renderCustomValues: PropTypes.func,
  /** Determines if the values selected are shown when select field not open */
  dontDisplaySelected: PropTypes.bool,
  /** Width of the select */
  width: PropTypes.string,
  /** Whether or not the select has an outline */
  outlined: PropTypes.bool,
};
