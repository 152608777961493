import { Property } from 'csstype';

export interface PaletteProps {
  color?: Property.Color;
  backgroundColor?: Property.BackgroundColor;
  bgcolor?: Property.BackgroundColor;
}

export type PaletteStyles = Omit<PaletteProps, 'bgcolor'>;

export const PaletteStyleGenerator = (props: PaletteProps): PaletteStyles => ({
  color: props.color,
  backgroundColor: props.backgroundColor || props.bgcolor,
});

const representative: PaletteProps = {
  color: undefined,
  backgroundColor: undefined,
  bgcolor: undefined,
};
export const PalettePropsKeys = Object.keys(representative);
