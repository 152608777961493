import React from "react";
import { connect } from "react-redux";
import { useTheme } from "@mui/material/styles";

import { Div, Flex } from "Common";

import pullrequestLogo from "resources/full-logo.svg";

export type ProblemHeaderProps = {
  value: string;
  subtitle?: string;
};

export const DocumentHeader: React.FC<ProblemHeaderProps> = ({
  value,
  subtitle,
}) => {
  const theme = useTheme();
  return (
    <Flex direction="column">
      <Flex justifyContent="space-between" alignItems="center">
        <Div width="400px" height="50px">
          <Div
            width="100%"
            height="100%"
            style={{
              background: `transparent url(${pullrequestLogo}) no-repeat`,
            }}
          />
        </Div>
        <Flex direction="column" textAlign="right">
          <Div fontWeight="bold" mb={1}>
            {value}
          </Div>
          <Div>{subtitle}</Div>
        </Flex>
      </Flex>
      <hr
        style={{
          marginTop: "20px",
          height: "1px",
          width: "100%",
          color: "gray",
          backgroundColor: "gray",
        }}
      />
    </Flex>
  );
};

function mapStateToProps(state: any, ownProps: {}) {
  return {};
}

export default connect(mapStateToProps)(DocumentHeader);
