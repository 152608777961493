import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { match as Match } from "react-router";
import { useTheme } from "@mui/material/styles";
import { faSave, faDownload } from "@fortawesome/pro-solid-svg-icons";

import { Div, Flex, Button, FontIcon, PageContainer } from "Common";
import { Text } from "Common/components/Atoms/Text";
import { Builder } from "Common/components/DynamicForm/Builder";
import { FormItem } from "Common/components/DynamicForm";

import { APIData } from "utils/apiData";
import { Template } from "reducers/templates";
import { actions, selectors, Product } from "reducers/challengesV2";

import APIRequestSuccessToast from "components/lib/APIRequestSuccessToast";

import ImportFormModal from "./ImportFormModal";
import ChallengeTemplate from "./ChallengeTemplate";

export type ChallengeViewProps = {
  parentPath: string;
  challengeId: string;
  challenge?: Product;
  updateResponse: APIData<Product>;
};

export const ChallengeView: React.FC<ChallengeViewProps> = ({
  challengeId,
  challenge,
  parentPath,
  updateResponse,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [form, setForm] = useState<FormItem[]>([]);
  const [problemTemplate, setProblemTemplate] = useState<Template | null>(null);
  const [reviewerTemplate, setReviewerTemplate] = useState<Template | null>(
    null
  );
  const [isImportOpen, setIsImportOpen] = useState(false);

  // 0 - fetch challenge even if we have it already
  useEffect(() => {
    dispatch(actions.products.get({ id: challengeId }));
    return () => {
      dispatch(actions.products.clearUpdate({ id: challengeId }));
    };
  }, [dispatch, challengeId]);

  // 1 - set template/form values when the challenge is updated
  useEffect(() => {
    if (challenge) {
      setForm(challenge.submission_form || []);
      setProblemTemplate(challenge.problem_template || null);
      setReviewerTemplate(challenge.reviewer_template || null);
    }
  }, [challenge]);

  if (!challenge) {
    return null;
  }

  const onSave = () => {
    dispatch(
      actions.products.update({
        id: challengeId,
        name: challenge.name,
        slug: challenge.slug,
        problem_template: problemTemplate && problemTemplate.id,
        reviewer_template: reviewerTemplate && reviewerTemplate.id,
        submission_form: form.length > 0 ? form : null,
      })
    );
  };

  const onUpdateForm = (form: FormItem[]): void => {
    setForm(form);
  };

  // TODO: Maybe make these breadcrumbs passed in and just extend with the
  // one representing the current page.
  const breadcrumbs = [
    {
      name: "Configure",
      url: parentPath,
    },
    {
      name: "Products",
      url: `${parentPath}/products`,
    },
    {
      name: challenge.name,
    },
  ];

  return (
    <>
      <PageContainer
        pagetitle={challenge.name}
        subtitle={
          <Flex justifyContent="space-between" center>
            <Div>{challenge.slug}</Div>
            <Button
              variant="contained"
              color="primary"
              mt={2}
              onClick={() => onSave()}
            >
              <FontIcon icon={faSave} />
              <Div ml={2}>Save</Div>
            </Button>
          </Flex>
        }
        breadcrumbs={breadcrumbs}
      >
        <Flex direction="column" overflow="auto">
          <Div>
            <ChallengeTemplate
              title="Problem"
              template={problemTemplate}
              onChange={(template: Template | null) =>
                setProblemTemplate(template)
              }
            />
          </Div>
          <Text mt={1}>
            This template represents the problem that the candidate, the
            requester, and the reviewer will see. The following variables are
            available:
          </Text>
          <ul>
            <li>
              {"${"}LANGUAGE{"}"} - the language selected for the challenge
            </li>
          </ul>
          <Div pt={2}>
            <ChallengeTemplate
              title="Reviewer Prompt"
              template={reviewerTemplate}
              onChange={(template: Template | null) =>
                setReviewerTemplate(template)
              }
            />
          </Div>
          <Text mt={1}>
            This template represents what the reviewer will see in the review
            body. The following variables are available:
          </Text>
          <ul>
            <li>
              {"${"}LANGUAGE{"}"} - the language selected for the challenge
            </li>
            <li>
              {"${"}CHALLENGE_ID{"}"} - the unique ID of the technical screening
              being reviewed
            </li>
          </ul>
          <Text variant="h2" mt={4}>
            Submission Form
          </Text>
          <Text>
            The reviewer will be given this form in lieu of the traditional
            review prompt.
          </Text>
          <Button
            variant="contained"
            color="primary"
            width="120px"
            mt={2}
            onClick={() => setIsImportOpen(true)}
          >
            <FontIcon icon={faDownload} />
            <Div ml={2}>Import</Div>
          </Button>
          <Builder form={form} onUpdateForm={onUpdateForm} />
        </Flex>
      </PageContainer>
      <ImportFormModal
        isOpen={isImportOpen}
        onUpdate={(product: Product | null) => {
          setForm(product?.submission_form || []);
        }}
        onRequestClose={() => setIsImportOpen(false)}
      />
      <APIRequestSuccessToast
        results={updateResponse}
        message="Product updated successfully!"
      />
    </>
  );
};

type OwnProps = {
  match: Match<{ challengeId: string }>;
};

function mapStateToProps(state: any, { match }: OwnProps) {
  const { challengeId } = match.params;
  const challenge = selectors.products.entry(state, { id: challengeId });
  const updateResponse = selectors.products.update(state, { id: challengeId });
  return {
    challengeId,
    challenge: challenge.isFilled ? challenge.data : undefined,
    updateResponse,
  };
}

export default connect(mapStateToProps)(ChallengeView);
