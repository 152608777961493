import React from 'react';
import styled, { css } from 'styled-components';
import Select, {
  GroupTypeBase,
  SelectComponentsConfig,
  StylesConfig,
} from 'react-select';
import { useTheme } from '@mui/material/styles';
import { SizingProps, SpacingProps } from '../../../types/MaterialUI';
import { Div } from '../../Atoms/Div';

export interface Option {
  value: string;
  label: string;
}

export interface Props extends StyleProps {
  styles?: StylesConfig<Option, false, GroupTypeBase<Option>>;
  placeholder?: string;
  isClearable?: boolean;
  options: Option[];
  value?: Option;
  onChange: (option: Option | null) => void;
  onInputChange?: (inputValue: string) => void;
  components?: SelectComponentsConfig<Option, false, GroupTypeBase<Option>>;
}

interface StyleProps extends SpacingProps, SizingProps {
  fontSize?: string;
  border?: string;
  menuWidth?: string;
  menuMarginLeft?: string;
  menuZIndex?: number;
}

const fontStyles = css<StyleProps>`
  font-size: ${(props) =>
    props.fontSize || props.theme.typography.body1.fontSize};
`;

const borderStyles = css<StyleProps>`
  border: ${(props) => props.border || '1px solid'};
  border-color: ${(props) => props.theme.palette.divider};
  border-radius: 4px;
`;

const focuswithShadow = css<StyleProps>`
  border: ${(props) =>
    props.border === 'none'
      ? 'none'
      : `1px solid ${props.theme.palette.divider}`};
  outline: none;
  box-shadow: ${(props) =>
    props.border === 'none'
      ? 'none'
      : `0 0 1px 1px ${props.theme.palette.info.main}`};
  transition: 0.2s;
`;

const StyledSelect = styled(Select)<StyleProps>`
  ${fontStyles};
  &&.dialog-select-container {
    width: 100%;
    height: 100%;
  }
  .dialog-select__dropdown-indicator {
    color: ${(props) => props.theme.palette.text.secondary};
    &:hover {
      color: ${(props) => props.theme.palette.text.secondary};
    }
  }
  .dialog-select__control {
    background: ${(props) => props.theme.palette.background.paper};
    padding: ${(props) => props.theme.spacing(1)};
    ${borderStyles};
    width: 100%;
    height: 100%;
    &--is-focused {
      ${focuswithShadow};
    }
    &:hover {
      ${borderStyles};
      cursor: pointer;
    }
    &--menu-is-open {
      .dialog-select__dropdown-indicator {
        transform: rotate(180deg);
      }
    }
  }
  .dialog-select__input {
    color: ${(props) => props.theme.palette.text.primary};
  }
  .dialog-select__single-value {
    color: ${(props) => props.theme.palette.text.primary};
  }
  .dialog-select__placeholder {
    color: ${(props) => props.theme.palette.text.primary};
  }
  .dialog-select__indicator-separator {
    display: none;
  }
  .dialog-select__menu {
    background: ${(props) => props.theme.palette.background.paper};
    box-sizing: border-box;
    color: ${(props) => props.theme.palette.text.primary};
    width: ${(props) => props.menuWidth || props.width};
    margin-top: ${(props) => props.theme.spacing(1)};
    margin-left: ${(props) => props.menuMarginLeft};
    padding-top: ${(props) => props.theme.spacing(0.75)};
    padding-bottom: ${(props) => props.theme.spacing(0.75)};
    z-index: ${(props) => props.menuZIndex};
  }
  .dialog-select__menu-list {
    cursor: pointer;
    padding: 0 ${(props) => props.theme.spacing(0.5)};
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: ${(props) => props.theme.spacing(1)};
    }
    &::-webkit-scrollbar-thumb {
      border-radius: ${(props) => props.theme.spacing(1)};
      background-color: ${(props) => props.theme.palette.grey[500]};
      border: 1px solid white;
    }
  }
  .dialog-select__menu-notice {
    color: ${(props) => props.theme.palette.text.secondary};
  }
  .dialog-select__option {
    box-sizing: border-box;
    padding: ${(props) => props.theme.spacing(1)};
    border-radius: 4px;
    &--is-focused {
      background: ${(props) => props.theme.palette.action.hover};
      color: ${(props) => props.theme.palette.text.primary};
    }
    &--is-selected {
      background: ${(props) => props.theme.palette.info.dim};
      color: ${(props) => props.theme.palette.info.light};
      &:hover {
        background: ${(props) => props.theme.palette.info.dim} !important;
      }
    }
    &:hover {
      cursor: pointer;
      background: ${(props) => props.theme.palette.action.hover};
    }
  }
`;

const DialogSelectComponent = (props: Props): JSX.Element => {
  const {
    placeholder,
    isClearable,
    options,
    value,
    onChange,
    onInputChange,
    menuMarginLeft,
    menuWidth,
    menuZIndex,
    border,
    fontSize,
    components,
    styles,
    ...passThroughProps
  } = props;
  const selectProps = {
    placeholder,
    isClearable,
    options,
    value,
    onChange,
    onInputChange,
    menuMarginLeft,
    menuWidth,
    menuZIndex,
    border,
    fontSize,
    components,
    styles,
  };
  const theme = useTheme();
  return (
    <Div {...passThroughProps} dataTest="dialogselect-molecule">
      <StyledSelect
        {...selectProps}
        theme={theme}
        className="dialog-select-container"
        classNamePrefix="dialog-select"
      />
    </Div>
  );
};

DialogSelectComponent.defaultProps = {
  width: '200px',
  height: '40px',
};

DialogSelectComponent.displayName = 'DialogSelect';

export const DialogSelect = DialogSelectComponent;
