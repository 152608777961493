import React from 'react';
import MuiRadio, { RadioProps } from '@mui/material/Radio';
import makeStyles from '@mui/styles/makeStyles';

export interface Props extends RadioProps {}

const useStyles = makeStyles({
  root: {
    padding: 0,
    width: 16,
    height: 16,
  },
});

const RadioComponent = React.forwardRef<HTMLButtonElement, Props>(
  (props: Props, ref: Ref<HTMLButtonElement>) => {
    const { root } = useStyles();

    return <MuiRadio className={root} ref={ref} {...props} />;
  }
);

RadioComponent.displayName = 'Radio';
RadioComponent.defaultProps = {
  disableRipple: true,
  disableFocusRipple: true,
  disableTouchRipple: true,
};

export const Radio = RadioComponent;
