import React from "react";
import { connect } from "react-redux";
import { match as Match } from "react-router";

import { APIData } from "utils/apiData";
import { selectors, CandidateChallengeStatus } from "reducers/challengesV2";

import UnauthedHeader from "components/UnauthedHeader";

import Download from "./Download";
import Waiting from "./Waiting";
import Submitted from "./Submitted";

export type StateMachineProps = {
  challengeId: string;
  challengeStatus?: CandidateChallengeStatus;
};

export const StateMachine: React.FC<StateMachineProps> = ({
  challengeId,
  challengeStatus,
}) => {
  if (!challengeStatus) {
    // This is an invalid case as this route should only be available if the
    // challenge status has been queried.
    return null;
  }
  return (
    <>
      <UnauthedHeader />
      {challengeStatus.status === "download" ? (
        <Download challengeId={challengeId} />
      ) : null}
      {challengeStatus.status === "waiting" ? (
        <Waiting challengeId={challengeId} />
      ) : null}
      {challengeStatus.status === "submitted" ? (
        <Submitted challengeId={challengeId} />
      ) : null}
    </>
  );
};

export type OwnProps = {
  match?: Match<{ challengeId?: string }>;
};

function mapStateToProps(state: any, ownProps: OwnProps) {
  const { match } = ownProps;
  const challengeId = match?.params?.challengeId || "";
  const challengeStatus = selectors.candidate.status(state, {
    id: challengeId,
  });
  return {
    challengeId,
    challengeStatus: challengeStatus.isFilled
      ? challengeStatus.data
      : undefined,
  };
}

export default connect(mapStateToProps)(StateMachine);
