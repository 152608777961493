import { all, takeEvery } from "redux-saga/effects";

import { types as challengesTypes } from "reducers/challenges";

import * as challengesSagas from "./challenges";
import usersSagas from "./users";
import templatesSagas from "./templates";
import challengesV2Sagas from "./challengesV2";
import organizationsSagas from "./organizations";
import organizationsV2Sagas from "./organizationsV2";

export default function* rootSaga() {
  yield all([
    takeEvery(challengesTypes.CREATE_ORG_REQUEST, challengesSagas.createOrg),
    takeEvery(
      challengesTypes.BILLING_FOR_ORG_REQUEST,
      challengesSagas.fetchBillingForOrg
    ),
    takeEvery(
      challengesTypes.CHALLENGES_FOR_ORG_REQUEST,
      challengesSagas.fetchChallengesForOrg
    ),
    takeEvery(
      challengesTypes.CHALLENGE_REQUEST,
      challengesSagas.fetchChallenge
    ),
    takeEvery(
      challengesTypes.CHALLENGE_CREATE_REQUEST,
      challengesSagas.createChallenge
    ),
    takeEvery(
      challengesTypes.CHALLENGE_CANCEL_REQUEST,
      challengesSagas.cancelChallenge
    ),
    takeEvery(
      challengesTypes.CHALLENGE_DOWNLOAD_REPORT_REQUEST,
      challengesSagas.downloadReport
    ),
    takeEvery(
      challengesTypes.CHALLENGE_DOWNLOAD_SUBMISSION_REQUEST,
      challengesSagas.downloadSubmission
    ),
    takeEvery(
      challengesTypes.CHALLENGE_PROBLEM_DOWNLOAD_REQUEST,
      challengesSagas.downloadChallengeProblem
    ),

    usersSagas,
    templatesSagas,
    challengesV2Sagas,
    organizationsSagas,
    organizationsV2Sagas,
  ]);
}
