import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { Redirect } from "react-router-dom";

import { APIData } from "utils/apiData";
import queryString from "utils/queryString";
import { actions, selectors, UserAuth } from "reducers/users";

export type OauthCallbackProps = {
  oauthLoginResponse: APIData<UserAuth>;
};

export const OauthCallback: React.FC<OauthCallbackProps> = ({
  oauthLoginResponse,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const { code, state } = queryString.parse(location.search);
    if (code && state) {
      dispatch(actions.oauthLogin({ code, state }));
    }
  }, [location, dispatch]);

  const { code, state } = queryString.parse(location.search);
  if (code && state && !oauthLoginResponse.isFilled) {
    return null;
  }

  // once the request has complete just redirect back home either way
  // TODO add some kind of error reporting to the UI if this failed
  return <Redirect to="/" />;
};

function mapStateToProps(state: any, ownProps: {}) {
  const oauthLoginResponse = selectors.oauthLogin(state);
  return {
    oauthLoginResponse,
  };
}

export default connect(mapStateToProps)(OauthCallback);
