/** Package for turning directives into headers */
import { DirectiveType, DirectiveArgumentType } from "./directive";

const visit = require("unist-util-visit");

export function headers(options = {}) {
  return (tree: any, file: any) => {
    visit(tree, DirectiveType, (headerNode: any) => {
      if (headerNode.directive !== "document_header") {
        return;
      }

      headerNode.type = "documentHeader";
      visit(headerNode, DirectiveArgumentType, (arg: any) => {
        if (arg.name === "subtitle") {
          headerNode.subtitle = arg.value;
        }
      });
    });
  };
}

export function rehypeHeaders(h: any, node: any) {
  const { subtitle, value } = node;
  return h(node, "documentHeader", { subtitle, value });
}
