import React, { useState } from 'react';
import { Div } from '../../../Atoms/Div';
import { Button } from '../../../Atoms/Button';
import { Toast } from '../../../Molecules/Toast';
import { DialogSelect, Option } from '../../../Molecules/DialogSelect';
import { FormItem } from '../../../DynamicForm';
import { LabelItemEditor } from './LabelItemEditor';
import { MarkdownItemEditor } from './MarkdownItemEditor';
import { SelectItemEditor } from './SelectItemEditor';
import { useTheme } from '@mui/material/styles';

type Props = {
  type?: string;
  item?: FormItem;
  onChangeType: (type: string) => void;
  onSaveItem: (item: FormItem) => string | undefined;
  onCancel: () => void;
  onDeleteItem: () => void;
};

const typeOptions = [
  {
    value: 'header',
    label: 'Header',
  },
  {
    value: 'subtitle',
    label: 'Subtitle',
  },
  {
    value: 'markdown',
    label: 'Markdown Input',
  },
  {
    value: 'select',
    label: 'Select Input',
  },
];

export const FormItemEditor: React.FC<Props> = ({
  type = 'header',
  item: originalItem,
  onChangeType,
  onCancel,
  onSaveItem,
  onDeleteItem,
}: Props) => {
  const theme = useTheme();

  // item is the item that is being edited
  const [item, setItem] = useState(originalItem);
  const [isComplete, setIsComplete] = useState(false);
  const [errorString, setErrorString] = useState<string>();

  const onUpdateItem = (item: FormItem, isComplete: boolean) => {
    setItem(item);
    setIsComplete(isComplete);
  };

  const selectedOption = typeOptions.find((option) => option.value === type);

  const onSelectType = (option: Option | null) => {
    onChangeType(option?.value ?? type);
    setIsComplete(false);
  };

  const onSave = () => {
    if (!item) {
      return;
    }
    const errorString = onSaveItem(item);
    setErrorString(errorString);
  };

  return (
    <Div
      mt={2}
      display="flex"
      alignItems="center"
      border={`solid 1px ${theme.palette.divider}`}
      borderRadius={0.5}
      width="100%"
      boxSizing="border-box"
      p={2}
    >
      <Div ml={2} display="flex" flexDirection="column" width="100%">
        <DialogSelect
          options={typeOptions}
          value={selectedOption}
          onChange={onSelectType}
        />
        {type === 'header' && (
          <LabelItemEditor
            type={type}
            item={item?.type === 'header' ? item : undefined}
            onUpdate={onUpdateItem}
          />
        )}
        {type === 'subtitle' && (
          <LabelItemEditor
            type={type}
            item={item?.type === 'subtitle' ? item : undefined}
            onUpdate={onUpdateItem}
          />
        )}
        {type === 'markdown' && (
          <MarkdownItemEditor
            item={item?.type === 'markdown' ? item : undefined}
            onUpdate={onUpdateItem}
          />
        )}
        {type === 'select' && (
          <SelectItemEditor
            item={item?.type === 'select' ? item : undefined}
            onUpdate={onUpdateItem}
          />
        )}
        <Div display="flex" flexDirection="row-reverse" width="100%" mt={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={onSave}
            disabled={!isComplete}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={onCancel}
            mr={2}
          >
            Cancel
          </Button>
          {Boolean(originalItem) && (
            <Button
              variant="outlined"
              customcolor="red"
              onClick={onDeleteItem}
              mr={2}
            >
              Delete
            </Button>
          )}
        </Div>
      </Div>
      <Toast
        showToast={Boolean(errorString)}
        message={errorString}
        severity="error"
        handleClose={() => setErrorString(undefined)}
      />
    </Div>
  );
};
