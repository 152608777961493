import React, { useState, useEffect, useRef } from 'react';
import { Flex } from '../../../Atoms/Flex';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCode,
  faSquare,
  faListOl,
  faListUl,
  faLink,
  faHeading,
  faBold,
  faItalic,
  faSmile,
} from '@fortawesome/pro-regular-svg-icons';
import { faQuoteLeft } from '@fortawesome/pro-solid-svg-icons';
import { useSlate, ReactEditor } from 'slate-react';
import { Editor, Transforms, Range } from 'slate';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import { ClickAwayListener } from '@mui/material';
import { Modal } from '../../Modal';
import { Input } from '../../../Atoms/Input';
import { Text } from '../../../Atoms/Text';
import { Tooltip } from '../../../Atoms/Tooltip';
import { Portal } from '../../../Atoms/Portal';
import { uriTransformer } from 'react-markdown';

const PICKER_OFFSET_TOP = 400;
const PICKER_OFFSET_LEFT = 290;

const getModifier = () => {
  let platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  if (macosPlatforms.indexOf(platform) !== -1) {
    return 'cmd';
  } else {
    return 'ctrl';
  }
};

const LinkInputModal = ({
  setShowLinkModal,
  isOpen,
  setShowLinkModalHotkey,
  selection,
}) => {
  const editor = useSlate();
  const theme = useTheme();
  const [tempText, setTempText] = useState(null);
  const [tempUrl, setTempUrl] = useState('');
  const saveLink = () => {
    ReactEditor.focus(editor);
    if (selection) {
      Transforms.select(editor, selection);
      if (tempUrl) {
        const transformedUrl = uriTransformer(tempUrl);
        Editor.addMark(editor, 'url', transformedUrl);
        Editor.addMark(editor, 'link', true);
        if (tempText || Editor.string(editor, selection)) {
          Editor.insertText(
            editor,
            tempText || Editor.string(editor, selection)
          );
        } else {
          Editor.insertText(editor, transformedUrl);
        }
        Transforms.collapse(editor, { edge: 'anchor' });
        Editor.removeMark(editor, 'link');
      } else {
        Editor.removeMark(editor, 'link');
      }
    }
    setTempText(null);
    setTempUrl('');
    setShowLinkModal(false);
    setShowLinkModalHotkey(false);
  };
  const onCancel = () => {
    Editor.removeMark(editor, 'link');
    setShowLinkModal(false);
    setShowLinkModalHotkey(false);
  };
  return (
    <Modal
      variant="custom"
      icon={faLink}
      title="Add Link"
      width={500}
      height={300}
      primaryIconColor={theme.palette.secondary.main}
      secondaryIconColor={theme.palette.secondary.dim}
      isOpen={isOpen}
      onRequestClose={() => onCancel()}
      onClickPrimaryButton={() => saveLink()}
      onClickSecondaryButton={() => onCancel()}
      primaryButtonLabel="Save"
      showSecondaryButton={true}
      secondaryButtonLabel="Cancel"
    >
      <Flex direction="column">
        <Flex direction="column" mt={1}>
          <Text variant="body1" mb={1}>
            Text
          </Text>
          <Input
            type="text"
            width="360px"
            defaultValue={
              selection ? Editor.string(editor, selection) : tempText
            }
            onChange={(event) => setTempText(event.target.value)}
          ></Input>
        </Flex>
        <Flex direction="column" mt={2}>
          <Text variant="body1" mb={1}>
            Link
          </Text>
          <Input
            width="360px"
            onChange={(event) => setTempUrl(event.target.value)}
          />
        </Flex>
      </Flex>
    </Modal>
  );
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const start = {
  anchor: { offset: 0, path: [0, 0] },
  focus: { offset: 0, path: [0, 0] },
};

const useStyles = makeStyles((theme) => ({
  itemGroup: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '&:first-of-type': {
      paddingLeft: theme.spacing(0),
    },
  },
  isActive: {
    color: `${theme.palette.secondary.main} !important`,
  },
  formattingIcon: {
    fontSize: '14px',
    color: theme.palette.grey[600],
    margin: '0px 8px',
    outline: 'none',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    '&:hover': {
      color: theme.palette.grey[800],
    },
  },
  codeBlockIcon: {
    position: 'relative',
  },
  codeIcon: {
    fontSize: '10px !important',
    position: 'absolute',
    top: '5px',
    left: '1px',
  },
  squareIcon: {
    fontSize: '16px',
  },
  emojiPicker: {
    position: 'relative',
  },
}));

export const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const toggleMark = (editor, format, url) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else if (!isBlockActive(editor, 'code-block')) {
    if (url) {
      Editor.addMark(editor, 'url', url);
    }
    Editor.addMark(editor, format, true);
  }
};

export const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => n.type === format,
  });

  return !!match;
};

export const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isBulletedList = format === 'bulleted-list';
  const isNumberedList = format === 'numbered-list';
  const isList = isBulletedList || isNumberedList;
  const isCode = format === 'code-block';
  const isQuote = format === 'block-quote';

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes(n.type),
    split: true,
  });

  Transforms.unwrapNodes(editor, {
    match: (n) => n.type === 'code-block',
    split: true,
  });

  Transforms.unwrapNodes(editor, {
    match: (n) => n.type === 'block-quote',
    split: true,
  });

  Transforms.setNodes(editor, {
    type:
      isActive || isCode || isQuote
        ? 'paragraph'
        : isBulletedList
        ? 'bulleted-list-item'
        : isNumberedList
        ? 'numbered-list-item'
        : format,
  });

  if (!isActive && isList) {
    if (isNumberedList) {
      Transforms.wrapNodes(
        editor,
        { type: 'numbered-list', children: [] },
        {
          match: (n) => n.type === 'numbered-list-item',
        }
      );
    } else {
      Transforms.wrapNodes(
        editor,
        { type: 'bulleted-list', children: [] },
        {
          match: (n) => n.type === 'bulleted-list-item',
        }
      );
    }
  }
  if (!isActive && !isList && (isCode || isQuote)) {
    const { selection } = editor;
    const currentBlock = Editor.above(editor, {
      match: (n) => Editor.isBlock(editor, n),
    });
    const path = currentBlock ? currentBlock[1] : [];
    const start = Editor.start(editor, path);
    const end = Editor.end(editor, path);
    const range = { anchor: end, focus: start };
    if (isCode && Editor.string(editor, range)) {
      if (Range.isCollapsed(selection)) {
        Transforms.select(editor, range);
      }
      ['bold', 'italic', 'code', 'link'].forEach((f) => editor.removeMark(f));
    }
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const CodeBlockIcon = ({ selection, hotkey }) => {
  const classes = useStyles();
  const editor = useSlate();
  return (
    <Tooltip title={`Code Block ${hotkey}`}>
      <button
        onMouseDown={(event) => {
          event.preventDefault();
          if (!ReactEditor.isFocused(editor)) {
            ReactEditor.focus(editor);
          }
          if (!selection) {
            Transforms.select(editor, start);
          } else {
            Transforms.select(editor, selection);
          }
          toggleBlock(editor, 'code-block');
        }}
        className={`${classes.formattingIcon} ${classes.codeBlockIcon} ${
          isBlockActive(editor, 'code-block') ? classes.isActive : ''
        }`}
      >
        <FontAwesomeIcon icon={faCode} className={classes.codeIcon} />
        <FontAwesomeIcon icon={faSquare} className={classes.squareIcon} />
      </button>
    </Tooltip>
  );
};

function onClickEmoji(emoji, editor, setShowEmojiPicker, selection) {
  Transforms.select(editor, selection);
  Editor.insertText(editor, emoji.native);
  setShowEmojiPicker(false);
  ReactEditor.focus(editor);
}

const EmojiIcon = ({ selection }) => {
  const classes = useStyles();
  const theme = useTheme();
  const editor = useSlate();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const ref = useRef();
  const [pickerPosition, setPickerPosition] = useState({});
  // Dynamically sets absolute positioning of emoji picker due because we
  // are using a Portal to move the picker above other elements (i.e. code diff)
  useEffect(() => {
    const rect = ref.current.getBoundingClientRect();
    // If not enough room in viewport to render picker above TextEditor, move below
    if (rect.top < PICKER_OFFSET_TOP) {
      setPickerPosition({
        top: `${rect.top + window.pageYOffset + 60}px`,
        left: `${rect.left + window.pageXOffset - PICKER_OFFSET_LEFT}px`,
      });
    } else {
      setPickerPosition({
        top: `${rect.top + window.pageYOffset - PICKER_OFFSET_TOP}px`,
        left: `${rect.left + window.pageXOffset - PICKER_OFFSET_LEFT}px`,
      });
    }
  }, [ref, showEmojiPicker]);
  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      onClickAway={() => {
        setShowEmojiPicker(false);
      }}
    >
      <div className={classes.emojiPicker} ref={ref}>
        {showEmojiPicker && (
          <Portal container={document.body}>
            <Picker
              onClick={(emoji) => {
                onClickEmoji(emoji, editor, setShowEmojiPicker, selection);
              }}
              native={true}
              theme="light"
              set="native"
              emojiSize={20}
              sheetSize={16}
              perLine={9}
              title="Select an Emoji"
              emoji="thumbs_up"
              notFoundEmoji="sleuth_or_spy"
              color="#27557a"
              showPreview={false}
              showSkinTones={false}
              emojiTooltip={false}
              enableFrequentEmojiSort={true}
              useButton={false}
              style={{
                position: 'absolute',
                top: pickerPosition.top,
                left: pickerPosition.left,
                zIndex: theme.zIndex.drawer,
              }}
            />
          </Portal>
        )}

        <Tooltip title="Emoji">
          <button
            onMouseDown={(event) => {
              event.preventDefault();
              if (!ReactEditor.isFocused(editor)) {
                ReactEditor.focus(editor);
              }
              if (!selection) {
                Transforms.select(editor, start);
              } else {
                Transforms.select(editor, selection);
              }
              setShowEmojiPicker((prev) => !prev);
            }}
            className={`${classes.formattingIcon} ${
              showEmojiPicker ? classes.isActive : ''
            }`}
          >
            <FontAwesomeIcon icon={faSmile} />
          </button>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

const FormattingIcon = ({ item, selection, setShowLinkModal }) => {
  const classes = useStyles();
  const editor = useSlate();
  return (
    <Tooltip title={`${item.label} ${item.hotkey}`}>
      <button
        className={`${classes.formattingIcon} ${
          (item.type === 'block' && isBlockActive(editor, item.format)) ||
          (item.type === 'mark' && isMarkActive(editor, item.format))
            ? classes.isActive
            : ''
        }`}
        onMouseDown={(event) => {
          event.preventDefault();
          if (!ReactEditor.isFocused(editor)) {
            ReactEditor.focus(editor);
          }
          if (!selection) {
            Transforms.select(editor, start);
          } else {
            Transforms.select(editor, selection);
          }
          if (item.format === 'link' && !isBlockActive(editor, 'code-block')) {
            setShowLinkModal((prev) => !prev);
          }
          if (item.type === 'mark') {
            toggleMark(editor, item.format);
          } else {
            toggleBlock(editor, item.format);
          }
        }}
      >
        <FontAwesomeIcon icon={item.icon} />
      </button>
    </Tooltip>
  );
};

const items = (selection) => {
  return [
    [
      {
        type: 'block',
        label: 'Heading',
        icon: faHeading,
        format: 'heading',
        hotkey: `<${getModifier()}+h>`,
      },
      {
        type: 'mark',
        label: 'Bold',
        icon: faBold,
        format: 'bold',
        hotkey: `<${getModifier()}+b>`,
      },
      {
        type: 'mark',
        label: 'Italic',
        icon: faItalic,
        format: 'italic',
        hotkey: `<${getModifier()}+i>`,
      },
    ],
    [
      {
        type: 'block',
        label: 'Blockquote',
        icon: faQuoteLeft,
        format: 'block-quote',
        hotkey: `<${getModifier()}+shift+6>`,
      },
      {
        type: 'mark',
        label: 'Code',
        icon: faCode,
        format: 'code',
        hotkey: `<${getModifier()}+shift+c>`,
      },
      { type: 'block', label: 'Inline Code', format: 'inline-code' },
      {
        type: 'mark',
        label: 'Link',
        icon: faLink,
        format: 'link',
        hotkey: `<${getModifier()}+k>`,
      },
    ],
    [
      {
        type: 'block',
        label: 'Bulleted List',
        icon: faListUl,
        format: 'bulleted-list',
        hotkey: `<${getModifier()}+shift+7>`,
      },
      {
        type: 'block',
        label: 'Numbered List',
        icon: faListOl,
        format: 'numbered-list',
        hotkey: `<${getModifier()}+shift+8>`,
      },
      {
        type: 'block',
        label: 'Code Block',
        customIcon: (
          <CodeBlockIcon
            key="CodeBlock"
            selection={selection}
            hotkey={`<${getModifier()}+shift+9>`}
          />
        ),
        format: 'code-block',
      },
      {
        type: 'custom',
        label: 'Emoji',
        customIcon: <EmojiIcon key="EmojiPicker" selection={selection} />,
      },
    ],
  ];
};

const Toolbar = (props) => {
  const { selection, showLinkModalHotkey, setShowLinkModalHotkey } = props;
  const classes = useStyles();
  const [showLinkModal, setShowLinkModal] = useState(false);
  useEffect(() => {
    if (showLinkModalHotkey) {
      setShowLinkModal(true);
    } else {
      setShowLinkModal(false);
    }
  }, [showLinkModalHotkey]);
  return (
    <React.Fragment>
      <Flex center>
        {items(selection).map((itemGroup, i) => {
          return (
            <Flex key={i} className={classes.itemGroup}>
              {itemGroup.map((item) => {
                if (!item.icon && !item.customIcon) {
                  return null;
                }
                return (
                  item.customIcon || (
                    <FormattingIcon
                      key={item.label}
                      item={item}
                      selection={selection}
                      setShowLinkModal={setShowLinkModal}
                    />
                  )
                );
              })}
            </Flex>
          );
        })}
      </Flex>
      <LinkInputModal
        isOpen={showLinkModal}
        setShowLinkModal={setShowLinkModal}
        setShowLinkModalHotkey={setShowLinkModalHotkey}
        selection={selection}
      />
    </React.Fragment>
  );
};

export default Toolbar;
