import React from 'react';
import { Div } from '../Div';
import { Avatar as MuiAvatar, Badge } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import styled from 'styled-components';

export interface Props extends StyleProps {
  src?: string;
  /** alt text for Avatar; will fall back to first letter of alt text if image cannot be loaded */
  alt?: string;
  /** background color; will find appropriate contrast color for text if textcolor prop is not supplied */
  color?: string;
  /** shape variant */
  variant?: 'square' | 'rounded';
  /** optional bottom right mini-badge */
  badge?: JSX.Element;
  /** avatar children; use for text avatars, icons, or other non-image based avatars */
  children?: React.ReactNode;
}

interface StyleProps {
  /** override text color */
  textcolor?: string;
  /** height and width */
  size?: string;
  /** text sizing */
  fontSize?: string;
  /** optional border value */
  border?: string;
  cursor?: string;
  color?: string;
}

const StylePropsKeys = [
  'textcolor',
  'size',
  'fontSize',
  'border',
  'cursor',
  'color',
];

// use null-coalescing instead of `Avatar.DefaultProps`
// to use the theme object to better support darkmode
const StyledAvatar = styled(MuiAvatar).withConfig({
  shouldForwardProp: (prop) => !StylePropsKeys.includes(prop),
})<StyleProps>`
  && {
    display: flex;
    width: ${(props) => props.size ?? '32px'};
    height: ${(props) => props.size ?? '32px'};
    background-color: ${(props) =>
      props.color ?? props.theme.palette.background.paper};
    color: ${(props) =>
      props.textcolor ??
      props.theme.palette.getContrastText(
        props.theme.palette.background.paper
      )};
    border: ${(props) => props.border ?? 'none'};
    font-size: ${(props) => props.fontSize ?? '1rem'};
    cursor: ${(props) => props.cursor ?? 'auto'};
  }
`;

function AvatarComponent(props: Props): JSX.Element {
  const { src, alt, variant, children, size, badge, ...rest } = props;
  const theme = useTheme();

  if (badge) {
    return (
      <Div width={size} height={size} data-test="avatar-atom">
        <Badge
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={badge}
          overlap="circular"
        >
          <StyledAvatar
            theme={theme}
            variant={variant}
            src={src}
            alt={alt}
            size={size}
            {...rest}
          >
            {children}
          </StyledAvatar>
        </Badge>
      </Div>
    );
  }

  return (
    <StyledAvatar
      theme={theme}
      variant={variant}
      src={src}
      alt={alt}
      size={size}
      data-test="avatar-atom"
      {...rest}
    >
      {children}
    </StyledAvatar>
  );
}

AvatarComponent.displayName = 'Avatar';

export const Avatar = AvatarComponent;
