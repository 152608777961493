import React from 'react';
import { darken, lighten } from 'polished';
import { Button as MuiButton } from '@mui/material';
import styled from 'styled-components';
import {
  ElementProps,
  SpacingProps,
  SpacingPropsKeys,
  SizingProps,
  SizingPropsKeys,
  TypographyProps,
  TypographyPropsKeys,
} from '../../../types/MaterialUI';
import { Spacing, Sizing, Typography } from '../../../styles/MaterialUI';
import { useTheme } from '@mui/material/styles';

export type ButtonColor = 'inherit' | 'primary' | 'secondary';
export type ButtonVariant = 'contained' | 'outlined' | 'text';

export interface Props extends StyleProps {
  /** Determines if button is clickable or not. */
  disabled?: boolean;
  /** Determines if the button has a box shadow (depth) or not */
  disableElevation?: boolean;
  fullWidth?: boolean;
  children?: React.ReactNode;
  href?: string;
  target?: string;
  rel?: string;
  type?: 'button' | 'submit' | 'reset';
}

interface StyleProps
  extends ElementProps,
    SpacingProps,
    SizingProps,
    TypographyProps {
  variant: ButtonVariant;
  color?: ButtonColor;
  customcolor?: string;
  textcolor?: string;
  border?: string;
}

const propsNotToForward = [
  ...SpacingPropsKeys,
  ...SizingPropsKeys,
  ...TypographyPropsKeys,
  'customcolor',
  'textcolor',
  'border',
];

const StyledButton = styled(MuiButton).withConfig({
  shouldForwardProp: (prop, _) => !propsNotToForward.includes(prop),
})<StyleProps>`
  && {
    ${Spacing}
    ${Sizing}
    ${Typography}
    ${(props) =>
      !props.disabled &&
      `
      background-color: ${
        props.variant === 'contained' ? props.customcolor : ''
      };
      color: ${
        props.variant !== 'contained'
          ? props.customcolor
          : props.textcolor || props.theme.palette.common.white
      };
      border: ${
        props.variant === 'outlined'
          ? `1px solid ${props.customcolor}`
          : props.border
      };
    `}
    text-transform: none;
  }
  &:hover {
    background-color: ${(props) =>
      props.customcolor
        ? props.variant === 'contained'
          ? props.theme.palette.mode !== 'dark'
            ? darken(0.05, props.customcolor)
            : lighten(0.05, props.customcolor)
          : props.theme.palette.action.hover
        : ''};
  }
`;

StyledButton.defaultProps = {
  p: '6px 16px',
};

const ButtonComponent = React.forwardRef<HTMLButtonElement, Props>(
  (props: Props, ref: Ref<HTMLButtonElement>): JSX.Element => {
    const theme = useTheme();
    return (
      <StyledButton
        {...props}
        theme={theme}
        ref={ref}
        data-test="button-atom"
      />
    );
  }
);

ButtonComponent.displayName = 'Button';

export const Button = ButtonComponent;
