import React from 'react';
import { Div } from '../../Atoms/Div';
import { Text } from '../../Atoms/Text';
import { DialogSelect } from '../../Molecules/DialogSelect';
import { RadioGroup } from '../../Molecules/RadioGroup';
import { CompletedIcon } from '../CompletedIcon';
import { SelectFormOption } from '..';

export type Props = {
  label?: string;
  options: Array<SelectFormOption>;
  value?: string;
  onChange?: (value: string) => void;
};

export const Select = ({ label, options, onChange, value }: Props) => {
  const selectedOption = options.find((option) => option.value === value);
  const readonly = !onChange;
  let readOnlyOptions = null;
  if (readonly) {
    readOnlyOptions = options.map((option) => {
      return {
        ...option,
        // the radio group doesn't have any margins by default so adding some here
        label: <Div m={1}>{option.label}</Div>,
      };
    });
  }
  return (
    <Div>
      {label && (
        <Text ml={readonly ? '2px' : 4} mb={1} variant="h3">
          {label}
        </Text>
      )}
      {readOnlyOptions && (
        <RadioGroup ml={2} options={readOnlyOptions} value={value} />
      )}
      {!readonly && (
        <Div display="flex" alignItems="center">
          <CompletedIcon completed={Boolean(selectedOption)} />
          <DialogSelect
            ml={2}
            width="300px"
            options={options}
            value={selectedOption}
            onChange={(option) => onChange && onChange(option?.value ?? '')}
          />
        </Div>
      )}
    </Div>
  );
};
