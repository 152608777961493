import React from "react";
import { connect } from "react-redux";
import { Theme } from "@mui/material/styles";
import withTheme from "@mui/styles/withTheme";
import { faWrench } from "@fortawesome/pro-regular-svg-icons";

import { Div, Flex, Link, FontIcon, RouteLink } from "Common";

import { selectors, CurrentUser } from "reducers/users";
import qualifiedLogo from "resources/qualified-logo.svg";
import byPullRequestLogo from "resources/by-pullrequest-logo.svg";

import AccountDropdown from "./AccountDropdown";

export type HeaderBarProps = {
  theme: Theme;
  currentUser?: CurrentUser;
};

type LinkWrapperProps = {
  children?: React.ReactNode;
  currentUser?: CurrentUser;
};

export const LinkWrapper = ({ children, currentUser }: LinkWrapperProps) => {
  if (currentUser) {
    return <RouteLink to="/dash/">{children}</RouteLink>;
  }
  return <Link href="https://www.qualified.dev">{children}</Link>;
};

export const HeaderBar: React.FC<HeaderBarProps> = ({ theme, currentUser }) => {
  return (
    <header>
      <Flex
        alignItems="center"
        background="#092353"
        boxSizing="border-box"
        boxShadow={theme.shadows[3]}
        height="50px"
        p="9px 20px"
        zIndex={theme.zIndex.appBar}
      >
        <Div flexGrow={1}>
          <LinkWrapper currentUser={currentUser}>
            <Div display="flex">
              <Div
                style={{
                  background: `transparent url(${qualifiedLogo}) no-repeat`,
                  backgroundSize: "114px 80px",
                }}
                width="114px"
                height="80px"
              />
              <Div
                style={{
                  background: `transparent url(${byPullRequestLogo}) no-repeat`,
                  backgroundSize: "130px 80px",
                }}
                width="130px"
                height="80px"
                ml="12px"
              />
            </Div>
          </LinkWrapper>
        </Div>
        {currentUser && (
          <Flex center>
            {currentUser.is_staff ? (
              <RouteLink to="/staff/configure">
                <FontIcon
                  icon={faWrench}
                  color={theme.palette.secondary.main}
                  size="1x"
                  mr={2}
                />
              </RouteLink>
            ) : null}
            <AccountDropdown currentUser={currentUser} />
          </Flex>
        )}
      </Flex>
    </header>
  );
};

function mapStateToProps(state: any, ownProps: {}) {
  const currentUser = selectors.currentUser(state);
  return {
    currentUser: currentUser.isFilled ? currentUser.data : undefined,
  };
}

export default withTheme<Theme, React.JSXElementConstructor<HeaderBarProps>>(
  connect(mapStateToProps)(HeaderBar)
);
