import React, { PureComponent } from "react";
import withTheme from "@mui/styles/withTheme";
import withStyles from "@mui/styles/withStyles";
import qualifiedLogo from "../../resources/qualified-logo.svg";
import byPullRequestLogo from "../../resources/by-pullrequest-logo.svg";
import { Flex, Div, Link } from "Common";

const styles = (theme) => {
  return {
    header: {
      boxSizing: "border-box",
      boxShadow: theme.shadows[3],
      height: "40px",
      padding: "9px 20px",
      zIndex: theme.zIndex.appBar,
      position: "absolute",
      right: 0,
      left: 0,
    },
    logo: {
      background: `transparent url(${qualifiedLogo}) no-repeat`,
      backgroundSize: "100px 80px",
      width: "100px",
      height: "80px",
    },
    byPRlogo: {
      background: `transparent url(${byPullRequestLogo}) no-repeat`,
      backgroundSize: "130px 80px",
      width: "130px",
      height: "80px",
      marginLeft: "12px",
    },
  };
};

class UnauthedHeader extends PureComponent {
  render() {
    const { classes } = this.props;

    return (
      <header>
        <Flex
          className={classes.header}
          alignItems="center"
          justifyContent="center"
          background="#092353"
        >
          <Link href="https://www.qualified.dev">
            <Div display="flex">
              <div className={classes.logo}></div>
              <div className={classes.byPRlogo}></div>
            </Div>
          </Link>
        </Flex>
      </header>
    );
  }
}

export default withTheme(withStyles(styles)(UnauthedHeader));
