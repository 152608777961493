import React from "react";
import { connect } from "react-redux";
import { Theme } from "@mui/material/styles";
import withTheme from "@mui/styles/withTheme";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

import { Div, Flex, Card, FontIcon } from "Common";
import { Text } from "Common/components/Atoms/Text";

import { selectors, CandidateChallengeStatus } from "reducers/challengesV2";

export type SubmittedProps = {
  theme: Theme;
  challengeId: string;
  challengeStatus?: CandidateChallengeStatus;
};

export const Submitted: React.FC<SubmittedProps> = ({
  theme,
  challengeStatus,
}) => {
  if (!challengeStatus) {
    // This is an invalid case as this route should only be available if the
    // challenge status has been queried.
    return null;
  }

  const { redirect_url, company } = challengeStatus;
  if (redirect_url) {
    window.location.href = redirect_url;
    return null;
  }

  return (
    <Flex direction="column" justifyContent="center" alignItems="center">
      <Text variant="h1" color="primary" mt="141px">
        Submit your challenge
      </Text>
      <Card width="400px" mr="auto" ml="auto" mt="55px" p="32px 38px">
        <Div style={{ textAlign: "center" }}>
          <Text>
            <b>Success!</b>
          </Text>
          <Flex
            background={theme.palette.success.dim}
            borderRadius="50%"
            alignItems="center"
            mt="26px"
            mb="26px"
            justifyContent="center"
            ml="auto"
            mr="auto"
            width="134px"
            height="134px"
          >
            <FontIcon
              icon={faCheck}
              color={theme.palette.success.main}
              size="4x"
            />
          </Flex>
          <Text mb="26px">
            Your code challenge solution has been received and <b>{company}</b>{" "}
            has been notified.
          </Text>
          <Text>{`We've`} sent you a confirmation email for your records.</Text>
        </Div>
      </Card>
    </Flex>
  );
};

export type OwnProps = {
  challengeId: string;
};

function mapStateToProps(state: any, ownProps: OwnProps) {
  const { challengeId } = ownProps;
  const challengeStatus = selectors.candidate.status(state, {
    id: challengeId,
  });
  const submitResponse = selectors.candidate.submit(state, {
    id: challengeId,
  });
  return {
    challengeId,
    submitResponse,
    challengeStatus: challengeStatus.isFilled
      ? challengeStatus.data
      : undefined,
  };
}

export default withTheme<Theme, React.JSXElementConstructor<SubmittedProps>>(
  connect(mapStateToProps)(Submitted)
);
