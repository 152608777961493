import React, { useState, SyntheticEvent } from "react";
import { useDispatch } from "react-redux";
import { Theme } from "@mui/material/styles";
import withTheme from "@mui/styles/withTheme";
import {
  faChevronDown,
  faSignOutAlt,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";

import { Div, Flex, FontIcon, DropMenu, UserAvatar } from "Common";

import { actions, selectors, CurrentUser } from "reducers/users";

export type AccountDropdownProps = {
  theme: Theme;
  currentUser: CurrentUser;
};

export const AccountDropdown: React.FC<AccountDropdownProps> = ({
  theme,
  currentUser,
}) => {
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);
  const [element, setElement] = useState(null);
  const initials = `${currentUser.first_name.charAt(
    0
  )}${currentUser.last_name.charAt(0)}`;

  const onClickLogout = () => {
    dispatch(actions.logout());
  };

  const menu = [
    {
      icon: faUser,
      title: "User Settings",
      url: "/user",
    },
    {
      icon: faSignOutAlt,
      title: "Logout",
      url: "/",
      onClick: onClickLogout,
    },
  ];

  const onClickAvatar = (e: any) => {
    setShowDropdown(true);
    setElement(e.currentTarget);
  };

  return (
    <Div position="relative">
      <Flex
        alignItems="center"
        onClick={onClickAvatar}
        style={{ cursor: "pointer" }}
      >
        <UserAvatar
          imageUrl={currentUser.profile.photo_url}
          altText={currentUser.profile.name}
          text={initials}
          m="0 10px 0 0"
          size="28px"
          textBackground={theme.palette.info.dim}
          fontSize="14px"
        />
        <FontIcon
          icon={faChevronDown}
          size="xs"
          color={theme.palette.common.white}
        />
      </Flex>
      <DropMenu
        anchorEl={element}
        transformOrigin={{ vertical: -40, horizontal: "center" }}
        items={menu}
        show={showDropdown}
        dismiss={() => setShowDropdown(false)}
        width={180}
      />
    </Div>
  );
};

export default withTheme<
  Theme,
  React.JSXElementConstructor<AccountDropdownProps>
>(AccountDropdown);
