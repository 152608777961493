import { emptyPaginatedAPIData } from "../utils/pagination";
import { storeResults, emptyResults, loadingResults } from "../utils/apiData";
import { handlePageMapData } from "../utils/pageMap";

export const types = {
  CREATE_ORG_REQUEST: "CHALLENGES/CREATE_ORG_REQUEST",
  CREATE_ORG_SUCCESS: "CHALLENGES/CREATE_ORG_SUCCESS",
  CREATE_ORG_FAILURE: "CHALLENGES/CREATE_ORG_FAILURE",

  BILLING_FOR_ORG_REQUEST: "CHALLENGES/BILLING_FOR_ORG_REQUEST",
  BILLING_FOR_ORG_SUCCESS: "CHALLENGES/BILLING_FOR_ORG_SUCCESS",
  BILLING_FOR_ORG_FAILURE: "CHALLENGES/BILLING_FOR_ORG_FAILURE",

  CHALLENGES_FOR_ORG_REQUEST: "CHALLENGES/CHALLENGES_FOR_ORG_REQUEST",
  CHALLENGES_FOR_ORG_SUCCESS: "CHALLENGES/CHALLENGES_FOR_ORG_SUCCESS",
  CHALLENGES_FOR_ORG_FAILURE: "CHALLENGES/CHALLENGES_FOR_ORG_FAILURE",

  CHALLENGE_REQUEST: "CHALLENGES/CHALLENGE_REQUEST",
  CHALLENGE_SUCCESS: "CHALLENGES/CHALLENGE_SUCCESS",
  CHALLENGE_FAILURE: "CHALLENGES/CHALLENGE_FAILURE",

  CHALLENGE_CREATE_REQUEST: "CHALLENGES/CHALLENGE_CREATE_REQUEST",
  CHALLENGE_CREATE_SUCCESS: "CHALLENGES/CHALLENGE_CREATE_SUCCESS",
  CHALLENGE_CREATE_FAILURE: "CHALLENGES/CHALLENGE_CREATE_FAILURE",

  CHALLENGE_CANCEL_REQUEST: "CHALLENGES/CHALLENGE_CANCEL_REQUEST",
  CHALLENGE_CANCEL_SUCCESS: "CHALLENGES/CHALLENGE_CANCEL_SUCCESS",
  CHALLENGE_CANCEL_FAILURE: "CHALLENGES/CHALLENGE_CANCEL_FAILURE",

  CHALLENGE_DOWNLOAD_REPORT_REQUEST:
    "CHALLENGES/CHALLENGE_DOWNLOAD_REPORT_REQUEST",
  CHALLENGE_DOWNLOAD_REPORT_SUCCESS:
    "CHALLENGES/CHALLENGE_DOWNLOAD_REPORT_SUCCESS",
  CHALLENGE_DOWNLOAD_REPORT_FAILURE:
    "CHALLENGES/CHALLENGE_DOWNLOAD_REPORT_FAILURE",

  CHALLENGE_DOWNLOAD_SUBMISSION_REQUEST:
    "CHALLENGES/CHALLENGE_DOWNLOAD_SUBMISSION_REQUEST",
  CHALLENGE_DOWNLOAD_SUBMISSION_SUCCESS:
    "CHALLENGES/CHALLENGE_DOWNLOAD_SUBMISSION_SUCCESS",
  CHALLENGE_DOWNLOAD_SUBMISSION_FAILURE:
    "CHALLENGES/CHALLENGE_DOWNLOAD_SUBMISSION_FAILURE",

  CHALLENGE_PROBLEM_DOWNLOAD_REQUEST:
    "CHALLENGES/CHALLENGE_PROBLEM_DOWNLOAD_REQUEST",
  CHALLENGE_PROBLEM_DOWNLOAD_SUCCESS:
    "CHALLENGES/CHALLENGE_PROBLEM_DOWNLOAD_SUCCESS",
  CHALLENGE_PROBLEM_DOWNLOAD_FAILURE:
    "CHALLENGES/CHALLENGE_PROBLEM_DOWNLOAD_FAILURE",
};

export const emptyChallengeActions = {
  cancel: emptyResults,
  downloadReport: emptyResults,
  downloadProblem: emptyResults,
  downloadSubmission: emptyResults,
};

export const emptyChallenge = {
  ...emptyResults,
  actions: emptyChallengeActions,
};

export const emptyMemberActions = {};

export const emptyMember = {
  ...emptyResults,
  actions: emptyMemberActions,
};

export const initialState = {
  createOrg: emptyResults,
  orgChallenges: {},
  orgBilling: {},
  createChallenge: emptyResults,
  challenges: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_ORG_REQUEST: {
      return {
        ...state,
        createOrg: loadingResults,
      };
    }
    case types.CREATE_ORG_FAILURE:
    case types.CREATE_ORG_SUCCESS: {
      const { response } = action;
      return {
        ...state,
        createOrg: storeResults(response),
      };
    }

    case types.BILLING_FOR_ORG_REQUEST: {
      const { orgId } = action;
      const { orgBilling } = state;
      const billingForOrg = orgBilling[orgId] || loadingResults;
      return {
        ...state,
        orgBilling: {
          ...orgBilling,
          [orgId]: {
            ...billingForOrg,
          },
        },
      };
    }
    case types.BILLING_FOR_ORG_FAILURE:
    case types.BILLING_FOR_ORG_SUCCESS: {
      const { orgId, response } = action;
      const { orgBilling } = state;
      return {
        ...state,
        orgBilling: {
          ...orgBilling,
          [orgId]: storeResults(response),
        },
      };
    }

    case types.CHALLENGES_FOR_ORG_REQUEST: {
      const { orgId } = action;
      const { orgChallenges } = state;
      const challengesForOrg = orgChallenges[orgId] || emptyPaginatedAPIData;
      return {
        ...state,
        orgChallenges: {
          ...orgChallenges,
          [orgId]: {
            ...challengesForOrg,
          },
        },
      };
    }
    case types.CHALLENGES_FOR_ORG_FAILURE:
    case types.CHALLENGES_FOR_ORG_SUCCESS: {
      const { orgId, response } = action;
      const { orgChallenges, challenges } = state;
      const challengesForOrg = orgChallenges[orgId];

      // Update our challenges page map structure.
      const { pageData, pageMapData } = handlePageMapData(
        challengesForOrg,
        challenges,
        emptyChallenge,
        response
      );

      return {
        ...state,
        orgChallenges: {
          ...orgChallenges,
          [orgId]: pageData,
        },
        challenges: pageMapData,
      };
    }

    case types.CHALLENGE_REQUEST: {
      const { challengeId } = action;
      const { challenges } = state;
      const challenge = challenges[challengeId] || emptyChallenge;
      const { actions } = challenge;
      return {
        ...state,
        challenges: {
          ...challenges,
          [challengeId]: {
            ...loadingResults,
            actions,
          },
        },
      };
    }

    case types.CHALLENGE_SUCCESS:
    case types.CHALLENGE_FAILURE: {
      const { response, challengeId } = action;
      const { challenges } = state;
      const challenge = challenges[challengeId] || emptyChallenge;
      const { actions } = challenge;
      return {
        ...state,
        challenges: {
          ...challenges,
          [challengeId]: {
            ...storeResults(response),
            actions,
          },
        },
      };
    }

    case types.CHALLENGE_CREATE_REQUEST: {
      return {
        ...state,
        createChallenge: loadingResults,
      };
    }
    case types.CHALLENGE_CREATE_FAILURE:
    case types.CHALLENGE_CREATE_SUCCESS: {
      const { response } = action;
      return {
        ...state,
        createChallenge: storeResults(response),
      };
    }

    case types.CHALLENGE_CANCEL_REQUEST: {
      const { challengeId } = action;
      const { challenges } = state;
      const challenge = challenges[challengeId] || emptyChallenge;
      const { actions } = challenge;
      return {
        ...state,
        challenges: {
          ...challenges,
          [challengeId]: {
            ...challenge,
            actions: {
              ...actions,
              cancel: loadingResults,
            },
          },
        },
      };
    }
    case types.CHALLENGE_CANCEL_FAILURE: {
      const { challengeId, response } = action;
      const { challenges } = state;
      const challenge = challenges[challengeId] || emptyChallenge;
      const { actions } = challenge;
      return {
        ...state,
        challenges: {
          ...challenges,
          [challengeId]: {
            ...challenge,
            actions: {
              ...actions,
              cancel: storeResults(response),
            },
          },
        },
      };
    }
    case types.CHALLENGE_CANCEL_SUCCESS: {
      const { challengeId, response } = action;
      const { data } = response;
      const { challenges } = state;
      const challenge = challenges[challengeId] || emptyChallenge;
      const { actions } = challenge;
      return {
        ...state,
        challenges: {
          ...challenges,
          [challengeId]: {
            ...challenge,
            actions: {
              ...actions,
              cancel: storeResults(response),
            },

            // A successful cancel comes with new data. Set that here.
            data,
          },
        },
      };
    }

    case types.CHALLENGE_DOWNLOAD_REPORT_REQUEST: {
      const { challengeId } = action;
      const { challenges } = state;
      const challenge = challenges[challengeId] || emptyChallenge;
      const { actions } = challenge;
      return {
        ...state,
        challenges: {
          ...challenges,
          [challengeId]: {
            ...challenge,
            actions: {
              ...actions,
              downloadReport: loadingResults,
            },
          },
        },
      };
    }
    case types.CHALLENGE_DOWNLOAD_REPORT_FAILURE:
    case types.CHALLENGE_DOWNLOAD_REPORT_SUCCESS: {
      const { challengeId, response } = action;
      const { challenges } = state;
      const challenge = challenges[challengeId] || emptyChallenge;
      const { actions } = challenge;
      return {
        ...state,
        challenges: {
          ...challenges,
          [challengeId]: {
            ...challenge,
            actions: {
              ...actions,
              downloadReport: storeResults(response),
            },
          },
        },
      };
    }

    case types.CHALLENGE_DOWNLOAD_SUBMISSION_REQUEST: {
      const { challengeId } = action;
      const { challenges } = state;
      const challenge = challenges[challengeId] || emptyChallenge;
      const { actions } = challenge;
      return {
        ...state,
        challenges: {
          ...challenges,
          [challengeId]: {
            ...challenge,
            actions: {
              ...actions,
              downloadSubmission: loadingResults,
            },
          },
        },
      };
    }
    case types.CHALLENGE_DOWNLOAD_SUBMISSION_FAILURE:
    case types.CHALLENGE_DOWNLOAD_SUBMISSION_SUCCESS: {
      const { challengeId, response } = action;
      const { challenges } = state;
      const challenge = challenges[challengeId] || emptyChallenge;
      const { actions } = challenge;
      return {
        ...state,
        challenges: {
          ...challenges,
          [challengeId]: {
            ...challenge,
            actions: {
              ...actions,
              downloadSubmission: storeResults(response),
            },
          },
        },
      };
    }

    case types.CHALLENGE_PROBLEM_DOWNLOAD_REQUEST: {
      const { challengeId } = action;
      const { challenges } = state;
      const challenge = challenges[challengeId] || emptyChallenge;
      const { actions } = challenge;
      return {
        ...state,
        challenges: {
          ...challenges,
          [challengeId]: {
            ...challenge,
            actions: {
              ...actions,
              downloadProblem: loadingResults,
            },
          },
        },
      };
    }
    case types.CHALLENGE_PROBLEM_DOWNLOAD_FAILURE:
    case types.CHALLENGE_PROBLEM_DOWNLOAD_SUCCESS: {
      const { challengeId, response } = action;
      const { challenges } = state;
      const challenge = challenges[challengeId] || emptyChallenge;
      const { actions } = challenge;
      return {
        ...state,
        challenges: {
          ...challenges,
          [challengeId]: {
            ...challenge,
            actions: {
              ...actions,
              downloadProblem: storeResults(response),
            },
          },
        },
      };
    }

    default:
      return state;
  }
};

export const actions = {
  createOrg: (orgName) => ({
    type: types.CREATE_ORG_REQUEST,
    orgName,
  }),
  fetchBillingForOrg: (orgId) => ({
    type: types.BILLING_FOR_ORG_REQUEST,
    orgId,
  }),
  fetchChallengesForOrgPage: (orgId) => ({
    type: types.CHALLENGES_FOR_ORG_REQUEST,
    orgId,
  }),
  fetchChallenge: (challengeId) => ({
    type: types.CHALLENGE_REQUEST,
    challengeId,
  }),
  createChallenge: (
    orgId,
    candidateName,
    candidateEmail,
    skillLevel,
    challengeType
  ) => ({
    type: types.CHALLENGE_CREATE_REQUEST,
    orgId,
    candidateName,
    candidateEmail,
    skillLevel,
    challengeType,
  }),
  cancelChallenge: (challengeId) => ({
    type: types.CHALLENGE_CANCEL_REQUEST,
    challengeId,
  }),
  downloadReport: (challengeId) => ({
    type: types.CHALLENGE_DOWNLOAD_REPORT_REQUEST,
    challengeId,
  }),
  downloadSubmission: (challengeId) => ({
    type: types.CHALLENGE_DOWNLOAD_SUBMISSION_REQUEST,
    challengeId,
  }),
  downloadProblem: (challengeId) => ({
    type: types.CHALLENGE_PROBLEM_DOWNLOAD_REQUEST,
    challengeId,
  }),
};

const challenges = (state) => state.challenges.challenges || {};

export const selectors = {
  createOrgResults: (state) => {
    return state.challenges.createOrg;
  },
  billingForOrg: (state, orgId) => {
    return state.challenges.orgBilling[orgId] || emptyResults;
  },
  challengesForOrg: (state, orgId) => {
    return state.challenges.orgChallenges[orgId] || emptyPaginatedAPIData;
  },
  createChallengeStatus: (state) => {
    return state.challenges.createChallenge;
  },
  challenges,
  challengeById: (state, challengeId) => {
    return challenges(state)[challengeId] || emptyChallenge;
  },
};
