import React from 'react';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {
  Stepper as MuiStepper,
  Step,
  StepLabel,
  StepContent,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Text } from '../../Atoms/Text';

const useStyles = makeStyles((theme) => ({
  stepper: {
    backgroundColor: 'transparent',
    padding: 0,
  },
  iconContainer: {
    '& .MuiStepIcon-active circle': {
      color: theme.palette.secondary.main,
    },
    '& .MuiStepIcon-completed path': {
      color: theme.palette.secondary.main,
    },
  },
  stepLabel: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightSemiBold,
    '&.MuiStepLabel-active': {
      color: theme.palette.text.primary,
      fontSize: theme.typography.body1.fontSize,
      fontWeight: `${theme.typography.fontWeightSemiBold} !important`,
    },
  },
}));

export function Stepper(props) {
  const { activeStep, steps, orientation } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <MuiStepper
      activeStep={activeStep}
      orientation={orientation}
      className={classes.stepper}
    >
      {steps.map((data, index) => (
        <Step key={data.label}>
          <StepLabel
            classes={{
              iconContainer: classes.iconContainer,
              label: classes.stepLabel,
              active: classes.stepLabel,
            }}
          >
            {data.label} {activeStep === index && data.icon}
          </StepLabel>
          <StepContent>
            <Text variant="body1" fontSize="13px">
              {data.content}
            </Text>
          </StepContent>
        </Step>
      ))}
    </MuiStepper>
  );
}

Stepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.node,
      content: PropTypes.node,
    })
  ),
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
  activeStep: PropTypes.number,
};

Stepper.defaultProps = {
  orientation: 'vertical',
  background: 'transparent',
};

export default Stepper;
