import { all, call, put, select, takeEvery } from "redux-saga/effects";

import Api from "utils/Api";
import { takeApiRequestSaga, takeIndirectPaginationSaga } from "utils/types";

import { types, selectors } from "reducers/organizations";

export default all([
  takeIndirectPaginationSaga(
    types.MEMBERS_FOR_ORG,
    ({ id }) => `/organizations/${id}/members/`,
    selectors.members
  ),
  takeIndirectPaginationSaga(
    types.INTEGRATIONS_FOR_ORG,
    ({ id }) => `/organizations/${id}/integrations/`,
    selectors.integrations
  ),

  takeApiRequestSaga(types.UPDATE_MEMBER, ({ id, role }) =>
    call(Api.put, `/organization_members/${id}/`, { role })
  ),
  takeApiRequestSaga(types.INVITE_MEMBER, ({ id, email }) =>
    call(Api.post, `/organizations/${id}/member_invites/`, { email })
  ),

  takeApiRequestSaga(
    types.CREATE_GREENHOUSE_INTEGRATION,
    ({ id }) =>
      call(Api.post, `/organizations/${id}/create_greenhouse_integration/`),
    ({ id }) =>
      put({
        type: types.INTEGRATIONS_FOR_ORG.request,
        id,
        reset: true,
        fetchAll: true,
      })
  ),
  takeApiRequestSaga(
    types.DELETE_GREENHOUSE_INTEGRATION,
    ({ id }) =>
      call(Api.del, `/organizations/${id}/delete_greenhouse_integration/`),
    ({ id }) =>
      put({
        type: types.INTEGRATIONS_FOR_ORG.request,
        id,
        reset: true,
        fetchAll: true,
      })
  ),
  takeApiRequestSaga(types.RESET_GREENHOUSE_INTEGRATION_KEY, ({ id }) =>
    call(Api.post, `/organizations/${id}/reset_greenhouse_integration_key/`)
  ),
]);
