import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  table: {
    borderSpacing: 0,
    margin: `${theme.spacing(2)} 0`,
    '& tr': {
      borderTop: `1px solid ${theme.palette.grey['A100']}`,
    },
    '& th,td': {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      border: `1px solid ${theme.palette.grey['A100']}`,
    },
  },
}));

export interface Props {
  children?: React.ReactNode;
}

const TableComponent: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  return <table className={classes.table}>{props.children}</table>;
};

TableComponent.displayName = 'Table';

export const Table = TableComponent;
