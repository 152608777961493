import React from 'react';
import { useTheme } from '@mui/material';
import {
  faCheck,
  faExclamation,
  faPencil,
} from '@fortawesome/pro-solid-svg-icons';
import { faComment, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '../../Atoms/Flex';
import { Button } from '../../Atoms/Button';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    zIndex: theme.zIndex.modal,
    background: theme.palette.common.white,
    outline: 'none',
    borderRadius: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.1)',
    width: '100%',
  },
  title: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightSemiBold,
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
    width: '90%',
  },
  body: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
  },
  buttonGroup: {
    width: '100%',
    height: theme.spacing(7),
    background: theme.palette.divider,
    borderRadius: `0 0 ${theme.spacing(1)} ${theme.spacing(1)}`,
    '& button': {
      whiteSpace: 'nowrap',
    },
    padding: '20px',
    boxSizing: 'border-box',
  },
}));

export function StandardContainer({
  variant,
  icon,
  primaryIconColor,
  secondaryIconColor,
  title,
  showSecondaryButton,
  secondaryButtonLabel,
  onClickSecondaryButton,
  primaryButtonColor,
  secondaryButtonColor,
  primaryButtonLabel,
  onClickPrimaryButton,
  buttonWidth,
  children,
  contentsWidth,
  primaryButtonDisabled,
  secondaryButtonDisabled,
  primaryButtonTextColor,
  secondaryButtonTextColor,
  m,
  p,
  alignButtons,
}) {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <React.Fragment>
      <Flex
        m={m || `${theme.spacing(3)} ${theme.spacing(2)}`}
        p={p}
        fontFamily={theme.typography.fontFamily}
      >
        <ContainerIcon
          variant={variant}
          icon={icon}
          primaryIconColor={primaryIconColor}
          secondaryIconColor={secondaryIconColor}
        />
        <Flex direction="column" mx={theme.spacing(2)} width={contentsWidth}>
          {title && <div className={classes.title}>{title}</div>}
          <div className={classes.body}>{children}</div>
        </Flex>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent={
          alignButtons === 'flex-end' || alignButtons === 'flex-start'
            ? alignButtons
            : 'center'
        }
        className={classes.buttonGroup}
      >
        {showSecondaryButton && (
          <Button
            variant="contained"
            color={'secondary'}
            customcolor={!secondaryButtonDisabled ? secondaryButtonColor : null}
            width={buttonWidth}
            height={34}
            onClick={onClickSecondaryButton}
            textcolor={
              !secondaryButtonColor
                ? theme.palette.common.white
                : secondaryButtonTextColor || theme.palette.text.secondary
            }
            mr={4.5}
            disableElevation
            disabled={secondaryButtonDisabled}
          >
            {secondaryButtonLabel || 'No'}
          </Button>
        )}
        <Button
          variant="contained"
          customcolor={
            !primaryButtonDisabled
              ? primaryButtonColor || theme.palette.secondary.main
              : null
          }
          width={buttonWidth}
          height={34}
          onClick={onClickPrimaryButton}
          textcolor={
            !primaryButtonColor
              ? theme.palette.common.white
              : primaryButtonTextColor || theme.palette.text.primary
          }
          disableElevation
          disabled={primaryButtonDisabled}
        >
          {primaryButtonLabel || 'Close'}
        </Button>
      </Flex>
    </React.Fragment>
  );
}

/**
 * @return {null}
 */
function ContainerIcon({
  variant,
  icon,
  primaryIconColor,
  secondaryIconColor,
}) {
  const theme = useTheme();
  const variants = {
    alert: {
      icon: faExclamation,
      color: theme.palette.highlight.main,
      background: theme.palette.highlight.dim,
    },
    success: {
      icon: faCheck,
      color: theme.palette.success.main,
      background: theme.palette.success.dim,
    },
    error: {
      icon: faTimes,
      color: theme.palette.error.main,
      background: theme.palette.error.dim,
    },
    generic: {
      icon: faComment,
      color: theme.palette.secondary.main,
      background: theme.palette.secondary.dim,
    },
    update: {
      icon: faPencil,
      color: theme.palette.secondary.main,
      background: theme.palette.secondary.dim,
    },
  };
  if (variant === 'no-title') {
    return null;
  }
  return (
    <Flex
      width={33}
      height={33}
      fontSize={theme.typography.body1.fontSize}
      alignItems={'center'}
      justifyContent={'center'}
      borderRadius={'50%'}
      flexShrink={0}
      backgroundColor={
        !secondaryIconColor ? variants[variant].background : secondaryIconColor
      }
    >
      <FontAwesomeIcon
        icon={!icon ? variants[variant].icon : icon}
        color={!primaryIconColor ? variants[variant].color : primaryIconColor}
      />
    </Flex>
  );
}

StandardContainer.propTypes = {
  /** Specifies the type of modal (alert, success, error, generic, update, blank ) */
  variant: PropTypes.string,
  /** Custom icon for modal (optional) */
  icon: PropTypes.object,
  /** Icon color (optional) */
  primaryIconColor: PropTypes.string,
  /** Icon background color (optional) */
  secondaryIconColor: PropTypes.string,
  /** If modal variant isn't blank, renders the title of the modal */
  title: PropTypes.string,
  /** Add custom child nodes to any modal to render body content */
  children: PropTypes.node,
  /** Function called when the primary button is clicked (right).*/
  onClickPrimaryButton: PropTypes.func,
  /** Sets the color of the primary button */
  primaryButtonColor: PropTypes.string,
  /** Sets the color of the primary button */
  secondaryButtonColor: PropTypes.string,
  /** Sets the label of the primary button */
  primaryButtonLabel: PropTypes.node,
  /** Determines if the secondary button is shown. */
  showSecondaryButton: PropTypes.bool,
  /** Function called when the secondary button is clicked (left).*/
  onClickSecondaryButton: PropTypes.func,
  /** Sets the label of the secondary button */
  secondaryButtonLabel: PropTypes.node,
  /** Sets the width of both buttons. Or just the primary button if showSecondaryButton is false. */
  buttonWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Width of the container body */
  contentsWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Determines if primary button is disabled */
  primaryButtonDisabled: PropTypes.bool,
  /** Determines if secondary button is disabled */
  secondaryButtonDisabled: PropTypes.bool,
  /** Sets the color of the primary button text */
  primaryButtonTextColor: PropTypes.string,
  /** Sets the color of the secondary button text */
  secondaryButtonTextColor: PropTypes.string,
  /** Margin for container */
  m: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Padding for container */
  p: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Align modal buttons "flex-start" or "flex-end", default alignment is center */
  alignButtons: PropTypes.string,
};
