import { combineReducers } from "redux";
import { createSelector } from "reselect";

import { FormItem } from "Common/components/DynamicForm";

import {
  APITypes,
  APIRequest,
  apiRequestStore,
  IndirectIDAPIArgs,
  IndirectAPIRequest,
  indirectIDAPIAction,
  IndirectIDAPIRequest,
  emptyIndirectAPIData,
  indirectAPIRequestStore,
  indirectIDAPIRequestSelector,
} from "utils/apiData";
import {
  getMapEntries,
  PageMapKeyFunction,
  indirectPageMapStore,
} from "utils/pageMapV2";
import {
  pageStore,
  PaginatedAPIRequest,
  emptyPaginatedAPIData,
  IndirectPaginatedAPIRequest,
} from "utils/pagination";
import { types as organizationsTypes, Screening } from "./organizationsV2";
import { Template, TemplateVersion } from "./templates";

export const types = {
  screenings: {
    GET: new APITypes("CHALLENGES/SCREENINGS/GET"),
    CANCEL: new APITypes("CHALLENGES/SCREENINGS/CANCEL"),
    PROBLEM_TEMPLATE: new APITypes("CHALLENGES/SCREENINGS/PROBLEM_TEMPLATE"),
    download: {
      PROBLEM: new APITypes("CHALLENGES/SCREENINGS/DOWNLOAD/PROBLEM"),
      SUBMISSION: new APITypes("CHALLENGES/SCREENINGS/DOWNLOAD/SUBMISSION"),
      REPORT: new APITypes("CHALLENGES/SCREENINGS/DOWNLOAD/REPORT"),
    },
  },
  products: {
    GET: new APITypes("CHALLENGES/PRODUCTS/GET"),
    LIST: new APITypes("CHALLENGES/PRODUCTS/LIST"),
    CREATE: new APITypes("CHALLENGES/PRODUCTS/CREATE"),
    UPDATE: new APITypes("CHALLENGES/PRODUCTS/UPDATE"),
  },
  candidate: {
    STATUS: new APITypes("CHALLENGES/CANDIDATE/STATUS"),
    PROBLEM: new APITypes("CHALLENGES/CANDIDATE/PROBLEM"),
    DOWNLOAD: new APITypes("CHALLENGES/CANDIDATE/DOWNLOAD"),
    SUBMIT: new APITypes("CHALLENGES/CANDIDATE/SUBMIT"),
  },
};

/******************************************************************************
 * Types
 *****************************************************************************/
export type Product = {
  id: string;
  name: string;
  slug: string;
  problem_template: Template | null;
  reviewer_template: Template | null;
  submission_form?: Array<FormItem>;
  created_at: string;
  updated_at: string;
};

export const ProductKey: PageMapKeyFunction<Product> = (entry: Product) => {
  return entry.id;
};

export const ScreeningKey: PageMapKeyFunction<Screening> = (
  entry: Screening
) => {
  return entry.id;
};

export type CandidateChallengeStatus = {
  status: "download" | "waiting" | "canceled" | "submitted";
  name: string;
  email: string;
  company: string;
  language: string;
  product_type: "view" | "download";
  redirect_url: string | null;
};

export type CreateProductRequest = APIRequest<Product> & {
  name: string;
  slug: string;
  problem_template?: string;
  reviewer_template?: string;
  submission_form?: Array<FormItem>;
};

export type UpdateProductArgs = IndirectIDAPIArgs<Product> & {
  name?: string;
  slug?: string;
  problem_template?: string | null;
  reviewer_template?: string | null;
  submission_form?: Array<FormItem> | null;
};

export class UpdateProductRequest extends IndirectIDAPIRequest<Product> {
  name?: string;
  slug?: string;
  problem_template?: string | null;
  reviewer_template?: string | null;
  submission_form?: Array<FormItem> | null;
  constructor(args: UpdateProductArgs) {
    super(args);
    this.name = args.name;
    this.slug = args.slug;
    this.problem_template = args.problem_template;
    this.reviewer_template = args.reviewer_template;
    this.submission_form = args.submission_form;
  }
}

export type SubmitChallengeArgs = IndirectIDAPIArgs<null> & {
  submission?: FormData;
};

export class SubmitChallengeRequest extends IndirectIDAPIRequest<null> {
  submission?: FormData;
  constructor(args?: SubmitChallengeArgs) {
    super(args);
    this.submission = args?.submission;
  }
}

export type ProblemTemplate = {
  template_id: string;
  templates: Array<TemplateVersion>;
};

/******************************************************************************
 * State
 *****************************************************************************/
export default combineReducers({
  screenings: combineReducers({
    entries: indirectPageMapStore<IndirectIDAPIRequest<Screening>, Screening>(
      types.screenings.GET,
      organizationsTypes.screenings.LIST,
      ScreeningKey
    ),
    cancel: indirectAPIRequestStore<IndirectIDAPIRequest<Screening>, null>(
      types.screenings.CANCEL
    ),
    problemTemplate: indirectAPIRequestStore<
      IndirectIDAPIRequest<ProblemTemplate>,
      ProblemTemplate
    >(types.screenings.PROBLEM_TEMPLATE),
    download: combineReducers({
      problem: indirectAPIRequestStore<IndirectIDAPIRequest<null>, null>(
        types.screenings.download.PROBLEM
      ),
      submission: indirectAPIRequestStore<IndirectIDAPIRequest<null>, null>(
        types.screenings.download.SUBMISSION
      ),
      report: indirectAPIRequestStore<IndirectIDAPIRequest<null>, null>(
        types.screenings.download.REPORT
      ),
    }),
  }),
  products: combineReducers({
    pages: pageStore<PaginatedAPIRequest, Product>(types.products.LIST),
    entries: indirectPageMapStore<IndirectIDAPIRequest<Product>, Product>(
      types.products.GET,
      types.products.LIST,
      ProductKey
    ),
    create: apiRequestStore<CreateProductRequest, Product>(
      types.products.CREATE
    ),
    update: indirectAPIRequestStore<UpdateProductRequest, Product>(
      types.products.UPDATE
    ),
  }),
  candidate: combineReducers({
    status: indirectAPIRequestStore<
      IndirectIDAPIRequest<CandidateChallengeStatus>,
      CandidateChallengeStatus
    >(types.candidate.STATUS),
    problem: indirectAPIRequestStore<
      IndirectIDAPIRequest<ProblemTemplate>,
      ProblemTemplate
    >(types.candidate.PROBLEM),
    download: indirectAPIRequestStore<IndirectIDAPIRequest<null>, null>(
      types.candidate.DOWNLOAD
    ),
    submit: indirectAPIRequestStore<SubmitChallengeRequest, null>(
      types.candidate.SUBMIT
    ),
  }),
});

/******************************************************************************
 * Actions
 *****************************************************************************/
export const actions = {
  screenings: {
    clearGet: indirectIDAPIAction<Screening>(types.screenings.GET.clear),
    get: indirectIDAPIAction<Screening>(types.screenings.GET.request),
    clearCancel: indirectIDAPIAction<Screening>(types.screenings.CANCEL.clear),
    cancel: indirectIDAPIAction<Screening>(types.screenings.CANCEL.request),
    clearProblemTemplate: indirectIDAPIAction<ProblemTemplate>(
      types.screenings.PROBLEM_TEMPLATE.clear
    ),
    problemTemplate: indirectIDAPIAction<ProblemTemplate>(
      types.screenings.PROBLEM_TEMPLATE.request
    ),
    download: {
      clearProblem: indirectIDAPIAction<Screening>(
        types.screenings.download.PROBLEM.clear
      ),
      problem: indirectIDAPIAction<Screening>(
        types.screenings.download.PROBLEM.request
      ),
      clearSubmission: indirectIDAPIAction<Screening>(
        types.screenings.download.SUBMISSION.clear
      ),
      submission: indirectIDAPIAction<Screening>(
        types.screenings.download.SUBMISSION.request
      ),
      clearReport: indirectIDAPIAction<Screening>(
        types.screenings.download.REPORT.clear
      ),
      report: indirectIDAPIAction<Screening>(
        types.screenings.download.REPORT.request
      ),
    },
  },
  products: {
    clearGet: indirectIDAPIAction<Product>(types.products.GET.clear),
    get: indirectIDAPIAction<Product>(types.products.GET.request),
    clearList: (request?: PaginatedAPIRequest) => ({
      type: types.products.LIST.clear,
      request,
    }),
    list: (request?: PaginatedAPIRequest) => ({
      type: types.products.LIST.request,
      request,
    }),
    clearCreate: (request?: CreateProductRequest) => ({
      type: types.products.CREATE.clear,
      request,
    }),
    create: (request?: CreateProductRequest) => ({
      type: types.products.CREATE.request,
      request,
    }),
    clearUpdate: (args: UpdateProductArgs) => ({
      type: types.products.UPDATE.clear,
      request: new UpdateProductRequest(args),
    }),
    update: (args: UpdateProductArgs) => ({
      type: types.products.UPDATE.request,
      request: new UpdateProductRequest(args),
    }),
  },
  candidate: {
    clearStatus: indirectIDAPIAction<CandidateChallengeStatus>(
      types.candidate.STATUS.clear
    ),
    status: indirectIDAPIAction<CandidateChallengeStatus>(
      types.candidate.STATUS.request
    ),
    problem: indirectIDAPIAction<ProblemTemplate>(
      types.candidate.PROBLEM.request
    ),
    download: indirectIDAPIAction<null>(types.candidate.DOWNLOAD.request),
    submit: (args?: SubmitChallengeArgs) => ({
      type: types.candidate.SUBMIT.request,
      request: new SubmitChallengeRequest(args),
    }),
  },
};

/******************************************************************************
 * Selectors
 *****************************************************************************/
const productEntries = (state: any) =>
  state.challengesV2.products.entries || emptyIndirectAPIData;
const productPages = (state: any) =>
  state.challengesV2.products.pages || emptyPaginatedAPIData;
const productList = createSelector(
  [productEntries, productPages],
  (entries, pages) => {
    const list: Array<Product> = [];
    for (let product of getMapEntries(pages, entries, ProductKey)) {
      if (!product.isFilled || !product.data) {
        continue;
      }
      list.push(product.data);
    }
    return list;
  }
);

export const selectors = {
  screenings: {
    entry: indirectIDAPIRequestSelector<Screening>(
      (state) => state.challengesV2.screenings.entries
    ),
    cancel: indirectIDAPIRequestSelector<Screening>(
      (state) => state.challengesV2.screenings.cancel
    ),
    entries: (state: any) => state.challengesV2.screenings.entries,
    problemTemplate: indirectIDAPIRequestSelector<ProblemTemplate>(
      (state) => state.challengesV2.screenings.problemTemplate
    ),
    download: {
      problem: indirectIDAPIRequestSelector<null>(
        (state) => state.challengesV2.screenings.download.problem
      ),
      submission: indirectIDAPIRequestSelector<null>(
        (state) => state.challengesV2.screenings.download.submission
      ),
      report: indirectIDAPIRequestSelector<null>(
        (state) => state.challengesV2.screenings.download.report
      ),
    },
  },
  products: {
    entry: indirectIDAPIRequestSelector<Product>(
      (state) => state.challengesV2.products.entries
    ),
    entries: productEntries,
    pages: productPages,
    list: productList,
    create: (state: any) => state.challengesV2.products.create,
    update: indirectIDAPIRequestSelector<Product>(
      (state) => state.challengesV2.products.update
    ),
  },
  candidate: {
    status: indirectIDAPIRequestSelector<CandidateChallengeStatus>(
      (state) => state.challengesV2.candidate.status
    ),
    problem: indirectIDAPIRequestSelector<ProblemTemplate>(
      (state) => state.challengesV2.candidate.problem
    ),
    download: indirectIDAPIRequestSelector<null>(
      (state) => state.challengesV2.candidate.download
    ),
    submit: indirectIDAPIRequestSelector<null>(
      (state) => state.challengesV2.candidate.submit
    ),
  },
};
