import React, {
  useMemo,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { createEditor, Editor, Range, Point, Transforms } from 'slate';
import {
  Slate,
  Editable,
  withReact,
  ReactEditor,
  useSelected,
  useFocused,
} from 'slate-react';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from './Toolbar';
import { Div } from '../../Atoms/Div';
import { Portal } from '../../Atoms/Portal';
import isHotkey from 'is-hotkey';
import {
  toggleMark,
  toggleBlock,
  isMarkActive,
  isBlockActive,
} from './Toolbar/index';
import { Blockquote } from '../Markdown/Blockquote';
import { withHistory } from 'slate-history';
import { emojiIndex } from 'emoji-mart';
import { Text } from '../../Atoms/Text';
import { Link } from '../../Atoms/Link';
import { Flex } from '../../Atoms/Flex';
import { serialize } from './serialize.js';
import { deserialize } from './deserialize.js';
import { withPreviews } from './withPreviews/withPreviews';
import { withImages } from './withImages/withImages';

const HOTKEYS = {
  'mod+h': 'heading',
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+z': 'undo',
  'mod+y': 'redo',
  'mod+k': 'link',
  'mod+shift+c': 'code',
  'mod+shift+6': 'block-quote',
  'mod+shift+7': 'bulleted-list',
  'mod+shift+8': 'numbered-list',
  'mod+shift+9': 'code-block',
};

const BLOCK_SHORTCUTS = {
  '-': 'bulleted-list-item',
  '+': 'bulleted-list-item',
  '>': 'block-quote',
  '###': 'heading',
  '```': 'code-block',
};

const initialValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

const useStyles = makeStyles((theme) => ({
  editor: {
    background: theme.palette.background.paper,
    borderRadius: '4px',
    border: `1px solid ${theme.palette.divider}`,
    cursor: 'text',
  },
  isFocused: {
    border: `1px solid ${theme.palette.secondary.main} !important`,
    boxShadow: `0 0 1px 4px ${theme.palette.secondary.dim}`,
  },
  defaultText: {
    margin: 0,
    display: 'block',
  },
  inlineCode: {
    color: theme.palette.error.main,
    background: theme.palette.grey[200],
    padding: '2px 4px',
    borderRadius: '4px',
  },
  codeBlockContainer: {
    background: theme.palette.grey[200],
    padding: '8px',
    borderRadius: '4px',
  },
  linkStyle: {
    color: `${theme.palette.secondary.main} !important`,
    '&:hover': {
      textDecoration: 'underline !important',
      cursor: 'pointer !important',
    },
  },
  imageStyle: {},
}));

const withShortcuts = (editor) => {
  const { deleteBackward, insertText, insertBreak } = editor;

  editor.insertText = (text) => {
    const { selection } = editor;

    if (text === ' ' && selection && Range.isCollapsed(selection)) {
      const { anchor } = selection;
      const block = Editor.above(editor, {
        match: (n) => Editor.isBlock(editor, n),
      });
      const path = block ? block[1] : [];
      const start = Editor.start(editor, path);
      const range = { anchor, focus: start };
      const beforeText = Editor.string(editor, range);
      const type = BLOCK_SHORTCUTS[beforeText];

      if (type) {
        Transforms.select(editor, range);
        Transforms.delete(editor);
        Transforms.setNodes(
          editor,
          { type },
          { match: (n) => Editor.isBlock(editor, n) }
        );

        if (type === 'block-quote') {
          Transforms.setNodes(editor, { type: 'paragraph' });
          const blockQuote = { type: 'block-quote' };
          Transforms.wrapNodes(editor, blockQuote, {
            match: (n) => n.type === 'paragraph',
          });
        }

        if (type === 'bulleted-list-item') {
          const list = { type: 'bulleted-list', children: [] };
          Transforms.setNodes(editor, { type: 'bulleted-list-item' });
          Transforms.wrapNodes(editor, list, {
            match: (n) => n.type === 'bulleted-list-item',
          });
        }

        if (type === 'code-block') {
          Transforms.setNodes(editor, { type: 'paragraph' });
          const codeBlock = { type: 'code-block' };
          Transforms.wrapNodes(editor, codeBlock, {
            match: (n) => n.type === 'paragraph',
          });
        }
        insertText('');
        return;
      }
    }
    insertText(text);
  };

  editor.insertBreak = (...args) => {
    const { selection } = editor;
    const { anchor } = selection;
    const block = Editor.above(editor, {
      match: (n) => Editor.isBlock(editor, n),
    });
    const isCodeBlock = Editor.above(editor, {
      match: (n) => n.type === 'code-block',
    });
    const path = block ? block[1] : [];
    const start = Editor.start(editor, path);
    const range = { anchor, focus: start };
    const beforeText = Editor.string(editor, range);
    const type = BLOCK_SHORTCUTS[beforeText];
    if (type && isCodeBlock === undefined && type !== 'bulleted-list-item') {
      Transforms.select(editor, range);
      Transforms.delete(editor);
      Transforms.setNodes(editor, { type: 'paragraph' });
      Transforms.wrapNodes(
        editor,
        { type },
        { match: (n) => Editor.isBlock(editor, n) }
      );
      return;
    }

    const currentNodeEntry = Editor.above(editor, {
      match: (n) => Editor.isBlock(editor, n),
    });
    const isBlockTextEmpty = (node) => {
      return (
        node.children &&
        node.children[node.children.length - 1] &&
        node.children[node.children.length - 1].text === ''
      );
    };

    if (currentNodeEntry) {
      const [currentNode] = currentNodeEntry;

      if (isCodeBlock) {
        const codeBlockChildren = isCodeBlock[0].children;
        const isGreaterThanThreeLines =
          codeBlockChildren && codeBlockChildren.length >= 3;
        if (isGreaterThanThreeLines) {
          const firstEmptyLine =
            codeBlockChildren[codeBlockChildren.length - 2].children[0].text ===
            '';
          const secondEmptyLine =
            codeBlockChildren[codeBlockChildren.length - 1].children[0].text ===
            '';
          if (
            firstEmptyLine &&
            secondEmptyLine &&
            isBlockTextEmpty(currentNode)
          ) {
            Transforms.removeNodes(editor);
            Transforms.unwrapNodes(editor, {
              match: (n) => n.type === 'code-block',
              split: true,
            });
            Transforms.setNodes(editor, { type: 'paragraph' });
            return;
          }
        }
      }

      if (isBlockTextEmpty(currentNode)) {
        const parent = Editor.above(editor, {
          match: (n) =>
            n.type === 'bulleted-list' || n.type === 'numbered-list',
        });

        if (parent) {
          Transforms.unwrapNodes(editor, {
            match: (n) =>
              n.type === 'bulleted-list' || n.type === 'numbered-list',
            split: true,
          });
          Transforms.setNodes(editor, { type: 'paragraph' });

          return;
        }
      }
    }
    insertBreak();
  };

  editor.deleteBackward = (...args) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const isQuote = Editor.above(editor, {
        match: (n) => n.type === 'block-quote',
      });
      const isCodeBlock = Editor.above(editor, {
        match: (n) => n.type === 'code-block',
      });
      const isList = Editor.above(editor, {
        match: (n) =>
          n.type === 'bulleted-list' ||
          n.type === 'numbered-list' ||
          n.type === 'bulleted-list-item' ||
          n.type === 'numbered-list-item',
      });

      const isHeading = Editor.above(editor, {
        match: (n) => n.type === 'heading',
      });

      if (isHeading) {
        const [, path] = isHeading;
        const start = Editor.start(editor, path);
        if (Point.equals(selection.anchor, start)) {
          Transforms.setNodes(editor, { type: 'paragraph' });
        }
      }

      if (isQuote) {
        const [, path] = isQuote;
        const start = Editor.start(editor, path);
        if (Point.equals(selection.anchor, start)) {
          Transforms.unwrapNodes(editor, {
            match: (n) => n.type === 'block-quote',
            split: true,
          });
        }
      }

      if (isCodeBlock) {
        const [, path] = isCodeBlock;
        const start = Editor.start(editor, path);
        if (Point.equals(selection.anchor, start)) {
          Transforms.unwrapNodes(editor, {
            match: (n) => n.type === 'code-block',
            split: true,
          });
        }
      }

      if (isList) {
        const [, path] = isList;
        const start = Editor.start(editor, path);
        if (Point.equals(selection.anchor, start)) {
          Transforms.unwrapNodes(editor, {
            match: (n) =>
              n.type === 'bulleted-list' || n.type === 'numbered-list',
            split: true,
          });
          Transforms.setNodes(editor, { type: 'paragraph' });
        }
      }

      deleteBackward(...args);
    }
  };

  return editor;
};

const Element = ({ attributes, children, element }) => {
  const classes = useStyles();
  const selected = useSelected();
  const focused = useFocused();
  switch (element.type) {
    case 'line-break':
      return (
        <span {...attributes}>
          <br />
          {children}
        </span>
      );
    case 'block-quote':
      return <Blockquote>{children}</Blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading':
      return <h3 {...attributes}>{children}</h3>;
    case 'image':
      return (
        <div {...attributes}>
          <div contentEditable={false}>
            <img
              src={element.url}
              style={{
                display: 'block',
                maxWidth: '100%',
                maxHeight: '20em',
                boxShadow: `${
                  selected && focused ? '0 0 0 3px #B4D5FF' : 'none'
                }`,
              }}
              alt="preview"
            />
          </div>
          {children}
        </div>
      );
    case 'bulleted-list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'code-block':
      return (
        <pre {...attributes} className={classes.codeBlockContainer}>
          {children}
        </pre>
      );
    case 'emoji':
      return <span {...attributes}>{`${element.character.native}`}</span>;
    default:
      return (
        <p className={classes.defaultText} {...attributes}>
          {children}
        </p>
      );
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  const classes = useStyles();
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code className={classes.inlineCode}>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.link) {
    children = (
      <React.Fragment>
        <Link
          onClick={(event) => {
            event.stopPropagation();
            window.open(leaf.url, '_blank', 'noopener');
          }}
          className={classes.linkStyle}
          variant="body1"
        >
          {children}
        </Link>
      </React.Fragment>
    );
  }

  return <span {...attributes}>{children}</span>;
};

export const TextEditor = (props) => {
  const { message, onTextUpdate, showToolbar, autoFocus, minRows } = props;
  const theme = useTheme();
  const classes = useStyles();
  let minHeight = theme.typography.htmlFontSize;
  if (minRows) {
    minHeight *= minRows;
  }
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    if (value === initialValue && message && message.length) {
      setValue(deserialize(message));
    }
  }, [message, value]);
  const isSelectAllHotkey = isHotkey('mod+a');
  const isDeleteHotkey = isHotkey('backspace');
  const [selection, setSelection] = useState(null);
  const [target, setTarget] = useState();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [isAllSelected, setAllSelected] = useState(false);
  const [showLinkModalHotkey, setShowLinkModalHotkey] = useState(false);
  const ref = useRef();
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(
    () =>
      withHistory(
        withImages(withPreviews(withShortcuts(withReact(createEditor()))))
      ),
    []
  );

  useEffect(() => {
    if (!message && !ReactEditor.isFocused(editor)) {
      setValue([
        {
          type: 'paragraph',
          children: [{ text: '' }],
        },
      ]);
    }
  }, [message, editor]);

  let emojis = [];
  if (search) {
    emojis = emojiIndex
      .search(search)
      .map((o) => {
        return { native: o.native, id: o.id };
      })
      .slice(0, 5);
  }

  useEffect(() => {
    if (target && emojis.length > 0 && ref.current) {
      const el = ref.current;
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      el.style.left = `${rect.left + window.pageXOffset}px`;
    }
  }, [emojis.length, editor, index, search, target, ref]);

  return (
    <React.Fragment>
      <Slate
        editor={editor}
        value={value}
        onChange={(value) => {
          if (!editor.selection) {
            setIsFocused(false);
          } else {
            setIsFocused(true);
          }
          setValue(value);
          if (editor.selection) {
            setSelection(editor.selection);
          }

          if (editor.selection && Range.isCollapsed(editor.selection)) {
            const [start] = Range.edges(editor.selection);
            const wordBefore = Editor.before(editor, start, { unit: 'word' });
            const before = wordBefore && Editor.before(editor, wordBefore);
            const beforeRange = before && Editor.range(editor, before, start);
            const beforeText =
              beforeRange && Editor.string(editor, beforeRange);
            const beforeMatch = beforeText && beforeText.match(/^:(\w+)$/);
            const after = Editor.after(editor, start);
            const afterRange = Editor.range(editor, start, after);
            const afterText = Editor.string(editor, afterRange);
            const afterMatch = afterText.match(/^(\s|$)/);

            if (beforeMatch && afterMatch) {
              setTarget(beforeRange);
              setSearch(beforeMatch[1]);
              setIndex(0);
              return;
            } else {
              setTarget(null);
            }
          }

          if (onTextUpdate) {
            onTextUpdate(serialize(value));
          }
        }}
      >
        {showToolbar && (
          <Toolbar
            onChange={(value) => setValue(value)}
            selection={selection}
            showLinkModalHotkey={showLinkModalHotkey}
            setShowLinkModalHotkey={setShowLinkModalHotkey}
          />
        )}
        <Div
          m="10px"
          className={`${classes.editor} ${isFocused ? classes.isFocused : ''}`}
        >
          <Editable
            autoFocus={autoFocus || false}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder="Leave a comment..."
            onKeyDown={(event) => {
              // Workaround for issue with nodes not resetting when all text is selected and deleted
              // https://github.com/ianstormtaylor/slate/issues/2500
              if (isDeleteHotkey(event)) {
                if (isAllSelected) {
                  Transforms.delete(editor);
                  Transforms.setNodes(editor, { type: 'paragraph' });
                  setAllSelected(false);
                  return;
                }
              }

              if (isSelectAllHotkey(event)) {
                setAllSelected(!isAllSelected);
              } else {
                setAllSelected(false);
              }
              if (target) {
                switch (event.key) {
                  case 'ArrowDown': {
                    event.preventDefault();
                    const prevIndex =
                      index >= emojis.length - 1 ? 0 : index + 1;
                    setIndex(prevIndex);
                    break;
                  }
                  case 'ArrowUp': {
                    event.preventDefault();
                    const nextIndex =
                      index <= 0 ? emojis.length - 1 : index - 1;
                    setIndex(nextIndex);
                    break;
                  }
                  case 'Tab':
                  case 'Enter':
                    event.preventDefault();
                    Transforms.select(editor, target);
                    Editor.insertText(editor, emojis[index].native);
                    setTarget(null);
                    break;
                  case 'Escape':
                    event.preventDefault();
                    setTarget(null);
                    break;
                  default:
                    break;
                }
              } else {
                if (
                  Editor.marks(editor).link &&
                  (event.keyCode === 8 ||
                    event.keyCode === 32 ||
                    event.keyCode === 13)
                ) {
                  Editor.removeMark(editor, 'link');
                }
                if (Editor.marks(editor).code && event.keyCode === 13) {
                  Editor.removeMark(editor, 'code');
                }
                if (
                  isBlockActive(editor, 'code-block') &&
                  event.key === 'ArrowDown'
                ) {
                  Editor.insertBreak(editor);
                  Transforms.unwrapNodes(editor, {
                    match: (n) => n.type === 'code-block',
                    split: true,
                  });
                }
              }

              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event)) {
                  event.preventDefault();
                  const hotkeyAction = HOTKEYS[hotkey];
                  if (hotkeyAction === 'undo') {
                    editor.undo();
                  } else if (hotkeyAction === 'redo') {
                    editor.redo();
                  } else if (hotkeyAction === 'link') {
                    if (isMarkActive(editor, 'link')) {
                      Editor.removeMark(editor, 'link');
                    } else if (!isBlockActive(editor, 'code-block')) {
                      setShowLinkModalHotkey((prev) => !prev);
                    }
                  } else if (
                    hotkeyAction === 'heading' ||
                    hotkeyAction === 'block-quote' ||
                    hotkeyAction === 'bulleted-list' ||
                    hotkeyAction === 'numbered-list' ||
                    hotkeyAction === 'code-block'
                  ) {
                    toggleBlock(editor, hotkeyAction);
                  } else if (!isBlockActive(editor, 'code-block')) {
                    toggleMark(editor, hotkeyAction);
                  }
                }
              }
            }}
            style={{ padding: '10px', minHeight }}
          />
          {target && emojis.length > 0 && (
            <Portal container={document.body}>
              <div
                ref={ref}
                style={{
                  top: '-9999px',
                  left: '-9999px',
                  position: 'absolute',
                  zIndex: theme.zIndex.drawer,
                  padding: '3px',
                  background: theme.palette.background.paper,
                  borderRadius: theme.shape.borderRadius,
                  boxShadow: theme.shadows[3],
                }}
              >
                {emojis.map((char, i) => (
                  <Flex
                    key={char.id}
                    py="1px"
                    px="3px"
                    borderRadius="3px"
                    backgroundColor={
                      i === index ? theme.palette.grey[200] : 'transparent'
                    }
                  >
                    {char.native}
                    <Text variant="h3" ml={1}>
                      {char.id}
                    </Text>
                  </Flex>
                ))}
              </div>
            </Portal>
          )}
        </Div>
      </Slate>
    </React.Fragment>
  );
};

TextEditor.propTypes = {
  message: PropTypes.string,
  onTextUpdate: PropTypes.func,
  showToolbar: PropTypes.bool,
  autoFocus: PropTypes.bool,
  minRows: PropTypes.number,
};
