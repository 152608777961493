import React from 'react';
import { Div } from '../../../../../Atoms/Div';
import { SelectFormOption } from '../../../../../DynamicForm';
import { TextField } from '@mui/material';

export interface Props {
  option: SelectFormOption;
  onUpdate: (option: SelectFormOption) => void;
}

export const SelectOptionEditor: React.FC<Props> = ({
  option,
  onUpdate,
}: Props) => {
  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    onUpdate({
      ...option,
      value: event.target.value,
    });
  };

  const onChangeLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
    onUpdate({
      ...option,
      label: event.target.value,
    });
  };

  return (
    <Div display="flex" flexDirection="column" width="100%">
      <TextField
        required
        label="Value"
        value={option.value}
        onChange={onChangeValue}
      />
      <TextField
        required
        label="Label"
        value={option.label}
        onChange={onChangeLabel}
      />
    </Div>
  );
};
