import React from 'react';
import { Div } from '../../Atoms/Div';
import { Tooltip } from '../../Atoms/Tooltip';
import { Text, Props as TextProps } from '../../Atoms/Text';
import { SizingProps, SpacingProps } from '../../../types/MaterialUI';
import { TooltipStyleProps, Placement } from '../../Atoms/Tooltip';
import { Property } from 'csstype';

import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import RelativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(LocalizedFormat);
dayjs.extend(RelativeTime);

export interface Props extends SizingProps, SpacingProps {
  timestamp?: string | null;
  place?: Placement;
  textProps?: TextProps;
  tooltipProps?: TooltipStyleProps;
  alignSelf?: Property.AlignSelf;
}

const RelativeDateComponent = React.forwardRef<HTMLDivElement, Props>(
  (props: Props, ref: Ref<HTMLDivElement>): JSX.Element => {
    const {
      timestamp,
      place,
      tooltipProps,
      textProps,
      alignSelf,
      ...passThroughProps
    } = props;
    const time = dayjs(timestamp || undefined);
    const isTimestampValidDate = timestamp && time.isValid();

    return (
      <Div
        {...passThroughProps}
        alignSelf={alignSelf || 'flex-start'}
        ref={ref}
        dataTest={'relativedate-molecule'}
      >
        <Tooltip
          {...tooltipProps}
          placement={place || 'right'}
          title={isTimestampValidDate ? time.format('L LT') : ''}
        >
          <Text variant="caption" {...textProps}>
            {isTimestampValidDate ? time.fromNow() : '<no timestamp>'}
          </Text>
        </Tooltip>
      </Div>
    );
  }
);

RelativeDateComponent.displayName = 'RelativeDate';

export const RelativeDate = RelativeDateComponent;
