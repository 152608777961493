import React from "react";

import { Flex } from "Common";
import { Text } from "Common/components/Atoms/Text";

export type SpecificHeadingProps = {
  children: React.ReactNode;
};

export type HeadingProps = SpecificHeadingProps & {
  level: number;
};

const levelMap: { [key: number]: "h1" | "h2" | "h3" } = {
  1: "h1",
  2: "h2",
};

export const Heading: React.FC<HeadingProps> = ({ children, level }) => {
  return (
    <Flex direction="column">
      <Text mt={3} variant={levelMap[level] || "h3"}>
        {children}
      </Text>
      {level === 1 ? (
        <hr
          style={{
            marginTop: "20px",
            height: "1px",
            width: "100%",
            color: "gray",
            backgroundColor: "gray",
          }}
        />
      ) : null}
    </Flex>
  );
};

export const H1: React.FC<SpecificHeadingProps> = ({ children }) => {
  return <Heading level={1}>{children}</Heading>;
};

export const H2: React.FC<SpecificHeadingProps> = ({ children }) => {
  return <Heading level={2}>{children}</Heading>;
};

export const H3: React.FC<SpecificHeadingProps> = ({ children }) => {
  return <Heading level={3}>{children}</Heading>;
};

export default Heading;
