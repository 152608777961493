import { faMinus } from '@fortawesome/pro-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faMinus);

export { ThemeProvider } from 'styled-components';

export { TwoFactor } from './components/Molecules/2fa/index';

export { Button } from './components/Atoms/Button/index';
export { Avatar } from './components/Atoms/Avatar/index';
export { Typography } from './components/Atoms/Typography/index';
export { Link, RouteLink, TabLink } from './components/Atoms/Link/index';
export { Div } from './components/Atoms/Div/index';
export { Input } from './components/Atoms/Input/index';
export { InputArea } from './components/Atoms/InputArea/index';
export { InputField } from './components/Atoms/InputField/index';
export { Card } from './components/Atoms/Card/index';
export { Modal } from './components/Molecules/Modal/index';
export { Toast } from './components/Molecules/Toast/index';
export { DialogSelect } from './components/Molecules/DialogSelect/index';
export { FeedbackSelect } from './components/Molecules/FeedbackSelect/index';
export { FetchSelect } from './components/Molecules/FetchSelect/index';
export { UserAvatar } from './components/Molecules/UserAvatar/index';
export { Flex, Row, Col } from './components/Atoms/Flex/index';
export { Tooltip } from './components/Atoms/Tooltip/index';
export { Label } from './components/Atoms/Label/index';
export { Banner } from './components/Molecules/Banner/index';
export { ActionMenu } from './components/Molecules/ActionMenu/index';
export { PillButton } from './components/Molecules/PillButton/index';
export { ActionAlert } from './components/Molecules/ActionAlert/index';
export { Markdown } from './components/Molecules/Markdown/index';
export { TextEditor } from './components/Molecules/TextEditor';
export { DropMenu, DividerItem } from './components/Molecules/DropMenu/index';
export { MarkdownInput } from './components/Molecules/MarkdownInput/index';
export { MarkDownEditor } from './components/Molecules/MarkDownEditor/index';
export { StarRating } from './components/Molecules/StarRating/index';
export { Breadcrumbs } from './components/Molecules/Breadcrumbs/index';
export {
  severityLevels,
  severityLevelToNumMap,
  calcSeverityLevel,
  generateStaticResultColors,
} from './components/Molecules/StaticResult/index';
export { PageContainer } from './components/Templates/PageContainer/index';
export { ContentLoader } from './components/Atoms/ContentLoader/index';
export { InfiniteLoadingTable } from './components/Organisms/InfiniteLoadingTable/index';
export { NavTabs } from './components/Organisms/NavTabs';
export { Tabs } from './components/Organisms/Tabs';
export { SidebarNav } from './components/Organisms/SidebarNav';
export { SidebarMenu } from './components/Organisms/SidebarMenu';
export { MuiTheme } from './theme';
export { languageColors } from './theme/Color/languages';
export { CenteredContainer } from './components/Templates/CenteredContainer/index';
export { FontIcon } from './components/Atoms/FontIcon/index';
export { StandardContainer } from './components/Molecules/StandardContainer/index';
export { MultiSelect } from './components/Molecules/MultiSelect/index';
export { Transition } from './components/Atoms/Transition/index';
export { Stepper } from './components/Molecules/Stepper/index';
export { SkillSelector } from './components/Molecules/SkillSelector/index';
export { ExpertiseLevels } from './components/Organisms/ExpertiseLevels/index';
export { Toggle } from './components/Atoms/Toggle/index';
export { Chip } from './components/Atoms/Chip/index';
export { RelativeDate } from './components/Molecules/RelativeDate/index';
export { InternetStatus } from './components/Organisms/InternetStatus';
export { InfoIcon } from './components/Molecules/InfoIcon';
export { createFormat } from './utilities/formatting/numbers';
