import React from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { sizing } from '@mui/system';
import { StandardContainer } from '../StandardContainer';

export const ModalStyles = styled(ReactModalAdapter).attrs({
  overlayClassName: 'Overlay',
  modalClassName: 'Modal',
})`
  .Overlay {
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: ${(props) => props.theme.zIndex.overlay};
  }

  .Modal {
    position: relative;
    z-index: ${(props) => props.theme.zIndex.modal};
    background: ${(props) => props.theme.palette.background.paper};
    outline: none;
    border-radius: 8px;
    ${sizing};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  }

  .Close {
    float: right;
    color: ${(props) => props.theme.palette.grey[600]};
    position: absolute;
    font-size: ${(props) => props.theme.typography.body1.fontSize};
    top: 9px;
    right: 12px;
    &:hover {
      color: ${(props) => props.theme.palette.grey[900]};
      cursor: pointer;
    }
  }

  .contentContainer {
    display: flex;
    margin: ${(props) => props.theme.spacing(3)}px ${(props) =>
  props.theme.spacing(2)}px;
    font-family: ${(props) => props.theme.typography.fontFamily};
  }

  .title {
    font-size: ${(props) => props.theme.typography.body1.fontSize};
    font-weight ${(props) => props.theme.typography.fontWeightSemiBold};
    color: ${(props) => props.theme.palette.text.primary};
    margin-bottom: ${(props) => props.theme.spacing(1)}px;
    width: 90%;
  }

  .body {
    color: ${(props) => props.theme.palette.text.primary};
    font-size: ${(props) => props.theme.typography.body1.fontSize};
    line-height: ${(props) => props.theme.typography.body1.lineHeight};
  }

  .buttonGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${(props) => props.theme.spacing(7)}px;
    border-radius: 0 0 8px 8px;
  }

  .buttonGroup button {
    white-space: nowrap;
  }
`;

/**@component */
export function Modal(props) {
  const theme = useTheme();
  return <ModalStyles {...props} theme={theme} />;
}

function ReactModalAdapter({
  variant,
  icon,
  primaryIconColor,
  secondaryIconColor,
  className,
  modalClassName,
  onRequestClose,
  title,
  showSecondaryButton,
  secondaryButtonLabel,
  secondaryButtonColor,
  onClickSecondaryButton,
  primaryButtonColor,
  primaryButtonLabel,
  onClickPrimaryButton,
  buttonWidth,
  children,
  primaryButtonDisabled,
  secondaryButtonDisabled,
  alignButtons,
  primaryButtonTextColor,
  secondaryButtonTextColor,
  blankNoWrap,
  ...props
}) {
  const theme = useTheme();
  // don't wrap the children with empty div if blankNoWrap is true
  const blankWrappedChildren = blankNoWrap ? children : <div>{children}</div>;
  return (
    <ReactModal
      className={modalClassName}
      portalClassName={className}
      onRequestClose={onRequestClose}
      {...props}
      ariaHideApp={false}
    >
      <FontAwesomeIcon
        className="Close"
        icon={faTimes}
        onClick={onRequestClose}
      />
      {variant !== 'blank' ? (
        <StandardContainer
          title={title}
          buttonWidth={buttonWidth}
          variant={variant}
          icon={icon}
          primaryIconColor={primaryIconColor}
          secondaryIconColor={secondaryIconColor}
          primaryButtonColor={primaryButtonColor}
          secondaryButtonColor={secondaryButtonColor}
          onClickPrimaryButton={onClickPrimaryButton || onRequestClose}
          onClickSecondaryButton={onClickSecondaryButton || onRequestClose}
          primaryButtonLabel={primaryButtonLabel}
          secondaryButtonLabel={secondaryButtonLabel}
          showSecondaryButton={showSecondaryButton}
          primaryButtonDisabled={primaryButtonDisabled}
          secondaryButtonDisabled={secondaryButtonDisabled}
          alignButtons={alignButtons}
          primaryButtonTextColor={
            primaryButtonTextColor || theme.palette.common.white
          }
          secondaryButtonTextColor={
            secondaryButtonTextColor || theme.palette.common.white
          }
        >
          {children}
        </StandardContainer>
      ) : (
        blankWrappedChildren
      )}
    </ReactModal>
  );
}

Modal.propTypes = {
  /** Specifies the type of modal (alert, success, error, generic, update, blank ) */
  variant: PropTypes.string,
  /** Custom icon for modal (optional) */
  icon: PropTypes.object,
  /** Icon color (optional) */
  primaryIconColor: PropTypes.string,
  /** Icon background color (optional) */
  secondaryIconColor: PropTypes.string,
  /** Handles the closing of a modal */
  onRequestClose: PropTypes.func.isRequired,
  /** Determines if a modal should be open or closed */
  isOpen: PropTypes.bool.isRequired,
  /** If modal variant isn't blank, renders the title of the modal */
  title: PropTypes.string,
  /** Add custom child nodes to any modal to render body content */
  children: PropTypes.node,
  /** Function called when the primary button is clicked (right). By default, calls onRequestClose */
  onClickPrimaryButton: PropTypes.func,
  /** Sets the color of the primary button */
  primaryButtonColor: PropTypes.string,
  /** Sets the label of the primary button */
  primaryButtonLabel: PropTypes.node,
  /** Determines if the secondary button is shown. */
  showSecondaryButton: PropTypes.bool,
  /** Function called when the secondary button is clicked (left). By default, calls onRequestClose */
  onClickSecondaryButton: PropTypes.func,
  /** Sets the label of the secondary button */
  secondaryButtonLabel: PropTypes.node,
  /** Sets the color of the secondary button */
  secondaryButtonColor: PropTypes.string,
  /** Sets the width of both buttons. Or just the primary button if showSecondaryButton is false. */
  buttonWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Determines if primary button is disabled */
  primaryButtonDisabled: PropTypes.bool,
  /** Determines if secondary button is disabled */
  secondaryButtonDisabled: PropTypes.bool,
  /** Align modal buttons (flex-start or flex-end), default alignment is center */
  alignButtons: PropTypes.oneOf(['flex-start', 'flex-end']),
  /** Sets color of the text of the primary buttom */
  primaryButtonTextColor: PropTypes.string,
  /** Sets color of the text of the secondary buttom */
  secondaryButtonTextColor: PropTypes.string,
  id: PropTypes.string,
  /** If set to true will not wrap the blank modal with an empty div. */
  blankNoWrap: PropTypes.bool,
};
