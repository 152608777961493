import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import githubGist from "react-syntax-highlighter/dist/esm/styles/hljs/github-gist";
import queryString from "query-string";

import { Div, Flex, Markdown, MarkdownInput } from "Common";

import { APIData } from "utils/apiData";
import {
  debug,
  directives,
  headers,
  rehypeHeaders,
  noNewlines,
  skipDirectives,
  templates,
  rehypeTemplate,
} from "utils/markdown";
import { actions, selectors, TemplateVersion } from "reducers/templates";

import { H1, H2, H3 } from "./Heading";
import DocumentHeader from "./DocumentHeader";

export type BaseTemplateProps = {
  editable?: boolean;
  readOnly?: boolean;
  templateId: string;
  templateVersion: APIData<TemplateVersion>;
  createTemplateVersionResponse: APIData<TemplateVersion>;
  variables?: {
    [key: string]: string;
  };
  externalRenderers?: {
    template?: any;
    [key: string]: any;
  };
};

function queryStringVal(val: string | string[]): string {
  if (val instanceof Array) {
    if (!val) {
      return "";
    }
    return val[0];
  }

  return val;
}

function wrapTemplateVariables({
  template,
  readOnly,
  variables = {},
}: {
  template: any;
  readOnly?: boolean;
  variables?: { [key: string]: string };
}): (props: any) => any {
  return (props: any) => {
    // Use a capitalized variable name here so the React rendering works as
    // expected below.
    const Template = template;
    return (
      <Template
        {...props}
        readOnly={readOnly}
        variables={{
          ...variables,
          ...props.variables,
        }}
      />
    );
  };
}

export const BaseTemplate: React.FC<BaseTemplateProps> = ({
  editable,
  readOnly,
  variables,
  templateId,
  templateVersion,
  externalRenderers,
  createTemplateVersionResponse,
}) => {
  const dispatch = useDispatch();

  // 0 - query the template on first render
  useEffect(() => {
    if (!readOnly) {
      dispatch(actions.fetchLatestTemplateVersion({ id: templateId }));
      return () => {
        dispatch(actions.clearLatestTemplateVersion({ id: templateId }));
        dispatch(actions.clearCreateTemplateVersion({ id: templateId }));
      };
    }
  }, [readOnly, dispatch, templateId]);

  const getMarkdownSource = () => {
    // If we have a "create template" request, use that version instead of the
    // latest.
    const latestTemplateVersion = createTemplateVersionResponse.isEmpty
      ? templateVersion
      : createTemplateVersionResponse;

    if (
      latestTemplateVersion.isFilled &&
      !latestTemplateVersion.success &&
      latestTemplateVersion.status === 404
    ) {
      // When the markdown is "editable", go ahead and render with "click to
      // edit". If not, return "null" as if we didn't get any data.
      return editable ? "click to edit" : null;
    }

    const data = latestTemplateVersion.isFilled && latestTemplateVersion.data;
    if (!data) {
      return null;
    }

    return data.data;
  };

  const originalSource = getMarkdownSource();
  if (originalSource === null) {
    // TODO: Return some sort of loading state here instead.
    return null;
  }

  const compiledSource =
    originalSource &&
    Object.entries(variables || {}).reduce((data, [key, val]) => {
      if (!val) {
        return data;
      }
      return data.replace(`\${${key}}`, queryStringVal(val));
    }, originalSource);

  const onSave = (content: string) => {
    // Nothing was changed, so don't do anything.
    if (content === originalSource) {
      return;
    }

    dispatch(
      actions.createTemplateVersion({
        id: templateId,
        data: content,
      })
    );
  };

  const commonProps = {
    externalPlugins: [
      directives,
      templates,
      headers,
      skipDirectives,
      noNewlines,
      debug,
    ],
    externalRenderers: {
      h1: H1,
      h2: H2,
      h3: H3,
      documentHeader: DocumentHeader,
      ...externalRenderers,
    },

    // TODO: There's probably a better way to do this than provide these simple
    // rehype plugins that simply turn nodes into other nodes. But this gets us
    // to react-markdown@8 for now.
    rehypeHandlers: {
      documentHeader: rehypeHeaders,
      template: rehypeTemplate,
    },
  };

  // If a renderer is given for "template" directive, go ahead and add our
  // variables in so that sub-templates inherit our variables.
  if (commonProps.externalRenderers?.template) {
    commonProps.externalRenderers.template = wrapTemplateVariables({
      template: commonProps.externalRenderers.template,
      readOnly,
      variables,
    });
  }

  return (
    <Flex direction="column">
      {editable ? (
        <Div width="825px">
          <MarkdownInput
            value={originalSource || ""}
            onSave={onSave}
            {...commonProps}
          />
        </Div>
      ) : (
        <Div width="800px">
          <Markdown source={compiledSource} {...commonProps} />
        </Div>
      )}
    </Flex>
  );
};

type OwnProps = {
  templateId: string;
};

function mapStateToProps(state: any, { templateId }: OwnProps) {
  const templateVersion = selectors.latestTemplateVersion(state, {
    id: templateId,
  });
  const createTemplateVersionResponse = selectors.createTemplateVersion(state, {
    id: templateId,
  });
  return {
    templateId,
    templateVersion,
    createTemplateVersionResponse,
  };
}

export default connect(mapStateToProps)(BaseTemplate);
