import React from 'react';
import MuiPortal from '@mui/material/Portal';

export interface Props {
  children: React.ReactElement;
  container?: Element | (() => Element | null) | null | undefined;
}

export const PortalComponent = (props: Props): JSX.Element => {
  const { children, container } = props;
  return <MuiPortal container={container}>{children}</MuiPortal>;
};

PortalComponent.displayName = 'Portal';
PortalComponent.defaultProps = {
  container: document.body,
};

export const Portal = PortalComponent;
