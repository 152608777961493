import React from 'react';
import styled from 'styled-components';
import { Div } from '../Div';
import {
  ElementProps,
  SpacingPropsKeys,
  SpacingProps,
  SizingPropsKeys,
  SizingProps,
} from '../../../types/MaterialUI';
import { Spacing, Sizing } from '../../../styles/MaterialUI';
import { Property } from 'csstype';
import { useTheme } from '@mui/material/styles';

export interface Props extends SpacingProps, SizingProps, ElementProps {
  /** set type equal to "circle" to get a circular content loader */
  type?: 'circle';
  display?: Property.Display;
  zIndex?: Property.ZIndex;
  backgroundColor?: string;
  children?: React.ReactNode;
  dataTest?: string;
}

const StyledContentLoader = styled(Div).withConfig({
  shouldForwardProp: (prop) =>
    !SizingPropsKeys.includes(prop) && !SpacingPropsKeys.includes(prop),
})<Props>`
  ${Spacing}
  ${Sizing}
  box-sizing: border-box;
  display: ${(props) => props.display};
  border-radius: ${(props) => (props.type === 'circle' ? '50%' : '3px')};
  z-index: ${(props) => props.zIndex};
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeholder;
  animation-name: placeholder;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  background: #f6f7f8;
  background: #eeeeee;
  background: ${(props) => `-webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, ${props.theme.palette.action.selected}),
    color-stop(18%, ${props.theme.palette.action.disabledBackground}),
    color-stop(33%, ${props.theme.palette.action.selected})
  )`};
  background: ${(props) => `-webkit-linear-gradient(
    left,
    ${props.theme.palette.action.selected} 8%,
    ${props.theme.palette.action.disabledBackground} 18%,
    ${props.theme.palette.action.selected} 33%
  )`};
  background: ${(props) =>
    `linear-gradient(to right, ${props.theme.palette.action.selected} 8%, ${props.theme.palette.action.disabledBackground} 18%, ${props.theme.palette.action.selected} 33%)`};
  -webkit-background-size: 200em 104px;
  background-size: 200em 104px;
  position: relative;

  @-webkit-keyframes placeholder {
    0% {
      background-position: -100em 0;
    }
    100% {
      background-position: 100em 0;
    }
  }

  @keyframes placeholder {
    0% {
      background-position: -100em 0;
    }
    100% {
      background-position: 100em 0;
    }
  }
`;

const ContentLoaderComponent = React.forwardRef<HTMLDivElement, Props>(
  (props: Props, ref: Ref<HTMLDivElement>): JSX.Element => {
    const { children, dataTest, ...passThroughProps } = props;
    const theme = useTheme();
    return (
      <StyledContentLoader
        {...passThroughProps}
        ref={ref}
        dataTest={dataTest ?? 'contentloader-atom'}
        aria-label={'loading indicator'}
        theme={theme}
      >
        {children}
      </StyledContentLoader>
    );
  }
);

ContentLoaderComponent.displayName = 'ContentLoader';

export const ContentLoader = ContentLoaderComponent;
