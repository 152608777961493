import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withStyles from '@mui/styles/withStyles';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { Text } from '../../Atoms/Text';

const styles = (theme) => ({
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    borderBottom: 'none',
  },
  table: (props) => ({
    minWidth: `${props.minWidth}px`,
    // temporary right-to-left patch, waiting for
    // https://github.com/bvaughn/react-virtualized/issues/454
    '& .ReactVirtualized__Table__headerRow': {
      flip: false,
      paddingRight: theme.direction === 'rtl' ? '0px !important' : undefined,
      borderBottom: `1px solid ${theme.palette.divider}`,
      background: theme.palette.action.selected,
      borderRadius: '4px 4px 0 0',
    },
    '& .ReactVirtualized__Table__headerColumn': {
      overflow: 'hidden',
    },
    '& .ReactVirtualized__Grid__innerScrollContainer': {
      minWidth: `${props.minWidth}px`,
    },
  }),
  tableRow: (props) => ({
    minWidth: `${props.minWidth}px`,
    color: theme.palette.text.primary,
    overflowX: 'auto',
    outline: 'none',
    cursor: `${props.disableRowHover ? 'initial' : 'pointer'}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-of-type': {
      borderBottom: 'none',
    },
  }),
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.secondary.main,
    },
  },
  headerCell: {
    '&:first-of-type': {
      padding: '16px 0px 16px 16px',
    },
    padding: '16px 0px 16px 0px',
    minWidth: '0',
  },
  tableCell: {
    flex: 1,
  },
  tableGrid: (props) => ({
    minWidth: `${props.minWidth}px`,
    outline: 'none',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '9px',
      borderLeft: `1px solid ${theme.palette.divider}`,
      backgroundClip: 'content-box',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[700],
      borderRadius: '5px',
    },
  }),
  noClick: {
    cursor: 'initial',
  },
  columnHeader: {
    color: theme.palette.grey[600],
    fontWeight: theme.typography.fontWeightRegular,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

class VirtualizedTable extends React.PureComponent {
  static defaultProps = {
    headerHeight: 48,
    rowHeight: 48,
  };

  getRowClassName = ({ index }) => {
    const { classes, onRowClick, disableRowHover } = this.props;

    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick && !disableRowHover,
    });
  };

  headerRenderer = ({ label }) => {
    const { headerHeight, classes } = this.props;

    return (
      <div
        className={clsx(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick,
          classes.headerCell
        )}
        style={{ height: headerHeight }}
      >
        <Text variant="body1" className={classes.columnHeader}>
          {label}
        </Text>
      </div>
    );
  };

  render() {
    const {
      onRowsRendered,
      registerChild,
      classes,
      columns,
      rowHeight,
      headerHeight,
      cellRenderer,
      ...tableProps
    } = this.props;
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            ref={registerChild}
            onRowsRendered={onRowsRendered}
            height={height}
            width={width}
            rowHeight={rowHeight}
            gridStyle={{
              direction: 'inherit',
              width: width,
            }}
            headerHeight={headerHeight}
            className={classes.table}
            {...tableProps}
            rowClassName={this.getRowClassName}
            gridClassName={classes.tableGrid}
          >
            {columns.map(({ dataKey, ...other }, index) => {
              return (
                <Column
                  key={dataKey}
                  headerRenderer={(headerProps) =>
                    this.headerRenderer({
                      ...headerProps,
                      columnIndex: index,
                    })
                  }
                  className={classes.flexContainer}
                  cellRenderer={cellRenderer}
                  dataKey={dataKey}
                  flexGrow={1}
                  width={width}
                  {...other}
                />
              );
            })}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

VirtualizedTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  headerHeight: PropTypes.number,
  rowHeight: PropTypes.number.isRequired,
  onRowClick: PropTypes.func,
  onRowsRendered: PropTypes.func,
  onRegisterChild: PropTypes.func,
  cellRenderer: PropTypes.func,
  rowCount: PropTypes.number.isRequired,
  rowGetter: PropTypes.func.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  minWidth: PropTypes.number,
  disableRowHover: PropTypes.bool,
  classes: PropTypes.object,
};

export default withStyles(styles)(VirtualizedTable);
