import React from 'react';
import { SpacingProps, SpacingPropsKeys } from '../../../types/MaterialUI';
import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';
import { Spacing } from '../../../styles/MaterialUI';

export interface Props extends NavLinkProps<unknown>, SpacingProps {}

const StyledTabLink = styled(NavLink).withConfig({
  shouldForwardProp: (prop, _) => !SpacingPropsKeys.includes(prop),
})`
  ${Spacing}
  position: relative;
  font-size: 16px;
  color: ${(props) => props.theme.palette.grey[500]};
  font-weight: ${(props) => props.theme.typography.fontWeightSemiBold};
  text-decoration: none;
  &::after {
    content: '';
    height: 4px;
    bottom: -5px;
    position: absolute;
    background: transparent;
    right: 50%;
    left: 50%;
  }
  &:not(.active):hover::after {
    right: 0;
    left: 0;
    background: ${(props) => props.theme.palette.secondary.main};
    opacity: 0.5;
    transition: 0.3s ease all;
  }
  &.active {
    color: ${(props) => props.theme.palette.secondary.main};
    &::after {
      right: 0;
      left: 0;
      background: ${(props) => props.theme.palette.secondary.main};
    }
  }
`;

const TabLinkComponent = React.forwardRef<HTMLAnchorElement, Props>(
  (props: Props, ref: Ref<HTMLAnchorElement>) => {
    const { activeClassName, activeStyle, exact, strict, isActive, location } =
      props;
    const theme = useTheme();
    return (
      <StyledTabLink
        {...props}
        activeClassName={activeClassName}
        activeStyle={activeStyle}
        exact={exact}
        strict={strict}
        isActive={isActive}
        location={location}
        theme={theme}
        ref={ref}
        data-test="tablink-atom"
      >
        {props.children}
      </StyledTabLink>
    );
  }
);

TabLinkComponent.displayName = 'TabLink';

export const TabLink = TabLinkComponent;
