import { all, call, put, select } from "redux-saga/effects";
import { types, selectors } from "reducers/challenges";
import { types as usersTypes } from "reducers/users";
import { types as templatesTypes, TemplateRequest } from "reducers/templates";
import Api from "utils/Api";

export function* createOrg({ orgName }) {
  const url = `/organizations/request_new/`;
  const response = yield call(Api.post, url, { name: orgName });
  if (!response.success) {
    yield put({
      type: types.CREATE_ORG_FAILURE,
      response,
    });
    return;
  }
  yield put({
    type: types.CREATE_ORG_SUCCESS,
    response,
  });

  // Re-query the current user value to update state as this action should
  // re-direct the user to the "success" page for having already requested an
  // org to be created.
  yield put({
    type: usersTypes.CURRENT_USER.request,
  });
}

export function* fetchBillingForOrg({ orgId }) {
  const url = `/organizations/${orgId}/billing/`;
  const response = yield call(Api.get, url);
  if (!response.success) {
    yield put({
      type: types.BILLING_FOR_ORG_FAILURE,
      orgId,
      response,
    });
    return;
  }
  yield put({
    type: types.BILLING_FOR_ORG_SUCCESS,
    orgId,
    response,
  });
}

export function* fetchChallengesForOrg({ orgId, page }) {
  if (!page) {
    const challenges = yield select(selectors.challengesForOrg, orgId);

    // There isn't a next page, bail.
    if (challenges.hasData && !challenges.nextPage) {
      return;
    }
    page = challenges.nextPage || `/organizations/${orgId}/techscreenings/`;
  }
  const response = yield call(Api.get, page);
  if (!response.success) {
    yield put({
      type: types.CHALLENGES_FOR_ORG_FAILURE,
      orgId,
      response,
    });
    return;
  }
  yield put({
    type: types.CHALLENGES_FOR_ORG_SUCCESS,
    orgId,
    response,
  });
}

export function* fetchChallenge({ challengeId }) {
  const response = yield call(Api.get, `/techscreenings/${challengeId}/`);
  if (!response.success) {
    yield put({
      type: types.CHALLENGE_FAILURE,
      challengeId,
      response,
    });
    return;
  }
  yield put({
    type: types.CHALLENGE_SUCCESS,
    challengeId,
    response,
  });
}

export function* createChallenge({
  orgId,
  candidateName,
  candidateEmail,
  skillLevel,
  challengeType,
}) {
  const url = `/organizations/${orgId}/request_new_techscreening/`;
  const response = yield call(Api.post, url, {
    candidate_name: candidateName,
    candidate_email: candidateEmail,
    skill_level: skillLevel,
    challenge_type: challengeType,
  });
  if (!response.success) {
    yield put({
      type: types.CHALLENGE_CREATE_FAILURE,
      response,
    });
    return;
  }
  yield put({
    type: types.CHALLENGE_CREATE_SUCCESS,
    response,
  });

  const challenges = yield select(selectors.challengesForOrg, orgId);
  yield all(
    challenges.pageQueries.map((page) =>
      put({
        type: types.CHALLENGES_FOR_ORG_REQUEST,
        orgId,
        page,
      })
    )
  );
}

export function* cancelChallenge({ challengeId }) {
  const url = `/techscreenings/${challengeId}/cancel/`;
  const response = yield call(Api.post, url);
  if (!response.success) {
    yield put({
      type: types.CHALLENGE_CANCEL_FAILURE,
      challengeId,
      response,
    });
    return;
  }
  yield put({
    type: types.CHALLENGE_CANCEL_SUCCESS,
    challengeId,
    response,
  });
}

export function* downloadReport({ challengeId }) {
  const url = `/techscreenings/${challengeId}/report/`;
  const response = yield call(Api.get, url);
  if (!response.success) {
    yield put({
      type: types.CHALLENGE_DOWNLOAD_REPORT_FAILURE,
      challengeId,
      response,
    });
    return;
  }
  yield put({
    type: types.CHALLENGE_DOWNLOAD_REPORT_SUCCESS,
    challengeId,
    response,
  });

  Api.downloadResult(response);
}

export function* downloadSubmission({ challengeId }) {
  const url = `/techscreenings/${challengeId}/submission/`;
  const response = yield call(Api.get, url);
  if (!response.success) {
    yield put({
      type: types.CHALLENGE_DOWNLOAD_SUBMISSION_FAILURE,
      challengeId,
      response,
    });
    return;
  }
  yield put({
    type: types.CHALLENGE_DOWNLOAD_SUBMISSION_SUCCESS,
    challengeId,
    response,
  });

  Api.downloadResult(response);
}

export function* downloadChallengeProblem({ challengeId }) {
  const url = `/techscreenings/${challengeId}/problem/`;
  const response = yield call(Api.get, url);
  if (!response.success) {
    yield put({
      type: types.CHALLENGE_PROBLEM_DOWNLOAD_FAILURE,
      challengeId,
      response,
    });
    return;
  }
  yield put({
    type: types.CHALLENGE_PROBLEM_DOWNLOAD_SUCCESS,
    challengeId,
    response,
  });

  Api.downloadResult(response);
}
