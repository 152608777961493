import React from "react";
import { useTheme } from "@mui/material/styles";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

import { Div, Card, Flex, Link, FontIcon } from "Common";
import { Text } from "Common/components/Atoms/Text";

import { CurrentUser } from "reducers/users";

export type Props = {
  currentUser: CurrentUser;
};

export const Thanks: React.FC<Props> = ({ currentUser }) => {
  const theme = useTheme();
  return (
    <Flex direction="column" justifyContent="center" alignItems="center">
      <Text variant="h1" mt="141px">
        Create your organization
      </Text>
      <Card width="400px" mr="auto" ml="auto" mt="55px" p="32px 38px">
        <Div style={{ textAlign: "center" }}>
          <Text>You{"'"}re all set!</Text>
          <Flex
            background={theme.palette.success.dim}
            borderRadius="50%"
            alignItems="center"
            mt="26px"
            mb="26px"
            justifyContent="center"
            ml="auto"
            mr="auto"
            width="134px"
            height="134px"
          >
            <FontIcon
              icon={faCheck}
              color={theme.palette.success.main}
              size="4x"
            />
          </Flex>
          <Text mb="26px">
            One of our team members will finish creating your organization and
            reach out to you at <b>{currentUser.email}</b> when it{"'"}s ready.
          </Text>
          <Text>
            Questions? Send a message to{" "}
            <Link href="mailto:accounts@pullrequest.com">
              accounts@pullrequest.com
            </Link>
          </Text>
        </Div>
      </Card>
    </Flex>
  );
};

export default Thanks;
