import React from "react";
import { match as Match } from "react-router";
import { connect } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { Switch, Route, Redirect } from "react-router-dom";

import {
  Button,
  Card,
  Div,
  Flex,
  NavTabs,
  PageContainer,
  UserAvatar,
} from "Common";
import { Text } from "Common/components/Atoms/Text";

import { APIData } from "utils/apiData";

import { selectors, CurrentUser } from "reducers/users";

import Security from "./Security";
import UserInfo from "./UserInfo";

export type Props = {
  match: Match;
  currentUser?: CurrentUser;
};

const TabTitle = ({ children }: { children: React.ReactNode }): JSX.Element => {
  return <Flex alignItems="center">{children}</Flex>;
};

export const User: React.FC<Props> = ({ match, currentUser }) => {
  const theme = useTheme();

  if (!currentUser) {
    return null;
  }

  const { profile } = currentUser;
  const breadcrumbs = [
    {
      name: `${profile.name} / User Settings`,
    },
  ];

  return (
    <PageContainer pagetitle="User Settings" breadcrumbs={breadcrumbs}>
      <NavTabs
        items={[
          {
            title: <TabTitle>Profile</TabTitle>,
            to: `${match.path}/profile`,
            component: <UserInfo currentUser={currentUser} />,
          },
          {
            title: <TabTitle>Security</TabTitle>,
            to: `${match.path}/security`,
            component: <Security />,
          },
        ]}
      />
    </PageContainer>
  );
};

function mapStateToProps(state: any, ownProps: {}) {
  const currentUser = selectors.currentUser(state);
  return { currentUser: currentUser.isFilled ? currentUser.data : undefined };
}

export default connect(mapStateToProps)(User);
