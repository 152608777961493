import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { match as Match } from "react-router";

import { actions, selectors } from "reducers/challengesV2";
import { ProblemTemplate } from "reducers/challengesV2";
import { Screening } from "reducers/organizationsV2";

import PrintTemplate from "components/lib/Template/PrintTemplate";

export type Props = {
  challengeId: string;
  challenge?: Screening;
  problemTemplate?: ProblemTemplate;
};

export const Problem: React.FC<Props> = ({
  challengeId,
  challenge,
  problemTemplate,
}) => {
  const dispatch = useDispatch();

  // 0 - Fetch the relevant template data
  useEffect(() => {
    dispatch(actions.screenings.get({ id: challengeId }));
    dispatch(actions.screenings.problemTemplate({ id: challengeId }));
    return () => {
      dispatch(actions.screenings.clearGet({ id: challengeId }));
      dispatch(actions.screenings.clearProblemTemplate({ id: challengeId }));
    };
  }, [dispatch, challengeId]);

  if (!challenge || !problemTemplate) {
    return null;
  }

  return (
    <PrintTemplate
      readOnly
      templateId={problemTemplate.template_id}
      variables={{
        LANGUAGE: challenge.product_language,
      }}
    />
  );
};

export type OwnProps = {
  match: Match<{ challengeId: string }>;
};

function mapStateToProps(state: any, ownProps: OwnProps) {
  const { challengeId } = ownProps.match.params;
  const challenge = selectors.screenings.entry(state, { id: challengeId });
  const problemTemplate = selectors.screenings.problemTemplate(state, {
    id: challengeId,
  });
  return {
    challengeId,
    challenge: challenge.isFilled ? challenge.data : undefined,
    problemTemplate: problemTemplate.isFilled
      ? problemTemplate.data
      : undefined,
  };
}

export default connect(mapStateToProps)(Problem);
