import { Property } from 'csstype';
import { Theme } from '@mui/material/styles';

export interface ShadowProps {
  boxShadow?: Property.BoxShadow | number;
}

export interface ShadowStyles {
  boxShadow?: Property.BoxShadow;
}

// Unfortunately in Typescript, using the spread operator doesn't remove properties that aren't on the interface
export const ShadowStyleGenerator = (
  theme: Theme,
  props: ShadowProps
): ShadowStyles => {
  const boxShadow = Number.isInteger(props.boxShadow)
    ? theme.shadows[Number(props.boxShadow)]
    : props.boxShadow;

  return {
    boxShadow: boxShadow as string | undefined,
  };
};

const representative: ShadowProps = { boxShadow: undefined };
export const ShadowPropsKeys = Object.keys(representative);
