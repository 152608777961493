import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { InputField } from '../../Atoms/InputField';
import { Div } from '../../Atoms/Div';
import { Flex } from '../../Atoms/Flex';
import { Text } from '../../Atoms/Text';
import { FontIcon } from '../../Atoms/FontIcon';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { Tooltip } from '../../Atoms/Tooltip';
import { Slider } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  thumb: {
    color: theme.palette.secondary.main,
  },
  track: {
    backgroundColor: theme.palette.secondary.main,
  },
  rail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const yearsOptions = [
  { label: 'Less than 1', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10+', value: 10 },
];

export function ExpertiseLevels(props) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const {
    skills,
    onChangeExperienceSelector,
    onChangeSkillLevel,
    onClickRemove,
  } = props;

  const skillRows = Object.values(skills);

  const handleSliderChange = (id) => (event, newValue) => {
    onChangeSkillLevel(id, newValue);
  };

  return (
    <Flex direction="column">
      <Flex>
        <Div width="15%" mr="15px" />
        <Flex ml="15px" mb={1} flexGrow={1} justifyContent="space-between">
          <Text variant="caption">Somewhat Comfortable</Text>
          <Text variant="caption">Very Comfortable</Text>
        </Flex>
        <Div width="30px" />
      </Flex>
      <div>
        {skillRows.map((skill) => (
          <Flex mb={theme.spacing(2)} key={skill.id}>
            <Flex
              direction="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              width="100px"
              mr="15px"
            >
              <Flex
                width="100%"
                height="25px"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Text variant="h4" fontSize="13px">
                  {skill.name}
                </Text>
              </Flex>
              <Flex
                width="100%"
                height="25px"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Text variant="caption">Years Exp.</Text>
              </Flex>
            </Flex>
            <Flex flexGrow={1} direction="column">
              <Flex mx="5px" height="25px" alignItems="center">
                <Slider
                  classes={{
                    thumb: classes.thumb,
                    track: classes.track,
                    rail: classes.rail,
                  }}
                  skillid={skill.skill_id}
                  value={skill.skill_level}
                  step={1}
                  min={1}
                  max={11}
                  marks
                  onChange={handleSliderChange(skill.id)}
                  size="small"
                />
              </Flex>
              <Flex alignItems="center">
                <InputField
                  id="yearsExperience"
                  select
                  selectOptions={yearsOptions}
                  onChange={(e) =>
                    onChangeExperienceSelector(skill.id, e.target.value)
                  }
                  width="100px"
                  size="small"
                  inputProps={{
                    style: {
                      height: '25px',
                    },
                  }}
                  value={
                    Number.isInteger(skill.years_experience)
                      ? skill.years_experience
                      : ''
                  }
                />
              </Flex>
            </Flex>
            <Flex width="30px" height="25px" alignItems="center">
              <Tooltip title="Remove Skill" placement="top">
                <FontIcon
                  icon={faTimesCircle}
                  color={theme.palette.text.secondary}
                  size="1x"
                  onClick={() => onClickRemove(skill.id)}
                  ml="15px"
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            </Flex>
          </Flex>
        ))}
      </div>
    </Flex>
  );
}

ExpertiseLevels.propTypes = {
  skills: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      skill_level: PropTypes.number,
      years_experience: PropTypes.number,
    })
  ).isRequired,
  onChangeExperienceSelector: PropTypes.func.isRequired,
  onChangeSkillLevel: PropTypes.func.isRequired,
  onClickRemove: PropTypes.func.isRequired,
};
