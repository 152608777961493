import { all, call } from "redux-saga/effects";

import Api from "utils/Api";

import { takeAPIRequestSaga } from "utils/apiData";
import { PaginatedAPIRequest, takePaginationSaga } from "utils/pagination";
import {
  types,
  Template,
  selectors,
  TemplateRequest,
  TemplateVersion,
  CreateTemplateRequest,
  TemplateVersionRequest,
} from "reducers/templates";

export default all([
  takeAPIRequestSaga<CreateTemplateRequest, Template>(
    types.TEMPLATE_CREATE,
    (request: CreateTemplateRequest) => call(Api.post, "/templates/", request)
  ),

  takePaginationSaga(
    types.TEMPLATES,
    (request: PaginatedAPIRequest) => "/templates/",
    selectors.templatesPages
  ),
  takeAPIRequestSaga<TemplateRequest<Template>, Template>(
    types.TEMPLATE,
    (request: TemplateRequest<Template>) =>
      call(Api.get, `/templates/${request.id}/`)
  ),
  takeAPIRequestSaga<TemplateRequest<TemplateVersion>, TemplateVersion>(
    types.TEMPLATE_VERSION_LATEST,
    (request: TemplateRequest<TemplateVersion>) =>
      call(Api.get, `/templates/${request.id}/latest_version/`)
  ),
  takeAPIRequestSaga<TemplateVersionRequest<TemplateVersion>, TemplateVersion>(
    types.TEMPLATE_VERSION_CREATE,
    (request: TemplateVersionRequest<TemplateVersion>) => {
      const body = new FormData();
      body.append("template_id", request.id || "");
      body.append(
        "data",
        new Blob([request.data || ""], { type: "application/markdown" }),
        "filename"
      );
      return call<typeof Api.fetchApi>(
        Api.fetchApi,
        `/templates/${request.id}/versions/`,
        {
          method: "POST",
          body,
        }
      );
    }
  ),
]);
