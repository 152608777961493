import React from 'react';
import { FontIcon, Props as FontIconProps } from '../../Atoms/FontIcon';
import { faInfoCircle as solidInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { faInfoCircle as regularInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { MuiTheme } from '../../../theme';

export interface Props extends Omit<FontIconProps, 'icon'> {
  isSolid?: boolean;
}

const InfoIconComponent = React.forwardRef<any, Props>(
  (props: Props, ref: any) => {
    const { isSolid, ...passThroughProps } = props;
    return (
      <FontIcon
        {...passThroughProps}
        ref={ref}
        icon={isSolid ? solidInfoCircle : regularInfoCircle}
        data-test="infoicon-molecule"
      />
    );
  }
);

InfoIconComponent.displayName = 'InfoIcon';
InfoIconComponent.defaultProps = {
  color: MuiTheme.palette.grey[500],
  size: 'sm',
  isSolid: true,
};

export const InfoIcon = InfoIconComponent;
