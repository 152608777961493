import React from 'react';
import { CircularProgress, LinearProgress } from '@mui/material';
import styled from 'styled-components';
import { Div } from '../Div';
import { Flex } from '../Flex';
import {
  ElementProps,
  SpacingProps,
  SizingProps,
} from '../../../types/MaterialUI';
import { Spacing, Sizing } from '../../../styles/MaterialUI';

export interface Props extends SpacingProps, SizingProps, ElementProps {
  variant?: 'circle' | 'linear';
  color?: 'primary' | 'secondary';
  customcolor?: string;
  disableShrink?: boolean;
  size?: number | string;
  thickness?: number;
  classes?: {
    [key: string]: string;
  };
  ref?: Ref<HTMLDivElement>;
}

export interface DivProps extends SpacingProps, SizingProps, ElementProps {
  customcolor?: string;
}

const LoadingContainer = styled(Flex).withConfig({
  shouldForwardProp: (prop) => !['customcolor'].includes(prop),
})`
  ${Spacing};
  ${Sizing};
  justify-content: center;
  align-items: center;
  svg {
    color: ${(props: DivProps) => props.customcolor || 'inherit'};
  }
`;

const LoadingLineContainer = styled(Div)`
  ${Spacing};
  ${Sizing};
`;

const LoadingCircle = (props: Props) => {
  const {
    variant,
    color,
    disableShrink,
    size,
    thickness,
    classes,
    ...passThruProps
  } = props;
  return (
    <LoadingContainer
      {...passThruProps}
      dataTest="loading-atom"
      aria-label="loading indicator"
    >
      <CircularProgress
        color={color}
        disableShrink={disableShrink}
        size={size}
        thickness={thickness}
        classes={classes}
      />
    </LoadingContainer>
  );
};

const LoadingLine = (props: Props) => {
  const { variant, color, classes, ...passThruProps } = props;
  return (
    <LoadingLineContainer
      {...passThruProps}
      dataTest="loading-atom"
      aria-label="loading indicator"
    >
      <LinearProgress color={color} classes={classes} />
    </LoadingLineContainer>
  );
};

const LoadingComponent = React.forwardRef<HTMLDivElement, Props>(
  (props: Props, ref: Ref<HTMLDivElement>): JSX.Element | null => {
    const { variant } = props;
    if (variant === 'linear') {
      return <LoadingLine {...props} ref={ref} />;
    }

    return <LoadingCircle {...props} ref={ref} />;
  }
);

LoadingComponent.displayName = 'Loading';

export const Loading = LoadingComponent;
