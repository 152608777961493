import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { match as Match } from "react-router";
import { useTheme } from "@mui/material/styles";
import { faDownload, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import {
  statusColor,
  statusStrings,
  statuses,
} from "../../utils/statusHelpers";

import {
  Div,
  Flex,
  Banner,
  Button,
  Tooltip,
  FontIcon,
  PageContainer,
} from "Common";
import { Text } from "Common/components/Atoms/Text";

import { UserOrganization } from "reducers/users";
import { Screening } from "reducers/organizationsV2";
import { actions, selectors } from "reducers/challengesV2";

export type ChallengeDetailProps = {
  match: Match<{ challengeId: string }>;
  challengeId: string;
  challenge?: Screening;
  organization: UserOrganization;
};

export const ChallengeDetail: React.FC<ChallengeDetailProps> = ({
  match,
  challengeId,
  challenge,
  organization,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  // 0 - fetch the challenge information
  useEffect(() => {
    dispatch(actions.screenings.get({ id: challengeId }));
  }, [dispatch, challengeId]);

  if (!challenge) {
    return null;
  }

  const breadcrumbs = [
    {
      name: organization.display_name,
      url: `/dash`,
    },
    {
      name: "Code Challenges",
      url: "/dash/challenges",
    },
    {
      name: challenge.candidate_name,
    },
  ];

  const status = challenge.status;
  const color = statusColor(status, theme);
  const variant = statusStrings[status];
  const submissionEnabledArr = [
    statuses.REVIEWING_SUBMISSION,
    statuses.REPORT_COMPLETE,
    statuses.FINALIZING_REPORT,
  ];
  const submissionEnabled = submissionEnabledArr.indexOf(status) > -1;

  const onDownloadProblem = () => {
    if (challenge.product_type === "view") {
      window.open(`${match.url}/problem`, "_blank");
      return;
    }

    dispatch(actions.screenings.download.problem({ id: challengeId }));
  };
  const onDownloadSubmission = () => {
    dispatch(actions.screenings.download.submission({ id: challengeId }));
  };
  const onDownloadReport = () => {
    dispatch(actions.screenings.download.report({ id: challengeId }));
  };

  return (
    <PageContainer
      pagetitle={`${challenge.challenge_type} Code Challenge`}
      breadcrumbs={breadcrumbs}
      mt="50px"
    >
      <Banner
        icon={faInfoCircle}
        color={color.color}
        background={theme.palette.common.white}
        mb="38px"
      >
        <Div ml="15px">
          <Text variant="body1">
            <b>{variant.bold}</b> {variant.main}
          </Text>
        </Div>
      </Banner>
      <Flex>
        <Tooltip
          title="The code challenge document that was sent to the candidate."
          placement="top"
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={onDownloadProblem}
            mr="40px"
          >
            <FontIcon icon={faDownload} style={{ marginRight: "10px" }} />
            {challenge.product_type === "view"
              ? "View Challenge"
              : "Download Challenge"}
          </Button>
        </Tooltip>
        <Tooltip title="The solution the candidate uploaded." placement="top">
          <Button
            variant="contained"
            color="secondary"
            onClick={onDownloadSubmission}
            mr="40px"
            disabled={!submissionEnabled}
          >
            <FontIcon icon={faDownload} style={{ marginRight: "10px" }} />
            Download Submission
          </Button>
        </Tooltip>

        <Tooltip
          title="The candidate's performance assessment results."
          placement="top"
        >
          <Button
            variant="contained"
            customcolor={theme.palette.success.main}
            onClick={onDownloadReport}
            disabled={challenge.status !== statuses.REPORT_COMPLETE}
          >
            <FontIcon icon={faDownload} style={{ marginRight: "10px" }} />
            Download Report
          </Button>
        </Tooltip>
      </Flex>
    </PageContainer>
  );
};

type OwnProps = {
  match: Match<{ challengeId: string }>;
};

function mapStateToProps(state: any, { match }: OwnProps) {
  const { challengeId } = match.params;
  const challenge = selectors.screenings.entry(state, { id: challengeId });
  return {
    challengeId,
    challenge: challenge.isFilled ? challenge.data : undefined,
  };
}

export default connect(mapStateToProps)(ChallengeDetail);
