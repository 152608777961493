export interface NumberFormats {
  integer: Intl.NumberFormat;
  decimal: Intl.NumberFormat;
  roundedDecimal: Intl.NumberFormat;
  usd: Intl.NumberFormat;
  percent: Intl.NumberFormat;
  percentSingleFraction: Intl.NumberFormat;
  roundedPercent: Intl.NumberFormat;
  roundedUSD: Intl.NumberFormat;
}

function getNumberFormats(
  locales: string | string[] | undefined = undefined
): NumberFormats {
  return {
    integer: new Intl.NumberFormat(locales, { maximumFractionDigits: 0 }),
    decimal: new Intl.NumberFormat(locales, { maximumFractionDigits: 2 }),
    roundedDecimal: new Intl.NumberFormat(locales, {
      maximumFractionDigits: 1,
    }),
    usd: new Intl.NumberFormat(locales, {
      style: 'currency',
      currency: 'USD',
    }),
    percent: new Intl.NumberFormat(locales, {
      style: 'percent',
      maximumFractionDigits: 2,
    }),
    percentSingleFraction: new Intl.NumberFormat(locales, {
      style: 'percent',
      maximumFractionDigits: 1,
    }),
    roundedPercent: new Intl.NumberFormat(locales, {
      style: 'percent',
      maximumFractionDigits: 0,
    }),
    roundedUSD: new Intl.NumberFormat(locales, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }),
  };
}

type As = { as: (format: keyof NumberFormats) => string };
type Format = (num: number) => As;

export function createFormat(
  locales: string | string[] | undefined = undefined
): Format {
  const formats = getNumberFormats(locales);

  return (num: number) => {
    return {
      as: (format: keyof NumberFormats) => formats[format].format(num),
    };
  };
}

export default createFormat();
