import { combineReducers } from "redux";
import { createSelector } from "reselect";
import { emptyPaginatedAPIData } from "utils/pagination";
import { emptyResults, storeResults } from "utils/apiData";
import { handlePageMapData } from "utils/pageMap";
import {
  apiRequestTypes,
  indirectPaginationTypes,
  indirectPaginationStore,
  indirectApiRequestStore,
  indirectApiRequestSelector,
  indirectPaginationSelectors,
} from "utils/types";

export const types = {
  MEMBERS_FOR_ORG: apiRequestTypes("ORGANIZATIONS/MEMBERS_FOR_ORG"),

  INTEGRATIONS_FOR_ORG: apiRequestTypes("ORGANIZATIONS/INTEGRATIONS_FOR_ORG"),

  UPDATE_MEMBER: apiRequestTypes("ORGANIZATIONS/UPDATE_MEMBER"),
  INVITE_MEMBER: apiRequestTypes("ORGANIZATIONS/INVITE_MEMBER"),

  CREATE_GREENHOUSE_INTEGRATION: apiRequestTypes(
    "ORGANIZATIONS/CREATE_GREENHOUSE_INTEGRATION"
  ),
  DELETE_GREENHOUSE_INTEGRATION: apiRequestTypes(
    "ORGANIZATIONS/DELETE_GREENHOUSE_INTEGRATION"
  ),
  RESET_GREENHOUSE_INTEGRATION_KEY: apiRequestTypes(
    "ORGANIZATIONS/RESET_GREENHOUSE_INTEGRATION_KEY"
  ),
};

export const membersStateMachine = indirectPaginationStore(
  types.MEMBERS_FOR_ORG
);

export const integrationsStateMachine = indirectPaginationStore(
  types.INTEGRATIONS_FOR_ORG
);

export default combineReducers({
  members: membersStateMachine,
  integrations: integrationsStateMachine,
  actions: combineReducers({
    updateMember: indirectApiRequestStore(types.UPDATE_MEMBER),
    inviteMember: indirectApiRequestStore(types.INVITE_MEMBER),

    createGreenhouseIntegration: indirectApiRequestStore(
      types.CREATE_GREENHOUSE_INTEGRATION
    ),
    deleteGreenhouseIntegration: indirectApiRequestStore(
      types.DELETE_GREENHOUSE_INTEGRATION
    ),
    resetGreenhouseIntegrationKey: indirectApiRequestStore(
      types.RESET_GREENHOUSE_INTEGRATION_KEY
    ),
  }),
});

export const actions = {
  fetchMembersForOrgPage: ({ id, page, fetchAll, reset }) => ({
    type: types.MEMBERS_FOR_ORG.request,
    id,
    page,
    fetchAll,
    reset,
  }),
  fetchIntegrationsForOrgPage: ({ id, page, fetchAll, reset }) => ({
    type: types.INTEGRATIONS_FOR_ORG.request,
    id,
    page,
    fetchAll,
    reset,
  }),

  updateMemberClear: (args) => ({
    type: types.UPDATE_MEMBER.clear,
    ...args,
  }),
  updateMember: ({ id, role, callback }) => ({
    type: types.UPDATE_MEMBER.request,
    id,
    role,
    callback,
  }),
  inviteMemberClear: (args) => ({
    type: types.INVITE_MEMBER.clear,
    ...args,
  }),
  inviteMember: ({ id, email, callback }) => ({
    type: types.INVITE_MEMBER.request,
    id,
    email,
    callback,
  }),

  createGreenhouseIntegration: (id) => ({
    type: types.CREATE_GREENHOUSE_INTEGRATION.request,
    id,
  }),
  deleteGreenhouseIntegration: (id) => ({
    type: types.DELETE_GREENHOUSE_INTEGRATION.request,
    id,
  }),
  resetGreenhouseIntegrationKey: (id) => ({
    type: types.RESET_GREENHOUSE_INTEGRATION_KEY.request,
    id,
  }),
};

const members = indirectPaginationSelectors(
  (state) => state.organizations.members
);

const integrations = indirectPaginationSelectors(
  (state) => state.organizations.integrations
);

const greenhouseInstalled = createSelector([integrations.list], (list) => {
  for (let integration of list) {
    const { isLoading, isEmpty, data } = integration;
    if (isLoading || isEmpty) {
      continue;
    }

    switch (data.integration_type) {
      case "Greenhouse":
        return true;
      default:
    }
  }
  return false;
});

export const selectors = {
  members,
  integrations,
  greenhouseInstalled,

  updateMemberRequest: indirectApiRequestSelector(
    (state) => state.organizations.actions.updateMember
  ),
  inviteMemberRequest: indirectApiRequestSelector(
    (state) => state.organizations.actions.inviteMember
  ),

  createGreenhouseIntegration: indirectApiRequestSelector(
    (state) => state.organizations.actions.createGreenhouseIntegration
  ),
  deleteGreenhouseIntegration: indirectApiRequestSelector(
    (state) => state.organizations.actions.deleteGreenhouseIntegration
  ),
  resetGreenhouseIntegrationKey: indirectApiRequestSelector(
    (state) => state.organizations.actions.resetGreenhouseIntegrationKey
  ),
};
