import React from 'react';
import {
  MuiProps,
  MuiPropsKeys,
  getElementProps,
} from '../../../types/MaterialUI';
import styled from 'styled-components';
import { MaterialUI } from '../../../styles/MaterialUI';
import { useTheme } from '@mui/material/styles';

export interface Props extends MuiProps {
  /** Set background. Can also provide bgcolor instead */
  background?: string;
  /** background onHover  */
  hoverBackground?: string;
  /** xOffset, yOffset, blur, spread. Can also provide boxShadow instead */
  shadow?: string | number;
  dataTest?: string;
  backgroundImage?: string;
}

interface StyleProps extends MuiProps {
  background?: string;
  hoverBackground?: string;
  backgroundImage?: string;
}

const StyledDiv = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !MuiPropsKeys.includes(prop) && defaultValidatorFn(prop),
})<StyleProps>`
  ${MaterialUI}
  background: ${(props) => props.background};
  background-image: ${(props) =>
    props.backgroundImage ? `url(${props.backgroundImage})` : ''};
  &:hover {
    background: ${(props) => props.hoverBackground};
  }
`;

const DivComponent = React.forwardRef<HTMLDivElement, Props>(
  (props: Props, ref: Ref<HTMLDivElement>): JSX.Element => {
    const {
      className,
      hoverBackground,
      background,
      backgroundColor,
      bgcolor,
      shadow,
      boxShadow,
      dataTest,
      backgroundImage,
      ...potentialPassThroughProps
    } = props;
    const theme = useTheme();
    const passThroughProps = getElementProps(potentialPassThroughProps);

    const styleProps: StyleProps = {
      ...props,
      backgroundColor: backgroundColor || bgcolor,
      background,
      boxShadow: shadow || boxShadow,
      hoverBackground: hoverBackground,
      backgroundImage,
    };

    return (
      <StyledDiv
        theme={theme}
        {...styleProps}
        {...passThroughProps}
        data-test={dataTest || 'div-atom'}
        ref={ref}
        className={className}
      />
    );
  }
);

DivComponent.displayName = 'Div';

export const Div = DivComponent;
