import { all } from "redux-saga/effects";

import { takeAPIRequestSaga } from "utils/apiData";
import {
  takePaginationSaga,
  IndirectPaginatedIDAPIRequest,
} from "utils/pagination";

import { types, Screening, selectors } from "reducers/organizationsV2";

/******************************************************************************
 * Screenings GET/LIST
 *****************************************************************************/
export const listScreenings = takePaginationSaga(
  types.screenings.LIST,
  ({ id }: IndirectPaginatedIDAPIRequest) =>
    `/organizations/${id}/techscreenings/`,
  selectors.screenings.pages
);

export default all([listScreenings]);
