import React from "react";
import { Theme } from "@mui/material/styles";
import withTheme from "@mui/styles/withTheme";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { Div, RouteLink } from "Common";

import { APIData } from "utils/apiData";
import { Product } from "reducers/challengesV2";

dayjs.extend(relativeTime);

export const COLUMNS = [
  {
    label: "ID",
    dataKey: "id",
    width: 300,
  },
  {
    label: "NAME",
    dataKey: "name",
    width: 300,
  },
  {
    label: "LAST UPDATED",
    dataKey: "updated_at",
    width: 75,
  },
];

export type ChallengeCellProps = {
  theme: Theme;
  dataKey: string;
  challenge?: APIData<Product> | null;
  match: {
    path: string;
  };
};

export const ChallengeCell: React.FC<ChallengeCellProps> = ({
  theme,
  dataKey,
  challenge,
  match,
}) => {
  if (!challenge) {
    return null;
  }

  const data = challenge.isFilled && challenge.data;
  if (!data) {
    return null;
  }

  const renderCell = () => {
    switch (dataKey) {
      case "id":
        return data.id;
      case "name":
        return data.name;
      case "updated_at":
        return dayjs(data.updated_at).fromNow();
      default:
        return null;
    }
  };

  return (
    <RouteLink
      to={`${match.path}/${data.id}`}
      textDecoration="none"
      color={theme.palette.common.black}
    >
      <Div pl={2}>{renderCell()}</Div>
    </RouteLink>
  );
};

export default withTheme<
  Theme,
  React.JSXElementConstructor<ChallengeCellProps>
>(ChallengeCell);
