import React from "react";
import { connect } from "react-redux";
import { useTheme } from "@mui/material/styles";

import { selectors, CurrentUser } from "reducers/users";

import Create from "./Create";
import Thanks from "./Thanks";

export type Props = {
  currentUser?: CurrentUser;
};

export const CreateOrganization: React.FC<Props> = ({ currentUser }) => {
  const theme = useTheme();

  if (!currentUser) {
    return null;
  }

  if (!currentUser.profile.requested_org_name) {
    return <Create currentUser={currentUser} />;
  }
  return <Thanks currentUser={currentUser} />;
};

function mapStateToProps(state: any, ownProps: {}) {
  const currentUser = selectors.currentUser(state);
  return {
    currentUser: currentUser.isFilled ? currentUser.data : undefined,
  };
}

export default connect(mapStateToProps)(CreateOrganization);
