import React from 'react';
import { MuiTheme } from '../../../theme';
import { Div, Props as DivProps } from '../Div';

export type Props = Omit<DivProps, 'textAlign' | 'display'>;

const LabelComponent = React.forwardRef<HTMLDivElement, Props>(
  (props: Props, ref: Ref<HTMLDivElement>): JSX.Element => {
    return (
      <Div
        {...props}
        ref={ref}
        dataTest="label-atom"
        textAlign="center"
        display="inline-block"
      />
    );
  }
);

LabelComponent.defaultProps = {
  py: 0.5,
  px: 1,
  fontSize: 14,
  color: MuiTheme.palette.common.white,
  borderRadius: 1,
};

LabelComponent.displayName = 'Label';

export const Label = LabelComponent;
