import React from 'react';
import { Text, Variant } from '../../Atoms/Text';

export type HeaderProps = {
  readonly?: boolean;
  text: string;
  variant?: Variant;
};

export const Header = ({ readonly, text, variant = 'h2' }: HeaderProps) => (
  <Text ml={readonly ? undefined : '28px'} variant={variant}>
    {text}
  </Text>
);
