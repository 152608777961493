import React from 'react';
import { Property } from 'csstype';
import {
  MuiProps,
  MuiPropsKeys,
  getElementProps,
} from '../../../types/MaterialUI';
import styled from 'styled-components';
import { MaterialUI } from '../../../styles/MaterialUI';

export interface Props extends Omit<MuiProps, 'display'> {
  /** Flex direction: row, row-reverse, column, column-reverse, etc. */
  direction?: Property.FlexDirection;
  /** Can also just add center prop which is the same as alignItems="center"  */
  center?: boolean;
  /** xOffset, yOffset, blur, spread */
  shadow?: string | number;
  /** specifies background color */
  background?: string;
  /** flex wrap */
  wrap?: Property.FlexWrap;
  dataTest?: string;
}

const StyledDiv = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !MuiPropsKeys.includes(prop) && defaultValidatorFn(prop),
})<MuiProps>`
  ${MaterialUI}
`;

const FlexComponent = React.forwardRef<HTMLDivElement, Props>(
  (props: Props, ref: Ref<HTMLDivElement>): JSX.Element => {
    const {
      className,
      background,
      bgcolor,
      backgroundColor,
      center,
      justifyContent,
      alignItems,
      direction,
      flexDirection,
      shadow,
      boxShadow,
      wrap,
      flexWrap,
      dataTest,
      ...potentialPassThroughProps
    } = props;

    const passThroughProps = getElementProps(potentialPassThroughProps);

    const styledProps = {
      ...props,
      display: 'flex',
      boxShadow: shadow || boxShadow,
      flexDirection: direction || flexDirection,
      flexWrap: wrap || flexWrap,
      alignItems: center ? 'center' : alignItems,
      backgroundColor: background || backgroundColor || bgcolor,
    };

    return (
      <StyledDiv
        {...styledProps}
        {...passThroughProps}
        ref={ref}
        className={className}
        data-test={dataTest || 'flex-atom'}
      />
    );
  }
);

FlexComponent.displayName = 'Flex';

const RowComponent = React.forwardRef(
  (props: Props, ref: Ref<HTMLDivElement>): JSX.Element => {
    return <Flex {...props} ref={ref} direction={'row'} />;
  }
);

RowComponent.displayName = 'Row';

const ColumnComponent = React.forwardRef(
  (props: Props, ref: Ref<HTMLDivElement>): JSX.Element => {
    return <Flex {...props} ref={ref} direction={'column'} />;
  }
);

ColumnComponent.displayName = 'Col';

export const Flex = FlexComponent;
export const Row = RowComponent;
export const Col = ColumnComponent;
