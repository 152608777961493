import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useTheme } from "@mui/material/styles";
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";

import { APIData } from "utils/apiData";
import queryString from "utils/queryString";

import { actions, selectors, UserAuth, CurrentUser } from "reducers/users";

import { Banner, Button, Card, Flex, PageContainer } from "Common";
import { Text } from "Common/components/Atoms/Text";

export type Props = {
  currentUser?: CurrentUser;
  verifyEmailResponse: APIData<UserAuth>;
};

export const VerifyEmail: React.FC<Props> = ({
  currentUser,
  verifyEmailResponse,
}) => {
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();

  const token = queryString.parse(location.search).token;
  const verifyFailed =
    verifyEmailResponse.isFilled && !verifyEmailResponse.success;
  const showPage = currentUser && (!Boolean(token) || verifyFailed);

  const [verificationResent, setVerificationResent] = useState(false);

  const onReset = () => {
    dispatch(actions.resetEmailVerification());
    setVerificationResent(true);
  };

  // 0 - Verify token if given. This will cause a state update, which makes
  // this route invalid, which will redirect us properly.
  useEffect(() => {
    if (token) {
      dispatch(actions.verifyEmail({ token }));
    }
    return () => {
      dispatch(actions.clearVerifyEmail());
      dispatch(actions.clearResetEmailVerification());
    };
  }, [token, dispatch]);

  return (
    <PageContainer>
      {showPage && (
        <Flex direction="column" justifyContent="center" center>
          <Text variant="h1">Verify Email Address</Text>
          <Card mt="10px" width="500px" p="30px">
            <Flex direction="column">
              {verifyFailed ? (
                <Banner
                  icon={faExclamationCircle}
                  color={theme.palette.error.main}
                  background={theme.palette.grey[100]}
                  mb="20px"
                  style={{ padding: "5px 10px" }}
                >
                  <Text variant="body2" fontSize="14px" ml={2}>
                    This link contains an invalid verification token for the
                    logged-in account. Either log in to the correct account and
                    re-click the verification link or click the button below to
                    be sent a new link.
                  </Text>
                </Banner>
              ) : (
                <Text variant="body2" fontSize="14px" ml={2}>
                  A verification email has been sent to{" "}
                  <strong>{currentUser?.email}</strong>. Please click the link
                  in that email to proceed. Or you can click the button below to
                  be sent a new link.
                </Text>
              )}
              <Button
                mt={2}
                variant="contained"
                color="primary"
                onClick={onReset}
                disabled={verificationResent}
                fullWidth
              >
                {verificationResent
                  ? "Verification Resent!"
                  : "Resend Email Verification"}
              </Button>
            </Flex>
          </Card>
        </Flex>
      )}
    </PageContainer>
  );
};

function mapStateToProps(state: any, ownProps: {}) {
  const verifyEmailResponse = selectors.verifyEmail(state);
  const currentUser = selectors.currentUser(state);

  return {
    currentUser: currentUser.isFilled ? currentUser.data : undefined,
    verifyEmailResponse,
  };
}

export default connect(mapStateToProps)(VerifyEmail);
