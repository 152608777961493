import React, { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { match as Match } from "react-router";
import { connect } from "react-redux";
import { faComment } from "@fortawesome/pro-regular-svg-icons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";

import {
  Button,
  Card,
  FontIcon,
  PageContainer,
  InfiniteLoadingTable,
} from "Common";

import { FilledAPIData, IndirectAPIData } from "utils/apiData";
import { getEntryByIndex, PaginatedAPIData } from "utils/pagination";
import { getMapEntryByIndex } from "utils/pageMapV2";
import { actions, selectors, Product, ProductKey } from "reducers/challengesV2";

import ChallengeCell, { COLUMNS } from "./ChallengeCell";
import NewChallengeModal from "./NewChallengeModal";

export type ProductsListProps = {
  challenges: IndirectAPIData<Product>;
  challengesPages: PaginatedAPIData<Product>;
  parentPath: string;
  match: Match;
};

const TABLE_ROW_HEIGHT = 60;
const TABLE_HEADER_HEIGHT = 48;

export const ChallengeList: React.FC<ProductsListProps> = ({
  challenges,
  challengesPages,
  parentPath,
  match,
}) => {
  const dispatch = useDispatch();
  const [newChallengeModalIsOpen, setNewChallengeModalIsOpen] = useState(false);

  useEffect(() => {
    dispatch(actions.products.list({ fetchAll: true, reset: true }));
  }, [dispatch]);

  const breadcrumbs = [
    {
      name: "Configure",
      url: parentPath,
    },
    {
      name: "Challenges",
    },
  ];

  const { hasData, resultsCount, updateCount } = challengesPages;

  const rowGetter = ({ index }: { index: number }) => {
    return getMapEntryByIndex(challengesPages, challenges, ProductKey, index);
  };

  const isRowLoaded = ({ index }: { index: number }) => {
    return Boolean(rowGetter({ index }));
  };

  const renderCellWithDataKey = ({
    dataKey,
    rowIndex,
  }: {
    dataKey: string;
    rowIndex: number;
  }) => {
    const challenge = rowGetter({ index: rowIndex });
    return (
      <ChallengeCell dataKey={dataKey} challenge={challenge} match={match} />
    );
  };

  const onRowClick = ({
    event,
    index,
  }: {
    event: SyntheticEvent;
    index: number;
  }) => {
    return;
  };

  return (
    <React.Fragment>
      <PageContainer
        breadcrumbs={breadcrumbs}
        pagetitle="Challenges"
        titleChildren={
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setNewChallengeModalIsOpen(true)}
          >
            <FontIcon icon={faPlus} mr={2} />
            New Challenge
          </Button>
        }
      >
        {hasData && resultsCount > 0 && (
          <Card
            height="100vh"
            maxHeight={`${
              TABLE_HEADER_HEIGHT + resultsCount * TABLE_ROW_HEIGHT
            }px`}
            display="block"
          >
            <InfiniteLoadingTable
              columns={COLUMNS}
              rowHeight={TABLE_ROW_HEIGHT}
              isRowLoaded={isRowLoaded}
              loadMoreRows={() => {}}
              rowCount={resultsCount}
              rowGetter={rowGetter}
              cellRenderer={renderCellWithDataKey}
              // Force a re-render every time the page table updates
              updateCount={updateCount}
              onRowClick={onRowClick}
            />
          </Card>
        )}
      </PageContainer>
      {
        <NewChallengeModal
          isOpen={newChallengeModalIsOpen}
          onRequestClose={() => {
            setNewChallengeModalIsOpen(false);

            // Re-fetch challenges so the new one shows up in the list
            dispatch(actions.products.list({ fetchAll: true, reset: true }));
          }}
        />
      }
    </React.Fragment>
  );
};

function mapStateToProps(state: any, ownProps: {}) {
  const challengesPages = selectors.products.pages(state);
  const challenges = selectors.products.entries(state);
  return {
    challenges,
    challengesPages,
  };
}

export default connect(mapStateToProps)(ChallengeList);
