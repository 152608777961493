import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  actions as challengesActions,
  selectors as challengesSelectors,
} from "../../reducers/challenges";
import withTheme from "@mui/styles/withTheme";
import { DialogSelect, Modal, Input, Flex } from "Common";
import { Text } from "Common/components/Atoms/Text";
import { APIResultProp } from "../../utils/apiData";

const skillLevelOptions = [
  { value: "JU", label: "Junior Developer" },
  { value: "MI", label: "Mid-level Developer" },
  { value: "SE", label: "Senior Developer" },
];

const CATEGORY_FRONTEND = "Frontend";
const CATEGORY_BACKEND = "Backend";
const CATEGORY_MOBILE = "Mobile";

const ChallengeCategories = [
  {
    value: CATEGORY_FRONTEND,
    label: "Web Frontend (ie- React, Angular, Vue.js)",
  },
  { value: CATEGORY_BACKEND, label: "Backend (ie- Go, PHP, Python)" },
  { value: CATEGORY_MOBILE, label: "Mobile (ie- Swift, Kotlin, React Native)" },
];

const TYPE_OTHER = "Other";

const ChallengeTypeByCategory = {
  [CATEGORY_FRONTEND]: [
    { value: "React", label: "React" },
    { value: "Vue.js", label: "Vue.js" },
    { value: "AngularJS", label: "AngularJS" },
    { value: "Angular", label: "Angular" },
    { value: "Ember.js", label: "Ember.js" },
    { value: "Backbone", label: "Backbone" },
    { value: TYPE_OTHER, label: TYPE_OTHER },
  ],
  [CATEGORY_BACKEND]: [
    { value: "Node.js", label: "Node.js" },
    { value: "Ruby", label: "Ruby" },
    { value: "PHP", label: "PHP" },
    { value: "Python", label: "Python" },
    { value: "Go", label: "Go" },
    { value: "Java", label: "Java" },
    { value: "Elixir", label: "Elixir" },
    { value: "Rust", label: "Rust" },
    { value: "C#", label: "C#" },
    { value: "C++", label: "C++" },
    { value: TYPE_OTHER, label: TYPE_OTHER },
  ],
  [CATEGORY_MOBILE]: [
    { value: "Swift", label: "Swift" },
    { value: "Objective-C", label: "Objective-C" },
    { value: "React Native", label: "React Native" },
    { value: "Kotlin", label: "Kotlin" },
    { value: TYPE_OTHER, label: TYPE_OTHER },
  ],
};

const STATE_CANDIDATE = "candidate";
const STATE_CHALLENGE = "challenge";
const STATE_REVIEW = "review";

const initialState = {
  error: null,
  formState: STATE_CANDIDATE,
  candidateName: "",
  candidateEmail: "",
  skillLevel: null,
  challengeCategory: null,
  challengeType: null,
  challengeTypeOther: "",
  submitting: false,
};

class ChallengeOrderModal extends React.Component {
  state = initialState;
  resetState() {
    this.setState(initialState);
  }
  componentDidUpdate(prevProps) {
    const { createChallengeStatus, onRequestClose } = this.props;
    const { createChallengeStatus: prevCreateChallengeStatus } = prevProps;
    const { submitting } = this.state;

    // Call succeeded
    if (
      submitting &&
      createChallengeStatus &&
      createChallengeStatus.success &&
      (!prevCreateChallengeStatus || !prevCreateChallengeStatus.success)
    ) {
      onRequestClose();
      this.resetState();
      return;
    }

    // Call failed
    if (
      submitting &&
      createChallengeStatus &&
      createChallengeStatus.success === false &&
      (!prevCreateChallengeStatus || prevCreateChallengeStatus.success === null)
    ) {
      this.setState({ submitting: false, error: "Creation failed" });
    }
  }

  // State machine
  candidateToChallenge = () => {
    const { candidateName, candidateEmail, skillLevel, challengeCategory } =
      this.state;
    if (
      !candidateName ||
      !candidateEmail ||
      !skillLevel ||
      !challengeCategory
    ) {
      this.setState({ error: "Please fill in the options" });
      return;
    }
    this.setState({ formState: STATE_CHALLENGE, error: null });
  };
  challengeToCandidate = () => {
    this.setState({
      formState: STATE_CANDIDATE,
      challengeType: null,
      challengeTypeOther: "",
    });
  };
  challengeToReview = () => {
    const { challengeType, challengeTypeOther } = this.state;
    if (
      !challengeType ||
      (challengeType.value === TYPE_OTHER && !challengeTypeOther)
    ) {
      this.setState({ error: "Please fill in the options" });
      return;
    }
    this.setState({ formState: STATE_REVIEW, error: null });
  };
  reviewToChallenge = () => {
    this.setState({ formState: STATE_CHALLENGE });
  };
  submitChallenge = () => {
    const { organization, createChallenge } = this.props;
    const {
      submitting,
      candidateName,
      candidateEmail,
      skillLevel,
      challengeType,
      challengeTypeOther,
    } = this.state;

    if (submitting) {
      return;
    }

    const challengeTypeValue =
      challengeType.value === TYPE_OTHER
        ? challengeTypeOther
        : challengeType.value;

    createChallenge(
      organization.id,
      candidateName,
      candidateEmail,
      skillLevel.value,
      challengeTypeValue
    );
    this.setState({ submitting: true });
  };

  renderCandidateForm() {
    const { theme } = this.props;
    const {
      candidateName,
      candidateEmail,
      skillLevel,
      challengeCategory,
      error,
    } = this.state;
    const labelProps = {
      mt: "12px",
      variant: "body1",
      color: theme.palette.grey[500],
    };

    return (
      <React.Fragment>
        <Text variant="body1" mb="32px">
          Provide your candidate’s contact information, target skill level, and
          the language or framework you need to assess the candidate for.
        </Text>
        {error && (
          <Text variant="body1" color="error">
            {error}
          </Text>
        )}
        <Text {...labelProps}>Candidate name</Text>
        <Input
          type="text"
          name="candidate_name"
          id="candidate_name"
          placeholder="First Last"
          value={candidateName}
          onChange={(e) => this.setState({ candidateName: e.target.value })}
        />
        <Text {...labelProps}>Candidate email</Text>
        <Input
          type="text"
          name="candidate_email"
          id="candidate_email"
          placeholder="example@gmail.com"
          value={candidateEmail}
          onChange={(e) => this.setState({ candidateEmail: e.target.value })}
        />
        <Text {...labelProps}>Target skill level</Text>
        <DialogSelect
          width="312px"
          placeholder="Select skill level"
          options={skillLevelOptions}
          value={skillLevel}
          onChange={(opt) => {
            this.setState({
              skillLevel: opt,
            });
          }}
        />
        <Text {...labelProps}>Code challenge type</Text>
        <DialogSelect
          width="312px"
          placeholder="Select screening type"
          options={ChallengeCategories}
          value={challengeCategory}
          onChange={(opt) => {
            this.setState({
              challengeCategory: opt,
            });
          }}
        />
      </React.Fragment>
    );
  }
  renderChallengeForm() {
    const { theme } = this.props;
    const { challengeCategory, challengeType, challengeTypeOther, error } =
      this.state;
    const labelProps = {
      mt: "12px",
      variant: "body1",
      customcolor: theme.palette.grey[500],
    };

    return (
      <React.Fragment>
        <Text variant="body1" mb="32px">
          Select the language or framework you need the code challenge written
          for.
        </Text>
        {error && (
          <Text variant="body1" customcolor={theme.palette.error.main}>
            {error}
          </Text>
        )}
        <Text {...labelProps}>Language/Framework</Text>
        <DialogSelect
          width="312px"
          placeholder="Select language/framework"
          options={ChallengeTypeByCategory[challengeCategory.value]}
          value={challengeType}
          onChange={(opt) => {
            this.setState({
              challengeType: opt,
            });
          }}
        />
        {challengeType && challengeType.value === TYPE_OTHER && (
          <React.Fragment>
            <Text {...labelProps}>
              What should the challenge be written for?
            </Text>
            <Input
              type="text"
              name="challenge_other"
              id="challenge_other"
              placeholder="ie - Rust, F#"
              value={challengeTypeOther}
              onChange={(e) =>
                this.setState({ challengeTypeOther: e.target.value })
              }
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
  renderReview() {
    const { theme } = this.props;
    const {
      candidateName,
      candidateEmail,
      skillLevel,
      challengeCategory,
      challengeType,
      challengeTypeOther,
      error,
    } = this.state;
    const labelProps = {
      mt: "12px",
      variant: "body1",
      color: theme.palette.grey[500],
    };

    const challengeTypeText =
      challengeType.value === TYPE_OTHER
        ? challengeTypeOther
        : challengeType.label;
    const screeningTypeText = `${challengeCategory.value}, ${challengeTypeText}`;

    return (
      <React.Fragment>
        <Text variant="body1" mb="32px">
          Confirm your candidate&apos;s contact information and details for the
          code challenge are correct.
        </Text>
        {error && (
          <Text variant="body1" customcolor={theme.palette.error.main}>
            {error}
          </Text>
        )}
        <Text {...labelProps}>Candidate name</Text>
        <Text variant="body1">{candidateName}</Text>
        <Text {...labelProps}>Candidate email</Text>
        <Text variant="body1">{candidateEmail}</Text>
        <Text {...labelProps}>Target skill level</Text>
        <Text variant="body1">{skillLevel.label}</Text>
        <Text {...labelProps}>Code challenge type</Text>
        <Text variant="body1">{screeningTypeText}</Text>
      </React.Fragment>
    );
  }
  render() {
    const { isOpen, onRequestClose, theme } = this.props;
    const { formState } = this.state;

    let title = null;
    let primaryButtonColor = null;
    let primaryButtonLabel = null;
    let onClickPrimaryButton = null;
    let showSecondaryButton = false;
    let secondaryButtonLabel = null;
    let onClickSecondaryButton = null;
    let child = null;

    switch (formState) {
      case STATE_CANDIDATE: {
        title = "Invite candidate";
        primaryButtonLabel = "Next";
        onClickPrimaryButton = this.candidateToChallenge;
        child = this.renderCandidateForm();
        break;
      }
      case STATE_CHALLENGE: {
        title = "Invite candidate";
        primaryButtonLabel = "Next";
        onClickPrimaryButton = this.challengeToReview;
        showSecondaryButton = true;
        secondaryButtonLabel = "Back";
        onClickSecondaryButton = this.challengeToCandidate;
        child = this.renderChallengeForm();
        break;
      }
      case STATE_REVIEW: {
        title = "Verify details and send invite";
        primaryButtonColor = theme.palette.success.main;
        primaryButtonLabel = "Send Invite";
        onClickPrimaryButton = this.submitChallenge;
        showSecondaryButton = true;
        secondaryButtonLabel = "Back";
        onClickSecondaryButton = this.reviewToChallenge;
        child = this.renderReview();
        break;
      }
      default:
        return null;
    }

    return (
      <Modal
        width={600}
        variant="generic"
        isOpen={isOpen}
        title={title}
        primaryButtonColor={primaryButtonColor}
        primaryButtonLabel={primaryButtonLabel}
        showSecondaryButton={showSecondaryButton}
        secondaryButtonLabel={secondaryButtonLabel}
        onClickPrimaryButton={onClickPrimaryButton}
        onClickSecondaryButton={onClickSecondaryButton}
        onRequestClose={onRequestClose}
      >
        <Flex direction="column">{child}</Flex>
      </Modal>
    );
  }
}

ChallengeOrderModal.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  createChallenge: PropTypes.func.isRequired,
  createChallengeStatus: APIResultProp(),
  onRequestClose: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  const createChallengeStatus =
    challengesSelectors.createChallengeStatus(state);

  return {
    createChallengeStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(challengesActions, dispatch);
}

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(ChallengeOrderModal)
);
