import React, { useState } from 'react';
import { Flex } from '../../Atoms/Flex';
import styled from 'styled-components';
import { MuiTheme as theme } from '../../../theme';
import { FontIcon } from '../../Atoms/FontIcon';
import { faStar as faUnfilledStar } from '@fortawesome/pro-light-svg-icons';
import { faStar as faFilledStar } from '@fortawesome/pro-solid-svg-icons';
import { SpacingProps } from '../../../types/MaterialUI';
import { Size } from '../../Atoms/FontIcon';

const ratingDescriptions = [
  'Terrible',
  'It was bad',
  'Neutral',
  'Good, but there were some issues',
  'Great',
];

interface Props extends SpacingProps {
  /** Amount of stars in the meter */
  max?: number;
  /** Starting value */
  value: number;
  /** Callback for star selection */
  onChange?: (value: number) => void;
  /** Callback for star hover */
  onHover?: (value: number) => void;
  /** Size of the stars. Takes any value FontAwesome icons would. See: https://fontawesome.com/how-to-use/on-the-web/styling/sizing-icons */
  starSize?: Size;
  /** Color of the stars */
  starColor?: string;
  /** Color of the unfilled stars */
  unfilledStarColor?: string;
  hoverStarColor?: string;
}

interface State {
  value: number;
  hover: number;
}

function Stars({
  max,
  value,
  onChange,
  onHover,
  starSize,
  starColor,
  unfilledStarColor,
  hoverStarColor,
  ...passThroughProps
}: Props) {
  const [state, setState] = useState<State>({ value, hover: 0 });

  const setValue = (value: number) => {
    setState({ ...state, value: value });
    if (onChange) {
      onChange(value);
    }
  };

  const setHover = (value: number) => {
    setState({ ...state, hover: value });
    if (onHover) {
      onHover(value);
    }
  };

  const setColor = (i: number, state: State) => {
    if (state.hover >= i) {
      return hoverStarColor;
    }
    if (state.value >= i) {
      return starColor;
    } else {
      return unfilledStarColor;
    }
  };

  return (
    <Flex direction="column" {...passThroughProps}>
      {(!max || max === 5) && (
        <Flex height="1.5rem">
          {state.hover > 0 && ratingDescriptions[state.hover - 1]}
        </Flex>
      )}
      <Flex onMouseLeave={() => setHover(0)}>
        {[...Array(max || 5).keys()]
          .map((i) => i + 1)
          .map((i) => (
            <FontIcon
              key={i}
              onClick={() => setValue(i)}
              onMouseEnter={() => setHover(i)}
              icon={
                (state.hover || state.value) >= i
                  ? faFilledStar
                  : faUnfilledStar
              }
              size={starSize}
              color={setColor(i, state)}
            />
          ))}
      </Flex>
    </Flex>
  );
}

Stars.defaultProps = {
  max: 5,
  value: 0,
  starSize: '1x',
  starColor: theme.palette.highlight.main,
  hoverStarColor: theme.palette.highlight.main,
  unfilledStarColor: theme.palette.text.secondary,
  onChange: () => {},
  onHover: () => {},
};

Stars.displayName = 'StarRating';

export const StarRating = styled(Stars)`
  position: relative;
`;
