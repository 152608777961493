import React from 'react';
import { Collapse, Fade, Grow, Slide, Zoom } from '@mui/material';

export type Variant = 'fade' | 'collapse' | 'grow' | 'slide' | 'zoom';

export interface Props {
  variant: Variant;
  show: boolean;
  direction?: 'down' | 'left' | 'right' | 'up';
  timeout?: number;
  collapsedHeight?: number | string;
  children: React.ReactElement;
}

const TransitionComponent: React.FC<Props> = (props: Props): JSX.Element => {
  const { variant, show, children, ...transitionProps } = props;

  return (
    <>
      {variant === 'fade' && (
        <Fade in={show} {...transitionProps}>
          {children}
        </Fade>
      )}
      {variant === 'collapse' && (
        <Collapse in={show} {...transitionProps}>
          {children}
        </Collapse>
      )}
      {variant === 'grow' && (
        <Grow in={show} {...transitionProps}>
          {children}
        </Grow>
      )}
      {variant === 'slide' && (
        <Slide in={show} {...transitionProps}>
          {children}
        </Slide>
      )}
      {variant === 'zoom' && (
        <Zoom in={show} {...transitionProps}>
          {children}
        </Zoom>
      )}
    </>
  );
};

TransitionComponent.displayName = 'Transition';

export const Transition = TransitionComponent;
