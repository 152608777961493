import React from 'react';
import { Flex } from '../../Atoms/Flex';
import { Button } from '../../Atoms/Button';
import { Text } from '../../Atoms/Text';
import { useTheme } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export interface Props {
  action: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  icon?: IconDefinition;
  label?: string;
  buttonLabel?: string;
}

const ActionAlertComponent = React.forwardRef(
  (props: Props, ref: Ref<HTMLDivElement>) => {
    const { action, icon, label, buttonLabel } = props;
    const theme = useTheme();

    return (
      <Flex
        dataTest="actionalert-molecule"
        ref={ref}
        p={1}
        center
        justifyContent="space-around"
        borderRadius="4px"
        background={theme.palette.warning.dim}
        border={`1px solid ${theme.palette.warning.main}`}
      >
        <Text variant="h4" color="warning" mr="5px">
          {label}
        </Text>{' '}
        <Button
          variant="contained"
          disableElevation={true}
          onClick={action}
          customcolor={theme.palette.warning.main}
        >
          <Flex center>
            {icon && <FontAwesomeIcon icon={icon} />}
            <Text variant="body1" color={theme.palette.common.white} ml="5px">
              {buttonLabel}
            </Text>
          </Flex>
        </Button>
      </Flex>
    );
  }
);

ActionAlertComponent.displayName = 'ActionAlert';
ActionAlertComponent.defaultProps = {
  label: 'Heads up! This review is outdated',
  buttonLabel: 'Refresh',
};

export const ActionAlert = ActionAlertComponent;
