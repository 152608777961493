import React from "react";

import SubTemplate from "./SubTemplate";
import BaseTemplate from "./BaseTemplate";

export type TemplateProps = {
  editable?: boolean;
  readOnly?: boolean;
  templateId: string;
  externalRenderers?: {
    [key: string]: any;
  };
  variables?: {
    [key: string]: string;
  };
};

export const Template: React.FC<TemplateProps> = (props) => {
  return (
    <BaseTemplate
      {...props}
      externalRenderers={{
        template: SubTemplate,
        ...props.externalRenderers,
      }}
    />
  );
};

export default Template;
