import React from 'react';
import { Link } from '../../../Atoms/Link/Link';
import { Text } from '../../../Atoms/Text';
import { MuiTheme as theme } from '../../../../theme';
import { ControlledBreadcrumb } from '../Breadcrumb';
import { BreadcrumbWrapper } from '../Wrapper';
import { Property } from 'csstype';
import styled from 'styled-components';

interface StyledProps {
  hover?: string;
}

const StyledLink = styled(Link)<StyledProps>`
  text-decoration: none;
  &:hover {
    color: ${(props) => props.hover};
    text-decoration: none;
  }
`;

export interface Props {
  breadcrumbs: ControlledBreadcrumb[];
  color?: Property.Color;
  hover?: string;
}

const ControlledBreadcrumbsComponent = React.forwardRef<HTMLDivElement, Props>(
  (props: Props, ref: Ref<HTMLDivElement>) => {
    const { breadcrumbs, color, hover } = props;

    return (
      <BreadcrumbWrapper ref={ref} dataTest="breadcrumbs-molecule">
        {breadcrumbs.map((crumb, index) => {
          if (crumb.onClick) {
            return (
              <StyledLink
                hover={hover}
                key={index}
                href={crumb.href}
                fontSize={theme.typography.body1.fontSize}
                customcolor={color}
                lineHeight="20px"
                onClick={crumb.onClick}
                shouldRedirectOnClick={false}
              >
                {crumb.name}
              </StyledLink>
            );
          }

          return (
            <Text key={index} variant="h4" color={color}>
              {crumb.name}
            </Text>
          );
        })}
      </BreadcrumbWrapper>
    );
  }
);

ControlledBreadcrumbsComponent.defaultProps = {
  color: theme.palette.grey[500],
  hover: theme.palette.grey[800],
};

ControlledBreadcrumbsComponent.displayName = 'Breadcrumbs';

export const ControlledBreadcrumbs = ControlledBreadcrumbsComponent;
