import React from 'react';
import { Div } from '../../Atoms/Div';
import { FontIcon } from '../../Atoms/FontIcon';
import { Link } from 'react-router-dom';
import { Text } from '../../Atoms/Text';
import { Avatar } from '../../Atoms/Avatar';
import { Divider, Menu, PopoverOrigin } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const ITEM_HEIGHT = 48;

const StyledLink = styled(Link)<StyleProps>`
  display: flex;
  height: ${ITEM_HEIGHT}px;
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  align-items: center;
  cursor: pointer;
  background-color: ${(props) => props.background ?? 'initial'};
  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }
  &.active {
    background-color: ${({ theme }) => theme.palette.action.selected};
  }
  &:focus {
    outline: 0;
  }
`;

interface StyleProps {
  background?: string;
}

export interface Item {
  /** Display title of link */
  title: string;
  /** Link url */
  url: string;
  /** FontAwesome icon object */
  icon?: IconDefinition;
  /** icon alignment */
  iconAlign?: 'start' | 'end';
  /** show Avatar */
  avatar?: boolean;
  /** image url for Avatar */
  imageUrl?: string;
  /** background for Avatar text fallback */
  avatarTextBackground?: string;
  /** function to execute on click */
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  background?: string;
  active?: boolean;
  divider?: boolean;
}

export interface Props {
  id?: string;
  /** Menu items to display */
  items: Item[];
  /** defines the element to which to anchor the menu */
  anchorEl?: Element | null;
  /** Show or hide the menu */
  show: boolean;
  /** function to hide the menu */
  dismiss: () => void;
  /** maximum number of items to show before scrolling */
  maxItems?: number;
  /** menu zIndex  */
  zIndex?: number;
  /** at which points should the menu attach to the anchor element */
  transformOrigin?: PopoverOrigin;
  /** menu width */
  width?: number | string;
  /** persistent footer item, uses same format as menu items */
  footer?: Item;
}

const MenuItem = ({ item, dismiss }: { item: Item; dismiss: () => void }) => {
  const theme = useTheme();
  return (
    <StyledLink
      to={item.url}
      key={item.url}
      className={item.active ? 'active' : undefined}
      background={item.background}
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        if (item.onClick) {
          item.onClick(e);
        }
        dismiss();
      }}
      data-test="dropmenu-menuitem"
    >
      {item.avatar && (
        <Div mr={2}>
          <Avatar
            src={item.imageUrl}
            alt={item.title}
            color={item.avatarTextBackground || theme.palette.secondary.main}
            size="20px"
            fontSize="12px"
          />
        </Div>
      )}
      {item.icon && (
        <FontIcon
          icon={item.icon}
          size="1x"
          ml={item.iconAlign === 'end' ? 'auto' : 0}
          mr={item.iconAlign === 'end' ? 0 : 2}
          style={{
            order: item.iconAlign === 'end' ? 5 : 0,
          }}
          color={
            item.active
              ? theme.palette.secondary.main
              : theme.palette.text.secondary
          }
        />
      )}
      <Text
        variant="body1"
        flexWrap="nowrap"
        pr={2}
        color={
          item.active
            ? theme.palette.secondary.main
            : theme.palette.text.secondary
        }
        fontWeight={
          item.active
            ? (theme.typography.fontWeightSemiBold as number)
            : (theme.typography.fontWeightRegular as number)
        }
        title={item.title}
      >
        {item.title}
      </Text>
    </StyledLink>
  );
};

export const DividerItem = {
  divider: true,
  title: 'divider',
  url: '',
};

export const DropMenuComponent = (props: Props): JSX.Element => {
  const {
    id,
    show,
    dismiss,
    items,
    anchorEl,
    maxItems,
    transformOrigin,
    width,
    footer,
  } = props;

  const theme = useTheme();
  const numDividers = items.map((item) => item.divider).length;
  const maxHeight = maxItems ? ITEM_HEIGHT * maxItems + numDividers : undefined;

  return (
    <Menu
      id={id}
      anchorEl={anchorEl}
      keepMounted
      open={show}
      onClose={dismiss}
      PaperProps={{
        style: {
          width: Number.isInteger(width) ? `${width}px` : width,
        },
      }}
      transformOrigin={transformOrigin}
      MenuListProps={{
        style: {
          padding: 0,
        },
      }}
      data-test="dropmenu-menu"
    >
      <Div maxHeight={maxHeight} overflow="auto">
        {items.map((item, i) =>
          item.divider ? (
            <Divider key={`divider-${i}`} />
          ) : (
            <MenuItem key={i} item={item} dismiss={dismiss} />
          )
        )}
      </Div>
      {footer && (
        <Div
          borderTop={
            items.length ? `solid 1px ${theme.palette.divider}` : undefined
          }
        >
          <MenuItem item={footer} dismiss={dismiss} />
        </Div>
      )}
    </Menu>
  );
};
DropMenuComponent.displayName = 'DropMenu';
DropMenuComponent.defaultProps = {
  maxItems: 5,
};

export const DropMenu = DropMenuComponent;
