import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { match as Match } from "react-router";
import { Switch, Route, Redirect } from "react-router-dom";

import { PaginatedAPIData } from "utils/pagination";
import { actions, selectors, Product } from "reducers/challengesV2";

import ChallengeList from "./ChallengeList";
import ChallengeView from "./ChallengeView";

export type ChallengesProps = {
  match: Match;
  parentPath: string;
  productPages: PaginatedAPIData<Product>;
};

export const Challenges: React.FC<ChallengesProps> = ({
  match,
  parentPath,
  productPages,
}) => {
  const dispatch = useDispatch();

  // 0 - Clear all products and product entries on unmount
  useEffect(() => {
    return () => {
      dispatch(actions.products.clearGet());
      dispatch(actions.products.clearList());
    };
  }, [dispatch]);

  return (
    <Switch>
      <Route
        exact
        path={match.path}
        render={(props) => {
          return <ChallengeList parentPath={parentPath} {...props} />;
        }}
      />
      <Route
        exact
        path={`${match.path}/:challengeId`}
        render={(props) => {
          return <ChallengeView parentPath={parentPath} {...props} />;
        }}
      />
      <Redirect to={match.path} />
    </Switch>
  );
};

function mapStateToProps(state: any, ownProps: {}) {
  const productPages = selectors.products.pages(state);
  return {
    productPages,
  };
}

export default connect(mapStateToProps)(Challenges);
