import { combineReducers } from "redux";

import {
  APITypes,
  APIRequest,
  apiRequestStore,
  apiRequestSelector,
  APIRequestStoreFunction,
} from "utils/apiData";

// Allow the use of the codeportal-specific login page
export const ALLOW_LOGIN = process.env.REACT_APP_ALLOW_LOGIN === "true";

/******************************************************************************
 * Types
 *****************************************************************************/
export const types = {
  SIGNUP: new APITypes("USERS/SIGNUP"),

  LOGIN: new APITypes("USERS/LOGIN"),
  OAUTH_LOGIN: new APITypes("USERS/OAUTH_LOGIN"),
  LOGOUT: new APITypes("USERS/LOGOUT"),

  RESET_PASSWORD_REQUEST: new APITypes("USERS/RESET_PASSWORD_REQUEST"),
  RESET_PASSWORD: new APITypes("USERS/RESET_PASSWORD"),

  CURRENT_USER: new APITypes("USERS/CURRENT_USER"),
  UPDATE_USER: new APITypes("USERS/UPDATE_USER"),
  UPDATE_PASSWORD: new APITypes("USERS/UPDATE_PASSWORD"),

  VERIFY_EMAIL: new APITypes("USERS/VERIFY_EMAIL"),
  RESET_EMAIL_VERIFICATION: new APITypes("USERS/RESET_EMAIL_VERIFICATION"),

  INVITE_INFO: new APITypes("USERS/INVITE_INFO"),
  ACCEPT_INVITE: new APITypes("USERS/ACCEPT_INVITE"),

  user: {
    CREATE_ORGANIZATION: new APITypes("USERS/USER/CREATE_ORG"),
  },
};

export type FeatureFlags = {
  [flag: string]: boolean;
};

export type UserOrganization = {
  id: string;
  role: string;
  display_name: string;
  feature_flags?: FeatureFlags;
};

export type CurrentUser = {
  first_name: string;
  last_name: string;
  email: string;
  is_staff: boolean;
  profile: {
    name: string;
    photo_url: string | null;
    email_verified: boolean;
    company_name: string | null;
    requested_org_name: string | null;
  };
  pullrequest_dash_url: string;
  feature_flags?: FeatureFlags;
  organizations: Array<UserOrganization>;
};

export type UserAuth = {
  token: string;
  expiry: string;
  user: CurrentUser;
};

export type InviteInfo = {
  organization_name?: string;
  email?: string;
};

/******************************************************************************
 * Requests
 *****************************************************************************/
export type SignUpRequest = APIRequest<UserAuth> & {
  name: string;
  email?: string;
  password: string;
  companyName: string;
  inviteKey?: string;
};

export type LogInRequest = APIRequest<UserAuth> & {
  email: string;
  password: string;
};

export type OauthLogInRequest = APIRequest<UserAuth> & {
  code: string;
  state: string;
};

export type ResetPasswordRequestRequest = APIRequest<null> & {
  email: string;
};

export type ResetPasswordRequest = APIRequest<UserAuth> & {
  email: string;
  token: string;
  password: string;
};

export type UpdateUserRequest = APIRequest<CurrentUser> & {
  name: string;
};

export type UpdatePasswordRequest = APIRequest<UserAuth> & {
  password: string;
  newPassword: string;
};

export type VerifyEmailRequest = APIRequest<CurrentUser> & {
  token: string;
};

export type InviteRequest<T> = APIRequest<T> & {
  inviteKey: string;
};

export type CreateOrganizationRequest = APIRequest<null> & {
  name?: string;
};

/******************************************************************************
 * Store
 *****************************************************************************/

/**
 * Helper store function that simply takes a R (request) type and assumes the T
 * return type is a UserAuth.
 */
export function userAuthStore<R>(
  types: APITypes
): APIRequestStoreFunction<R, UserAuth> {
  return apiRequestStore<R, UserAuth>(types);
}

export default combineReducers({
  signup: userAuthStore<SignUpRequest>(types.SIGNUP),

  login: userAuthStore<LogInRequest>(types.LOGIN),
  oauthLogin: userAuthStore<OauthLogInRequest>(types.OAUTH_LOGIN),
  logout: apiRequestStore<APIRequest<null>, null>(types.LOGOUT),

  resetPasswordRequest: apiRequestStore<ResetPasswordRequestRequest, null>(
    types.RESET_PASSWORD_REQUEST
  ),
  resetPassword: userAuthStore<ResetPasswordRequest>(types.RESET_PASSWORD),

  currentUser: apiRequestStore<APIRequest<null>, CurrentUser>(
    types.CURRENT_USER
  ),
  updateUser: apiRequestStore<UpdateUserRequest, CurrentUser>(
    types.UPDATE_USER
  ),
  updatePassword: userAuthStore<UpdatePasswordRequest>(types.UPDATE_PASSWORD),

  verifyEmail: userAuthStore<VerifyEmailRequest>(types.VERIFY_EMAIL),
  resetEmailVerification: apiRequestStore<APIRequest<null>, null>(
    types.RESET_EMAIL_VERIFICATION
  ),

  inviteInfo: apiRequestStore<InviteRequest<InviteInfo>, InviteInfo>(
    types.INVITE_INFO
  ),
  acceptInvite: apiRequestStore<InviteRequest<null>, null>(types.ACCEPT_INVITE),

  user: combineReducers({
    createOrganization: apiRequestStore<CreateOrganizationRequest, null>(
      types.user.CREATE_ORGANIZATION
    ),
  }),
});

/******************************************************************************
 * Actions
 *****************************************************************************/
export const actions = {
  signup: (request?: SignUpRequest) => ({
    type: types.SIGNUP.request,
    request,
  }),

  login: (request?: LogInRequest) => ({
    type: types.LOGIN.request,
    request,
  }),
  oauthLogin: (request?: OauthLogInRequest) => ({
    type: types.OAUTH_LOGIN.request,
    request,
  }),
  logout: (request?: APIRequest<null>) => ({
    type: types.LOGOUT.request,
    request,
  }),

  clearResetPasswordRequest: (request?: ResetPasswordRequestRequest) => ({
    type: types.RESET_PASSWORD_REQUEST.clear,
    request,
  }),
  resetPasswordRequest: (request?: ResetPasswordRequestRequest) => ({
    type: types.RESET_PASSWORD_REQUEST.request,
    request,
  }),
  clearResetPassword: (request?: ResetPasswordRequest) => ({
    type: types.RESET_PASSWORD.clear,
    request,
  }),
  resetPassword: (request?: ResetPasswordRequest) => ({
    type: types.RESET_PASSWORD.request,
    request,
  }),

  currentUser: (request?: APIRequest<CurrentUser>) => ({
    type: types.CURRENT_USER.request,
    request,
  }),
  clearUpdateUser: (request?: UpdateUserRequest) => ({
    type: types.UPDATE_USER.clear,
    request,
  }),
  updateUser: (request?: UpdateUserRequest) => ({
    type: types.UPDATE_USER.request,
    request,
  }),
  clearUpdatePassword: (request?: UpdatePasswordRequest) => ({
    type: types.UPDATE_PASSWORD.clear,
    request,
  }),
  updatePassword: (request?: UpdatePasswordRequest) => ({
    type: types.UPDATE_PASSWORD.request,
    request,
  }),

  clearVerifyEmail: (request?: VerifyEmailRequest) => ({
    type: types.VERIFY_EMAIL.clear,
    request,
  }),
  verifyEmail: (request?: VerifyEmailRequest) => ({
    type: types.VERIFY_EMAIL.request,
    request,
  }),
  clearResetEmailVerification: (request?: APIRequest<null>) => ({
    type: types.RESET_EMAIL_VERIFICATION.clear,
    request,
  }),
  resetEmailVerification: (request?: APIRequest<null>) => ({
    type: types.RESET_EMAIL_VERIFICATION.request,
    request,
  }),

  inviteInfo: (request?: InviteRequest<InviteInfo>) => ({
    type: types.INVITE_INFO.request,
    request,
  }),
  acceptInvite: (request?: InviteRequest<null>) => ({
    type: types.ACCEPT_INVITE.request,
    request,
  }),

  user: {
    clearCreateOrganization: (request?: CreateOrganizationRequest) => ({
      type: types.user.CREATE_ORGANIZATION.clear,
      request,
    }),
    createOrganization: (request?: CreateOrganizationRequest) => ({
      type: types.user.CREATE_ORGANIZATION.request,
      request,
    }),
  },
};

/******************************************************************************
 * Selectors
 *****************************************************************************/
const selectCurrentUser = apiRequestSelector<CurrentUser>(
  (state) => state.users.currentUser
);
function userOrganization(state: any): UserOrganization | undefined {
  const currentUser = selectCurrentUser(state);
  if (!currentUser.isFilled || !currentUser.data) {
    return undefined;
  }

  const { organizations } = currentUser.data;
  if (organizations.length <= 0) {
    return undefined;
  }

  return organizations[0];
}
function userIntegrationsAllowed(state: any): boolean {
  const organization = userOrganization(state);
  if (!organization) {
    return false;
  }

  const role = organization.role;
  const feature_flags = organization.feature_flags;
  if (!feature_flags) {
    return false;
  }
  return feature_flags.ALLOW_INTEGRATIONS === true && role === "owner";
}

export const selectors = {
  currentUser: selectCurrentUser,

  updateUser: apiRequestSelector<CurrentUser>(
    (state) => state.users.updateUser
  ),
  updatePassword: apiRequestSelector<UserAuth>(
    (state) => state.users.updatePassword
  ),

  signup: apiRequestSelector<UserAuth>((state) => state.users.signup),
  login: apiRequestSelector<UserAuth>((state) => state.users.login),
  oauthLogin: apiRequestSelector<UserAuth>((state) => state.users.oauthLogin),

  logout: apiRequestSelector<null>((state) => state.users.logout),

  resetPasswordRequest: apiRequestSelector<null>(
    (state) => state.users.resetPasswordRequest
  ),
  resetPassword: apiRequestSelector<UserAuth>(
    (state) => state.users.resetPassword
  ),

  verifyEmail: apiRequestSelector<UserAuth>((state) => state.users.verifyEmail),
  resetEmailVerification: apiRequestSelector<null>(
    (state) => state.users.resetEmailVerification
  ),

  inviteInfo: apiRequestSelector<InviteInfo>((state) => state.users.inviteInfo),
  acceptInvite: apiRequestSelector<null>((state) => state.users.acceptInvite),

  user: {
    createOrganization: apiRequestSelector<null>(
      (state) => state.users.user.createOrganization
    ),
    organization: userOrganization,
    integrationsAllowed: userIntegrationsAllowed,
  },
};
