import React, { ReactType, useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { faMarkdown } from '@fortawesome/free-brands-svg-icons';
import { Button } from '../../Atoms/Button';
import { InputArea } from '../../Atoms/InputArea';
import { Div } from '../../Atoms/Div';
import { FontIcon } from '../../Atoms/FontIcon';
import { Tooltip } from '../../Atoms/Tooltip';

// Using require here because the file is in js and otherwise causes an error from missing a ts module.
// Can remove if the file is converted to TypeScript.
const { Markdown } = require('../../Molecules/Markdown');

export type Props = {
  value: string;
  onSave: (value: string) => void;
  placeholder?: string;
  // optional if you want this to be a controlled component for the editing state too
  isEditing?: boolean;
  onEditingStateChange?: (isEditing: boolean) => void;

  // optional if you want to persist the editing state across different components
  editingStorageKey?: string;

  externalPlugins?: any[] | (() => void);
  externalRenderers?: { [nodeType: string]: ReactType };
  rehypeHandlers?: { [nodeType: string]: any };
};

const useStyles = makeStyles((theme) => ({
  tabs: {
    minHeight: 10,
    marginTop: theme.spacing(-3),
  },
  tab: {
    minWidth: 54,
    width: 54,
    fontSize: '0.75em',
    color: theme.palette.text.secondary,
    minHeight: 10,
  },
}));

enum EditTab {
  Write = 0,
  Preview = 1,
}

export const storage: { [key: string]: string } = {};

export const MarkdownInput: React.FC<Props> = ({
  value,
  onSave,
  onEditingStateChange,
  placeholder = 'Write something...',
  externalPlugins,
  externalRenderers,
  rehypeHandlers,
  editingStorageKey,
  isEditing: controlledIsEditing,
}) => {
  const classes = useStyles();

  const storedEditingValue = editingStorageKey && storage?.[editingStorageKey];

  const [unstoredEditingValue, setEditingValue] = useState(
    storedEditingValue ?? value
  );
  const [uncontrolledIsEditing, setUncontrolledIsEditing] = useState(false);
  const [selectedTab, setSelectedTab] = useState<EditTab>(EditTab.Write);

  const editingValue = storedEditingValue ?? unstoredEditingValue;

  const isEditing =
    controlledIsEditing !== undefined
      ? controlledIsEditing
      : uncontrolledIsEditing;

  const updateEditingState = (isEditing: boolean) => {
    setUncontrolledIsEditing(isEditing);
    onEditingStateChange && onEditingStateChange(isEditing);
  };

  const updateEditingValue = (value: string) => {
    setEditingValue(value);
    if (editingStorageKey !== undefined) {
      storage[editingStorageKey] = value;
    }
  };

  const onSaveClick = () => {
    onSave(editingValue);
    updateEditingState(false);
    updateEditingValue('');
    setSelectedTab(EditTab.Write);
  };

  const onCancelClick = () => {
    updateEditingState(false);
    updateEditingValue('');
    setSelectedTab(EditTab.Write);
  };

  const onUpdateText = (event: React.SyntheticEvent<HTMLTextAreaElement>) =>
    updateEditingValue(event.currentTarget.value);

  const onClickMarkdownInput = () => {
    if (!isEditing) {
      updateEditingValue(value);
      updateEditingState(true);
    }
  };

  const markdownText = isEditing ? editingValue : value;

  const markdownInput = (
    <Div
      style={{ cursor: 'text' }}
      border="1px solid #e0e0e0"
      borderRadius="4px"
      padding="10px"
      onClick={onClickMarkdownInput}
      dataTest="markdown-input-container"
    >
      <Markdown
        externalPlugins={externalPlugins}
        externalRenderers={externalRenderers}
        rehypeHandlers={rehypeHandlers}
      >
        {markdownText || placeholder}
      </Markdown>
    </Div>
  );

  if (!isEditing) {
    return <Div>{markdownInput}</Div>;
  }

  return (
    <Div>
      {selectedTab === EditTab.Write && (
        <InputArea
          autoFocus
          value={editingValue}
          onChange={onUpdateText}
          placeholder={placeholder}
        />
      )}
      {selectedTab === EditTab.Preview && markdownInput}
      <Div mt={1} display="flex" alignItems="center">
        <Tabs
          classes={{ root: classes.tabs }}
          value={selectedTab}
          onChange={(e: any, selected: number) => setSelectedTab(selected)}
        >
          <Tab label="Write" disableRipple classes={{ root: classes.tab }} />
          <Tab label="Preview" disableRipple classes={{ root: classes.tab }} />
        </Tabs>
        <Div flexGrow={1} />
        <Tooltip title="Styling with markdown is supported." placement="left">
          <FontIcon icon={faMarkdown} mr={2} color="gray" />
        </Tooltip>
        <Button variant="outlined" onClick={onCancelClick}>
          Cancel
        </Button>
        <Button
          ml={1}
          variant="contained"
          color="secondary"
          onClick={onSaveClick}
        >
          Save
        </Button>
      </Div>
    </Div>
  );
};
