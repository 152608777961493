import React from 'react';
import { Div, Props as DivProps } from '../Div/index';
import { useTheme } from '@mui/material/styles';

export type Props = Omit<DivProps, 'boxShadow' | 'borderRadius'>;

const CardComponent = React.forwardRef<HTMLDivElement, Props>(
  (props: Props, ref: Ref<HTMLDivElement>): JSX.Element => {
    const theme = useTheme();
    return (
      <Div
        {...props}
        ref={ref}
        dataTest={props.dataTest || 'card-atom'}
        boxShadow={3}
        borderRadius={1}
        backgroundColor={
          props.backgroundColor || theme.palette.background.paper
        }
      />
    );
  }
);

CardComponent.displayName = 'Card';

export const Card = CardComponent;
