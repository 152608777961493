import React from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import styled from 'styled-components';
import { Spacing, Typography } from '../../../styles/MaterialUI';
import {
  SpacingProps,
  SpacingPropsKeys,
  TypographyProps,
  TypographyPropsKeys,
} from '../../../types/MaterialUI';
import { useTheme } from '@mui/material/styles';

export interface Props extends SpacingProps, TypographyProps, RouterLinkProps {
  textDecoration?: string;
}

const StyledRouteLink = styled(RouterLink).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !SpacingPropsKeys.includes(prop) &&
    !TypographyPropsKeys.includes(prop) &&
    prop !== 'color' &&
    defaultValidatorFn(prop),
})<Props>`
  ${Spacing}
  ${Typography};
  color: ${(props) => props.color || props.theme.palette.secondary.main};
  &:hover {
    text-decoration: none;
  }
  cursor: pointer;
  text-decoration: ${(props) => props.textDecoration};
`;

const RouteLinkComponent = (props: Props): JSX.Element => {
  const { component, to, replace, innerRef } = props;
  const theme = useTheme();
  return (
    <StyledRouteLink
      {...props}
      component={component}
      to={to}
      replace={replace}
      theme={theme}
      ref={innerRef}
      data-test="routelink-atom"
    >
      {props.children}
    </StyledRouteLink>
  );
};

RouteLinkComponent.displayName = 'RouteLink';

export const RouteLink = RouteLinkComponent;
