import React from 'react';
import { Props as AvatarProps } from '../Avatar';
import MuiChip from '@mui/material/Chip';
import makeStyles from '@mui/styles/makeStyles';
import {
  MuiProps,
  MuiStyleGenerator,
  getElementProps,
} from '../../../types/MaterialUI';
import classNames from 'classnames';

interface BaseProps extends MuiProps {
  /** clickable chips use the ripple effect, pointer cursor */
  clickable?: boolean;
  /** alternate component to render as */
  component?: React.ElementType<any>;
  /** chip color, uses theme primary and secondary colors. These values have to be more specific than MuiProps because of our MuiChip dependency */
  color?: 'default' | 'primary' | 'secondary';
  deleteIcon?: React.ReactElement; // TODO: use props to define type, similar to Avatar (below)
  /** if disabled the chip will be faded and not clickable */
  disabled?: boolean;
  /** text (or node) to display inside the chip */
  label?: React.ReactNode;
  /** function to call when the 'delete' button is clicked; providing this function will make a 'delete' button appear */
  onDelete?: React.EventHandler<any>;
  /** chip size */
  size?: 'small' | 'medium';
  /** chip style. These values have to be more specific than MuiProps because of our MuiChip dependency */
  variant?: 'filled' | 'outlined';
}

/**
 * Material UI throws an error when avatar and icon are provided together.
 * By using 'never' in conjunction with the union operator for these two interfaces,
 * we can force a compile time error if both are provided as props.
 */

interface ChipAvatarProps extends BaseProps {
  /** avatar to show at left side of chip */
  avatar?: React.ReactElement<AvatarProps>;
  icon?: never;
}

interface ChipIconProps extends BaseProps {
  /** icon to show at left side of chip */
  icon?: React.ReactElement; // TODO: use props to define type, similar to ChipAvatarProps
  avatar?: never;
}

export type Props = ChipAvatarProps | ChipIconProps;

const useStyles = makeStyles((theme) => ({
  root: (props: Props) => ({
    ...MuiStyleGenerator(theme, props),
  }),
}));

type Ref =
  | ((instance: HTMLElement | null) => void)
  | React.MutableRefObject<HTMLElement | null>
  | null;

const ChipComponent = React.forwardRef<HTMLElement, Props>(
  (props: Props, ref: Ref): JSX.Element => {
    const {
      clickable,
      component,
      onDelete,
      className,
      deleteIcon,
      disabled,
      label,
      size,
      variant,
      avatar,
      icon,
      color,
      ...potentialPassThroughProps
    } = props;

    const { root } = useStyles(props);
    const classes = classNames(root, className);

    const passThroughProps = getElementProps(potentialPassThroughProps);

    // Remove children from passThroughProps
    const { children, ...otherPassThroughProps } = passThroughProps;
    return (
      <MuiChip
        {...otherPassThroughProps}
        ref={ref}
        data-test="chip-atom"
        clickable={clickable}
        color={color}
        component={component || 'div'}
        className={classes}
        deleteIcon={deleteIcon}
        disabled={disabled}
        onDelete={onDelete}
        label={label}
        size={size}
        variant={variant}
        avatar={avatar}
        icon={icon}
      />
    );
  }
);

ChipComponent.displayName = 'Chip';
ChipComponent.defaultProps = {
  p: 0.5,
  component: 'div',
};

export const Chip = ChipComponent;
