/** Package for replacing newlines of "text" nodes to spaces */

const visit = require("unist-util-visit");

export function noNewlines(options = {}) {
  return (tree: any, file: any) => {
    visit(tree, "text", (node: any) => {
      node.value = node.value.replaceAll("\n", " ");
    });
  };
}
