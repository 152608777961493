import React, { useState } from 'react';
import styled from 'styled-components';
import { Div } from '../../Atoms/Div';
import { Flex } from '../../Atoms/Flex';
import { Avatar } from '../../Atoms/Avatar';
import { Text } from '../../Atoms/Text';
import { NavLink } from 'react-router-dom';
import { Tooltip } from '../../Atoms/Tooltip';
import { FontIcon } from '../../Atoms/FontIcon';
import {
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faCircle,
} from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

const Container = styled(Flex)`
  height: 100%;
  transition: all 0.5s;
  background: ${(props) => props.scheme.background};
`;

const HoverBlock = styled(Flex)`
  cursor: pointer;
  &:hover {
    background: ${(props) => props.scheme.linkHoverBackground};
  }
`;

const MenuLink = styled(NavLink)`
  padding-left: 20px;
  height: 48px;
  box-sizing: border-box;
  color: ${(props) => props.scheme.linkColor};
  font-size: 14px;
  width: 100%;
  text-decoration: none;
  &.active {
    background-color: ${(props) => props.scheme.activeLinkBackground};
    color: ${(props) => props.scheme.activeLinkColor};
    padding-left: 17px;
    border-left: solid 3px ${(props) => props.scheme.activeLinkBorder};
  }
  &:not(.active):hover {
    background-color: ${(props) => props.scheme.linkHoverBackground};
    color: ${(props) => props.scheme.linkHoverColor};
  }
`;

const SubMenuLink = styled(MenuLink)`
  background-color: ${(props) => props.scheme.subMenuLinkBackground};
  padding-left: 17px;
  border-left: solid 3px ${(props) => props.scheme.activeLinkBorder};
  &.active {
    background-color: ${(props) => props.scheme.activeSubMenuLinkBackground};
    color: ${(props) => props.scheme.activeLinkColor};
  }
  &:hover {
    background-color: ${(props) => props.scheme.activeSubMenuLinkBackground};
    color: ${(props) => props.scheme.activeLinkColor};
  }
  &:not(.active):hover {
    background-color: ${(props) => props.scheme.activeSubMenuLinkBackground};
    color: ${(props) => props.scheme.linkHoverColor};
  }
`;

const Overflow = styled(Flex)`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  overflow: hidden;
  white-space: nowrap;
`;

export const SidebarNav = (props) => {
  const {
    onClickHome,
    homeUrl,
    headerIcon,
    headerImageUrl,
    headerName,
    subHeaderImageUrl,
    subHeaderName,
    subHeaderUrl,
    menuItems,
    onCollapse,
    collapsed,
    width,
    location,
    history,
  } = props;

  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  const theme = useTheme();
  const defaultScheme = {
    textColor: theme.palette.text.primary,
    activeLinkColor: theme.palette.secondary.main,
    activeLinkBackground: theme.palette.secondary.dim,
    activeLinkBorder: theme.palette.secondary.main,
    linkColor: theme.palette.text.secondary,
    linkHoverBackground: theme.palette.action.hover,
    linkHoverColor: theme.palette.text.secondary,
    subMenuLinkBackground: '#e7f2fa',
    activeSubMenuLinkBackground: '#d7e9f7',
    background: theme.palette.background.default,
    subHeaderIconColor: theme.palette.secondary.dim,
    borderColor: theme.palette.divider,
    headerIconColor: theme.palette.common.white,
  };
  const scheme = { ...defaultScheme, ...props.scheme };

  return (
    <Container
      width={isCollapsed ? '60px' : width + 'px'}
      minWidth={isCollapsed ? '60px' : width + 'px'}
      direction="column"
      borderRight={`solid 1px ${scheme.borderColor}`}
      scheme={scheme}
    >
      {headerName && (
        <HoverBlock
          direction="column"
          justifyContent="center"
          alignItems="center"
          borderBottom={`solid 1px ${scheme.borderColor}`}
          height={isCollapsed ? '80px' : width}
          scheme={scheme}
          onClick={() => {
            if (onClickHome) {
              onClickHome();
            }
            if (homeUrl) {
              history.push(homeUrl);
            }
          }}
        >
          <Div mb={isCollapsed ? 0 : 1}>
            {headerIcon ? (
              <FontIcon icon={headerIcon} mb={isCollapsed ? 0 : 2} size="3x" />
            ) : (
              <Avatar
                src={headerImageUrl}
                alt={headerName}
                size={isCollapsed ? '44px' : '66px'}
                color={theme.palette.primary.main}
                textcolor={scheme.headerIconColor}
                fontSize={isCollapsed ? '22px' : '36px'}
                cursor="pointer"
              />
            )}
          </Div>
          <Overflow
            width={isCollapsed ? 0 : 'auto'}
            height={isCollapsed ? 0 : 'auto'}
          >
            <Text
              width="100%"
              variant="h3"
              color={scheme.textColor}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              maxWidth={width - 20}
              title={headerName}
            >
              {headerName}
            </Text>
          </Overflow>
        </HoverBlock>
      )}
      {subHeaderName && (
        <HoverBlock
          p={isCollapsed ? '8px' : '12px'}
          justifyContent="flex-start"
          alignItems="center"
          borderBottom={`solid 1px ${scheme.borderColor}`}
          style={{ position: 'relative' }}
          scheme={scheme}
          onClick={() => (subHeaderUrl ? history.push(subHeaderUrl) : null)}
        >
          <Div mr={isCollapsed ? 0 : 1}>
            <Avatar
              src={subHeaderImageUrl}
              variant="rounded"
              size="44px"
              color={scheme.subHeaderIconColor}
              alt={subHeaderName}
            >
              {subHeaderName.charAt(0)}
            </Avatar>
          </Div>
          <Overflow width={isCollapsed ? 0 : 'auto'}>
            <Flex alignItems="center" width="100%">
              <Text
                variant="h4"
                color={scheme.textColor}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                maxWidth={width - 64}
                title={subHeaderName}
              >
                {subHeaderName}
              </Text>
            </Flex>
          </Overflow>
        </HoverBlock>
      )}
      <Flex direction="column" flexGrow={1}>
        {menuItems.map((item) => {
          return (
            <React.Fragment key={`menu-${item.url}`}>
              <Tooltip
                placement="right"
                title={
                  item.submenu || !isCollapsed
                    ? undefined
                    : item.tooltip || item.title
                }
                color={theme.palette.primary.main}
                fontColor={theme.palette.common.white}
                shadow="none"
                hideArrow
              >
                <Flex
                  style={{
                    position: 'relative',
                    cursor: 'pointer',
                    ...item.style,
                  }}
                >
                  <MenuLink
                    exact={!!item.exact}
                    to={item.url}
                    isActive={item.isActive}
                    onClick={item.onClick}
                    scheme={scheme}
                  >
                    <Flex alignItems="center" height="100%">
                      <Div width="16px" mr="15px">
                        <FontIcon icon={item.icon} size="1x" />
                      </Div>
                      <Overflow width={isCollapsed ? 0 : 'auto'}>
                        {item.title}
                      </Overflow>
                      {item.indicator && !isCollapsed && (
                        <FontIcon
                          icon={faCircle}
                          color={
                            item.indicatorColor || theme.palette.error.main
                          }
                          size="xs"
                          ml="auto"
                          mr="20px"
                        />
                      )}
                    </Flex>
                  </MenuLink>
                </Flex>
              </Tooltip>
              {item.submenu &&
                location.pathname.includes(item.url) &&
                item.submenu.map((submenuItem) => {
                  return (
                    <Tooltip
                      key={`submenu-${submenuItem.url}`}
                      title={!isCollapsed ? undefined : submenuItem.title}
                      placement="right"
                      color={theme.palette.primary.main}
                      fontColor={theme.palette.common.white}
                      shadow="none"
                      hideArrow
                    >
                      <SubMenuLink
                        to={submenuItem.url}
                        exact={!!submenuItem.exact}
                        isActive={submenuItem.isActive}
                        onClick={submenuItem.onClick}
                        scheme={scheme}
                      >
                        <Flex alignItems="center" height="100%">
                          {isCollapsed ? (
                            <React.Fragment>
                              <Div width="16px" mr="15px">
                                <FontIcon icon={submenuItem.icon} size="1x" />
                              </Div>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <Div width="31px" />
                              {submenuItem.title}
                            </React.Fragment>
                          )}
                        </Flex>
                      </SubMenuLink>
                    </Tooltip>
                  );
                })}
            </React.Fragment>
          );
        })}
      </Flex>
      <HoverBlock
        p="12px"
        alignItems="center"
        borderTop={`solid 1px ${scheme.borderColor}`}
        onClick={() => {
          setIsCollapsed(!isCollapsed);
          onCollapse();
        }}
        scheme={scheme}
      >
        <Div width="16px" ml={1} mr="15px">
          <FontIcon
            icon={isCollapsed ? faChevronDoubleRight : faChevronDoubleLeft}
            size="sm"
            color={scheme.linkColor}
          />
        </Div>
        <Overflow width={isCollapsed ? 0 : 'auto'}>
          <Text variant="body1" color={scheme.linkColor}>
            Collapse Sidebar
          </Text>
        </Overflow>
      </HoverBlock>
    </Container>
  );
};

SidebarNav.propTypes = {
  /** Callback for when header section is clicked */
  onClickHome: PropTypes.func,
  /** URL of the root page; headerImage links here */
  homeUrl: PropTypes.string,
  /** Icon to use for header rather than an image */
  headerIcon: PropTypes.node,
  /** Avatar image for main header section (org icon) */
  headerImageUrl: PropTypes.string,
  /** Title for main header section (org name) */
  headerName: PropTypes.string,
  /** Avatar image for sub header section (project icon) */
  subHeaderImageUrl: PropTypes.string,
  /** Title for sub header section (project name) */
  subHeaderName: PropTypes.string,
  /** URL to link from the subheader */
  subHeaderUrl: PropTypes.string,
  /** array of menu items */
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.node.isRequired,
      tooltip: PropTypes.string,
      exact: PropTypes.bool,
      isActive: PropTypes.func,
      onClick: PropTypes.func,
      icon: PropTypes.object,
      indicator: PropTypes.bool,
      indidatorColor: PropTypes.string,
      submenu: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string.isRequired,
          title: PropTypes.node.isRequired,
          exact: PropTypes.bool,
          isActive: PropTypes.func,
          onClick: PropTypes.func,
          icon: PropTypes.object,
        })
      ),
    })
  ),
  /** optional color scheme definition; will fall back to default if options are not provided */
  scheme: PropTypes.shape({
    textColor: PropTypes.string,
    activeLinkColor: PropTypes.string,
    activeLinkBackground: PropTypes.string,
    activeLinkBorder: PropTypes.string,
    linkColor: PropTypes.string,
    linkHoverBackground: PropTypes.string,
    linkHoverColor: PropTypes.string,
    subMenuLinkBackground: PropTypes.string,
    activeSubMenuLinkBackground: PropTypes.string,
    background: PropTypes.string,
    subHeaderIconColor: PropTypes.string,
    borderColor: PropTypes.string,
    headerIconColor: PropTypes.string,
  }),
  /** function to fire when sidebar is collapsed */
  onCollapse: PropTypes.func.isRequired,
  /** initial collapse state of sidebar */
  collapsed: PropTypes.bool,
  /** width of sidebar */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** location object passed by route */
  location: PropTypes.object,
  /** history object passed by route; necessary if homeUrl or subHeaderUrl are included */
  history: PropTypes.object,
};

SidebarNav.defaultProps = {
  width: 210,
};
