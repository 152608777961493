import React, { useState } from "react";
import { faPrint } from "@fortawesome/pro-solid-svg-icons";

import { Div, Flex, Button, FontIcon } from "Common";

import Template, { TemplateProps } from "./index";

export const PrintTemplate: React.FC<TemplateProps> = (props) => {
  const [printMode, setPrintMode] = useState(false);

  const flexProps: { [key: string]: string } = {};
  if (!printMode) {
    flexProps.alignItems = "center";
    flexProps.justifyContent = "center";
    flexProps.pt = "50px";
    flexProps.pb = "50px";
  }

  const onPrint = () => {
    setPrintMode(true);

    // Give an arbitrary timeout of 500 ms here so that the screen has a chance
    // to re-render before the print window opens.
    setTimeout(() => {
      window.print();
    }, 500);

    // After the the print window opens, the javascript on the page stops
    // rendering, so any amount of wait at this point will execute after the
    // print completes - either the page is printed or the print is cancelled.
    setTimeout(() => {
      setPrintMode(false);
    }, 2000);
  };

  return (
    <Flex {...flexProps} width="100%">
      <Flex width="800px" direction="column">
        {!printMode ? (
          <Button
            mb={4}
            variant="contained"
            color="primary"
            width="100px"
            onClick={onPrint}
          >
            <FontIcon mr={1} icon={faPrint} />
            Print
          </Button>
        ) : null}
        <Template {...props} />
      </Flex>
    </Flex>
  );
};

export default PrintTemplate;
