import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Switch, Route } from "react-router-dom";

import { Div, Flex } from "Common";

import { APIData } from "utils/apiData";
import { actions, selectors, CurrentUser } from "reducers/users";

import CandidateCodeChallenge from "./candidates";
import OauthCallback from "./OauthCallback";
import AcceptInvite from "./AcceptInvite";
import LoggedInApp from "./LoggedInApp";
import UnauthedApp from "./UnauthedApp";

import Problem from "./dashboard/Problem";

export type AppProps = {
  currentUser: APIData<CurrentUser>;
};

export const App: React.FC<AppProps> = ({ currentUser }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.currentUser());
  }, [dispatch]);

  const isLoggedIn = currentUser.isFilled && currentUser.success;
  const isNotAuthed = currentUser.isFilled && !currentUser.success;
  return (
    <Div height="100%">
      <Switch>
        {/* This is the oauth callback route to complete oauth handshake */}
        <Route path="/login-oauth/" component={OauthCallback} exact />
        <Route
          path="/candidates/codechallenge/:challengeId"
          component={CandidateCodeChallenge}
        />
        <Route path="/accept-invite" exact component={AcceptInvite} />
        {isLoggedIn ? (
          <Route
            exact
            path={`/dash/challenges/:challengeId/problem`}
            component={Problem}
          />
        ) : null}
        {isLoggedIn ? <LoggedInApp /> : null}
        {isNotAuthed ? <UnauthedApp /> : null}
      </Switch>
    </Div>
  );
};

function mapStateToProps(state: any, ownProps: {}) {
  const currentUser = selectors.currentUser(state);
  return {
    currentUser,
  };
}

export default connect(mapStateToProps)(App);
