import React from 'react';
import PropTypes from 'prop-types';
import { InfiniteLoader } from 'react-virtualized';
import VirtualizedTable from '../VirtualizedTable';

export class InfiniteLoadingTable extends React.PureComponent {
  render() {
    const { isRowLoaded, loadMoreRows, minWidth } = this.props;
    return (
      <InfiniteLoader
        isRowLoaded={
          isRowLoaded ||
          (() => {
            return false;
          })
        }
        loadMoreRows={
          loadMoreRows ||
          (() => {
            return null;
          })
        }
        minWidth={minWidth}
        // This can be an arbitrarily large number
        rowCount={1000000}
      >
        {({ onRowsRendered, registerChild }) => (
          <VirtualizedTable
            onRowsRendered={onRowsRendered}
            registerChild={registerChild}
            {...this.props}
          ></VirtualizedTable>
        )}
      </InfiniteLoader>
    );
  }
}

InfiniteLoadingTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  headerHeight: PropTypes.number,
  rowHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  cellRenderer: PropTypes.func,
  /** Required for InfiniteLoading. Tracks loaded state of each row.
   * ({ index: number }): boolean
   */
  isRowLoaded: PropTypes.func,
  /** Callback invoked when more rows must be loaded. It should return a Promise to be resolved once row data has finished loading. It will be used to determine when to refresh the list with the newly loaded data. This may be called multiple times in reaction to a single scroll event. */
  loadMoreRows: PropTypes.func,
  rowCount: PropTypes.number.isRequired,
  rowGetter: PropTypes.func,
  /** Setting a minWidth on the Table will allow for horizontal scrolling within the Table window */
  minWidth: PropTypes.number,
  /** Disables row hover styling (no cursor, no color change) */
  disableRowHover: PropTypes.bool,
};
