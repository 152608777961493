import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Div } from '../../Atoms/Div';
import { Flex } from '../../Atoms/Flex';
import { InfoIcon } from '../../Molecules/InfoIcon';
import { Text } from '../../Atoms/Text';
import { Tooltip } from '../../Atoms/Tooltip';
import {
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { FontIcon } from '../../Atoms/FontIcon';
import { Resizable } from 're-resizable';

export const Container = styled(Flex)`
  height: 100%;
  transition: all 0.3s linear;
  background: ${(props) => props.scheme.background};
`;

export const HoverBlock = styled(Flex)`
  cursor: pointer;
  &:hover {
    background: ${(props) => props.scheme.linkHoverBackground};
  }
  flex-grow: 1;
  width: ${(props) => (props.isCollapsed ? 0 : '100%')};
  padding: ${(props) => (props.isCollapsed ? 0 : '12px')};
  box-sizing: border-box;
  align-self: flex-end;
`;

export const MenuItem = styled.button`
  height: 48px;
  box-sizing: border-box;
  font-size: 14px;
  width: 100%;
  text-decoration: none;
  background-color: ${(props) =>
    props.isActive
      ? props.scheme.activeLinkBackground
      : props.scheme.background};
  color: ${(props) =>
    props.isActive ? props.scheme.activeLinkColor : props.scheme.linkColor};
  padding: ${(props) => (props.isActive ? '0 0 0 17px' : '0 0 0 20px')};
  border: 0;
  border-left: ${(props) =>
    props.isActive ? `3px solid ${props.scheme.activeLinkBorder}` : '0'};
  &:hover {
    background-color: ${(props) =>
      !props.isActive
        ? props.scheme.linkHoverBackground
        : props.scheme.activeLinkBackground};
    color: ${(props) =>
      !props.isActive
        ? props.scheme.linkHoverColor
        : props.scheme.activeLinkColor};
  }
  cursor: pointer;
  outline: none;
`;

const Overflow = styled(Flex)`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  overflow: hidden;
  white-space: nowrap;
`;

export const Menu = styled(Resizable)`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.scheme.background};
  border-right: 1px solid;
  padding-top: 20px;
  overflow: hidden;
`;

export const SidebarMenu = (props) => {
  const {
    menuItems,
    onCollapse,
    collapsed,
    renderMenuContent,
    defaultWidth,
    activeItemKey,
    onClickMenuItem,
    minWidth,
    maxWidth,
  } = props;

  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  const [menuWidth, setMenuWidth] = useState(defaultWidth);
  const [isResizing, setIsResizing] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (activeItemKey && isCollapsed) {
      setIsCollapsed(false);
    }
  }, [activeItemKey, isCollapsed]);

  const defaultScheme = {
    textColor: theme.palette.text.primary,
    activeLinkColor: theme.palette.secondary.main,
    activeLinkBackground: theme.palette.secondary.dim,
    activeLinkBorder: theme.palette.secondary.main,
    linkColor: theme.palette.text.secondary,
    linkHoverBackground: theme.palette.action.hover,
    linkHoverColor: theme.palette.text.secondary,
    background: theme.palette.background.default,
    borderColor: theme.palette.divider,
  };
  const scheme = { ...defaultScheme, ...props.scheme };

  const handleClick = (item) => {
    if (isCollapsed) {
      setIsCollapsed(false);
      onClickMenuItem(item.key);
    } else {
      if (activeItemKey === item.key) {
        setIsCollapsed(true);
        onClickMenuItem(null);
      } else {
        onClickMenuItem(item.key);
      }
    }
    if (item.onClick) {
      item.onClick();
    }
  };

  const activeItem = menuItems.find((item) => {
    return item.key === activeItemKey ? item : null;
  });

  return (
    <Container width="auto" direction="column" scheme={scheme}>
      <Flex height="100%">
        <Flex
          width="60px"
          direction="column"
          borderRight={`1px solid ${scheme.borderColor}`}
          pt="20px"
        >
          {menuItems.map((item) => {
            const notificationColor =
              (item.hasNotification && item.notificationColor) ||
              theme.palette.highlight.main;
            return (
              <Tooltip
                key={`menu-${item.key}`}
                title={item.tooltip || item.title}
                placement="right"
                color={
                  item.hasNotification
                    ? notificationColor
                    : theme.palette.primary.main
                }
                fontColor={theme.palette.common.white}
                shadow="none"
              >
                <Flex
                  style={{
                    position: 'relative',
                    cursor: 'pointer',
                    ...item.style,
                  }}
                >
                  <MenuItem
                    isActive={activeItemKey === item.key && !isCollapsed}
                    onClick={() => handleClick(item)}
                    scheme={scheme}
                  >
                    <Flex alignItems="center" height="100%">
                      <Div width="16px" mr="15px">
                        <FontIcon icon={item.icon} size="1x" />
                        {item.hasNotification && (
                          <FontIcon
                            icon={item.notificationIcon || faExclamation}
                            color={notificationColor}
                            style={{
                              fontSize: '.75em',
                              position: 'absolute',
                              right: '45px',
                              top: '19px',
                            }}
                          />
                        )}
                      </Div>
                    </Flex>
                  </MenuItem>
                </Flex>
              </Tooltip>
            );
          })}
        </Flex>
        {!isCollapsed && (
          <Menu
            scheme={scheme}
            size={{ width: menuWidth }}
            onResizeStart={() => setIsResizing(true)}
            onResizeStop={(e, direction, ref, d) => {
              setMenuWidth(menuWidth + d.width);
              setIsResizing(false);
            }}
            enable={{
              top: false,
              right: true,
              bottom: false,
              left: false,
              topRight: false,
              bottomRight: false,
              bottomLeft: false,
              topLeft: false,
            }}
            style={
              isResizing
                ? { borderRight: `2px solid ${scheme.activeLinkBorder}` }
                : { borderRight: `1px solid ${scheme.borderColor}` }
            }
            minWidth={minWidth}
            maxWidth={maxWidth}
          >
            <Flex direction="column" height="100%" overflow="auto">
              <Flex center>
                <Text
                  variant="body1"
                  ml={2}
                  my={1}
                  fontWeight={theme.typography.fontWeightMedium}
                >
                  {activeItem ? activeItem.title : ''}
                </Text>
                {activeItem && activeItem.infoTooltip && (
                  <Tooltip
                    title={activeItem.infoTooltip}
                    placement="right"
                    width="320px"
                    color={theme.palette.primary.main}
                    fontColor={theme.palette.common.white}
                    shadow="none"
                  >
                    <Div ml={1}>
                      <InfoIcon size="xs" />
                    </Div>
                  </Tooltip>
                )}
              </Flex>
              {!isCollapsed && renderMenuContent(activeItemKey)}
            </Flex>
            <HoverBlock
              alignItems="center"
              borderTop={`solid 1px ${scheme.borderColor}`}
              onClick={() => {
                setIsCollapsed(!isCollapsed);
                onClickMenuItem(null);
                if (onCollapse) {
                  onCollapse();
                }
              }}
              scheme={scheme}
              isCollapsed={isCollapsed}
            >
              <Div width="16px" ml={1} mr="15px">
                <FontIcon
                  icon={
                    isCollapsed ? faChevronDoubleRight : faChevronDoubleLeft
                  }
                  size="sm"
                  color={scheme.linkColor}
                />
              </Div>
              <Overflow width={isCollapsed ? 0 : 'auto'}>
                <Text variant="caption" color={scheme.linkColor}>
                  Collapse Sidebar
                </Text>
              </Overflow>
            </HoverBlock>
          </Menu>
        )}
      </Flex>
    </Container>
  );
};

SidebarMenu.propTypes = {
  /** array of menu items */
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.node.isRequired,
      tooltip: PropTypes.string,
      infoTooltip: PropTypes.string,
      isActive: PropTypes.bool,
      onClick: PropTypes.func,
      icon: PropTypes.object,
    })
  ),
  /** optional color scheme definition; will fall back to default if options are not provided */
  scheme: PropTypes.shape({
    textColor: PropTypes.string,
    activeLinkColor: PropTypes.string,
    activeLinkBackground: PropTypes.string,
    activeLinkBorder: PropTypes.string,
    linkColor: PropTypes.string,
    linkHoverBackground: PropTypes.string,
    linkHoverColor: PropTypes.string,
    background: PropTypes.string,
    subHeaderIconColor: PropTypes.string,
    borderColor: PropTypes.string,
    headerIconColor: PropTypes.string,
  }),
  /** function to fire when sidebar is collapsed */
  onCollapse: PropTypes.func,
  /** initial collapse state of sidebar */
  collapsed: PropTypes.bool,
  /** defaultWidth of Menu component */
  defaultWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** minWidth of Menu component */
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** maxWidth of Menu component */
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** sets the default active menu item for when the menu is expanded */
  activeItemKey: PropTypes.string,
  /** determines the menu content when sidebar is not collapsed */
  renderMenuContent: PropTypes.func.isRequired,
  /** callback when any menu item is clicked */
  onClickMenuItem: PropTypes.func,
};

SidebarMenu.defaultProps = {
  defaultWidth: 300,
  minWidth: 175,
  maxWidth: 625,
};
