import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import withTheme from "@mui/styles/withTheme";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

import {
  Button,
  Div,
  Flex,
  Link,
  Modal,
  PageContainer,
  Toast,
  Tooltip,
  FontIcon,
} from "Common";
import { Text } from "Common/components/Atoms/Text";

import { APIResultProp, finishedLoading } from "utils/apiData";
import { paginatedAPIResultsProp } from "utils/pagination";
import {
  actions as organizationsActions,
  selectors as organizationsSelectors,
} from "reducers/organizations";

import { GreenhouseLogo } from "./GreenhouseCard";

export function CreateKeyModal({
  theme,
  isOpen,
  onRequestClose,
  onClickPrimaryButton,
  onClickSecondaryButton,
}) {
  return (
    <Modal
      variant="alert"
      title="Create Greenhouse Access Key?"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      primaryButtonLabel="Create Access Key"
      primaryButtonColor={theme.palette.success.main}
      onClickPrimaryButton={onClickPrimaryButton}
      showSecondaryButton={true}
      secondaryButtonLabel="Cancel"
      secondaryButtonColor={theme.palette.info.main}
      onClickSecondaryButton={onClickSecondaryButton}
    >
      <Text variant="body1">
        {/* TODO: Figure out correct messaging here */}
        This will create an access key that can be used with the Greenhouse
        application so that it can access the PullRequest code challenges.
      </Text>
    </Modal>
  );
}

export function ResetKeyModal({
  theme,
  isOpen,
  onRequestClose,
  onClickPrimaryButton,
  onClickSecondaryButton,
}) {
  return (
    <Modal
      variant="alert"
      title="Reset Greenhouse Access Key?"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      primaryButtonLabel="Reset"
      primaryButtonColor={theme.palette.success.main}
      onClickPrimaryButton={onClickPrimaryButton}
      showSecondaryButton={true}
      secondaryButtonLabel="Cancel"
      secondaryButtonColor={theme.palette.info.main}
      onClickSecondaryButton={onClickSecondaryButton}
    >
      <Text variant="body1">
        {/* TODO: Figure out correct messaging here */}
        Once you reset the access key, you will have to update your Greenhouse
        integration with the new key.
      </Text>
    </Modal>
  );
}

export function DeleteKeyModal({
  theme,
  isOpen,
  onRequestClose,
  onClickPrimaryButton,
  onClickSecondaryButton,
}) {
  return (
    <Modal
      variant="alert"
      title="Revoke Greenhouse Access Key?"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      primaryButtonLabel="Revoke"
      primaryButtonColor={theme.palette.error.main}
      onClickPrimaryButton={onClickPrimaryButton}
      showSecondaryButton={true}
      secondaryButtonLabel="Cancel"
      secondaryButtonColor={theme.palette.info.main}
      onClickSecondaryButton={onClickSecondaryButton}
    >
      <Text variant="body1">
        {/* TODO: Figure out correct messaging here */}
        Revoking the access key will remove the ability to request code
        challenges through Greenhouse.
      </Text>
    </Modal>
  );
}

export function NewKeyModal({
  theme,
  classes,
  isOpen,
  token,
  onRequestClose,
  onClickPrimaryButton,
}) {
  return (
    <Modal
      variant="alert"
      title="New Access Key"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      primaryButtonLabel="OK"
      primaryButtonColor={theme.palette.info.main}
      onClickPrimaryButton={onClickPrimaryButton}
    >
      <Flex direction="column">
        <Text variant="body1">
          {/* TODO: Figure out correct messaging here */}
          Please update your Greenhouse integration with the following access
          key.
        </Text>
        <Div
          background={theme.palette.grey[200]}
          border={theme.palette.divider}
          borderRadius="4px"
          padding="8px 12px"
          color={theme.palette.text.primary}
          fontSize="14px"
          fontWeight="bold"
          fontFamily="monospace"
          mt="10px"
        >
          {token}
        </Div>
      </Flex>
    </Modal>
  );
}

export function GreenhouseSubtitle({
  greenhouseInstalled,
  theme,
  onInstallKeyClick,
  onResetKeyClick,
  onUninstallKeyClick,
}) {
  return (
    <Flex>
      {!greenhouseInstalled && (
        <Div pt="20px" fontSize="20px">
          <Tooltip title="Connect with Greenhouse" placement="bottom">
            <Button
              variant="contained"
              customcolor={theme.palette.success.main}
              onClick={onInstallKeyClick}
            >
              Connect
            </Button>
          </Tooltip>
        </Div>
      )}
      {greenhouseInstalled && (
        <Div pt="20px" fontSize="20px">
          Installed{" "}
          <FontIcon
            icon={faCheck}
            color={theme.palette.success.main}
            size="1x"
          />
          <Tooltip
            title="Reset the access key for your Greenhouse integration"
            placement="bottom"
          >
            <Button
              ml="20px"
              variant="contained"
              customcolor={theme.palette.text.secondary}
              onClick={onResetKeyClick}
            >
              Reset Key
            </Button>
          </Tooltip>
          <Tooltip
            title="Uninstall your Greenhouse integration"
            placement="bottom"
          >
            <Button
              ml="10px"
              variant="contained"
              customcolor={theme.palette.error.main}
              onClick={onUninstallKeyClick}
            >
              Uninstall
            </Button>
          </Tooltip>
        </Div>
      )}
    </Flex>
  );
}

export class Greenhouse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createKeyModalIsOpen: false,
      resetKeyModalIsOpen: false,
      deleteKeyModalIsOpen: false,
      newKeyModalIsOpen: false,
      token: null,
      error: null,
    };
  }
  componentDidUpdate(prevProps) {
    if (finishedLoading(prevProps.createAction, this.props.createAction)) {
      this.setState({
        createKeyModalIsOpen: false,
        newKeyModalIsOpen: this.props.createAction.success,
        token: this.props.createAction.data?.token,
        error: this.props.createAction.success
          ? null
          : "Error creating Greenhouse access key",
      });
    }
    if (finishedLoading(prevProps.resetAction, this.props.resetAction)) {
      this.setState({
        resetKeyModalIsOpen: false,
        newKeyModalIsOpen: this.props.resetAction.success,
        token: this.props.resetAction.data?.token,
        error: this.props.resetAction.success
          ? null
          : "Error resetting Greenhouse access key",
      });
    }
    if (finishedLoading(prevProps.deleteAction, this.props.deleteAction)) {
      this.setState({
        deleteKeyModalIsOpen: false,
        error: this.props.deleteAction.success
          ? null
          : "Error revoking Greenhouse access key",
      });
    }
  }
  render() {
    const {
      theme,
      organization,
      createAction,
      createGreenhouseIntegration,
      resetAction,
      resetGreenhouseIntegrationKey,
      deleteAction,
      deleteGreenhouseIntegration,
    } = this.props;
    const {
      error,
      token,
      createKeyModalIsOpen,
      resetKeyModalIsOpen,
      deleteKeyModalIsOpen,
      newKeyModalIsOpen,
    } = this.state;
    const breadcrumbs = [
      {
        name: organization.display_name,
        url: `/dash`,
      },
      {
        name: "Settings",
        url: `/dash/settings`,
      },
      {
        name: "Integrations",
        url: `/dash/settings/integrations`,
      },
      {
        name: "Greenhouse",
      },
    ];
    const subtitle = (
      <GreenhouseSubtitle
        {...this.props}
        onInstallKeyClick={() => {
          this.setState({ createKeyModalIsOpen: true });
        }}
        onResetKeyClick={() => {
          this.setState({ resetKeyModalIsOpen: true });
        }}
        onUninstallKeyClick={() => {
          this.setState({ deleteKeyModalIsOpen: true });
        }}
      />
    );
    return (
      <React.Fragment>
        <PageContainer
          pagetitle={<GreenhouseLogo />}
          breadcrumbs={breadcrumbs}
          subtitle={subtitle}
        >
          <Flex direction="column">
            <Text variant="body3" maxWidth="600px">
              <Link
                href="https://greenhouse.io"
                rel="noreferrer noopener"
                target="_blank"
              >
                Greenhouse
              </Link>{" "}
              provides the infrastructure for building out candidate pipelines
              for companies looking to expand their team. With this integration,
              you can automatically send out our world-class code challenges to
              candidates that reach a certain point in your hiring process.
            </Text>
          </Flex>
        </PageContainer>
        <Toast
          color="error"
          severity="error"
          message={error}
          vertical="top"
          horizontal="center"
          showToast={Boolean(error)}
          handleClose={() => {
            this.setState({ error: null });
          }}
        />
        <CreateKeyModal
          isOpen={createKeyModalIsOpen}
          onRequestClose={() => {
            this.setState({ createKeyModalIsOpen: false });
          }}
          primaryButtonDisabled={createAction.isLoading}
          onClickPrimaryButton={() => {
            createGreenhouseIntegration(organization.id);
          }}
          secondaryButtonDisabled={createAction.isLoading}
          onClickSecondaryButton={() => {
            this.setState({ createKeyModalIsOpen: false });
          }}
          theme={theme}
        />
        <ResetKeyModal
          isOpen={resetKeyModalIsOpen}
          onRequestClose={() => {
            this.setState({ resetKeyModalIsOpen: false });
          }}
          primaryButtonDisabled={resetAction.isLoading}
          onClickPrimaryButton={() => {
            resetGreenhouseIntegrationKey(organization.id);
          }}
          secondaryButtonDisabled={resetAction.isLoading}
          onClickSecondaryButton={() => {
            this.setState({ resetKeyModalIsOpen: false });
          }}
          theme={theme}
        />
        <DeleteKeyModal
          isOpen={deleteKeyModalIsOpen}
          onRequestClose={() => {
            this.setState({ deleteKeyModalIsOpen: false });
          }}
          primaryButtonDisabled={deleteAction.isLoading}
          onClickPrimaryButton={() => {
            deleteGreenhouseIntegration(organization.id);
          }}
          secondaryButtonDisabled={deleteAction.isLoading}
          onClickSecondaryButton={() => {
            this.setState({ deleteKeyModalIsOpen: false });
          }}
          theme={theme}
        />
        <NewKeyModal
          isOpen={newKeyModalIsOpen}
          onRequestClose={() => {
            this.setState({ newKeyModalIsOpen: false });
          }}
          primaryButtonDisabled={resetAction.isLoading}
          onClickPrimaryButton={() => {
            this.setState({ newKeyModalIsOpen: false });
          }}
          theme={theme}
          token={token}
        />
      </React.Fragment>
    );
  }
}

Greenhouse.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.string.isRequired,
    display_name: PropTypes.string.isRequired,
  }).isRequired,
  createAction: APIResultProp({
    token: PropTypes.string.isRequired,
  }).isRequired,
  resetAction: APIResultProp({
    token: PropTypes.string.isRequired,
  }).isRequired,
  deleteAction: APIResultProp({
    token: PropTypes.string.isRequired,
  }).isRequired,
  greenhouseInstalled: PropTypes.bool,
  createGreenhouseIntegration: PropTypes.func.isRequired,
  resetGreenhouseIntegrationKey: PropTypes.func.isRequired,
  deleteGreenhouseIntegration: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
};

export function mapStateToProps(state, ownProps) {
  const { organization } = ownProps;
  const createAction = organizationsSelectors.createGreenhouseIntegration(
    state,
    organization.id
  );
  const resetAction = organizationsSelectors.resetGreenhouseIntegrationKey(
    state,
    organization.id
  );
  const deleteAction = organizationsSelectors.deleteGreenhouseIntegration(
    state,
    organization.id
  );
  return {
    createAction,
    resetAction,
    deleteAction,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(organizationsActions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(Greenhouse));
