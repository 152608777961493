import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Theme } from "@mui/material/styles";

import withTheme from "@mui/styles/withTheme";

import { Flex, Modal } from "Common";

import { APIResult } from "utils/Api";
import { APIData } from "utils/apiData";
import { actions, selectors, Product } from "reducers/challengesV2";

import APIRequestFormInput from "components/lib/APIRequestFormInput";
import APIRequestSuccessToast from "components/lib/APIRequestSuccessToast";

export type NewChallengeModalProps = {
  theme: Theme;
  isOpen: boolean;
  onRequestClose: () => void;
  createChallengeResults: APIData<Product>;
};

/**
 * Transform the given name to a slug. Typically, this turns a title of "My
 * Great Challenge" to "my-great-challenge".
 *
 * @param name - name to transform
 * @return slug - resulting slug
 */
export function createSlugForName(name: string): string {
  return name
    .split(/[^a-zA-Z0-9]+/)
    .filter((tok) => Boolean(tok))
    .map((tok) => tok.toLowerCase())
    .join("-");
}

export const NewChallengeModal: React.FC<NewChallengeModalProps> = ({
  theme,
  isOpen,
  onRequestClose,
  createChallengeResults,
}) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const primaryButtonDisabled = createChallengeResults.isLoading;

  const callback = (result: APIResult<Product>) => {
    if (result.success) {
      onRequestClose();
      setName("");
    }
  };

  const onClickPrimaryButton = () => {
    const slug = createSlugForName(name);
    dispatch(actions.products.create({ name, slug, callback }));
  };

  // 0 - Schedule the clearing of "create challenge" on unmount
  useEffect(() => {
    return () => {
      dispatch(actions.products.clearCreate());
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <Modal
        width={400}
        variant="generic"
        title="New Challenge"
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        primaryButtonLabel="Create"
        primaryButtonColor={theme.palette.secondary.main}
        primaryButtonTextColor={theme.palette.common.white}
        primaryButtonDisabled={primaryButtonDisabled}
        onClickPrimaryButton={onClickPrimaryButton}
      >
        <Flex direction="column">
          <APIRequestFormInput
            m="12px 0 0 0"
            id="name"
            errorField="name"
            results={createChallengeResults}
            fullWidth
            placeholder="Challenge Name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Flex>
      </Modal>
      <APIRequestSuccessToast
        results={createChallengeResults}
        message="Success! A new challenge was created."
      />
    </React.Fragment>
  );
};

function mapStateToProps(state: any, ownProps: {}) {
  const createChallengeResults = selectors.products.create(state);
  return {
    createChallengeResults,
  };
}

export default withTheme<
  Theme,
  React.JSXElementConstructor<NewChallengeModalProps>
>(connect(mapStateToProps)(NewChallengeModal)) as any;
