import React from 'react';
import { Header } from './Header';
import { Select } from './Select';
import { Markdown } from './Markdown';
import { Div } from '../Atoms/Div';

export type HeaderFormItem = {
  type: 'header';
  text: string;
};

export type SubtitleFormItem = {
  type: 'subtitle';
  text: string;
};

export type SelectFormOption = {
  value: string;
  label: string;
};

export type SelectFormItem = {
  type: 'select';
  name: string;
  label?: string;
  options: Array<SelectFormOption>;
};

export type MarkdownFormItem = {
  type: 'markdown';
  name: string;
  label?: string;
  optional?: boolean;
  // TODO: add limit support
  // limit: number;
};

export type FormItem =
  | HeaderFormItem
  | SubtitleFormItem
  | SelectFormItem
  | MarkdownFormItem;

export type EditableFormItem = SelectFormItem | MarkdownFormItem;

export const isEditableItem = (item: FormItem): item is EditableFormItem =>
  ['markdown', 'select'].includes((item as EditableFormItem).type);

export const isNonOptionalEditableItem = (
  item: FormItem
): item is MarkdownFormItem => !(item as MarkdownFormItem)?.optional;

export const requiredEditableItemNames = (form: Array<FormItem>) =>
  form
    .filter(isEditableItem)
    .filter(isNonOptionalEditableItem)
    .map((item) => item.name);

export interface BaseProps {
  onUpdate?: (item: FormItem, value: string) => void;
  values?: { [name: string]: string };
  // editing states are not used for every input, but just those that have a "pending"
  // state like the markdown input, where the updates haven't been saved yet.
  editingStates?: { [name: string]: boolean };
  onEditStateChange?: (item: FormItem, isEditing: boolean) => void;
  // persist editing states in-memory across renders with this key to distinguish from other forms
  editingStorageKey?: string;
}

export interface Props extends BaseProps {
  form: Array<FormItem>;
}

const getElementForFormItem = (
  item: FormItem,
  values: { [name: string]: string },
  onUpdate?: (item: FormItem, value: string) => void,
  onEditStateChange?: (item: FormItem, isEditing: boolean) => void,
  editingStates?: { [name: string]: boolean },
  editingStorageKey?: string
) => {
  const onUpdateWithItem = (value: string) => onUpdate && onUpdate(item, value);
  switch (item.type) {
    case 'header':
      return <Header text={item.text} readonly={!onUpdate} />;
    case 'subtitle':
      return (
        <Header text={item.text} variant="subtitle" readonly={!onUpdate} />
      );
    case 'select':
      return (
        <Select
          options={item.options}
          label={item.label}
          // only pass onUpdateWithItem if onUpdate is defined since it indicates read-only mode
          onChange={onUpdate && onUpdateWithItem}
          value={values[item.name]}
        />
      );
    case 'markdown':
      return (
        <Markdown
          label={item.label}
          // only pass onUpdateWithItem if onUpdate is defined since it indicates read-only mode
          onChange={onUpdate && onUpdateWithItem}
          value={values[item.name]}
          isEditing={editingStates && editingStates[item.name]}
          onEditStateChange={(isEditing) =>
            onEditStateChange && onEditStateChange(item, isEditing)
          }
          editingStorageKey={
            editingStorageKey && `${editingStorageKey}__${item.name}`
          }
          optional={item.optional}
        />
      );
  }
};

export const DynamicForm: React.FC<Props> = ({
  form,
  values = {},
  onUpdate,
  onEditStateChange,
  editingStates,
  editingStorageKey,
}) => {
  return (
    <React.Fragment>
      {form.map((item, index) => (
        <SingleFormItem
          key={index}
          item={item}
          values={values}
          onUpdate={onUpdate}
          onEditStateChange={onEditStateChange}
          editingStates={editingStates}
          editingStorageKey={editingStorageKey}
        />
      ))}
    </React.Fragment>
  );
};

interface SingleFormItemProps extends BaseProps {
  item: FormItem;
}

export const SingleFormItem: React.FC<SingleFormItemProps> = ({
  item,
  values = {},
  onUpdate,
  onEditStateChange,
  editingStates,
  editingStorageKey,
}: SingleFormItemProps) => {
  return (
    <Div mt={3}>
      {getElementForFormItem(
        item,
        values,
        onUpdate,
        onEditStateChange,
        editingStates,
        editingStorageKey
      )}
    </Div>
  );
};
