export default {
  hljs: {
    display: 'block',
    overflowX: 'auto',
    padding: '0.5em',
    color: '#c9d1d9',
  },
  'hljs-comment': {
    color: '#768390',
  },
  'hljs-quote': {
    color: '#006a00',
  },
  'hljs-keyword': {
    color: '#f47067',
  },
  'hljs-selector-tag': {
    color: '#b392f0',
  },
  'hljs-literal': {
    color: '#6cb6ff',
  },
  'hljs-name': {
    color: '#c9d1d9',
  },
  'hljs-variable': {
    color: '#f69d50',
  },
  'hljs-template-variable': {
    color: '#f69d50',
  },
  'hljs-string': {
    color: '#96d0ff',
  },
  'hljs-regexp': {
    color: '#96d0ff',
  },
  'hljs-link': {
    color: '#96d0ff',
  },
  'hljs-title': {
    color: '#f47067',
  },
  'hljs-tag': {
    color: '#f47067',
  },
  'hljs-symbol': {
    color: '#f47067',
  },
  'hljs-bullet': {
    color: '#f47067',
  },
  'hljs-number': {
    color: '#f47067',
  },
  'hljs-meta': {
    color: '#f47067',
  },
  'hljs-section': {
    color: '#c9d1d9',
  },
  'hljs-class .hljs-title': {
    color: '#c9d1d9',
  },
  'hljs-type': {
    color: '#c9d1d9',
  },
  'hljs-attr': {
    color: '#c9d1d9',
  },
  'hljs-built_in': {
    color: '#c9d1d9',
  },
  'hljs-builtin-name': {
    color: '#c9d1d9',
  },
  'hljs-params': {
    color: '#c9d1d9',
  },
  'hljs-attribute': {
    color: '#000',
  },
  'hljs-subst': {
    color: '#000',
  },
  'hljs-formula': {
    fontStyle: 'italic',
  },
  'hljs-addition': {
    color: '#57ab5a',
  },
  'hljs-deletion': {
    color: '#c93b36',
  },
  'hljs-selector-id': {
    color: '#9b703f',
  },
  'hljs-selector-class': {
    color: '#9b703f',
  },
  'hljs-doctag': {
    fontWeight: 'bold',
  },
  'hljs-strong': {
    fontWeight: 'bold',
  },
  'hljs-emphasis': {
    fontStyle: 'italic',
  },

  // Pygments style, used for server-rendered code.
  // See Chroma documentation for a description of each class.
  // https://github.com/alecthomas/chroma/blob/e5d9650a20f5a71ead51b9cd995cd98921b8eeaf/types.go#L221
  // TODO: improve base style

  w: {}, // Whitespace
  err: {}, // Error
  x: {}, // Other

  k: { color: '#f47067' }, // Keyword
  kc: { color: '#6cb6ff' }, // KeywordConstant
  kd: { color: '#f47067' }, // KeywordDeclaration
  kn: { color: '#f47067' }, // KeywordNamespace
  kp: { color: '#f47067' }, // KeywordPseudo
  kr: { color: '#f47067' }, // KeywordReserved

  kt: { color: '#f47067' }, // KeywordType

  n: { color: '#c9d1d9' }, // Name

  na: { color: '#6cb6ff' }, // NameAttribute
  nb: { color: '#c9d1d9' }, // NameBuiltin
  nc: { color: '#c9d1d9' }, // NameClass

  no: { color: '#f47067' }, // NameConstant
  nd: { color: '#c9d1d9' }, // NameDecorator
  ni: { color: '#b392f0' }, // NameEntity
  ne: { color: '#c9d1d9' }, // NameException
  nf: { color: '#dcbdfb' }, // NameFunction
  nl: { color: '#c9d1d9' }, // NameLabel
  nn: { color: '#c9d1d9' }, // NameNamespace
  nx: { color: '#c9d1d9' }, // NameOther
  nt: { color: '#c9d1d9' }, // NameTag
  nv: { color: '#f69d50' }, // NameVariable

  bp: { color: '#c9d1d9' }, // NameBuiltinPseudo

  fm: { color: '#f47067' }, // NameFunctionMagic

  py: { color: '#f47067' }, // NameProperty

  vc: { color: '#f69d50' }, // NameVariableClass
  vg: { color: '#f69d50' }, // NameVariableGlobal
  vi: { color: '#f69d50' }, // NameVariableInstance
  vm: { color: '#f69d50' }, // NameVariableMagic

  l: { color: '#b392f0' }, // Literal
  ld: { color: '#b392f0' }, // LiteralDate

  s: { color: '#96d0ff' }, // String
  sa: { color: '#96d0ff' }, // StringAffix
  sb: { color: '#96d0ff' }, // StringBacktick
  sc: { color: '#96d0ff' }, // StringChar
  sd: { color: '#96d0ff' }, // StringDoc
  s2: { color: '#96d0ff' }, // StringDouble
  se: { color: '#96d0ff' }, // StringEscape
  sh: { color: '#96d0ff' }, // StringHeredoc
  si: { color: '#96d0ff' }, // StringInterpol
  sx: { color: '#96d0ff' }, // StringOther
  sr: { color: '#96d0ff' }, // StringRegex
  s1: { color: '#96d0ff' }, // StringSingle
  ss: { color: '#96d0ff' }, // StringSymbol

  dl: { color: '#c41a16' }, // StringDelimiter

  m: { color: '#c9d1d9' }, // Number
  mb: { color: '#c9d1d9' }, // NumberBin
  mf: { color: '#c9d1d9' }, // NumberFloat
  mh: { color: '#c9d1d9' }, // NumberHex
  mi: { color: '#c9d1d9' }, // NumberInteger
  il: { color: '#c9d1d9' }, // NumberIntegerLong
  mo: { color: '#c9d1d9' }, // NumberOct

  o: { color: '#96d0ff' }, // Operator
  ow: {}, // OperatorWord

  p: { color: '#c9d1d9' }, // Punctuation

  c: { color: '#768390' }, // Comment
  ch: { color: '#768390' }, // CommentHashbang
  cm: { color: '#768390' }, // CommentMultiline
  cp: { color: '#768390' }, // CommentPreproc
  cpf: { color: '#768390' }, // CommentPreprocFile

  c1: { color: '#768390' }, // CommentSingle

  cs: {}, // CommentSpecial

  g: {}, // Generic
  gd: {}, // GenericDeleted
  ge: {}, // GenericEmph
  gr: {}, // GenericError
  gh: {}, // GenericHeading
  gi: {}, // GenericInserted
  go: {}, // GenericOutput
  gp: {}, // GenericPrompt
  gs: {}, // GenericStrong
  gu: {}, // GenericSubheading
  gt: {}, // GenericTraceback
  gl: {}, // GenericUnderline
};
