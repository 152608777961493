import React from 'react';
import { Div } from '../../../../Atoms/Div';
import { HeaderFormItem, SubtitleFormItem } from '../../../../DynamicForm';
import { TextField } from '@mui/material';

export interface Props {
  type: 'header' | 'subtitle';
  item?: HeaderFormItem | SubtitleFormItem;
  onUpdate: (
    item: HeaderFormItem | SubtitleFormItem,
    isComplete: boolean
  ) => void;
}

export const LabelItemEditor: React.FC<Props> = ({
  type,
  item,
  onUpdate,
}: Props) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const isComplete = value.length > 0;
    onUpdate(
      {
        type,
        text: value,
      },
      isComplete
    );
  };
  return (
    <Div mt={2}>
      <TextField
        style={{ width: '100%' }}
        required
        label="Text"
        value={item?.text || ''}
        onChange={onChange}
      />
    </Div>
  );
};
