import React from "react";
import { useTheme } from "@mui/material/styles";

import { Div, Flex, UserAvatar } from "Common";

import { CurrentUser } from "reducers/users";

export type Props = {
  currentUser: CurrentUser;
};

export const ProfilePhoto: React.FC<Props> = ({ currentUser }) => {
  const theme = useTheme();
  const initials = `${currentUser.first_name.charAt(
    0
  )}${currentUser.last_name.charAt(0)}`;
  return (
    <Flex direction="column" alignItems="center">
      <Div
        fontSize="13px"
        color={theme.palette.text.secondary}
        fontWeight={600}
        mb="7px"
        mt="10px"
      >
        Profile Image
      </Div>
      <Flex direction="column">
        <UserAvatar
          imageUrl={currentUser.profile.photo_url}
          text={initials}
          size="110px"
          fontSize="48px"
          cursor="pointer"
          textBackground={theme.palette.secondary.dim}
        />
      </Flex>
    </Flex>
  );
};

export default ProfilePhoto;
